/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import StateContext from "../../../store/StateContext";
import noImg from "../assets/images/no-image.svg";

// Global Fucntions
import { get_lang } from "../../../global-functions/apiGlobal";
// redux
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@material-ui/lab";


const Category = ({ category, urlLink }) => {
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:1440px)");

  return (
    <React.Fragment>
       
      { category.has_products ||   category?.products?.length > 0 || appState.storeInfo.subCategory ? (
        <div
          className={` ${appState.langValue == "ar" ? 'Ar' : 'En'}  ${matches ?  'col-6 col-md-4 col-lg-4 col-xl-6  ' : 'col-6 col-md-4 col-lg-4 col-xl-4  '}  p-1`
          }
          id={`category_${category.id}`}
        // id={`category_${category.name.split(" ").join("")}`}
        >
          <div className="inner_prod">
            <Link to={urlLink}>
              <div
                className="prdCardCat"
                style={{
                  background: `url(${category.image != null ? `${category.image}` : `${noImg}`
                    })center center / cover no-repeat`,
                }}
              >
                <h6   className={` ${appState.langValue == "ar" ? 'CategoryNameAr' : 'CategoryNameEn'}`
          }>      
                  <b className="text-capitalize ">{`${category.name != null ? get_lang(category.name, appState.langValue) : "Not Available"}`}</b>
                </h6>
              </div>
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Category;
