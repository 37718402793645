/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import "./deliveryGoogleMapsPopup.scss";
import { useHistory } from "react-router-dom";

// material ui
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  getDineInOrder,
  getPayTableInvoice,
  getTableInvoice,
} from "../../../../redux-store/action";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Avatar, useMediaQuery } from "@material-ui/core";
import noImg from "../../assets/images/no-image.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";
import GoogleMapss from "../googleMap/googleMap";
import Dialog from "@mui/material/Dialog";
import CircularIndeterminate from "../CircularIndeterminate";

function deliveryGoogleMapPopup(props) {
  const { open = false, setOpen } = props;
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:1200px)");

  // This is used only for the example
  const [location, setLocation] = useState(
    appState.deliveryAddsInfo != null
      ? appState.deliveryAddsInfo
      : {
          lat: 24.872130930013252,
          lng: 66.9828699111145,
        }
  );

  useEffect(() => {
    console.log(location);
  }, [location]);
  const handleChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLocation({
      ...location,
      [name]: value,
    });
  };

  const handleClickPay = () => {
    history.push("/checkout");
    appDispatch({
      type: "setDeliveryAddsPopup",
      deliveryAddsPopup: false,
    });
  };
  const handleClickOrder = () => {
    if (appState.storeInfo.iframeUrl != null) {
      history.push("/external");
    } else {
      appDispatch({
        type: "setDeliveryAddsPopup",
        deliveryAddsPopup: false,
      });
      appDispatch({
        type: "setQrOptionStatus",
        qrOptionStatus: false,
      });
    }
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  const handleConfirm = () => {
    const { address, lat, lng } = location;

    if (address && lat && lng) {
      appDispatch({
        type: "setDeliveryAddsInfo",
        deliveryAddsInfo: location,
      });
      appDispatch({
        type: "setDeliveryAddsPopup",
        deliveryAddsPopup: false,
      });
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `Delivery address is missing.`,
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={open}
        className="payTableBackdrop"
        // style={
        //   matches
        //     ? {}
        //     : {
        //         background: `url(${
        //           appState.storeInfo.cover != null
        //             ? `${appState.storeInfo.cover}`
        //             : `${noImg}`
        //         }) center top/100% 73% no-repeat`,
        //       }
        // }
      />
      {/* {open && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 15,
          }}
          open={open}
        >
          <CircularIndeterminate />
        </Backdrop>
      )} */}
      <div
        id="mySidenav"
        

        className={`delivery-google-map pb-3  ${
          appState.langValue == "ar" ? "ArabicDeliveryAddsPopup" : " "
        }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div id="storeTitlePayTable">
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Link to="/">
                    <div>
                      <Avatar
                        variant="square"
                        src={
                          appState.storeInfo.logo
                            ? `${appState.storeInfo.logo}`
                            : `https://api.enabill.dev/storage/app/public/stores/logo/no_image_logo.png`
                        }
                        className="img-fluid"
                      ></Avatar>
                    </div>
                  </Link>

                  <div className="mt-2 px-3">
                    <h1
                      className={`d-flex familyPoppins300 ${
                        appState.langValue === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      {get_lang(
                        appState.storeInfo.businessName,
                        appState.langValue
                      )}
                    </h1>
                  </div>
                </div>
                {appState.langValue == "ar" ? (
                  <Button
                    className={`infoBtn ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "familyManrope600"
                    }`}
                    variant="outlined"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    EN
                  </Button>
                ) : (
                  <Button
                    className={`infoBtn pb-3 ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "classForAr familyGraphikArabic600"
                    }`}
                    onClick={(e) => arLanguageHandle(e)}
                    variant="outlined"
                  >
                    عربي
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <GoogleMapss />

        <div className="container-fluid content px-4 mt-5 confirmBtnClass">
          <div className="row mt-3 px-2">
            {/* {appState.tableInvoice != null && } */}
            <div className="col-6 col-sm-12">
              {location && (
                <Button
                  className={` py-3 px-4  viewMenuBtn  justify-content-between ${
                    appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                  }`}
                  // onClick={handleConfirm}
                  onClick={() => setOpen(!open)}
                >
                  Confirm
                </Button>
              )}
            </div>  
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(deliveryGoogleMapPopup);
