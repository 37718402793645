import {
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatValue } from "../../../../utils/genral-functions";
import "./pickupOptionsBtns.scss";
import axios from "axios";
import moment from "moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../redux-store/store";

const   PickupOptionsBtns = (props) => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch()
  const {setPickupDateTimePopup} = bindActionCreators (actionCreators, appDispatch)
  const { data, availableTime, expanded, setExpanded } = props;

  const [isInRange, setIsInRange] = useState(false);


const handleClickDeliveryOptions = (name) => {
  if(name == "asap"){
    setExpanded(name)
    setPickupDateTimePopup(false)
  }
  else{
    setExpanded(name)
    setPickupDateTimePopup(true)

  }
}


 
  const [deliveryOptionsList, setDeliveryOptionsList] = useState([
    { show: false,
      title: "Delivery ASAP",
      description: "",
      icon: <AccessTimeIcon />,
      name: "asap",
    },
    { show:  false,
      title: "Delivery Later Today",
      description: "Book the order, deliver it later",
      icon: <DeliveryDiningIcon />,
    },
    { show:  appState.storeInfo?.deliveryShecheduled,
      title: "Schedule",
      description:  availableTime?.showDecidedTime || "Book delivery for a specific date and time",
      icon: <CalendarTodayIcon />,
      name: "later",
    },

  ]);

  
  return (
    <section id="pickupOptionsBtns" className="row m-0 py-2">
      <div className="col-12 p-0 d-flex justify-content-evenly align-items-center">
        {deliveryOptionsList.map((item, index) => (
         item.show && <Button  disabled={item.name == 'asap' && !isInRange} variant={expanded == item.name ? "contained" : "outlined"}  className={`${index==0 ? `btn-padding22`: `btn-padding5`} flex-column ${item.name == 'asap' && !isInRange ? 'disabled-btn' : ''} mx-1 ${expanded == item.name ? "option-btn-active" : "optionsBtn"}`} onClick={() => handleClickDeliveryOptions(item.name)} >
          <span>{item.icon}         {t(`${item.title}`)} </span>

         { (availableTime?.showDecidedTime && index==2) ? <span className={`show-time-content`}>{availableTime?.showDecidedTime}</span> : index ==2 ? <span > {t(`Pick Time and Date`)} </span> :<span>  </span> }
       </Button>
        ))}
      </div>
    </section>
  );
};

export default PickupOptionsBtns;
