/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Page from "../components/Page";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { freezOn, freezOff, handleError } from "../../../redux-store/action";
// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";

// redux
import { useSelector, useDispatch } from "react-redux";
import CloseBillOrders from "../components/closeBillOrders/closeBillOrders";
import CheckoutShortCutPopup from "../components/checkoutShortCutPopup/checkoutShortCutPopup";



function CloseBillCheckout({ openOrders }) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();



  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };


  // useEffect(() => {
  //   appDispatch({ type: "setCheckoutShortCutPopup", checkoutShortCutPopup: true })
  // }, [])


  if (false)
    return (
      <>
        <div className="row mx-1">
          <div className="col-1 col-md-1 px-2">
            <Skeleton height={50} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="row mx-1 d-flex justify-content-between">
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
        </div>

        {[0, 1, 2].map((a) => (
          <div key={a} className="row m-0 mb-4 mt-3">
            <div className="col-3 col-md-2 px-2">
              <Skeleton variant="rect" className="prRectSkeleton" />
            </div>
            <div className="col-9 col-md-10 px-2">
              <Skeleton
                height={30}
                style={{
                  transform: "translate(2px, 0)",
                  marginBottom: "10px",
                }}
              />
              <Skeleton height={25} />
              <Skeleton height={25} />
            </div>
          </div>
        ))}
      </>
    );

  // @Functions
  return (
    <Page title="checkout">
      <div id="wrapper">
        <section id="topNav" className="fixed-top">
          <nav className="bckNavbar col-md-12 col-xl-6 offset-xl-3 mr-auto d-flex justify-content-between align-items-center p-0 px-1 ">
            <div className="d-flex align-items-center">
              <Link to="/">
                <IconButton
                  aria-label="back"
                  className={
                    appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                  }
                >
                  {appState.langValue === "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: "0px !important" }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Link>
            </div>
            {/* <p className="text-center ">{get_lang(appState.storeInfo.businessName, appState.langValue)}</p> */}
            <h5 className="mt-2 d-flex">{t("navBar.Checkout")}</h5>

            <div>
              {appState.langValue == "ar" ? (
                <IconButton
                  id="headerBtn"
                  onClick={(e) => enLanguageHandle(e)}
                >
                  <span className={`lang-textScroll p-0`}>EN</span>
                </IconButton>
              ) : (
                <IconButton
                  id="headerBtn"
                  onClick={(e) => arLanguageHandle(e)}
                  className="arabicBtn"
                >
                  <span className={`arabicText lang-textScroll p-0 mb-2`}>
                    عربي{" "}
                  </span>
                </IconButton>
              )}
            </div>
          </nav>

        </section>



        <div id="openOrder" className="mt-5">
          <section id="order-pro" className="mt-4">
            {appState.cart && <h4
              className={` yourOrderhead mb-4 familyPoppins300 ${appState.langValue == "ar" ? "text-right" : "text-left"
                }`}
            >
              {" "}
              {t("open-order.Your-Order")}:
            </h4>}
            {appState.cart.length > 0 && appState.cart != undefined ? (
              appState.cart.map((item, ind) => (
                <div className="w-100" key={ind}>

                  <CloseBillOrders
                    item={item}
                    index={ind}
                  />

                  {ind === appState.cart.length - 1 ? "" : <hr />}
                </div>
              ))
            ) : (
              <div className="d-flex align-items-center justify-content-center flex-column">
                <img className="img-pro" src={`/images/Frame.png`} alt="" />
                <h1> {t("cart.No-items")}</h1>
                <p> {t("cart.You-don�t-have-any-items-in-your-basket")}</p>
              </div>
            )}

          </section>
        </div>
      </div>
     {/* {!appState.paymentPopUp  && <CheckoutShortCutPopup /> }  */}
     <CheckoutShortCutPopup />
    </Page>
  );
}

export default withTranslation()(CloseBillCheckout);
