/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import other components
// import noImg from "../compnent/assets/images/no-image.svg"
import noImg from "../../assets/images/no-image.svg";

// redux
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../redux-store/store";
import { get_lang } from "../../../../global-functions/apiGlobal";
import moment from "moment";



function DeliveryIsNotAvailable() {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { setDeliveryAddsInfo } = bindActionCreators(actionCreators, appDispatch)
  const [timeStatus, setTimeStatus] = useState(false)
  const [time, setTime] = useState({});
  let history = useHistory();
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState("")
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)


  // Set the date we're counting down to

  var currentTime = new Date().getTime();


  const checkingCountDown = () => {
    const countDownDate = moment(appState.deliveryAddsInfo?.start_time, "HH:mm").valueOf();
  setStartTime(moment(appState.deliveryAddsInfo?.start_time, "HH:mm").format("hh:mm A"));
    if (appState.deliveryAddsInfo?.inRange !== true && appState.deliveryAddsInfo?.start_time != '') {
      const x = setInterval(function () {
        // Get today's date and time
        const now = new Date().getTime();
        const distance = Number(Number(countDownDate) - Number(now));
  
        // Time calculations for days, hours, minutes, and seconds
        const days1 = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours1 = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes1 = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds1 = Math.floor((distance % (1000 * 60)) / 1000);
        setDays(days1)
        setHours(hours1)
        setMinutes(minutes1)
        setSeconds(seconds1)

        // Check if the elements exist before updating their content
        // if (document.getElementById("day")) {
        //   document.getElementById("day").innerHTML = days;
        // }
        // if (document.getElementById("hours")) {
        //   document.getElementById("hours").innerHTML = hours;
        // }
        // if (document.getElementById("min")) {
        //   document.getElementById("min").innerHTML = minutes;
        // }
        // if (document.getElementById("sec")) {
        //   document.getElementById("sec").innerHTML = seconds;
        // }
  
        // If the countdown is finished
        if (distance < 0) {
          clearInterval(x);
  
          // if (document.getElementById("day")) {
          //   document.getElementById("day").innerHTML = "EXPIRED";
          // }
          // if (document.getElementById("hours")) {
          //   document.getElementById("hours").innerHTML = "EXPIRED";
          // }
          // if (document.getElementById("min")) {
          //   document.getElementById("min").innerHTML = "EXPIRED";
          // }
          // if (document.getElementById("sec")) {
          //   document.getElementById("sec").innerHTML = "EXPIRED";
          // }
  
          // Update state to reflect the store is available
          setDeliveryAddsInfo({
            ...appState.deliveryAddsInfo,
            inRange: true,
          });
        }
      }, 1000);
  
      return () => clearInterval(x);  // Clean up the interval on component unmount
    }
  };
  // Update the count down every 1 second
  useEffect(() => {
    if (appState.deliveryAddsInfo?.start_time != '') {
      setTimeStatus(true)
      checkingCountDown()
    }
  }, [])


  return (
    <div>
      <div>
        <section id="info" className="bg-transparent">
          <Link className="p-0" to={`/${appState.orderQrCode}`}>
            <div className="row m-0 storeBusyParent justify-content-center align-items-center">
              {/* <div className={` ${appState.langValue === "ar" ? "arrowArabic " : "arrowEng"
                }`} >
                <IconButton
                  onClick={() => history.push(`/${appState.orderQrCode}`)}>
                  {appState.langValue === "ar" ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                </IconButton>
              </div> */}
              <div className="logoImg">
                <img
                  src={
                    appState.storeInfo.logo
                      ? `${appState.storeInfo.logo}`
                      : `${noImg}`
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="infoContent px-2">
                <h4 className="m-0">
                  <b>{get_lang(appState.storeInfo.businessName, appState.langValue)}</b>
                </h4>
              </div>

            </div>
          </Link>
        </section>
        {/* StoreOption component */}
        {/* <StoreOption /> */}

        <div id="storeBusy">
          <div className="storeBusy d-flex flex-column align-items-center">
            <img
              src={`/images/storeBusyNew.svg`}
              className="my-3"
              alt=""
            />
            {/* <h2 className="text-center">{t("Delivery is not available right now.")}</h2> */}
            <h5 className="text-center mt-5">{t("Oops! Delivery is not available right now.")}</h5>
            <p className="text-muted  text-center">
              {t("Service.The-page-you-are-looking-temporarily-unavailable")}
            </p>
            {/* <Link to="/">Back to homepage</Link>x */}
          </div>
          <p className="text-muted text-center mt-3">{t("Delivery will be start soon")} </p>
         { !isNaN(hours) && <h3 className=" text-center" style={{ direction: "ltr" }}>{startTime}</h3>}
        </div>


      {(timeStatus && !isNaN(hours))&&  (
          <div className="timer" style={{direction: "ltr"}}>
            <div className="text-ceter timerContent mb-2" style={{ textAlign: "center" }}>
              <span className="digits">
                <h1 id="day">{days}</h1>

                <span className="text-muted" >{t("Service.Day")}</span>
              </span>

              <span className="digits">
                <h1 id="hours"> {hours} </h1>

                <span className="text-muted">{t("Service.Hours")}</span>
                
              </span>

              <span className="digits mili-sec">
                <h1 id="min"> {minutes} </h1>

                <span className="text-muted">{t("Service.Minutes")}</span>
              </span>

              <span className="digits mili-sec">
                <h1 id="sec"> {seconds} </h1>

                <span className="text-muted" >{t("Service.Seconds")}</span>
              </span>

            </div>
          </div>
        )}
        <section id="reviewOrderButton" className="d-flex align-items-center justify-content-center">
          <div id="process-btn" className="row  col-xl-5 col-md-12 m-0">
            <Button
              onClick={() => history.push(`/${appState.orderQrCode}`)}
              className="col-12 text-capitalize btn-main align-item-center "

            >
              {t("Service.Back")}
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withTranslation()(DeliveryIsNotAvailable);
