// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import noImg from "../assets/images/no-image.svg";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleFeaturedProduct } from "../../../redux-store/action";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { handleAddonsQtyByProduct, handleStockQuantity } from "../../../global-functions/addToCartGlobal";


export const useGetFeaturedProduct = (setIsLoading, addons, quantityValue, setQuantityValue, product,setCheckCart,checkCart,placedBy,setAddNote) => {

	//States:
	const { t } = useTranslation();
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const { mSlug, slug, islug } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let parent_id = searchParams.get("parent");
	const [featuredProduct, setFeaturedProduct] = useState();
	const [stock,setStock] = useState(null)
	let prodTotalQty = 0;

	///// handleFunctions:

	const fetchFeaturedProduct = async () => {
		setIsLoading(true)
		var response = await appState.featuredProducts[0];
		let filterResult = "";
		let filterCart = appState.cart.find( ////// dekh rraha p2
			(item, i) =>  item.product_id == response.id && item.placed_by == placedBy && item.pGroupId == appState.productGroupId
		);

		// console.log("filterCart",filterCart)

		if (filterCart == undefined) {
			filterResult = response;
		} else { ////  dekh rraha p2
			filterResult = response;
			setQuantityValue(filterCart.quantity);
			setAddNote(filterCart.note);
			setCheckCart(true)
		}


		
		filterResult = {
		  ...filterResult,
		  maxQuantity: filterResult.quantity,
		};
		prodTotalQty = await handleStockQuantity (
			appState.cart,
			filterResult,
			appDispatch,
			appState
			);
			if (prodTotalQty > 0) {
				if (filterResult.quantity != null) {
					filterResult.quantity = filterResult.quantity - prodTotalQty;
					filterResult.maxQuantity = filterResult.quantity;
				}
			}
			// console.log(filterResult)
		setFeaturedProduct(filterResult);
		setIsLoading(false)
	}
	///// useffects
	useEffect(() => {

		if (appState.featuredProducts != []) {
		  fetchFeaturedProduct()
		}
	
	  }, [appState.featuredProducts])

	  useEffect(() => {

		if (featuredProduct) {

			const handleStockOnIncrement = async ()=>{
				let prodTotalQty = 0;
				prodTotalQty = await handleStockQuantity (
					appState.cart,
					featuredProduct,
					appDispatch,
					appState
					);
					if (prodTotalQty > 0) {
						if (featuredProduct.quantity != null) {
							if(checkCart){
								let stock = featuredProduct.maxQuantity - (prodTotalQty + (quantityValue-1));
								setStock(stock)
								// console.log("result" ,  featuredProduct.maxQuantity - (prodTotalQty + (quantityValue-1)) )
							}else{
								let stock = featuredProduct.maxQuantity - (prodTotalQty + quantityValue);
								setStock(stock)
								// console.log("result" ,  featuredProduct.maxQuantity - (prodTotalQty + quantityValue) )
							}
					
						}
					}
			}
			handleStockOnIncrement()
		  
		}
	
	  }, [featuredProduct,quantityValue])



	return { featuredProduct,stock };
};