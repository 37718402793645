import React, { useState, useEffect } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const mapStyles = {        
  height: "15vh",
  width: "100%",
  position: 'relative',
  borderRadius: '10px', // Set border-radius here
  overflow: 'hidden',    // Ensures content stays within the rounded corners
  cursor: 'pointer'
};

const GoogleMApp = () => {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const [coordinates, setCoordinates] = useState({
    lat: appState.deliveryAddsInfo?.lat || 29.3759,
    lng: appState.deliveryAddsInfo?.lng || 47.9774
  });

  // Get current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          appDispatch({
            type: "setDeliveryAddsInfo",
            deliveryAddsInfo: {
              ...appState.deliveryAddsInfo,
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          });
        },
        (error) => {
          console.error("Error getting location: ", error);
          // You can handle location errors here (e.g., default to a certain location)
        }
      );
    }
  }, []);

  // Google Maps Static URL with dynamic coordinates
  const staticMapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates.lat},${coordinates.lng}&zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:C%7C${coordinates.lat},${coordinates.lng}&key=AIzaSyABahfPxb8GWRHM731v5QfZesxyEypXuGc`;

  return (
    <div style={mapStyles} onClick={() => { history.push('/map') }} >
      <img 
        src={staticMapImageUrl} 
        alt={`Static map centered on coordinates (${coordinates.lat}, ${coordinates.lng})`} 
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
      
      {/* Sticky Marker */}
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '10',
        pointerEvents: 'none'
      }}>
        <LocationOnIcon style={{ fontSize: '50px', color: appState.storeInfo?.primaryColor }} />
      </div>
    </div>
  );
}

export default GoogleMApp;
