// React Components
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import CustomizedDialogs from "../../components/CustomizedDialogs";
import Page from "../../components/Page";
import LoginDialog from "../../components/LoginModal";
import LoginModal from "../../../../loginSignUp/loginModal/loginModal";
// material ui
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import { styled } from "@mui/system";
import { withTranslation, useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";

import { getCookie, setCookie, erase } from "../../Functions/cookieFunction";
import GoSellElementsTrans from "../../components/GoSell";
import EditIcon from "@mui/icons-material/Edit";
import { ApplePayButton } from "react-apple-pay-button";
import Accordion from 'react-bootstrap/Accordion';


// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  freezOff,
  getSaveCard,
  cleanRest,
  resetSplitOptions,
  closePayTable,
  handleError,
  getPayTableInvoice,
  getPayTableInvoiceNoOrders,
  pushShopMode,
  popShopMode,
} from "../../../../redux-store/action";
import SaveCard from "../../components/SaveCard";
// Global Fucntions
import { get_lang } from "../../../../global-functions/apiGlobal";
import SplitBillOptions from "../../../../sharedComponent/splitBillOptions/splitBillOptions";
import PayForItems from "../../../../sharedComponent/payForItems/payForItems";
import DivideBill from "../../../../sharedComponent/divideBill/divideBill";
import PayCustomAmount from "../../../../sharedComponent/payCustomAmount/payCustomAmount";
import AddTipCustom from "../../../../sharedComponent/AddTipCustom/AddTipCustom";
import TopUpPopUp from "../../../../sharedComponent/TopUpPopUp/TopUpPopUp";
import { getApplePayToken } from "../../../../global-functions/applePay";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplayIcon from "@mui/icons-material/Replay";
import RestoreIcon from "@mui/icons-material/Restore";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import {
  handleInvoice,
  handleUserInfo,
} from "../../../../global-functions/customeObj";
import PaymentMethods from "../../components/paymentMethods/paymentMethods";
import {
  parseFloatFixed,
  parseNumber,
  roundFixed,
} from "../../../../global-functions/roundFixed";
import CheckoutNoOrder from "../CheckoutNoOrder/CheckoutNoOrder";
import { getLiveData } from "../../../../global-functions/pusher";
import {
  handleCalculatedSubtotal,
  handleTapCharges,
} from "../../../../global-functions/charges";
import { handleOrdersPayload } from "../../../../global-functions/addToCartGlobal";
import SelectLoyaltyCardPopup from "../../components/selectLoyaltyCardPopup/selectLoyaltyCardPopup";
import DiscountOptions from "../../components/promoVocher/DiscountOptions";
import { getLoyaltyCardDiscount } from "../../../../redux-store/action/apiCall";
import { isNotEmpty } from "../../../../utils";
import Vouceher from "../../components/promoVocher/Vouceher";
import { handleSplitPayements, handleStoreDiscount } from ".";
import LoyalitySlider from "../../components/loyalitySlider/loyalitySlider";
import SelectLoyalityCardSlider from "../../components/selectLoyalityCardSlider/selectLoyalityCardSlider";
import { getServiceName } from "../../../../global-functions/variables";
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#052d72",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
}));

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

function CheckOutOne({ oldInvoice }) {
  const { t } = useTranslation();
  let history = useHistory();
  const appState = useSelector((state) => state);
  const {
    storeInfo: { numberFixed, storeCurrency, roundLevel, roundMethod },
  } = appState;
  const appDispatch = useDispatch();
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`)) != null
      ? JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`))
      : []
  );
  const [saveCardId, setSaveCardId] = useState(null);
  const [orders] = useState(appState.cart != null ? appState.cart : "");
  const [openBillOrders, setOpenBillOrders] = useState([]);
  const { payments } = appState.newApi;
  const classes = useStyles();
  var payArray = [
    "knet",
    "kfast",
    "cash",
    "wallet",
    "card",
    "partial",
    "apple_pay",
  ];
  const childRef = useRef();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pLoading, setPLoading] = useState(false);
  const [promoStatus, setPromoStatus] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [open, setOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoAmount, setPromoAmount] = useState(0);
  const [tapId, setTapId] = useState("");
  const [tapStatus] = useState(!appState.storeInfo.tap);
  // login work
  const [loginOpen] = useState(false);
  const [customeShow, setCustomeShow] = useState(false);
  const [manualTip, setManualTip] = useState(0);
  const [manualTipBool, setManualTipBool] = useState(true);
  const [customeTip, setCustomeTip] = useState(0);
  const [splitBillAmount, setSplitBillAmount] = useState(0);
  const [personWillPay, setPersonWillPay] = useState(1);
  const [totalPerson, setTotalPerson] = useState(2);
  const [partialType, setPartialType] = useState(null);
  const [paySelectedItems, setPaySelectedItems] = useState([]);
  const [billTotal, setBillTotal] = useState(appState.subTotal);
  const [metaObj, setMetaObj] = useState([]);
  const [tipLoading, setTipLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [hangTip, setHangTip] = useState(0);
  const [tips, setTips] = useState([]);
  const [openBillTotal, setOpenBillTotal] = useState(0);
  const ApplePaySession = window.ApplePaySession;
  const [showTableOrder, setShowTableOrder] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [appleToken, setAppleToken] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showLoyaltyCardDetails, setShowLoyaltyCardDetails] = useState(false);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [checkedSaveCard, setCheckedSaveCard] = useState(false);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [campaignDiscount, setCampaignDiscount] = useState(0);
  const [hotelDiscount, setHotelDiscount] = useState(0);
  const [punchDiscount, setPunchDiscount] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [checkoutByCash, setCheckoutByCash] = useState(false);
  // const [discount, setDiscount] = useState(0);

  const [storeDiscount, setStoreDiscount] = useState(0);
  const [orderUpdateCount, setOrderUpdateCount] = useState(0);
  const [timer, setTimer] = useState(30);
  const [retryAgain, setRetryAgain] = useState(false);
  const [loyaltyCardId, setLoyaltyCardId] = useState(null);
  const [showPromocode, setShowPromocode] = useState(true);
  const [showVoucher, setShowVoucher] = useState(false);
  const [dOptionValue, setDOptionValue] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [voucherObj, setVoucherObj] = useState([]);
  const [rewardedDiscount, setRewardedDiscount] = useState(0);
  const [orderDetails, setOrderDetails] = useState([]);
  const [rewardsLoading, setRewardsLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const {
    totalAmount = 0,
    subTotalAmount = 0,
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    tip = 0,
    charges = [],
    currentInvoiceStatus = 0,
    paidAmount = 0,
    leftToPay = 0,
    fullAmount = 0,
    rewardsDiscount = 0,
  } = appState.invoiceTaxesChargesExtras;
  const handleClickLogin = () => {
    // appDispatch({ type: "setLoginModal", loginModal: true });
    var shop_url = window.location.href;
    var auth_url = `${process.env.REACT_APP_AUTH_URL}`;
    setCookie(`redirect`, shop_url);
    window.location.replace(auth_url);
  };
  const handleCloseLogin = () => {
    appDispatch({ type: "setLoginModal", loginModal: false });
  };

  const campaignCheckout = () => {
    orderInvoice();
  };
  useEffect(() => {
    appDispatch({
      type: "setPayTablePopUp",
      payTablePopUp: false,
    });
    appDispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: null });
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: [],
    });
  }, [])
  

  useEffect(() => {
    if (campaignDiscount >= openBillTotal) {
      setPaymentMethod("cash");
    }
    if (
      appState.shopMode.includes("is_partial") &&
      !appState.formData.openBill
    ) {
      appDispatch(
        popShopMode(appState, ["customer", "ratio", "product", "is_partial"])
      );
    }
  }, [campaignDiscount, formData]);

  // works
  const handleHotleDiscount = () => {
    if (appState.hotelObj != null) {
      let fixed = Number(appState.hotelObj.fixed);
      let percent = Number(appState.hotelObj.percent);
      let totalDiscount = Number((appState.total * percent) / 100 + fixed);
      if (appState.total > totalDiscount) {
        setHotelDiscount(totalDiscount);
      }
    }
  };

  const getPromo = async (e) => {
    e.preventDefault();

    setPromoLoading(true);
    if (promoStatus === false) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/store/store_promo/${appState.storeInfo.storeId}`,
          {
            promo_code: promoCode,
          }
        );
        if (response.data.success === false) {
          setPromoLoading(false);
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
        } else {
          if (response.data.data.type === "fixed") {
            setPromoLoading(false);
            if (response.data.data.amount < total) {
              let promo_amount = roundFixed(
                Number(response.data.data.amount),
                roundLevel,
                roundMethod
              );
              setPromoAmount(promo_amount);
              // setTotal(total - response.data.data.amount);
            } else {
              setPromoAmount(total);
              setDiscountType("promo-code");
            }
          } else if (response.data.data.type === "percentage") {
            setPromoLoading(false);
            var perCalc = (response.data.data.amount / 100) * total;
            if (perCalc <= total) {
              let promo_amount = roundFixed(
                Number(perCalc),
                roundLevel,
                roundMethod
              );
              setPromoAmount(promo_amount);
              // setTotal(total - perCalc);
            } else {
              setPromoAmount(total);
              setDiscountType("promo-code");
            }
          }
          setPromoStatus(true);
        }
      } catch (e) {
        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
          setPromoLoading(false);
        }
      }
    } else {
      setPromoStatus(false);
      setPromoLoading(false);
      setPromoAmount(0);
      let roundTotal = roundFixed(Number(total), roundLevel, roundMethod);
      setTotal(roundTotal);
      setPromoCode("");
    }
  };

  useEffect(() => {
    if (total > 0) {
      if (promoAmount > 0) {
        setCheckoutByCash(promoAmount >= Number(total));
      } else if (punchDiscount > 0) {
        setCheckoutByCash(punchDiscount >= Number(total));
      }
      else{
        setCheckoutByCash(false)
      }
    }
  }, [promoAmount, punchDiscount]);

  const handleCampaign = async (arr) => {
    appDispatch(freezOn());
    await axios
      .post(`${process.env.REACT_APP_API_URL}/campaign/get_discount`, {
        customer_id: appState.loggedIn
          ? appState.userInfo != []
            ? appState.userInfo.customerId
            : null
          : null,
        branch_id: appState.qrBranch,
        campaign: appState.campaignState[0],
        orders: arr,
      })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data;
          if (response.status == 200) {
            if (response.data.success == true) {
              let roundTotal = roundFixed(
                Number(response.data.data),
                roundLevel,
                roundMethod
              );
              setCampaignDiscount(roundTotal);
            }
          }
        }
      })
      .catch((error) => {
        if (error == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please check your network.",
          });
        }
        appDispatch({ type: "setCampaignState", campaignState: [] });
        // else {
        //   // appDispatch(handleError(error));
        // }

        appDispatch(freezOff());
      });
    appDispatch(freezOff());
  };

  const setPayTable = () => {
    if (appState.payTableOrders != null) {
      setOrderDetails(appState.payTableOrders);
      setShowTableOrder(true);
    }
    setTotal(totalAmount);
    setSubTotal(subTotalAmount);
    if (appState.storeInfo.allowLogin == 2) {
      appDispatch({ type: "setLoginSkip", loginSkip: true });
    } else {
      appDispatch({ type: "setLoginSkip", loginSkip: false });
    }
  };

  useEffect(() => {
    if (appState.payTableMode) {
      setPayTable();
    }
  }, [appState.total]);
  const getDineIn = async () => {
    setApiLoading(true);
    if (appState.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${appState.groupId}`
        )
        .then((response) => {
          // coesponse);
          if (response.data.success === true) {
            let res = response.data.data;
            let service = res.service;

            let invoiceExtras = handleInvoice(res, service, appState);
            appDispatch({
              type: "setInvoiceTaxesChargesExtras",
              invoiceTaxesChargesExtras: invoiceExtras,
            });

            setInvoiceId(response.data.data.service.invoice_id);
            let getOrders =
              response.data.data.service.orders.length > 0
                ? response.data.data.service.orders
                : [];
            let arr = [];
            if (appState.campaignState.length > 0 || getOrders.length > 0) {
              getOrders.forEach((ele, ind) => {
                if (ele.status == "Pending") {
                  if (ele.addons.length > 0) {
                    let addonArr = [];
                    ele.addons.forEach((addo, index) => {
                      addo = {
                        ...addo,
                        product_addon_id: addo.product_addon.id,
                      };
                      addonArr.push(addo);
                    });
                    ele.addons = addonArr;
                  }
                  ele = { ...ele, product_id: ele.product.id };
                  arr.push(ele);
                }
              });
            }
            setCurrentOrders(arr);
            setOpenBillOrders(getOrders);
            setOrderDetails(getOrders);
            if (response?.data?.data?.is_partial) {
              if (res?.meta) {
                let type = res?.meta?.partial_type;
                appDispatch(pushShopMode(a, "is_partial"));
                appDispatch(pushShopMode(a, type));
              }
            } else {
              appDispatch(
                popShopMode(a, [
                  "customer",
                  "ratio",
                  "product",
                  "is_partial",
                ])
              );
            }
            let roundTotal = roundFixed(
              Number(res.service.amount),
              roundLevel,
              roundMethod
            );
            setTotal(roundTotal - rewardsDiscount);
            setSubTotal(
              roundFixed(
                Number(res.service.total_amount),
                roundLevel,
                roundMethod
              )
            );
            setBillTotal(
              roundFixed(
                Number(
                  Number(res.service.total_amount) -
                  Number(res.service.discounted_amount)
                ),
                roundLevel,
                roundMethod
              )
            );
            if (arr.length > 0 && appState.campaignState.length > 0) {
              handleCampaign(arr);
            }
          } else if (response.data.success === false) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: response.data.message,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            appDispatch({
              type: "setPusherActive",
              pusherActive: false,
            });
          }
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
          appDispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          appDispatch(freezOff());
          history.push("/");
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${appState.userInfo.customerId}/${appState.qrBranch}`
        )
        .then((response) => {
          // coesponse);
          if (response.data.success === true) {
            let res = response.data.data;
            setInvoiceId(response.data.data.service.invoice_id);
            let getOrders =
              response.data.data.service.orders.length > 0
                ? response.data.data.service.orders
                : [];
            let arr = [];
            if (appState.campaignState.length > 0 || getOrders.length > 0) {
              getOrders.forEach((ele, ind) => {
                if (ele.status == "Pending") {
                  if (ele.addons.length > 0) {
                    let addonArr = [];
                    ele.addons.forEach((addo, index) => {
                      addo = {
                        ...addo,
                        product_addon_id: addo.product_addon.id,
                      };
                      addonArr.push(addo);
                    });
                    ele.addons = addonArr;
                  }
                  ele = { ...ele, product_id: ele.product.id };
                  arr.push(ele);
                }
              });
            }
            setCurrentOrders(arr);
            setOpenBillOrders(getOrders);
            let roundTotal = roundFixed(
              Number(res.service.total_after_discount),
              roundLevel,
              roundMethod
            );
            setTotal(roundTotal);
            setBillTotal(
              roundFixed(
                Number(
                  Number(res.service.total_amount) -
                  Number(res.service.discounted_amount)
                ),
                roundLevel,
                roundMethod
              )
            );
            if (arr.length > 0) {
              handleCampaign(arr);
            }
          } else if (response.data.success === false) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: response.data.message,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
          }
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }

          appDispatch(freezOff());
          history.push("/");
        });
    } else {
      let customeLink = !appState.shopMode.includes("share-bill")
        ? `${appState.deviceId}/${appState.qrBranch}`
        : `${appState.shareBill.deviceId}/${appState.shareBill.branchId}`;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${customeLink}`
        )
        .then((response) => {
          // coesponse);
          if (response.data.success === true) {
            let res = response.data.data;
            let service = res.service;
            let invoiceExtras = handleInvoice(res, service, appState);
            appDispatch({
              type: "setInvoiceTaxesChargesExtras",
              invoiceTaxesChargesExtras: invoiceExtras,
            });
            setInvoiceId(response.data.data.service.invoice_id);
            let getOrders =
              response.data.data.service.orders.length > 0
                ? response.data.data.service.orders
                : [];
            let arr = [];
            if (appState.campaignState.length > 0 || getOrders.length > 0) {
              getOrders.forEach((ele, ind) => {
                if (ele.status == "Pending") {
                  if (ele.addons.length > 0) {
                    let addonArr = [];
                    ele.addons.forEach((addo, index) => {
                      addo = {
                        ...addo,
                        product_addon_id: addo.product_addon.id,
                      };
                      addonArr.push(addo);
                    });
                    ele.addons = addonArr;
                  }
                  ele = { ...ele, product_id: ele.product.id };
                  arr.push(ele);
                }
              });
            }
            if (response?.data?.data?.is_partial) {
              if (res?.meta) {
                let type = res?.meta?.partial_type;
                appDispatch(pushShopMode(a, "is_partial"));
                appDispatch(pushShopMode(a, type));
              }
            } else {
              appDispatch(
                popShopMode(appState, [
                  "customer",
                  "ratio",
                  "product",
                  "is_partial",
                ])
              );
            }
            setCurrentOrders(arr);
            setOpenBillOrders(getOrders);
            setShowTableOrder(true);
            setOrderDetails(getOrders);

            let roundTotal = roundFixed(
              Number(res.service.amount),
              roundLevel,
              roundMethod
            );
            setTotal(roundTotal - rewardsDiscount);
            setSubTotal(
              roundFixed(
                Number(res.service.total_amount),
                roundLevel,
                roundMethod
              )
            );
            // setDiscount(
            //   roundFixed(
            //     Number(res.service.discounted_amount),
            //     roundLevel,
            //     roundMethod
            //   )
            // );
            setBillTotal(
              roundFixed(
                Number(
                  Number(res.service.total_amount) -
                  Number(res.service.discounted_amount) -
                  Number(rewardsDiscount)
                ),
                roundLevel,
                roundMethod
              )
            );
            if (arr?.length > 0 && appState.campaignState?.length > 0) {
              handleCampaign(arr);
            }
          } else if (response.data.success === false) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: response.data.message,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
          appDispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          appDispatch(freezOff());
          history.push("/");
        });
    }
    setApiLoading(false);
  };
  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
    // // consolelog(e.target.value);
    if (!payArray.includes(e.target.value)) {
      setSaveCardId(e.target.value);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getLoyaltyCardId = (appState, appDispatch, setLoyaltyCardId) => {
    if (
      appState.loyaltyRewardObj != undefined ||
      appState.loyaltyRewardObj != null
    ) {
      if (Object.keys(appState.loyaltyRewardObj).length < 1) {
        if (appState.loyaltyObj.length == 1) {
          if(appState.loyaltyObj[0].show){
            setDiscountType("punch-card")
            let loyaltyId = appState.loyaltyObj[0].id;
            setLoyaltyCardId(loyaltyId != undefined ? loyaltyId : null);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (appState.loyaltyObj != []) {
  //     getLoyaltyCardId(appState, appDispatch, setLoyaltyCardId);
  //   }
  // }, [appState.loyaltyObj]);

  const getLoyaltyRewards = async () => {
    appDispatch(freezOn());
    // eslint-disable-next-line no-unused-vars
    let arr = [];
    let flag = true;
    // appState.loyaltyObj.forEach(async (ele) => {
    // if (ele?.redeemable) {
    //   flag = false;
    //   let result = await getLoyaltyCardDiscount(
    //     appState,
    //     appDispatch,
    //     ele?.id,
    //     currentOrders,
    //   );
    //   if (isNotEmpty(result)) {
    //     arr.push(result);
    //   }
    // }
    // });

    for (let i = 0; i < appState.loyaltyObj.length; i++) {
      const ele = appState.loyaltyObj[i];
      if (ele?.redeemable) {
        flag = false;
        let result = await getLoyaltyCardDiscount(
          appState,
          appDispatch,
          ele?.id,
          currentOrders
        );
        if (isNotEmpty(result)) {
          arr.push(result);
        }
      }
      if (arr.length > 0 && i + 1 == appState.loyaltyObj.length) {
        setRewardsLoading(true);
      }
    }

    appDispatch({
      type: "setRewardsDetails",
      rewardsDetails: arr,
    });
    setPunchDiscount(arr);
    if (arr.length == 0) {
      appDispatch({
        type: "setOptionalRewardsDetails",
        optionalRewardsDetials: [],
      });
    }
    if (flag) {
      let active = appState.loyaltyObj.some((val) => val.active == true);
      if (active && discountType != "punch-card") {
        // appDispatch({
        //   type: "setSelectLoyaltyCardPopup",
        //   selectLoyaltyCardPopup: true,
        // });
      }
    }
    appDispatch(freezOff());
  };

  useEffect(() => {
    if (currentOrders.length > 0) {
      let res = getLoyaltyRewards();
    }
  }, [currentOrders]);

  // Find chg_id in URL
  useEffect(() => {
    handleCloseAll();
    const queryString = window.location.search;
    setTapId("");
    if (appState.payTableMode) {
      appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      setInvoiceId(appState.tableInvoice);
    } else if (
      appState.formData.openBill &&
      !appState.payTableMode &&
      !appState.hotelQrCode &&
      appState.qrTable
    ) {
      getDineIn();
    } else {
      if (appState.campaignState.length > 0) {
        handleCampaign(orders);
      }
      if (!appState.openBill) {
        setOrderDetails(orders);
        setCurrentOrders(orders);
        setShowTableOrder(true);
        setTotal(appState.subTotal);
        setSubTotal(appState.subTotal);
        handleHotleDiscount();
      }
      // setBillTotal(appState.subTotal);
    }
    // if (appState.loggedIn) {
    //   getWallet();
    // }
    if (appState.payTableMode) {
      if (appState.storeInfo.allowLogin == 2) {
        appDispatch({ type: "setLoginSkip", loginSkip: true });
      } else {
        if (!appState.loggedIn) {
          appDispatch({ type: "setLoginSkip", loginSkip: false });
        } else {
          appDispatch({ type: "setLoginSkip", loginSkip: true });
        }
      }
    } else {
      appDispatch({ type: "setLoginSkip", loginSkip: true });
    }
  }, []);

  const checkPaymentSource = (source) => {
    var ans = payArray.includes(source);
    if (ans) {
      return source;
    } else {
      return "save_card";
    }
  };
  useEffect(() => {
    console.log(appState.loyaltyRewardObj,'discounttype ---->', discountType);
  }, [discountType])
  
  const orderInvoice = async () => {
    setPLoading(true);
    appDispatch(freezOn());
    console.log(formData.order_invoice);
    if (formData.order_invoice === "pick_up") {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/pick_up`, {
          full_name: !appState.loggedIn
            ? `Customer${Date.now()}`
            : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? null : appState.userInfo.email,
          number: !appState.loggedIn ? null : appState.userInfo.number,
          country_code: !appState.loggedIn
            ? null
            : appState.userInfo.countryCode,
          orders: handleOrdersPayload(orders),
          branch_id: appState.qrBranch,
          pickup_qrcode_id: appState.qrPickup,
          device_id: appState.deviceId,
          store_id: appState.storeInfo.storeId,
          discount: 0,
          discount_type: "fixed",
          customer_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.customerId
              : null
            : null,
          user_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.userId
              : null
            : null,
          promo_code: promoCode ? promoCode : null,
          payment_source: paymentMethod
            ? checkPaymentSource(paymentMethod)
            : null,
          note: formData.note ? formData.note : "N/A",

          token: tapId != "" ? tapId : null,
          campaign:
            appState.campaignState.length > 0
              ? appState.campaignState[0]
              : null,
              save_card: checkedSaveCard,
            })
        .then((response) => {
          if (response.data.success == true) {
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            setPLoading(false);
            return;
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }

          setPLoading(false);
          appDispatch(freezOff());
        });
    } else if (formData.order_invoice === "dine_in") {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/dine_ins`, {
          loyalty_card_id:
            discountType == "punch-card"
              ? loyaltyCardId
              : discountType == "Rewards"
                ? appState.loyaltyRewardObj.id
                : null,
          loyalty_reward:
            discountType != "punch-card"
              ? appState.loyaltyRewardObj?.discount
                ? appState.loyaltyRewardObj.discount
                : undefined
              : null,
          full_name: !appState.loggedIn
            ? `Customer${Date.now()}`
            : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? null : appState.userInfo.email,
          number: !appState.loggedIn ? null : appState.userInfo.number,
          country_code: !appState.loggedIn
            ? null
            : appState.userInfo.countryCode,
          save_card: checkedSaveCard,
          table_id: appState.qrTable,
          branch_id: appState.qrBranch,
          store_id: appState.storeInfo.storeId,
          orders: handleOrdersPayload(currentOrders),
          device_id: appState.deviceId,
          customer_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.customerId
              : null
            : null,
          promo_code: promoCode ? promoCode : null,
          isOpen: false,
          // payment_source: null,
          payment_source: appState.splitBill ? "partial" : checkoutByCash ? "cash" : paymentMethod ? checkPaymentSource(paymentMethod) : null,
          note: formData.note ? formData.note : "N/A",
          token: tapId != "" ? tapId : null,
          tip: manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0,
          meta: {
            data:
              partialType == "ratio"
                ? {
                  person: totalPerson,
                  payer: personWillPay,
                }
                : partialType == "product"
                  ? paySelectedItems
                  : null,
            partial_type: partialType,
          },
          payment: appState.splitBill
            ? {
              payment_source: checkoutByCash
                ? "cash"
                : paymentMethod
                  ? checkPaymentSource(paymentMethod)
                  : null,
              type: partialType,
              token: tapId != "" ? tapId : "",
              card_id: saveCardId ? saveCardId : null,
              meta: {
                data:
                  partialType == "ratio"
                    ? {
                      person: totalPerson,
                      payer: personWillPay,
                    }
                    : partialType == "product"
                      ? paySelectedItems
                      : null,
                partial_type: partialType,
              },
              amount: Number(splitBillAmount),
              save_card: checkedSaveCard,
            }
            : [],
          campaign:
            campaignDiscount == 0
              ? null
              : appState.campaignState.length > 0
                ? appState.campaignState[0]
                : null,
          discount:
            storeDiscount < appState?.storeDiscount?.discount_max
              ? appState?.storeDiscount?.discount_percent
              : appState?.storeDiscount?.discount_max,
          discount_type:
            storeDiscount < appState?.storeDiscount?.discount_max
              ? "percent"
              : "fixed",
        })
        .then((response) => {
          // consolelog(response);
          if (response.data.success === true) {
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            setPLoading(false);
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })
        // null wala kam hatana he
        // appState.splitBill
        // ? "partial"
        // : checkoutByCash
        // ? "cash"
        // : paymentMethod
        // ? checkPaymentSource(paymentMethod)
        // : null,

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }
          setPLoading(false);
          appDispatch(freezOff());
        });
    } else if (formData.order_invoice === "hotel_order") {
      // consolelog(appState.storeInfo.storeId, formData.order_invoice);
      // flow with close bill
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/hotel_order`, {
          loyalty_card_id:
            discountType == "punch-card"
              ? loyaltyCardId
              : Object.keys(voucherObj).length > 0
                ? voucherObj.id
                : null,
          loyalty_reward:
            Object.keys(voucherObj).length > 0 ? voucherObj.discount : null,
          qrcode_id: Number(appState.hotelQrCode),
          full_name: !appState.loggedIn
            ? `Customer${Date.now()}`
            : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? null : appState.userInfo.email,
          device_id: appState.deviceId,
          number: !appState.loggedIn ? null : appState.userInfo.number,
          country_code: !appState.loggedIn
            ? null
            : appState.userInfo.countryCode,
          save_card: checkedSaveCard,
          // table_id: appState.qrTable,
          branch_id: appState.qrBranch,
          store_id: appState.storeInfo.storeId,
          orders: handleOrdersPayload(orders),
          customer_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.customerId
              : null
            : null,
          promo_code: promoCode ? promoCode : null,
          isOpen: false,
          payment_source: appState.splitBill
            ? "partial"
            : checkoutByCash
              ? "cash"
              : paymentMethod
                ? checkPaymentSource(paymentMethod)
                : null,
          note: formData.note ? formData.note : "N/A",
          token: tapId != "" ? tapId : null,
          tip: manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0,
          meta: {
            data:
              partialType == "ratio"
                ? {
                  person: totalPerson,
                  payer: personWillPay,
                }
                : partialType == "product"
                  ? paySelectedItems
                  : null,
            partial_type: partialType,
          },
          payment: appState.splitBill
            ? {
              payment_source: checkoutByCash
                ? "cash"
                : paymentMethod
                  ? checkPaymentSource(paymentMethod)
                  : null,
              type: partialType,
              token: tapId != "" ? tapId : "",
              card_id: saveCardId ? saveCardId : null,
              meta: {
                data:
                  partialType == "ratio"
                    ? {
                      person: totalPerson,
                      payer: personWillPay,
                    }
                    : partialType == "product"
                      ? paySelectedItems
                      : null,
                partial_type: partialType,
              },
              amount: Number(splitBillAmount),
              save_card: checkedSaveCard,
            }
            : [],
          campaign:
            campaignDiscount == 0
              ? null
              : appState.campaignState.length > 0
                ? appState.campaignState[0]
                : null,
        })
        .then((response) => {
          // consolelog(response);
          if (response.data.success === true) {
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            setPLoading(false);
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }
          setPLoading(false);
          appDispatch(freezOff());
        });
    } else if (formData.order_invoice === "delivery") {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/delivery`, {
          full_name: `${!appState.loggedIn
            ? `Customer${Date.now()}`
            : appState.userInfo.userFirstName
            }`,
          email: !appState.loggedIn ? null : appState.userInfo.email,
          number: !appState.loggedIn ? null : appState.userInfo.number,
          country_code: !appState.loggedIn
            ? null
            : appState.userInfo.countryCode,
          block: formData.block,
          street: formData.street,
          avenue: formData.avenue,
          notes: formData.notes,
          country_id: formData.country_id,
          city_id: formData.city_id,
          area_id: formData.area_id,
          store_id: formData.store_id,
          orders: handleOrdersPayload(orders),
          customer_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.customerId
              : null
            : null,
          user_id: appState.loggedIn
            ? appState.userInfo != []
              ? appState.userInfo.userId
              : null
            : null,
          promo_code: promoCode ? promoCode : null,
          payment_source: paymentMethod ? paymentMethod : null,
          note: formData.note ? formData.note : "N/A",
          token: tapId != "" ? tapId : null,
          campaign:
            appState.campaignState.length > 0
              ? appState.campaignState[0]
              : null,
        })
        .then((response) => {
          if (response.data.success === true) {
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            setPLoading(false);
            return;
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }
          setPLoading(false);
          appDispatch(freezOff());
        });
    } else if (formData.order_invoice === "open_bill") {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/order_invoice/close_open_bill`,
          {
            loyalty_card_id:
              discountType == "punch-card"
                ? loyaltyCardId
                : appState.loyaltyRewardObj
                  ? appState.loyaltyRewardObj?.id
                  : null,
            loyalty_reward:
              discountType != "punch-card"
                ? appState.loyaltyRewardObj
                  ? appState.loyaltyRewardObj?.discount
                  : null
                : null,
            save_card: checkedSaveCard,
            table_id: appState.qrTable,
            branch_id: appState.qrBranch,
            device_id: appState.shopMode.includes("share-bill")
              ? appState.shareBill?.deviceId
              : appState.deviceId,
            customer_id: appState.shopMode.includes("share-bill")
              ? appState.shareBill?.customerId
              : appState.loggedIn
                ? appState.userInfo.customerId
                : null,
            number: appState.shopMode.includes("share-bill")
              ? appState.shareBill?.number
              : !appState.loggedIn
                ? null
                : appState.userInfo.number,
            country_code: appState.shopMode.includes("share-bill")
              ? appState.shareBill?.countryCode
              : !appState.loggedIn
                ? null
                : appState.userInfo.countryCode,
            invoice_id: appState.payTableMode
              ? appState.tableInvoice
              : invoiceId != null
                ? invoiceId
                : null,
            // payment_source: null,
            payment_source: appState.splitBill
              ? "partial"
              : checkoutByCash
                ? "cash"
                : paymentMethod
                  ? checkPaymentSource(paymentMethod)
                  : null,
            meta: {
              data:
                partialType == "ratio"
                  ? {
                    person: totalPerson,
                    payer: personWillPay,
                  }
                  : partialType == "product"
                    ? paySelectedItems
                    : null,
              partial_type: partialType,
            },
            note: formData.note ? formData.note : "N/A",
            token: tapId != "" ? tapId : "",
            promo_code: promoCode ? promoCode : null,
            card_id: saveCardId ? saveCardId : null,
            tip: manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0,
            payment: appState.splitBill
              ? {
                payment_source: checkoutByCash
                  ? "cash"
                  : paymentMethod
                    ? checkPaymentSource(paymentMethod)
                    : null,
                type: partialType,
                token: tapId != "" ? tapId : "",
                card_id: saveCardId ? saveCardId : null,
                meta: {
                  data:
                    partialType == "ratio"
                      ? {
                        person: totalPerson,
                        payer: personWillPay,
                      }
                      : partialType == "product"
                        ? paySelectedItems
                        : null,
                  partial_type: partialType,
                },
                amount: Number(splitBillAmount),
                apple_pay_token: appleToken != null ? appleToken : null,
                save_card: checkedSaveCard,
                campaign:
                  appState.campaignState.length > 0
                    ? appState.campaignState[0]
                    : null,
              }
              : [],
            campaign:
              appState.campaignState.length > 0
                ? appState.campaignState[0]
                : null,
            orders: currentOrders,
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            appDispatch(cleanRest(appState, response, orders, history));
            setTapId("");
            setPLoading(false);
            if (appState.payTableMode) {
              appDispatch(closePayTable(appState));
            }
            return;
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }
          setPLoading(false);
          appDispatch(freezOff());
        });
      // consolelog('hi tpp ')
    } else {
      appDispatch(freezOff());

      history.push("/");
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `Some thing is wrong please try agian.`,
      });
    }
    appDispatch({ type: "setCheckoutShortCutPopup", checkoutShortCutPopup: false})
  };


  const handlCheckoutWithApplePay = () => {
    let extraction = true;
    setPLoading(true);
    appDispatch(freezOn());
    setPaymentMethod("apple_pay");
    let checkoutData = {
      paymentMethod: paymentMethod,
      checkPaymentSource: checkPaymentSource(),
      totalPerson: totalPerson,
      personWillPay: personWillPay,
      paySelectedItems: paySelectedItems,
      formData: formData,
      promoCode: promoCode,
      saveCardId: saveCardId,
      manualTip: manualTip,
      customeTip: customeTip,
      splitBillAmount: Number(splitBillAmount),
      orders: handleOrdersPayload(orders),
      invoiceId: invoiceId,
      orderInvoice: formData.order_invoice,
      total: total,
      openBillTotal: openBillTotal,
    };

    getApplePayToken(
      history,
      appState,
      appDispatch,
      setPLoading,
      setTapId,
      closePayTable,
      cleanRest,
      checkoutData,
      ApplePaySession,
      handleCloseAll,
      extraction
    );
  };

  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else if (appState.payTableMode && appState.tableInvoice) {
      setPayTable();
       
    }
  }, [appState.tableInvoice]);

  const handleCheckout = () => {
    setPLoading(true);
    appDispatch(freezOn());
    let checkoutData = {
      paymentMethod: paymentMethod,
      checkPaymentSource: checkPaymentSource(),
      totalPerson: totalPerson,
      personWillPay: personWillPay,
      paySelectedItems: paySelectedItems,
      formData: formData,
      promoCode: promoCode,
      saveCardId: saveCardId,
      manualTip: manualTip,
      customeTip: customeTip,
      splitBillAmount: Number(splitBillAmount),
      orders: handleOrdersPayload(orders),
      invoiceId: invoiceId,
      orderInvoice: formData.order_invoice,
    };

    if (paymentMethod == "card") {
      childRef.current.submitRequest();
    } else {
      orderInvoice();
    }
  };
  const submitCheckout = (e) => {
    e.preventDefault();
    if (
      appState.loggedIn == true ||
      appState.storeInfo.allowLogin == 2 ||
      appState.loginSkip ||
      appState.shopMode.includes("share-bill")
    ) {
      if (checkoutByCash) {
        orderInvoice();
      } else {
        if (
          appState.shopMode.includes("is_partial") &&
          (partialType == null || partialType == "")
        ) {
          setPartialType("customer");
          setSplitBillAmount(totalAmount);
        }
        appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
      }
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
  };
  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else if (!appState.openBill) {
      if (appState.loginSkip) {
        // appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
      }
    }
  }, [appState.loginSkip]);

  useEffect(() => {
    if (tapId != null && tapId != "") {
      orderInvoice();
    }
  }, [tapId]);

  const goBackBtn = () => {
    history.push("/");
  };
  useEffect(() => {
    if (
      !appState.payTableMode &&
      appState.qrTable &&
      openBillTotal <= 0 &&
      formData.order_invoice == undefined &&
      !isInitialMount
    ) {
      goBackBtn();
    }
  }, [openBillTotal]);



  const handleClickShowCustomeTipField = () => {
    setManualTip(0);
    setCustomeShow(true);
  };
  const handleViewMenu = () => {
    if (appState.storeInfo.iframeUrl != null) {
          history.push("/external");
    } else {
          history.push("/");
      appDispatch({
        type: "setPayTablePopUp",
        payTablePopUp: false,
      });
    }
  };
  useEffect(() => {
    if (manualTip > 0) {
      if (customeShow) {
        setCustomeShow(!customeShow);
      }
    }
    // consolelog(customeShow, manualTip);
  }, [manualTip]);

  useEffect(() => {
    const getTips = async () => {
      setTipLoading(true);
      let arry = [];
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tip`
        );
        let res = response.data.data;
        if (response.data.success) {
          res.map((val) => {
            let data = {
              id: val.id,
              name: val.name,
              value: val.amount,
              amount:
                val.type == "fixed"
                  ? parseFloat(val.amount).toFixed(3)
                  : parseFloat((val.amount / 100) * total).toFixed(3),
              type: val.type,
            };
            arry.push(data);
          });
          arry.push({
            id: res.length + 1,
            name: { en: "custom", ar: "أختيار" },
            value: null,
            amount: null,
          });
          setTips(arry);
          setTipLoading(false);
        }
      } catch (e) {
        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
        setTipLoading(false);
      }
    };
    if (total) {
      getTips();
    }
  }, [total]);

  const handleClickShowOption = () => {
    if (
      appState.loggedIn == true ||
      appState.storeInfo.allowLogin == 2 ||
      appState.loginSkip
    ) {
      // handleCheckout();
      if (!appState.shopMode.includes("is_partial")) {
        appDispatch({
          type: "setSplitBillOptions",
          splitBillOptions: true,
        });
        appDispatch({
          type: "setSplitBill",
          splitBill: true,
        });
      } else {
        handleSplitPayements(appState, appDispatch);
      }
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
  };
  const handleCloseAll = () => {
    appDispatch(resetSplitOptions());
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: false });
    setCustomeShow(false);
    setSplitBillAmount(0);
    setPersonWillPay(1);
    setTotalPerson(2);
    setPartialType(null);
    setPaySelectedItems([]);
    setPaymentMethod("");
  };

  const handleChangeTips = (e) => {
    if (e.target.name === "custom" || e.target.name === "أختيار") {
      handleClickShowCustomeTipField();
      setHangTip(Number(e.target.id));
    } else {
      if (e.target.id == hangTip) {
        setHangTip(0);
        setManualTip(Number(0));
      } else {
        setHangTip(Number(e.target.id));
        setManualTip(Number(e.target.value));
        setCustomeTip(0);
      }
    }
  };

  useEffect(() => {
    // calculate total
    let tempTotal = appState.payTableMode ? totalAmount : total;
    let getStoreDiscount = 0;
    if (formData.order_invoice == "dine_in") {
      getStoreDiscount =
        appState.storeDiscount != null
          ? handleStoreDiscount(appState, appDispatch, tempTotal)
          : 0;
      setStoreDiscount(getStoreDiscount);
    }
    let roundTotal =
      tempTotal -
      promoAmount +
      manualTip +
      Number(customeTip) -
      campaignDiscount -
      hotelDiscount -
      rewardedDiscount ?? 0 - getStoreDiscount;
    roundTotal = roundFixed(Number(roundTotal), roundLevel, roundMethod);
    // console.log(
    //   "tempTotal",tempTotal,
    //   "promoAmount",promoAmount,
    //   "manualTip",manualTip,
    //   "customeTip",customeTip,
    //   "campaignDiscount",campaignDiscount,
    //   "hotelDiscoun",hotelDiscount, 'rewardedDiscount', rewardedDiscount,);
   
    if(discountType == "Rewards"){
      setPunchDiscount(rewardedDiscount ?? 0)
    }
    else{
      setPunchDiscount(0)
    }

    setOpenBillTotal(roundTotal);
    setRewardedDiscount(rewardsDiscount);
  }, [
    handleChangeTips,
    manualTip,
    customeTip,
    promoAmount,
    total,
    campaignDiscount,
    appState.subTotal,
    appState.invoiceTaxesChargesExtras,
    hotelDiscount,
    appState.storeDiscount,
  ]);

  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  useEffect(() => {
    if (hasMounted) {
      if (appState.pusherActive > 0 && appState.payTableMode) {
        appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      } else if (appState.pusherActive > 0 && appState.openBill) {
        getDineIn();
      }
    } else {
      setHasMounted(true);
    }
  }, [appState.pusherActive]);
  useEffect(() => {
    if (appState.invoiceTaxesChargesExtras.tip > 0) {
      let showTip = appState.invoiceTaxesChargesExtras.tip;
      setCustomeTip(showTip);
    }
  }, [appState.invoiceTaxesChargesExtras]);

  useEffect(() => {
    if (invoiceId != null) {
      if (!appState.pusherActive) {
        getLiveData(
          invoiceId,
          appState,
          appDispatch,
          orderUpdateCount,
          setOrderUpdateCount
        );
      }
    }
  }, [invoiceId]);

  const handleRetry = () => {
    setRetryAgain(true);
    getPayTableInvoiceNoOrders(appState, appDispatch);

    var timeleft = 29;
    var downloadTimer = setInterval(function () {
      if (timeleft <= -1) {
        clearInterval(downloadTimer);
        setRetryAgain(false);
        setTimer(2);
      } else {
        setTimer(timeleft);
        timeleft -= 1;
      }
    }, 1000);
  };

  const handleShowLoyaltyCards = () => {
    if (loyaltyCardId) {
      setShowLoyaltyCardDetails(!showLoyaltyCardDetails);
    } else {
      appDispatch({
        type: "setSelectLoyaltyCardPopup",
        selectLoyaltyCardPopup: true,
      });
    }
  };
  return (
    // new Changes by sajid from top to bottom
    <React.Fragment>
      {(appState.splitBill ||
        customeShow ||
        appState.paymentPopUp ||
        appState.selectLoyaltyCardPopup ||
        appState.optionalRewardsPopUp) && (
          <Backdrop
            onClick={handleCloseAll}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 3,
            }}
            open={
              appState.splitBill ||
              customeShow ||
              appState.paymentPopUp ||
              appState.selectLoyaltyCardPopup ||
              appState.optionalRewardsPopUp
            }
          />
        )}
      <Page title="CheckOut">
        <div className="px-3 mt-3 mb-2">
        <Vouceher
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoStatus={promoStatus}
                getPromo={getPromo}
                promoLoading={promoLoading}
              />
        </div>

      <Accordion.Body className="">
      {appState.tableInvoice != null || !appState.payTableMode ? (
          <>
            {open ? (
              <TopUpPopUp
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                topUpOpen={open}
              />
            ) : (
              ""
            )}

            <div
              id="wrapper"
              className={`${pLoading ? "disableClass" : ""}  ${appState.langValue === "ar" ? "directionRtl" : ""
                }  ${appState.paymentPopUp || appState.splitBillOptions
                  ? "paddingForPopup"
                  : "paddingFirstTime"
                }`}
            >
              {/* <section id="topNav ">
                <div className="row m-0 checkOut_Nav justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                   
                    <div className="pl-1 mt-2">
                      <h4>
                        <strong>{t("navBar.Checkout")}</strong>
                      </h4>
                    </div>
                  </div>

                  <IconButton
                      id="headerBtn"
                      // onClick={(e) => arLanguageHandle(e)}
                      onClick={()=>appDispatch({ type: "setCheckoutShortCutPopup", checkoutShortCutPopup: false})}
                      className="arabicBtn"
                    >
                     <CloseIcon />
                    </IconButton>
                </div>
              </section> */}

              {payments.includes("tip") && (
                <section id="tip" className="row m-0 mt-2 pb-3">
                  <div className="col-12 p-0">
                    <div className="row m-0">
                      <h3 className="col-12 p-0 d-flex">
                        {t("checkOut.Add-a-tip")}{" "}
                        <span className="px-1">&#128079;</span>
                      </h3>
                      <h4
                        className={` col-12 p-0 text-muted d-flex ${appState.langValue === "ar"
                          ? "text-right familyGraphikArabic800 "
                          : " familyPoppins300"
                          }`}
                      >
                        {t("checkOut.Add-a-tip-desc")}
                      </h4>
                    </div>
                    {tipLoading ? (
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 px-2">
                          <Skeleton height={150} />
                        </div>
                        <div className="col-md-3 px-2">
                          <Skeleton height={150} />
                        </div>
                        <div className="col-md-3 px-2">
                          <Skeleton height={150} />
                        </div>
                        <div className="col-md-3 px-2">
                          <Skeleton height={150} />
                        </div>
                      </div>
                    ) : (
                      <div id="TipCheckBoxParent">
                        <div
                          className="row m-0 TipselectPaymentRow TipselectPaymentRowWallet"
                          onChange={handleChangeTips}
                        >
                          {tips.length > 0 &&
                            tips.map((val, ind) => {
                              return (
                                <div
                                  className="col-3 tipManinCol direction-ltr"
                                  key={ind}
                                >
                                  <Button
                                    className="w-100 h-100 p-0"
                                    variant="outlined"
                                  >
                                    <div className="paymentContainer w-100">
                                      <input
                                        type="checkbox"
                                        value={val.amount}
                                        name={get_lang(
                                          val.name,
                                          appState.langValue
                                        )}
                                        id={val.id}
                                        checked={hangTip == val.id}
                                      />
                                      <label htmlFor={val.id}>
                                        <span>
                                          <strong className="d-flex tiptext justify-content-center textOver">
                                            {get_lang(
                                              val.name,
                                              appState.langValue
                                            )}
                                          </strong>
                                        </span>

                                        {val.name.en === "custom" ? (
                                          <>
                                            <p className="">
                                              <EditIcon />
                                            </p>
                                          </>
                                        ) : (
                                          <p className="textBlack mb-0 d-flex flex-wrap justify-content-center familyManrope800">
                                            <span className="">                        {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
</span>
                                            &nbsp;
                                            <span className="">
                                              {val.amount}
                                            </span>
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              )}
              {/* {appState.splitBillOptions && <SplitBillOptions />} */}

              {appState.paymentPopUp && (
                <PaymentMethods
                  handlCheckoutWithApplePay={handlCheckoutWithApplePay}
                  handleCheckout={handleCheckout}
                  payments={payments}
                  setPaymentMethod={setPaymentMethod}
                  paymentMethod={paymentMethod}
                  tapStatus={tapStatus}
                  setTapId={setTapId}
                  setCheckedSaveCard={setCheckedSaveCard}
                  checkedSaveCard={checkedSaveCard}
                  setLoading={setLoading}
                  handleCloseAll={handleCloseAll}
                  campaignDiscount={campaignDiscount}
                  discount={storeDiscount > 0 ? storeDiscount : discount}
                  childRef={childRef}
                />
              )}

              {/* {appState.payForItems && (
                <PayForItems
                  orders={
                    appState.payTableMode
                      ? appState.payTableOrders
                      : formData.order_invoice === "open_bill"
                        ? openBillOrders
                        : orders
                  }
                  subTotal={total}
                  promoAmount={promoAmount}
                  paySelectedItems={paySelectedItems}
                  setPaySelectedItems={setPaySelectedItems}
                  setPartialType={setPartialType}
                  splitBillAmount={splitBillAmount}
                  setSplitBillAmount={setSplitBillAmount}
                  submitCheckout={submitCheckout}
                  campaignDiscount={campaignDiscount}
                  discount={storeDiscount > 0 ? storeDiscount : discount}
                />
              )}
              {appState.divideBill && (
                <DivideBill
                  subTotal={
                    formData.order_invoice == "close-bill" ? total : total
                  }
                  fullAmount={fullAmount}
                  orders={
                    appState.payTableMode
                      ? appState.payTableOrders
                      : formData.order_invoice === "open_bill"
                        ? openBillOrders
                        : orders
                  }
                  promoAmount={promoAmount}
                  setPartialType={setPartialType}
                  splitBillAmount={splitBillAmount}
                  setSplitBillAmount={setSplitBillAmount}
                  totalPerson={totalPerson}
                  setTotalPerson={setTotalPerson}
                  personWillPay={personWillPay}
                  setPersonWillPay={setPersonWillPay}
                  submitCheckout={submitCheckout}
                  campaignDiscount={campaignDiscount}
                  discount={storeDiscount > 0 ? storeDiscount : discount}
                  tip={
                    manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0
                  }
                />
              )}
              {appState.payCustomAmount && (
                <PayCustomAmount
                  subTotal={total}
                  promoAmount={promoAmount}
                  setPartialType={setPartialType}
                  splitBillAmount={splitBillAmount}
                  setSplitBillAmount={setSplitBillAmount}
                  submitCheckout={submitCheckout}
                  campaignDiscount={campaignDiscount}
                  discount={storeDiscount > 0 ? storeDiscount : discount}
                  tip={
                    manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0
                  }
                />
              )} */}
              {customeShow && (
                <AddTipCustom
                  subTotal={total}
                  setCustomeTip={setCustomeTip}
                  customeTip={customeTip}
                  setCustomeShow={setCustomeShow}
                  handleChangeTips={handleChangeTips}
                />
              )}
               
              {/* { 4(campaignDiscount , openBillTotal)} */}
              {campaignDiscount < total ? (
                
                <section id="reviewOrderButtonCheckout" className=" offset-xl-3 mr-auto col-xl-6 col-md-12 ">
                  {payments.includes("partial") && !customeShow ? (
                    <React.Fragment>
                      <div
                        id="process-btn"
                        className="reviewOrdButton  col-md-12 m-0"
                      >
                        <Button
                          className="col-12 px-4 py-3 text-capitalize d-flex  justify-content-between    btn-main align-item-center "
                          // onClick={newOrder}
                          onClick={submitCheckout}
                        >
                          <span>
                            {t("checkOut.pay-all")} &nbsp; (
                            {openBillTotal.toFixed(3)}
                            {get_lang(storeCurrency, appState.langValue)} )
                          </span>
                          <div className="iconsParent d-flex justify-content-center">
                            {appState.acceptedPayments.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {item === "knet" ? (
                                    <span className=" iconsBg ">
                                      <img
                                        className="img-pr"
                                        // width="25"
                                        src={`/images/pat-knet.svg`}
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {item === "card" ? (
                                    <span className="iconsBg">
                                      <img
                                        className="img-pr"
                                        // width="25"
                                        src={`/images/visa-small.svg`}
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {item == "apple_pay" ? (
                                    <span key={index} className="iconsBg">
                                      <img
                                        className="img-pr"
                                        width="50"
                                        src={`/images/pat-apple-n.svg`}
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </Button>
                      </div>
                      {/* {!checkoutByCash && (
                        <div
                          id="process-btn"
                          className="reviewOrdButton   col-md-12 m-0"
                        >
                          <Button
                            className="col-12 entext-capitalize splitBtn align-item-center "
                            onClick={handleClickShowOption}
                          >
                            {t("checkOut.Split-Bill-2")}
                          </Button>
                        </div>
                      )} */}

                      {appState.payTableMode && (
                        <div
                          id="process-btn"
                          className="reviewOrdButton  mr-auto col-md-12 m-0"
                        >
                          <div id="cartItemsNoOrder">
                            {!retryAgain ? (
                              <div className="d-flex justify-content-center">
                                <Button
                                  onClick={handleRetry}
                                  className="retryButton"
                                >
                                  {" "}
                                  <ReplayIcon className="mx-2" />
                                  {t("checkOut.Retry")}
                                </Button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <RestoreIcon className="mx-2" />
                                <p className="retryText">
                                  {" "}
                                  {t("checkOut.Retry-in")} 00:{timer}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <div
                      id="process-btn"
                      className="reviewOrdButton  mr-auto  col-md-12 m-0"
                    >
                      <Button
                        className="col-12 text-capitalize btn-main align-item-center "
                        // onClick={newOrder}
                        onClick={submitCheckout}
                      >
                        {/* {t("checkOut.Confirm-and-pay")} */}
                        {t("navBar.Checkout")}
                      </Button>
                    </div>
                  )}
                </section>
              ) : (
                <section id="reviewOrderButton" className="col-xl-6 offset-xl-3">
                  <div id="process-btn" className="reviewOrdButton   m-0">
                    <Button
                      className="col-12 text-capitalize btn-main align-item-center "
                      // onClick={newOrder}
                      onClick={campaignCheckout}
                      disabled={apiLoading}
                    >
                      {/* {t("checkOut.Confirm-and-pay")} */}
                      {t("navBar.Checkout")}
                    </Button>
                  </div>
                </section>
              )}
            </div>
           
          </>
          
        ) : (
          <CheckoutNoOrder />
        )}
            </Accordion.Body>
     
      </Page>
    </React.Fragment>
  );
}

export default withTranslation()(CheckOutOne);
