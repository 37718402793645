export const handleServiceModes = (settings,hotel_qrcode_obj,dispatch) => {
  if(settings?.services?.length > 0 ){
    if(settings?.services?.includes("dine-in") && !settings?.services?.includes("pay-table") && !settings?.services?.includes("open-bill")){
      let services = [...settings?.services]
      services.push("close-bill")
      dispatch({
        type: "SET_SERVICES_MODE",
        serviceMode: services ? services : [],
      });
    }else{
      dispatch({
        type: "SET_SERVICES_MODE",
        serviceMode: settings?.services ? settings?.services : [],
      });
    }
  }
  else{
    dispatch({
      type: "SET_SERVICES_MODE",
      serviceMode: settings?.services ? settings?.services : [],
    });
  }
    if(hotel_qrcode_obj){
      let services = [...settings?.services]
        services.push("hotel")
        dispatch({
          type: "SET_SERVICES_MODE",
          serviceMode: services ? services : [],
        });
      }
};