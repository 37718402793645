/* eslint-disable no-unused-vars */
/* eslint-disable  */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import noImg from "../assets/images/no-image.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// for Localization
import { withTranslation, useTranslation, Trans } from "react-i18next";
// global funtion for store
import { get_lang,  } from "../../../global-functions/apiGlobal";
import { handleAddonsIncrement, handleAddonsDecrement, handleAddonsQtyByProduct } from "../../../global-functions/addToCartGlobal"
// redux 
import { useSelector, useDispatch } from "react-redux"
import { roundFixed } from "../../../global-functions/roundFixed";

const CartItem = ({ item }) => {
  const appState = useSelector((state) => state);
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const [quantityValue, setQuantityValue] = useState(item.quantity);
  const [addonQuantity, setAddonQuantity] = useState(item.addons.quantity);
  const [maxquantity] = useState(item.maxQuantity);
  const [openModal, setOpenModal] = useState(false);
  const [addonsCount, setAddonsCount] = useState(0);

  const handleAddonsLimit = () => {
    let newArry = [...item.addons_limit];
    for (let i = 0; i < newArry.length; i++) {
      var arr = [];
      let sum = 0;
      for (let k = 0; k < item.addons.length; k++) {
        if (item.addons[k].category_addon_id == newArry[i].id) {
          arr.push(item.addons[k].quantity);
        }
      }
      for (let j = 0; j < arr.length; j++) {
        sum += arr[j];
        newArry[i].addons_qty = sum;
      }
    }
    item.addons_limit = newArry;
  };
  // addon Quantity Funtion
  const handleIncrementAddons = async (addon_id, category_id) => {
    let res = handleAddonsIncrement(
      item.addons_limit,
      item.addons,
      addon_id,
      category_id,
      appDispatch, appState, quantityValue
    );
    item.addons = res;
    sumAddons();
    handleAddonsLimit();
  };

  const handleDecrementAddons = (addon_id, category_id) => {
    let res = handleAddonsDecrement(
      item.addons_limit,
      item.addons,
      addon_id,
      category_id
    );
    item.addons = res;
    sumAddons();
    handleAddonsLimit();
  };

  // sum_addons function
  const sumAddons = async () => {
    let arry = item.addons;
    let sum_addons = 0;
    let count = 0;
    for (let i = 0; i < arry.length; i++) {
      let price_qty = arry[i].price * arry[i].quantity;
      count = count + arry[i].quantity;
      sum_addons = sum_addons + price_qty;
    }

    item.sum_addons = sum_addons;
    setAddonsCount(count);
    setAddonQuantity(sum_addons);
  };

  // qunatity work funtion for product
  const handleIncrement = (e) => {
    e.preventDefault();
    const qtyFlag = handleAddonsQtyByProduct(item.addons, quantityValue, appDispatch, appState);
    const product = appState.orderProdQty.find(order => order.product_id === item.product_id);
    if (product && qtyFlag && (!product.maxQuantity || product.prodTotalQty < product.maxQuantity)) {
      product.prodTotalQty++;
      setQuantityValue(quantityValue + 1);
    }
    console.log(product);
  };

  const handleDecrement = (e) => {
    e.preventDefault();

    appState.orderProdQty.forEach((order) => {
      if (order.product_id == item.product_id) {
        if (quantityValue <= 1) {
          return;
        }
        order.prodTotalQty = order.prodTotalQty - 1;
        setQuantityValue(quantityValue - 1);
      }
    });
    
  };
  useEffect(() => { 
    setQuantityValue(item.quantity);
    setAddonQuantity(item.addons.quantity);
    sumAddons();
    handleAddonsLimit();
    // console.log(appState.cart);
    // console.log(appState.orderProdQty);
  }, [appState.cart]);

  const addToCart = async () => {
    let newCart = [...appState.cart];
    const itmIndx = newCart.findIndex((el) => el.id === item.id);

    let prodTotalQty = 0;
    // await  appState.forEach(ele => {
    //       if(ele.product_id === item.id){
    //         prodTotalQty  = prodTotalQty + ele.quantity

    //       }
    //   });

    //  await  appState.forEach(ele => {
    //     if(ele.product_id === item.id){
    //       if(prodTotalQty > 0)
    //         ele.maxQuantity  = ele.maxQuantity - prodTotalQty
    //     }
    // });

    newCart[itmIndx].quantity = quantityValue;
    // newCart[itmIndx].price = parseFloat(item.price_after_discount) * newCart[itmIndx].quantity;
    newCart[itmIndx].sum_addons = addonQuantity;
    appDispatch({ type: "updateCart", cart: newCart });
  };

  useEffect(() => {
    addToCart();
  }, [quantityValue, addonQuantity, addonsCount]);

  const handleItemRemove = (item) => {
    let newCart = [...appState.cart];
    let addonCart = [...appState.addonCart];
    appState.orderProdQty.forEach((order) => {
      if (order.product_id == item.product_id) {
        order.prodTotalQty = order.prodTotalQty - item.quantity;
      }
    });

    const itemIndx = newCart.findIndex((el) => el.id === item.id);
    // console.log(newCart[itemIndx]);
    let total = appState.subTotal - newCart[itemIndx].price;
    total = roundFixed(
      Number(total),
      appState.storeInfo.roundLevel,
      appState.storeInfo.roundMethod
    );

    appDispatch({
      type: "updateSubTotal",
      subTotal: total,
    });
    newCart.splice(itemIndx, 1);
    appDispatch({ type: "updateCart", cart: newCart });
    if (appState.campaignState.length > 0) {
      let flag = true;
      newCart.forEach((cart) => {
        if (flag) {
          if (appState.campaignState[0].assigner_id == cart.category_id) {
            flag = false;
          }
          // console.log(cart)
        }
      });
      if (flag) {
        appDispatch({
          type: "setCampaignState",
          campaignState: [],
        });
      }
      // console.log(appState.campaignState)
    }

    // Remove Multiple Items From An Array
    let indexes = [];
    for (let i = 0; i < appState.addonCart.length; i++) {
      if (appState.addonCart[i].product_id === item.product_id) {
        indexes.push(i);
      }
    }
    let indexSet = new Set(indexes);
    let removed = addonCart.filter(
      (filteredItem, index) => !indexSet.has(index)
    );
    appDispatch({ type: "updateAddons", addonCart: removed });
    // Remove Multiple Items From An Array
  };

  const handleAddonsRemove = (items) => {
    let newCart = [...appState.cart];
    const itemIndx = newCart.findIndex(
      (el) => el.product_id === items.product_id
    );
    newCart[itemIndx].addons.splice(
      newCart[itemIndx].addons.findIndex(
        (a) => a.product_addon_id === items.product_addon_id
      ),
      1
    );
    appDispatch({ type: "updateCart", cart: newCart });
  };

  return (
    <div id={item.product_id}>
      <div className="row m-0">
        <div className="col-2 px-0">
          <Link
            to={`/${item.redirect_url}cart_id=${item.id}`}
            className="prod_link"
          >
            <img
              src={item.image ? `${item.image}` : `/images/no-image.svg`}
              alt=""
              height=""
              width="100%"
            />
          </Link>
        </div>
        <div
          className={
            appState.langValue === "ar" ? "col-10 pl-0" : "col-10 pr-0"
          }
        >
          <div className="row m-0 justify-content-between">
            <div className="col-10 col-sm-11 p-0">
              <Typography gutterBottom variant="h5" component="h2">
                <Link
                  to={`/${item.redirect_url}cart_id=${item.id}`}
                  className="prod_link itemcart"
                >
                  <strong className="toCapitialize d-flex">
                    {item.prod_title != null
                      ? get_lang(item.prod_title, appState.langValue)
                      : "Name is not available"}
                  </strong>
                </Link>
              </Typography>
            </div>
            <div className="col-2 col-sm-1 d-flex justify-content-end p-0">
              <div>
                <IconButton
                  id="removeItem"
                  size="small"
                  onClick={() => handleItemRemove(item)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="row m-0 align-items-center justify-content-between">
            <div className="row m-0 align-items-center">
              <h5 className="text-muted m-0">
                {parseFloat(item.price * item.quantity).toFixed(3)}{" "}
                {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}              </h5>
            </div>

            <div
              className={
                appState.langValue === "ar" ? "d-flex flex-row-reverse" : ""
              }
            >
              <React.Fragment>
                {quantityValue > 1 ? (
                  <IconButton
                    size="small"
                    className="qtyBtn"
                    onClick={handleDecrement}
                    id="IncDecItemQuan"
                  >
                    <RemoveIcon />
                  </IconButton>
                ) : (
                  <IconButton size="small" disabled>
                    <RemoveIcon />
                  </IconButton>
                )}
              </React.Fragment>
              <span style={{ fontSize: "1rem", padding: "15px" }}>
                {quantityValue}
              </span>
              <IconButton
                size="small"
                className={quantityValue == item.maxQuantity ? "" : "qtyBtn"}
                onClick={handleIncrement}
                disabled={quantityValue == item.maxQuantity}
                id="IncDecItemQuan"
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          {item.note ? (
            <div className="row m-0 align-items-center">
              <h6 className=" m-0  mt-1">
                (Note: {item.note.slice(0, 100)}{" "}
                {item.note.length > 15 && "..."})
              </h6>
            </div>
          ) : (
            ""
          )}

          {!item.addons.length ? (
            ""
          ) : (
            <h5 className="d-flex"> {t("single-product.Extras")}</h5>
          )}
          {item.addons
            ? item.addons.map((items, ind) => {
                return (
                  <div
                    className="row m-0 align-items-center justify-content-between cart-items"
                    key={ind}
                  >
                    {/* <div className="col-2 px-0">
                  
                  </div> */}
                    <div
                      className={`col-5 d-flex flex-column addonCart align-items-start ${
                        appState.langValue === "ar" ? `ml-auto` : `mr-auto`
                      }`}
                    >
                      <p>
                        {" "}
                        <strong>
                          {get_lang(items.addon.name, appState.langValue)}
                        </strong>
                      </p>
                      {items.price > 0 && <h6 className="text-muted m-0">
                        {items.price !== ""
                          ? parseFloat(items.price * items.quantity).toFixed(3)
                          : ""}{" "}
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </h6>}
                    </div>
                    {/* {appState.addonCart.map((items) => {
                                         <div className="row m-0 align-items-center">
                                         <h5 className="text-muted m-0">{items.addon_price
                                             ? `${items.addon_price}  ${t(`${appState.storeInfo.currencySymbol}`)}`
                                                 : ""
                                             }</h5>

                                     </div>
                                    })} */}

                    {items.required == false && (
                      <div className="col-5 px-0">
                        <div className="d-flex justify-content-end">
                          <IconButton
                            size="small"
                            onClick={() => handleAddonsRemove(items)}
                            id="removeItemAddons"
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </div>
                        <div
                          className={`d-flex mt-2 ${
                            appState.langValue === "ar"
                              ? "justify-content-start flex-row-reverse"
                              : "justify-content-end"
                          }`}
                        >
                          {items.quantity > 1 ? (
                            <div>
                              <IconButton
                                size="small"
                                className="qtyBtn"
                                onClick={() => {
                                  handleDecrementAddons(
                                    items.product_addon_id,
                                    items.category_addon_id
                                  );
                                }}
                                id="IncDecItemAddons"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <IconButton
                              id="IncDecItemAddons"
                              size="small"
                              disabled
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                          <span
                            style={{
                              fontSize: "1rem",
                              padding: "2px 14px 0px 17px",
                            }}
                          >
                            {items.quantity}
                          </span>

                          <div>
                            <IconButton
                              size="small"
                              className={
                                items.quantity === items.maxQuantity
                                  ? ""
                                  : "qtyBtn"
                              }
                              onClick={() => {
                                handleIncrementAddons(
                                  items.product_addon_id,
                                  items.category_addon_id
                                );
                              }}
                              disabled={items.quantity === items.maxQuantity}
                              id="IncDecItemAddons"
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
