/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Page from "../components/Page";

import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import OrderProduct from "../components/OrderProduct";
import { IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CircularIndeterminate from "../components/CircularIndeterminate";
import noImg from "../assets/images/no-image.svg";
import qrcodeIcon from "../assets/images/qrCode.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { freezOn, freezOff, handleError } from "../../../redux-store/action";
// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";
import processImage from "../assets/images/openOrderGif2.gif";

// redux
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Avatar from "@mui/material/Avatar";
import { getCookie } from "../../../global-functions/cookieFunction";
import { parseNumber, roundFixed } from "../../../global-functions/roundFixed";
import GroupOrderInvite from "../components/groupOrderInvite/groupOrderInvite";
import OrderProductv3 from "../components/OrderProductv3/OrderProductv3";
import OrderProductv2 from "../components/OrderProductv2/OrderProductv2";
import { getLiveData } from "../../../global-functions/pusher";
import { handleInvoice } from "../../../global-functions/customeObj";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import ShareNetwork from "../assets/images/ShareNetwork.svg";
import CryptoJS from "crypto-js";
import StatusHeader from "../components/OpenOrderStatus/StatusHeader";
import { getOpenBillFeaturedProducts } from "../../../redux-store/action/apiCall";
import FeaturedProductSlider from "../components/FeaturedProductSlider/FeaturedProductSlider";
import FeaturedProductPopup from "../components/FeaturedProductPopup/FeaturedProductPopup";
import FeaturedProductSlider2 from "../components/featuredProducts2/FeaturedProductSlider/FeaturedProductSlider2";
import { Backdrop } from "@mui/material";
import { handleOrdersPayload } from "../../../global-functions/addToCartGlobal";
import AskDailog from "../components/featuredProducts2/FeaturedProduct/AskDailog";
import StatusHeaderOffScroll from "../components/OpenOrderStatus/StatusHeaderOffScroll";
import Fade from "react-reveal/Fade";
import { getServiceName } from "../../../global-functions/variables";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function OpenOrder({ openOrders }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [dineinOrders, setDineInOrders] = useState([]);
  // console.log("dineinOrders", dineinOrders)
  const [groupBillDetails, setGroupBillDetails] = useState({});
  var initial_State = true;
  const [isLoading, setIsLoading] = useState(true);
  const [myOrdersAmount, setMyOrdersAmount] = useState(0);
  const [friendsOrdersAmount, setFriendsOrdersAmount] = useState(0);
  const [invoiceId, setInvoiceId] = useState(null);
  const [scroll, setScroll] = useState(false);
  const {
    totalAmount = 0,
    subTotalAmount = 0,
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    tip = 0,
    charges = [],
    rewardsDiscount = 0,
  } = appState.invoiceTaxesChargesExtras;
  const [url, setUrl] = useState(process.env.REACT_APP_SHOP_URL);

  const [formData, setFormData] = useState(
    JSON.parse(
      localStorage.getItem(
        `${appState.storeInfo.slug}_${getServiceName(appState)}_form`
      )
    ) !== null
      ? JSON.parse(
          localStorage.getItem(
            `${appState.storeInfo.slug}_${getServiceName(appState)}_form`
          )
        )
      : {}
  );
  const [formStatus, setFormStatus] = useState(false);
  let history = useHistory();
  const [dineInId, setDineInId] = useState(0);
  const setPayTable = () => {
    setIsLoading(true);
    setDineInOrders(appState.payTableOrders);
    setIsLoading(false);
  };

 

  const getDineIn = async () => {
    setIsLoading(true);
    getOpenBillFeaturedProducts(appState, appDispatch);

    if (appState.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${appState.groupId}`
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            if (res.service) {
              let orders = res.service.orders;
              let id = res.service?.invoice_id;
              setInvoiceId(id);
              setDineInId(response.data.data.id);
              setDineInOrders(orders);
              handleGroupBillMembers(res);
              setInvoiceId(res.service?.invoice_id);
              setGroupBillDetails(res.groupBill);
              let roundTotal = roundFixed(
                Number(res.service.amount),
                appState.storeInfo.roundLevel,
                appState.storeInfo.roundMethod
              );

              let invoiceExtras = handleInvoice(res, res.service, appState);
              appDispatch({
                type: "setInvoiceTaxesChargesExtras",
                invoiceTaxesChargesExtras: invoiceExtras,
              });
              // if(appState.loggedIn){
              //   handledoit(appState,id)
              // }
              if (!appState.pusherActive) {
                getLiveData(
                  id,
                  appState,
                  appDispatch,
                  setDineInOrders,
                  orders,
                  getDineIn
                );
              }

              // setSubTotal(roundFixed(Number(res.service.total_amount), appState.storeInfo.roundLevel, appState.storeInfo.roundMethod))
              // setDiscount(roundFixed(Number(res.service.discounted_amount), appState.storeInfo.roundLevel, appState.storeInfo.roundMethod))
              // 723(roundTotal);
              setIsLoading(false);
              let myOrdersAmountLocal = 0;
              let friendsOrdersAmountLocal = 0;
              res.service.orders.map((v, i) => {
                if (appState.deviceId == v.device_id) {
                  myOrdersAmountLocal += v.total + v.sum_addons;
                } else {
                  friendsOrdersAmountLocal += v.total + v.sum_addons;
                }
                let myAmount = roundFixed(
                  Number(myOrdersAmountLocal),
                  appState.storeInfo.roundLevel,
                  appState.storeInfo.roundMethod
                );
                let firendAmount = roundFixed(
                  Number(friendsOrdersAmountLocal),
                  appState.storeInfo.roundLevel,
                  appState.storeInfo.roundMethod
                );
                setMyOrdersAmount(myAmount);
                setFriendsOrdersAmount(firendAmount);
              });
            }
          } else if (response.data.success === false) {
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            appDispatch({
              type: "setPusherActive",
              pusherActive: false,
            });
            cleanLimitedState();
            appDispatch({
              type: "SET_TABLE",
              qrTable: null,
            });
            appDispatch({
              type: "SET_BRANCH",
              qrBranch: null,
            });
            history.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
          appDispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          cleanLimitedState();
          appDispatch({
            type: "SET_TABLE",
            qrTable: null,
          });
          appDispatch({
            type: "SET_BRANCH",
            qrBranch: null,
          });
          appDispatch(freezOff());
          history.push("/");
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${appState.userInfo.customerId}/${appState.qrBranch}`
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            if (res.service) {
              setInvoiceId(res.service?.invoice_id);
              setDineInOrders(res.service.orders);
              let roundTotal = roundFixed(
                Number(res.service.total_after_discount),
                appState.storeInfo.roundLevel,
                appState.storeInfo.roundMethod
              );
              // setTotal(roundTotal);
              setIsLoading(false);
            }
          } else if (response.data.success === false) {
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            history.push("/");
          }
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }

          appDispatch(freezOff());
          history.push("/");
        });
    } else {
      let customeLink = !appState.shopMode.includes("share-bill")
        ? `${appState.deviceId}/${appState.qrBranch}`
        : `${appState.shareBill.deviceId}/${appState.shareBill.branchId}`;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${customeLink}`
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            if (res.service) {
              let orders = res.service.orders;
              let id = res.service?.invoice_id;
              setInvoiceId(id);
              setDineInId(response.data.data.id);
              setDineInOrders(orders);
              let roundTotal = roundFixed(
                Number(res.service.amount),
                appState.storeInfo.roundLevel,
                appState.storeInfo.roundMethod
              );

              let invoiceExtras = handleInvoice(res, res.service, appState);
              appDispatch({
                type: "setInvoiceTaxesChargesExtras",
                invoiceTaxesChargesExtras: invoiceExtras,
              });
              // setSubTotal(roundFixed(Number(res.service.total_amount), appState.storeInfo.roundLevel, appState.storeInfo.roundMethod))
              // setDiscount(roundFixed(Number(res.service.discounted_amount), appState.storeInfo.roundLevel, appState.storeInfo.roundMethod))
              // setTotal(roundTotal);
              setIsLoading(false);
              // if(appState.loggedIn){
              //   handledoit(appState,id)
              // }
              if (!appState.pusherActive) {
                getLiveData(
                  id,
                  appState,
                  appDispatch,
                  setDineInOrders,
                  orders,
                  getDineIn
                );
              }
            }
          } else if (response.data.success === false) {
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            history.push("/");
          }
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
            setIsLoading(false);
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
          appDispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          appDispatch(freezOff());
          history.push("/");
        });
    }
  };

  const addOpenBill = async () => {
    if (
      appState.loggedIn ||
      appState.shopMode.includes("share-bill") ||
      appState.groupId
    ) {
      appDispatch(freezOn());
      var meta = appState.meta;
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/add_open_bill`, {
          table_id: appState.qrTable,
          branch_id: appState.qrBranch,
          device_id: appState.shopMode.includes("share-bill")
            ? appState.shareBill.deviceId
            : appState.deviceId,
          customer_id: appState.shopMode.includes("share-bill")
            ? appState.shareBill.customerId
            : appState.loggedIn
            ? appState.userInfo.customerId
            : null,
          group_id: appState.groupId != null ? appState.groupId : null,
          meta: meta ?? null,
          orders: handleOrdersPayload(appState.confirmOrder),
          dine_in_id: dineInId,
        })
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            if (res.service) {
              let orders = res.service.orders;
              let id = res.service?.invoice_id;
              setInvoiceId(id);
              setDineInId(response.data.data.id);
              setDineInOrders(orders);
              setInvoiceId(res.service?.invoice_id);
              let roundTotal = roundFixed(
                Number(res.service.amount),
                appState.storeInfo.roundLevel,
                appState.storeInfo.roundMethod
              );

              let invoiceExtras = handleInvoice(res, res.service, appState);
              appDispatch({
                type: "setInvoiceTaxesChargesExtras",
                invoiceTaxesChargesExtras: invoiceExtras,
              });
              if (!appState.pusherActive) {
                getLiveData(
                  id,
                  appState,
                  appDispatch,
                  setDineInOrders,
                  orders,
                  getDineIn
                );
              }
              setIsLoading(false);
              let myOrdersAmountLocal = 0;
              let friendsOrdersAmountLocal = 0;
              res.service.orders.map((v, i) => {
                if (appState.deviceId == v.device_id) {
                  myOrdersAmountLocal += v.total + v.sum_addons;
                } else {
                  friendsOrdersAmountLocal += v.total + v.sum_addons;
                }
                let myAmount = roundFixed(
                  Number(myOrdersAmountLocal),
                  appState.storeInfo.roundLevel,
                  appState.storeInfo.roundMethod
                );
                let firendAmount = roundFixed(
                  Number(friendsOrdersAmountLocal),
                  appState.storeInfo.roundLevel,
                  appState.storeInfo.roundMethod
                );
                setMyOrdersAmount(myAmount);
                setFriendsOrdersAmount(firendAmount);
              });
            }
            appDispatch(freezOff());
          } else if (response.data.success === false) {
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.data + response.data.message}`,
            });
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }

          appDispatch(freezOff());
        });
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
    appDispatch({
      type: "setConfirmPopup",
      confirmPopup: false,
      confirmOrder: [],
    });
  };

  // useEffect(async () => {
  //   if (invoiceId != '' && dineinOrders?.length > 0 && initial_State) {
  //     initial_State = false;
  //     console.log('me marwa raha hon',initial_State)
  //     let liveRes = await
  //   }
  // }, [invoiceId, dineinOrders])

  useEffect(() => {
    const handleShareInvoice = () => {
      const key = "hsDatawLRTS6RmGM";
      const encrypted = CryptoJS.AES.encrypt(`${invoiceId}`, key).toString();
      let store_slug = appState.storeInfo.slug;
      let link = `${process.env.REACT_APP_SHOP_URL}/${store_slug}?share-bill=${encrypted}`;
      setUrl(link);
      appDispatch({
        type: "setShareBillUrl",
        shareBillUrl: link,
      });
    };
    if (invoiceId) {
      handleShareInvoice();
    }
  }, [invoiceId]);

  const handleGroupBillMembers = (res) => {
    let groupBillOrders = [];
    let members = res.groupBill.members;
    for (var i = 0; i < res.service.orders.length; i++) {
      if (appState.deviceId == res.service.orders[i].device_id) {
        let order = { ...res.service.orders[i], orderOwner: "my_order" };
        groupBillOrders.push(order);
      } else if (appState.deviceId != res.service.orders[i].device_id) {
        let foundObject = members.find(
          (obj) => obj.device_id == res.service.orders[i].device_id
        );
        if (foundObject) {
          let order = {
            ...res.service.orders[i],
            orderOwner:
              foundObject.customer != null ? foundObject.customer.name : null,
          };
          groupBillOrders.push(order);
        } else {
          let order = { ...res.service.orders[i], orderOwner: null };
          groupBillOrders.push(order);
        }
      }
    }
    setDineInOrders(groupBillOrders);
  };

  useEffect(() => {
    appDispatch(freezOff());
    if (appState.qrBranch != null) {
      if (appState.payTableMode) {
        setPayTable();
      } else {
        getDineIn();
      }
    } else {
      history.push("/");
    }
  }, []);

  // @Functions
  const orderNow = async (e) => {
    localStorage.setItem(
      `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
      JSON.stringify({ ...formData, order_invoice: "open_bill" })
    );
    appDispatch({
      type: "SET_FORM_DATA",
      formData: formData,
      order_invoice: "open_bill",
    });
    // setFormStatus(true);
    history.push(`/checkout${appState.orderQrCode}`);
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  useEffect(() => {
    if (dineinOrders.length > 4) {
      const scrolling = () => {
        const handleScroll = () => {
          setScroll(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      };

      scrolling();
    }
  }, [dineinOrders]);

  if (isLoading)
    return (
      <>
        <div className="row mx-1">
          <div className="col-1 col-md-1 px-2">
            <Skeleton height={50} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="row mx-1 d-flex justify-content-between">
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
        </div>

        {[0, 1, 2].map((a) => (
          <div key={a} className="row m-0 mb-4 mt-3">
            <div className="col-3 col-md-2 px-2">
              <Skeleton variant="rect" className="prRectSkeleton" />
            </div>
            <div className="col-9 col-md-10 px-2">
              <Skeleton
                height={30}
                style={{
                  transform: "translate(2px, 0)",
                  marginBottom: "10px",
                }}
              />
              <Skeleton height={25} />
              <Skeleton height={25} />
            </div>
          </div>
        ))}
      </>
    );

  // @Functions
  return (
    <Page title="OpenBill">
      {!formStatus ? (
        <div id="wrapper">
          <section id="topNav" className="fixed-top">
            <nav className="bckNavbar col-md-12 col-xl-6 offset-xl-3 mr-auto d-flex justify-content-between align-items-center p-0 px-1 ">
              <div className="d-flex align-items-center">
                <Link to="/">
                  <IconButton
                    aria-label="back"
                    className={
                      appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                    }
                    // orderbckBtn"
                  >
                    {appState.langValue === "ar" ? (
                      <ArrowForwardIosIcon
                        style={{ padding: "0px !important" }}
                        fontSize="inherit"
                      />
                    ) : (
                      <ArrowBackIosIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Link>
              </div>
              {/* <p className="text-center ">{get_lang(appState.storeInfo.businessName, appState.langValue)}</p> */}
              <h5 className="mt-2 d-flex">{t("open-order.Open-Order")}</h5>

              <div>
                {appState.langValue == "ar" ? (
                  <IconButton
                    id="headerBtn"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    <span className={`lang-textScroll p-0`}>EN</span>
                  </IconButton>
                ) : (
                  <IconButton
                    id="headerBtn"
                    onClick={(e) => arLanguageHandle(e)}
                    className="arabicBtn"
                  >
                    <span className={`arabicText lang-textScroll p-0 mb-2`}>
                      عربي{" "}
                    </span>
                  </IconButton>
                )}
              </div>
            </nav>
          </section>

          <div id="openOrder" className="mt-5">
            {<StatusHeaderOffScroll url={url} scroll={scroll} />}

            {appState.openBillFeatured.length > 0 && (
              <FeaturedProductSlider2
                product={appState.openBillFeatured}
                placedBy={2}
                type="open-bill"
                invoiceId={dineInId}
                addOpenBill={addOpenBill}
                scroll={scroll}
              />
            )}

            {appState.serviceMode.includes("group-bill") &&
              appState.isAddToCartEnable &&
              appState.groupBillMode && (
                <GroupOrderInvite callingFrom="openOrder" />
              )}
            <section id="order-pro" className="mt-4">
              {dineinOrders && (
                <h4
                  className={` yourOrderhead mb-4 familyPoppins300 ${
                    appState.langValue == "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {" "}
                  {t("open-order.Your-Order")}:
                </h4>
              )}
              {dineinOrders.length > 0 && dineinOrders != undefined ? (
                dineinOrders.map((item, ind) => (
                  <div className="w-100" key={ind}>
                    {/* <OrderProduct item={item} /> */}
                    {/* <OrderProductv2
                      groupBillDetails={groupBillDetails}
                      index={ind}
                      item={item}  
                    /> */}
                    <OrderProductv3
                      groupBillDetails={groupBillDetails}
                      index={ind}
                      item={item}
                    />
                    {ind === dineinOrders.length - 1 ? "" : <hr />}
                  </div>
                ))
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <img className="img-pro" src={`/images/Frame.png`} alt="" />
                  <h1> {t("cart.No-items")}</h1>
                  <p> {t("cart.You-don�t-have-any-items-in-your-basket")}</p>
                </div>
              )}
            </section>

            <AskDailog addOpenBill={addOpenBill} />

            {dineinOrders.length > 0 && (
              <>
                {/* <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 5,
              }}
              open={appState.confirmPopup}
            /> */}
                {!appState.confirmPopup && (
                  <section
                    id="btnSection"
                    // style={{ zIndex: "9999 !important" }}
                    className={`${
                      appState.langValue === "ar" ? "ml-auto" : "mr-auto"
                    } col-12 col-xl-6 offset-xl-3 mr-auto bottomFixedBtn openBillDetails py-2  ${
                      appState.confirmPopup ? "confirmPopup-disabled" : ""
                    }`}
                  >
                    <div className="row px-2">
                      {(totalAmount > 0 && appState.storeInfo?.hasPayMethods) && (
                        <div className="col-12 px-1">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={`btn-main d-flex justify-content-between ${
                              appState.confirmPopup
                                ? "confirmPopup-disabled"
                                : ""
                            }`}
                            id="payNow"
                            onClick={orderNow}
                          >
                            <span className="px-2">
                              {t("checkOut.CheckOut")}
                            </span>
                            <div className="iconsParent d-flex justify-content-center">
                              {appState.acceptedPayments.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {item === "knet" ? (
                                      <span className=" iconsBg ">
                                        <img
                                          className="img-pr"
                                          // width="25"
                                          src={`/images/pat-knet.svg`}
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {item === "card" ? (
                                      <span className="iconsBg">
                                        <img
                                          className="img-pr"
                                          // width="25"
                                          src={`/images/visa-small.svg`}
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {item == "apple_pay" ? (
                                      <span key={index} className="iconsBg">
                                        <img
                                          className="img-pr"
                                          width="50"
                                          src={`/images/pat-apple-n.svg`}
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </Button>
                        </div>
                      )}
                      <div className={` ${`col-12`}  mt-3 px-1`}>
                        <Link to="/">
                          <Button
                            className="add-new h-100  w-100 py-3"
                            startIcon={<AddIcon />}
                            id="addMore"
                          >
                            <span className="px-1">{t("cart.Add-More")}</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </section>
                )}{" "}
              </>
            )}
          </div>
        </div>
      ) : (
        // <Redirect push to={`/checkout${appState.orderQrCode}`} /> 
        ''
      )}
    </Page>
  );
}

export default withTranslation()(OpenOrder);
