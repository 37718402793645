import axios from "axios";
import { freezOff, handleError } from "../redux-store/action";
import { getCountryCode, getPhoneNumber, handelNumber } from "./splitCountryCode";
export const getApplePayToken = async (
  history,
  appState,
  appDispatch,
  setPLoading,
  setTapId,
  closePayTable,
  cleanRest,
  checkoutData,
  ApplePaySession,
  handleCloseAll
) => {
  if (!ApplePaySession) {
    return;
  }
  let data = checkoutData;
  console.log(appState.storeInfo.countryNameCode)
  console.log(appState.storeInfo.countryNameCodess)

  const request = {
    requiredShippingContactFields: appState?.payTableMode ? ["phone", "name", "email"]  : !appState.loggedIn ? ["phone", "name", "email"]  : [],
    countryCode: `${appState.storeInfo.countryNameCode}`,
    currencyCode: appState.storeInfo.currencySymbol,
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: {
      label: "Pay with Apple Pay",
      type: "final",
      amount: appState.splitBill ? data.splitBillAmount : data.openBillTotal,
    },
  };

  const session = new ApplePaySession(6, request);
  console.log(request,"request");
  console.log(session,"session");


   session.onvalidatemerchant = async (event) => {
    let merchantBackendUrl = `${process.env.REACT_APP_API_URL}/apple-pay/merchant-validation`;
    let appUrl = `${process.env.REACT_APP_APP_DOMAIN}`;
    let body = {
      validation_url: event.validationURL,
      domain: appUrl
    };
    fetch(merchantBackendUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((merchantSession) => {
        session.completeMerchantValidation(merchantSession);
      })
      .catch((error) =>    appDispatch(handleError(error))      ); // We need to handle the error instead of just logging it to // console.
  }; // See "Providing Merchant Validation"
  session.onpaymentauthorized = async (event) => {
    const token = await event.payment.token;
    let  applePayCustomerInfo ;
    //console.log("onpaymentauthorized1",event)
    //console.log("onpaymentauthorized2",JSON.stringify(event));
    if(event?.payment?.shippingContact){
    //console.log("onpaymentauthorized3",JSON.stringify(event?.payment?.shippingContact?.phoneNumber));
      const formatedNumber = handelNumber(event?.payment?.shippingContact?.phoneNumber);
    //console.log("onpaymentauthorized4",JSON.stringify(formatedNumber));

      const countryCode = getCountryCode(formatedNumber);
    //console.log("onpaymentauthorized5",JSON.stringify(countryCode));

      const phoneNumber = getPhoneNumber(formatedNumber,countryCode)
    //console.log("onpaymentauthorized6",JSON.stringify(phoneNumber));

       applePayCustomerInfo = await {
        name:event?.payment?.shippingContact?.givenName,
        number:phoneNumber, 
        email:event?.payment?.shippingContact?.emailAddress,
        countryCode:countryCode,
      }
    }

    let tokenObj = await token.paymentData;

    //console.log("onpaymentauthorized7",JSON.stringify(data.formData.order_invoice));

    if (data.formData.order_invoice === "pick_up") {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/pick_up`,
         {
          full_name:
            !appState.loggedIn
              ?  applePayCustomerInfo?.name
              : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? applePayCustomerInfo?.email : appState.userInfo.email,
          number: !appState.loggedIn ? applePayCustomerInfo?.number : appState.userInfo.number,
          country_code:
            !appState.loggedIn ? applePayCustomerInfo?.countryCode : appState.userInfo.countryCode,
            branch_id: appState.qrBranch,
            pickup_qrcode_id: appState.qrPickup,
          store_id: appState.storeInfo.storeId,
          orders: data.orders,
          device_id: appState.deviceId,
          customer_id: appState.loggedIn
            ? appState?.userInfo 
              ? appState?.userInfo?.customerId
              : null
            : null,
          user_id: appState.loggedIn
            ? appState?.userInfo 
              ? appState?.userInfo?.userId
              : null
            : null,
          promo_code: data.promoCode ? data.promoCode : null,
          isOpen: false,
          payment_source: appState.splitBill ? "partial" : "apple_pay",
          note: data.formData.note ? data.formData.note : "N/A",
          token: data.tapId != "" ? data.tapId : null,
          tip:
            data.manualTip > 0
              ? data.manualTip
              : data.customeTip > 0
              ? data.customeTip
              : 0,
          apple_pay_token: token.paymentData,
          meta: {
            data:
              data.partialType == "ratio"
                ? {
                    person: data.totalPerson,
                    payer: data.personWillPay,
                  }
                : data.partialType == "product"
                ? data.paySelectedItems
                : null,
            partial_type: data.partialType,
          },
          payment: appState.splitBill
            ? {
                payment_source: "apple_pay",
                type: data.partialType,
                token: data.tapId != "" ? data.tapId : "",
                card_id: null,
                meta: {
                  data:
                    data.partialType == "ratio"
                      ? {
                          person: data.totalPerson,
                          payer: data.personWillPay,
                        }
                      : data.partialType == "product"
                      ? data.paySelectedItems
                      : null,
                  partial_type: data.partialType,
                },
                amount: data.splitBillAmount,
                apple_pay_token: token.paymentData,
              }
            : [],
          isGroup: 0,
          save_card: false,
          campaign:  appState.campaignState.length > 0 ? appState.campaignState[0] : null,

        },{
          headers: {
            ContentType: 'application/json',
          }
        })
        .then((response) => {
          if (response.data.success === true) {
             //console.log('response true 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response true 2')
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, data.orders, history));
            if (appState.payTableMode) {
              appDispatch(closePayTable(appState));
            }
            setTapId("");
            setPLoading(false);
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });
          }
          if (response.data.success == false) {
             //console.log('response false 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response false 2')
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
            });
          
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
           //console.log('response catch 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response catch 2')
          if (error !== undefined) {
            // appDispatch(handleError(error))
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error?.response?.data?.message,
            });
          } else {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          setPLoading(false);
          appDispatch(freezOff());
          handleCloseAll();
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
        });
    }
    else if (data.formData.order_invoice === "dine_in") {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/dine_ins`,
         {
          full_name:
            !appState.loggedIn
              ?  applePayCustomerInfo?.name
              : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? applePayCustomerInfo?.email : appState.userInfo.email,
          number: !appState.loggedIn ? applePayCustomerInfo?.number : appState.userInfo.number,
          country_code:
            !appState.loggedIn ? applePayCustomerInfo?.countryCode : appState.userInfo.countryCode,
          table_id: appState.qrTable,  
          branch_id: appState.qrBranch,
          store_id: appState.storeInfo.storeId,
          orders: data.orders,
          device_id: appState.deviceId,
          customer_id: appState.loggedIn
            ? appState?.userInfo 
              ? appState?.userInfo?.customerId
              : null
            : null,
          user_id: appState.loggedIn
            ? appState?.userInfo 
              ? appState?.userInfo?.userId
              : null
            : null,
          promo_code: data.promoCode ? data.promoCode : null,
          isOpen: false,
          payment_source: appState.splitBill ? "partial" : "apple_pay",
          note: data.formData.note ? data.formData.note : "N/A",
          token: data.tapId != "" ? data.tapId : null,
          tip:
            data.manualTip > 0
              ? data.manualTip
              : data.customeTip > 0
              ? data.customeTip
              : 0,
          apple_pay_token: token.paymentData,
          meta: {
            data:
              data.partialType == "ratio"
                ? {
                    person: data.totalPerson,
                    payer: data.personWillPay,
                  }
                : data.partialType == "product"
                ? data.paySelectedItems
                : null,
            partial_type: data.partialType,
          },
          payment: appState.splitBill
            ? {
                payment_source: "apple_pay",
                type: data.partialType,
                token: data.tapId != "" ? data.tapId : "",
                card_id: null,
                meta: {
                  data:
                    data.partialType == "ratio"
                      ? {
                          person: data.totalPerson,
                          payer: data.personWillPay,
                        }
                      : data.partialType == "product"
                      ? data.paySelectedItems
                      : null,
                  partial_type: data.partialType,
                },
                amount: data.splitBillAmount,
                apple_pay_token: token.paymentData,
              }
            : [],
          isGroup: 0,
          save_card: false,
          campaign:  appState.campaignState.length > 0 ? appState.campaignState[0] : null,

        },{
          headers: {
            ContentType: 'application/json',
          }
        })
        .then((response) => {
          if (response.data.success === true) {
             //console.log('response true 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response true 2')
            appDispatch({
              type: "setOrderInvoice",
              orderInvoice: response.data.data.service,
            });
            appDispatch(cleanRest(appState, response, data.orders, history));
            if (appState.payTableMode) {
              appDispatch(closePayTable(appState));
            }
            setTapId("");
            setPLoading(false);
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });
          }
          if (response.data.success == false) {
             //console.log('response false 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response false 2')
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
            });
          
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
          }
        })

        .catch((error) => {
           //console.log('response catch 1')
            setPLoading(false);
            appDispatch(freezOff());
            handleCloseAll();
             //console.log('response catch 2')
          if (error !== undefined) {
            // appDispatch(handleError(error))
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error?.response?.data?.message,
            });
          } else {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          setPLoading(false);
          appDispatch(freezOff());
          handleCloseAll();
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
        });
    } else if(data.formData.order_invoice === "hotel_order"){
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/order_invoice/hotel_order`,
          {qrcode_id: Number(appState.hotelQrCode),
            full_name:
            !appState.loggedIn
              ?  applePayCustomerInfo?.name
              : appState.userInfo.userFirstName,
          email: !appState.loggedIn ? applePayCustomerInfo?.email : appState.userInfo.email,
          number: !appState.loggedIn ? applePayCustomerInfo?.number : appState.userInfo.number,
          country_code:
            !appState.loggedIn ? applePayCustomerInfo?.countryCode : appState.userInfo.countryCode,
            branch_id: appState.qrBranch,
            store_id: appState.storeInfo.storeId,
            orders: data.orders,
            device_id: appState.deviceId,
            customer_id: appState.loggedIn
              ? appState?.userInfo 
                ? appState?.userInfo?.customerId
                : null
              : null,
            user_id: appState.loggedIn
              ? appState?.userInfo 
                ? appState?.userInfo?.userId
                : null
              : null,
            promo_code: data.promoCode ? data.promoCode : null,
            isOpen: false,
            payment_source: appState.splitBill ? "partial" : "apple_pay",
            note: data.formData.note ? data.formData.note : "N/A",
            token: data.tapId != "" ? data.tapId : null,
            tip:
              data.manualTip > 0
                ? data.manualTip
                : data.customeTip > 0
                ? data.customeTip
                : 0,
            apple_pay_token: token.paymentData,
            meta: {
              data:
                data.partialType == "ratio"
                  ? {
                      person: data.totalPerson,
                      payer: data.personWillPay,
                    }
                  : data.partialType == "product"
                  ? data.paySelectedItems
                  : null,
              partial_type: data.partialType,
            },
            payment: appState.splitBill
              ? {
                  payment_source: "apple_pay",
                  type: data.partialType,
                  token: data.tapId != "" ? data.tapId : "",
                  card_id: null,
                  meta: {
                    data:
                      data.partialType == "ratio"
                        ? {
                            person: data.totalPerson,
                            payer: data.personWillPay,
                          }
                        : data.partialType == "product"
                        ? data.paySelectedItems
                        : null,
                    partial_type: data.partialType,
                  },
                  amount: data.splitBillAmount,
                  apple_pay_token: token.paymentData,
                }
              : [],
            isGroup: 0,
            save_card: false,
            campaign:  appState.campaignState.length > 0 ? appState.campaignState[0] : null,
  
          },{
            headers: {
              ContentType: 'application/json',
            }
          }
        )
        .then((response) => {
         
 
          setPLoading(false);
          appDispatch(freezOff());
          if (response.data.success === true) {
            appDispatch(cleanRest(appState, response, data.orders, history));
            setTapId("");
            setPLoading(false);
            if (appState.payTableMode) {
              appDispatch(closePayTable(appState));
            }
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });
            return;
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
            handleCloseAll();
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
            });
           
          }
        })

        .catch((error) => {
          //console.log(JSON.stringify(error.response));
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
          if (error !== undefined) {
            // appDispatch(handleError(error))
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error?.response?.data?.message,
            });
          } else {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
          setPLoading(false);
          appDispatch(freezOff());
          handleCloseAll();
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/order_invoice/close_open_bill`,
          {
            table_id: appState.qrTable,
            invoice_id:
            appState.oldInvoice != null
              ? appState.oldInvoice
              : data?.invoiceId != null
              ? data?.invoiceId
              : null,
              full_name: appState.shopMode.includes('share-bill') ? appState.shareBill?.name : !appState.loggedIn
              ? applePayCustomerInfo?.name
              : appState.userInfo.userFirstName,
              email: !appState.loggedIn ? applePayCustomerInfo?.email : appState.userInfo.email,
            device_id:  appState.shopMode.includes('share-bill') ? appState.shareBill?.deviceId : appState.deviceId,
            customer_id: appState.shopMode.includes('share-bill') ? appState.shareBill?.customerId :
            appState.loggedIn
              ? appState.userInfo.customerId
              : null,
              number: appState.shopMode.includes('share-bill') ? appState.shareBill?.number : !appState.loggedIn ? applePayCustomerInfo?.number : appState.userInfo.number,
              country_code: appState.shopMode.includes('share-bill') ? appState.shareBill?.countryCode : !appState.loggedIn
              ? applePayCustomerInfo?.countryCode
              : appState.userInfo.countryCode,
            payment_source: appState.splitBill ? "partial" : "apple_pay",
            meta: {
              data:
                data.partialType == "ratio"
                  ? {
                      person: data.totalPerson,
                      payer: data.personWillPay,
                    }
                  : data.partialType == "product"
                  ? data.paySelectedItems
                  : null,
              partial_type: data.partialType,
            },
            note: data.formData.note ? data.formData.note : "N/A",
            token: "",
            promo_code: data.promoCode ? data.promoCode : null,
            card_id: null,
            apple_pay_token: token.paymentData,
            tip:
              data.manualTip > 0
                ? data.manualTip
                : data.customeTip > 0
                ? data.customeTip
                : 0,
            payment: appState.splitBill
              ? {
                  payment_source: "apple_pay" ,
                  type: data.partialType,
                  token: data.tapId != "" ? data.tapId : "",
                  card_id: null,
                  meta: {
                    data:
                      data.partialType == "ratio"
                        ? {
                            person: data.totalPerson,
                            payer: data.personWillPay,
                          }
                        : data.partialType == "product"
                        ? data.paySelectedItems
                        : null,
                    partial_type: data.partialType,
                  },
                  amount: data.splitBillAmount,
                  apple_pay_token: token.paymentData,
                }
              : [],
              isGroup: 0,
          save_card: false,
          campaign:  appState.campaignState.length > 0 ? appState.campaignState[0] : null,

          },{
            headers: {
              ContentType: 'application/json',
            }
          }
        )
        .then((response) => {
         
          setPLoading(false);
          appDispatch(freezOff());
          if (response.data.success === true) {
            appDispatch(cleanRest(appState, response, data.orders, history));
            setTapId("");
            setPLoading(false);
            if (appState.payTableMode) {
              appDispatch(closePayTable(appState));
            }
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });
            return;
          }
          if (response.data.success == false) {
            setPLoading(false);
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.message} : ${response.data.data} `,
            });
            handleCloseAll();
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
            });
           
          }
        })

        .catch((error) => {
          //console.log(JSON.stringify(error.response));
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
          if (error !== undefined) {
            // appDispatch(handleError(error))
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error?.response?.data?.message,
            });
          } else {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [error.toString()],
          });
          setPLoading(false);
          appDispatch(freezOff());
          handleCloseAll();
        });
    }
  }; // See "Authorizing Payment"


  session.oncancel = event => {
     console.log('oncancle 1',event)
    setPLoading(false);
    appDispatch(freezOff());
    handleCloseAll();
     console.log('oncancle 2',event)
    session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
    });
};

  session.begin();
};