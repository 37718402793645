/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import CircularIndeterminate from "../components/CircularIndeterminate";
import Product from "../components/Product";
import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import StateContext from "../../../store/StateContext";
import AppBar from "@material-ui/core/AppBar";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@material-ui/core/Tab";
import MiscBanner from "../components/MiscBanner";
import Page from "../components/Page";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";

// redux
import { useSelector, useDispatch } from "react-redux";
import AddToCartPopup from "../components/addToCartPopup/addToCartPopup";
import { handleCategory } from "../../../redux-store/action";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import Images from "../assets/images/images";
import noImg from "../../js/assets/images/no-image.svg";
import {
  filterTagDiet,
  iconsTagDiet,
} from "../../../global-functions/filterTagDiet";
import { handleMealType } from "../../../global-functions/mealType";
import { sortedByNumber } from "../../../utils";
import Productv2 from "../components/Productv2/Productv2";
import ChildCategory from "../components/ChildCategory/ChildCategory";
import CategoryProductsV2 from "../components/categoryProductsV2/CategoryProductsV2";
import SingleProductPopup from "../components/singleProductPopup/SingleProductPopup";
import {
  Backdrop,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
const Products = () => {
  const { t } = useTranslation();
  var { slug } = useParams();
  var slug = slug.trim();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let parent_id = searchParams.get("parent");
  let cat_id = searchParams.get("catid");
  // cat_id = cat_id != `null` ? cat_id : parent_id
    const [categoryImg, setCategoryImg] = useState("");
  const [childCategory, setChildCategory] = useState([]);
  const [isChildLoading, setIsChildLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [activeVTabs, setActiveVTabs] = useState(0);
  const [value, setValue] = React.useState(0);
  const [parentValue, setParentValue] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [childProducts, setChildProducts] = useState([]);
  const [id, setId] = useState(0);
  const [catId, setCatId] = useState("");
  const [tabsCategory, setTabsCategory] = useState([]);
  const [isTabsLoading, setIsTabsLoading] = useState(true);
  const [singleProductPopupShow, setSingleProductPopupShow] = useState(false);
  const [productID, setProductID] = useState(null);
  const [productSlug, setProductSlug] = useState(null);
  const [productInfo, setProductInfo] = useState({
    productID: null,
    productSlug: null,
    prodCatSlug: null,
  });
  let pSlug = "";
 
  let initial_mount = false;

  useEffect(() => {
    appDispatch({
      type: "setProductGroupId",
      productGroupId: null,
    });
    appDispatch({
      type: "SET_FEATURED_PRODUCTS",
      featuredProducts: null,
    });
  }, []);


  const getIdRes = async () => {
    if (appState.storeInfo.subCategory) {
      appState.parentCategories.length > 0 &&
      appState.parentCategories.forEach((category, ind) => {
        if (category.id == parent_id) {
            setParentValue(ind);
            if(category?.children?.length == 0){
    console.log(id,'getrestg')

              setId(parent_id)
              setCategoryImg(category.image)
            }else{
              category.children.forEach((child, ind) => {
                if (child.id == cat_id) {
                  setValue(ind);
                }
              });
            }
            
          }
        });
        for (var i = 0; i < appState.categories.length; i++) {
          // if()
         if (slug === appState.categories[i].slug) {
          var idValue = await appState.categories[i].id;
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          if (appState.categories[i] == cat_id) {
            setValue(i);
          }
        }
      }
    } else {
      for (const category of appState.categories) {
        // Check if the slug matches the main category
        if (slug === category.slug) {
          const idValue = await category.id;
          setId(idValue);
          setCategoryImg(category.image);
          setActiveVTabs(idValue);
          setValue(appState.categories.indexOf(category));
          break; // Stop further processing once a match is found
        }

        // Check if the slug matches any child category
        if (category.children?.length > 0) {
          const childMatch = category.children.find(
            (child) => child.slug === slug
          );
          if (childMatch) {
            const idValue = await category.id;
            setId(idValue);
            setCategoryImg(category.image);
            setActiveVTabs(idValue);
            setValue(appState.categories.indexOf(category));
            break; // Stop further processing once a match is found
          }
        }
      }
    }

    if (cat_id) {
      setIsChildLoading(true);
      let childArr = [];
      appState.categories.forEach((category) => {
        if (category?.children?.length > 0) {
          category.children.forEach((child) => {
            if (child?.parent?.id == cat_id) {
              childArr.push(child);
            }
          });
        }
      });

      setChildCategory(childArr);
    }
  };
  const toCart = () => {
    history.push("/cart");
  };
  useEffect(() => {
    getIdRes();
    appDispatch({
      type: "setPayTablePopUp",
      payTablePopUp: false,
    });
  }, [slug]);

  const handleChildCategory = async (id, childCategory, type) => {
    const res = await appDispatch(
      handleCategory(
        id,
        appState,
        cat_id,
        slug,
        childCategory,
        "childProductFlow"
      )
    );

    let findCategory = res;
    let product = findCategory;
    let arr = [];

    // Get tag-diet response for the product
    const tagDietResponse = await iconsTagDiet(
      arr,
      product,
      Images,
      appDispatch
    );
    arr = tagDietResponse;

    let finalArray;
    if (
      appState.productTags.length > 0 ||
      appState.productDietaries.length > 0
    ) {
      // Filter tags/diets based on appState if conditions are met
      const filterRes = await filterTagDiet(arr, appState, appDispatch);
      finalArray = filterRes;
    } else {
      finalArray = arr;
    }
    setChildProducts((prevChildProducts = []) => {

      const uniqueProducts = [
        ...prevChildProducts,
        ...finalArray.filter((newItem) => {
          const isDuplicate = prevChildProducts.some(
            (existingItem) => existingItem.id === newItem.id
          );
          if (!newItem.id) {
            console.warn("Skipping item without an ID:", newItem); // Warn for items without an ID
          } else if (isDuplicate) {
            console.log("Duplicate item found:", newItem); // Log duplicates
          }
          return newItem.id && !isDuplicate;
        }),
      ];

      console.log("Updated child products:", uniqueProducts); // Log the final unique list
      return uniqueProducts;
    });
    // Update childProducts state by preserving old data and adding new data without duplicates
    setChildProducts((prevChildProducts) => {
      // Use a Set to filter out duplicates based on unique identifiers (e.g., `id`)
      const uniqueProducts = [
        ...prevChildProducts,
        ...finalArray.filter(
          (newItem) =>
            !prevChildProducts.some(
              (existingItem) => existingItem.id === newItem.id
            )
        ),
      ];
      return uniqueProducts;
    });
  };
  const fetchProudct = async () => {
    // var prodArry = "";
    setIsTabsLoading(true);
    if (appState.storeInfo.progressive) {
      
      if (appState.categories.length !== 0) {
        var prodArr = [];
        var catArr = [];
        let ProductOrderListCount = 0;
        for (var i = 0; i < appState.categories.length; i++) {
          if (id === appState.categories[i].id) {
            catArr.push(appState.categories[i]);
            if (true) {
              setIsLoading(true);
              appDispatch({
                type: "setCurrentCategory",
                currentCategory:
                  id === appState.categories[i].id
                    ? appState.categories[i]
                    : appState.currentCategory,
              });

              var prodVal = await appState.categories[i].products;
              // if (appState.qrBranch != null) {
              prodVal.forEach((ele) => {
                let inActive = ele?.inactive_branches ?? [];
                let prodShow = !inActive.includes(appState.qrBranch);
                let has_parent = ele?.parent == null ? true : false;
                let mealTypes = ele?.mealTypes;
                // let prodShow = true;
                if (mealTypes?.length > 0 && appState.qrBranch && prodShow) {
                  prodShow = handleMealType(mealTypes, appState.qrBranch);
                }

                if (!appState.storeInfo?.showSoldProduct && prodShow) {
                  prodShow = ele.quantity == 0 ? false : true;
                }

                if (prodShow) {
                  ele.order = ele?.order_list ?? null;
                  ProductOrderListCount =
                    ele?.order_list ?? ProductOrderListCount + 1;
                  prodArr.push(ele);
                }
              });
              // if (appState.categories[i]?.children?.length > 0) {
              if (false) {
                //stoped
                for (
                  var j = 0;
                  j < appState.categories[i]?.children?.length;
                  j++
                ) {
                  var prodVal = await appState.categories[i].children[j]
                    .products;
                  catArr.push(appState.categories[i].children[j]);
                  prodVal.forEach((ele) => {
                    let inActive = ele?.inactive_branches ?? [];
                    let prodShow = !inActive.includes(appState.qrBranch);
                    let has_parent = ele?.parent == null ? true : false;
                    let mealTypes = ele?.mealTypes;
                    // let prodShow = true;
                    if (
                      mealTypes?.length > 0 &&
                      appState.qrBranch &&
                      prodShow
                    ) {
                      prodShow = handleMealType(mealTypes, appState.qrBranch);
                    }

                    if (!appState.storeInfo?.showSoldProduct && show) {
                      show = ele.quantity == 0 ? false : true;
                    }

                    if (prodShow) {
                      ele.order = ele?.order_list ?? ProductOrderListCount;
                      ProductOrderListCount =
                        ele?.order_list ?? ProductOrderListCount + 1;
                      prodArr.push({
                        ...ele,
                        category: appState.categories[i],
                        categorySlug: appState.categories[i].slug,
                        categoryId: appState.categories[i].id,
                      });
                    }
                  });
                }
              }
            }
            prodArr = sortedByNumber(prodArr);
          }
          let arr = [];

          const tagDietResponse = await iconsTagDiet(
            arr,
            prodArr,
            Images,
            appDispatch
          );
          arr = tagDietResponse;
          if (
            appState.productTags.length > 0 ||
            appState.productDietaries.length > 0
          ) {
            const filterRes = await filterTagDiet(arr, appState, appDispatch);
            setProducts(filterRes);
          } else {
            setProducts(arr);
          }
          setTabsCategory(catArr);
        }
      }
    }
    setIsTabsLoading(false);
    // else {
    //   if (appState.products.length > 0) {
    //     setProducts(appState.products);
    //   }
    // }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   
  // }, [products]);

  useEffect(() => {
    

    const getAllProducts = async () => {
      
      if (id != 0) {
        if (appState.storeInfo.progressive) {
          fetchProudct();
        } else {
          

          if (cat_id != catId) {
            
            let findCategory = undefined;
            if (findCategory != undefined) {
              let arr = [];
              let product = findCategory.data;
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(
                  arr,
                  appState,
                  appDispatch
                );
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
            } else {
              // let catID = cat_id;
              const trimmedCatId = cat_id ? cat_id.trim() : null;
              const trimmedParentId = parent_id ? parent_id.trim() : null;
               let findCat = trimmedParentId == trimmedCatId ? appState.parentCategories.find((cat) =>   cat.id == trimmedParentId  )  : appState.categories.find((cat) =>   cat.id == trimmedCatId  );
            let findCategoryId = findCat?.id;
          
            if(findCat == undefined) {
             findCat = appState.categories.find((cat) =>   cat.slug == slug  )
             findCategoryId = findCat?.id
            }
              

              const res = await appDispatch(
                handleCategory(
                  findCategoryId,
                  appState,
                  parent_id,
                  slug,
                  findCat,
                  "normalFlow"
                )
              );
              let findCategory = res;
              let product = findCategory;
              let arr = [];
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(
                  arr,
                  appState,
                  appDispatch
                );
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
              let data = [...appState.categoryProducts];
              let catData = {
                category_id: cat_id,
                data: res,
              };
              data.push(catData);
              appDispatch({
                type: "setCategoryProduct",
                categoryProducts: data,
              });
            }
          }

          let flagChildProd = true;
          appState.categories.forEach((cat) => {
            if (cat.id == cat_id) {
              if (cat.children.length > 0 && flagChildProd) {
                {
                  flagChildProd = false;
                  let childrens = cat?.children;
                  for (const prodChild of childrens) {
                    handleChildCategory(
                      prodChild.id,
                      prodChild,
                      "childProductFlow"
                    );
                  }
                }
                if (flagChildProd) {
                  setChildProducts([]);
                }
              }
              appDispatch({
                type: "setCurrentCategory",
                currentCategory: cat,
              });
            }
          });
          setIsLoading(false);
        }
      }
    };

    if(initial_mount){
      getAllProducts()
    }else{
      initial_mount = true
    }

    getAllProducts();
  }, [id, appState.productTags, appState.qrBranch]);


  // Tabs work
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Tabs work
  const handleParentsChange = (event, newValue) => {
    if (parentValue == newValue) {
      setParentValue(-1);
    } else {
      setParentValue(newValue);
    }
  };
  const handleClick = (e, Link, id) => {
    e.preventDefault();
    setIsLoading(true);
    history.push(Link);
  };
  const handleClickShowProductPopup = (id, slug, catSlug) => {
    if (id != null) {
      setSingleProductPopupShow(true);
      setProductInfo({
        productID: id,
        productSlug: slug,
        prodCatSlug: catSlug,
      });
      setProductID(id);
      setProductSlug(slug);
    }
  };
  const handlecloseProductPopup = () => {
    setSingleProductPopupShow(false);
    setProductInfo({
      productID: null,
      productSlug: null,
      prodCatSlug: null,
    });
    setProductID(null);
    setProductSlug(null);
  };

  useEffect(() => {
    if (singleProductPopupShow) {
      document.body.style.overflow = "hidden";
      
    } else {
      document.body.style.overflow = "visible";
      
    }
  }, [singleProductPopupShow]);

  return (
    <Page title="Category">
      <AddToCartPopup />
      {singleProductPopupShow && (
        <>
          <Backdrop
            onClick={handlecloseProductPopup}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 3,
            }}
            open={singleProductPopupShow}
          />
          <SingleProductPopup
            handlecloseProductPopup={handlecloseProductPopup}
            productInfo={productInfo}
          />
        </>
      )}
      <div id="wrapper">
        <div id="product-page" className="pb-5 mb-5">
          <MiscBanner
            image={categoryImg}
            urlLink={appState?.qrBranch ? `/${appState.orderQrCode}` : "/"}
          ></MiscBanner>
          {
            // appState.storeInfo.progressive ? (
            //   isTabsLoading ? (
            //     <p>...loading</p>
            //   ) : (
            //     <CategoryProductfsV2
            //       categories={tabsCategory}
            //       products={products}
            //       isLoading={isLoading}
            //       slug={slug}
            //       handleClickShowProductPopup={handleClickShowProductPopup}
            //     />
            //   )
            // ) :
            <>
              <div id="tabs_sec" className="tabsSec">
                <AppBar className="appBar" position="static">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* {( */}
                    {appState.storeInfo.subCategory && (
                      <Tabs
                        value={parentValue}
                        onChange={handleParentsChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile={true}
                        aria-label="scrollable auto tabs example"
                        className={`pt-4 layoutOnetabs ${
                          appState.langValue === "ar" ? "direction-ltr" : ""
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {appState.parentCategories.map((category, index) => {
                          // console.log(
                          //   `Rendering category at index ${index}:`,
                          //   category
                          // );

                          return (
                            <Tab
                              label={
                                category.name != null
                                  ? get_lang(category.name, appState.langValue)
                                  : "Not Available"
                              }
                              onClick={(e) => handleClick(e, category.urlLink)}
                              key={index}
                              id={`tab_section_${index}`}
                              className="familyManrope700 text-capitalize"
                              disabled={index == parentValue}
                              icon={
                                <img
                                  src={category.image ? category.image : noImg}
                                />
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            />
                          );
                        })}
                      </Tabs>
                    )}
                  </span>

                  <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#00acee" },
                    }}
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                    }}
                    className={`pt-2 layoutonetabs ${
                      appState.langValue === "ar" ? "direction-ltr" : ""
                    }`}
                  >
                    {appState.categories.map((category, index) =>
                      category.has_products || category?.products?.length > 0
                        ? appState.storeInfo?.subCategory
                          ? category?.parentId == parent_id && (
                              <Tab
                                label={
                                  category.name != null
                                    ? get_lang(
                                        category.name,
                                        appState.langValue
                                      )
                                    : "Not Available"
                                }
                                className="category_tabs text-capitalize"
                                onClick={(e) =>
                                  handleClick(e, category.urlLink, category.id)
                                }
                                key={`${index}-1`}
                                id={`tab_section_${index}`}
                                disabled={category.id == activeVTabs}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              />
                            )
                          : (category.has_products ||
                              category?.products?.length) && (
                              <Tab
                                label={
                                  category.name != null
                                    ? get_lang(
                                        category.name,
                                        appState.langValue
                                      )
                                    : "Not Available"
                                }
                                className="category_tabs text-capitalize"
                                onClick={(e) =>
                                  handleClick(e, category.urlLink)
                                }
                                key={index}
                                id={`tab_section_${index}`}
                                disabled={category.id == activeVTabs}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              />
                            )
                        : ""
                    )}
                  </Tabs>
                </AppBar>
              </div>

              <section
                id="Products"
                className={`products ${
                  products.length > 0 ? "" : "noProducts"
                } `}
              >
                {isLoading
                  ? [0, 1, 2, 3, 4].map((a) => (
                      <div
                        key={a}
                        className={`row m-0 mb-4 mt-3 ${
                          appState.langValue == "ar" ? "" : ""
                        }`}
                      >
                        <div className="col-5 col-md-4 px-2">
                          <Skeleton
                            variant="rect"
                            height={120}
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <div className="col-7 col-md-8 px-2">
                          <Skeleton
                            height={50}
                            style={{
                              transform: "translate(2px, 0)",
                              marginBottom: "10px",
                            }}
                          />
                          <Skeleton height={30} />
                          <Skeleton height={30} />
                        </div>
                      </div>
                    ))
                  : products &&
                    (products.length == 0 ? (
                      <div className="text-center mt-5">
                        <h4 className="text-muted">"No product found"</h4>
                      </div>
                    ) : (
                      <div
                        className={`row m-0 ${
                          appState.loginValue == "ar" ? "" : ""
                        }`}
                      >
                        {products.map((item, ind) => {
                          if (item?.category?.slug === slug) {
                            return products.length > 1 ? (
                              <div
                                className="prodContainer ani-boxes w-100 px-2"
                                key={ind}
                              >
                                {ind === 0 ? "" : <hr />}
                                {appState.deliveryMode ? (
                                  <Productv2
                                    item={item}
                                    ind={ind}
                                    parent_id={parent_id}
                                    myRef=""
                                    handleClickShowProductPopup={
                                      handleClickShowProductPopup
                                    }
                                  />
                                ) : (
                                  <Product
                                    item={item}
                                    ind={ind}
                                    parent_id={parent_id}
                                    myRef=""
                                    handleClickShowProductPopup={
                                      handleClickShowProductPopup
                                    }
                                  />
                                )}
                              </div>
                            ) : (
                              <div
                                className="prodContainer ani-boxes w-100 px-2"
                                key={ind}
                              >
                                {appState.deliveryMode ? (
                                  <Productv2
                                    item={item}
                                    ind={ind}
                                    parent_id={parent_id}
                                    myRef=""
                                    handleClickShowProductPopup={
                                      handleClickShowProductPopup
                                    }
                                  />
                                ) : (
                                  <Product
                                    item={item}
                                    ind={ind}
                                    parent_id={parent_id}
                                    myRef=""
                                    handleClickShowProductPopup={
                                      handleClickShowProductPopup
                                    }
                                  />
                                )}
                              </div>
                            );
                          }
                          return null; // Ensure to return null for items not matching the condition
                        })}
                      </div>
                    ))}
                {/* child category for non progressive */}
                {/* {!appState.storeInfo.progressive &&
                  // {!appState.storeInfo.progressive &&
                    (isLoading
                      ? [0, 1, 2, 3, 4].map((a) => (
                          <div
                            key={a}
                            className={`row m-0 mb-4 mt-3 ${
                              appState.langValue == "ar" ? "" : ""
                            }`}
                          >
                            <div className="col-5 col-md-4 px-2">
                              <Skeleton
                                variant="rect"
                                height={120}
                                style={{ borderRadius: "5px" }}
                              />
                            </div>
                            <div className="col-7 col-md-8 px-2">
                              <Skeleton
                                height={50}
                                style={{
                                  transform: "translate(2px, 0)",
                                  marginBottom: "10px",
                                }}
                              />
                              <Skeleton height={30} />
                              <Skeleton height={30} />
                            </div>
                          </div>
                        ))
                      : childCategory.length > 0 && (
                          <div
                            className={`row m-0 ${
                              appState.loginValue == "ar" ? "" : ""
                            }`}
                          >
                            {childCategory.map(
                              (child, index) =>
                                child?.parent?.id == cat_id && (
                                  <div
                                    className="prodContainer ani-boxes w-100 px-2"
                                    key={index}
                                  >
                                    <h4 className="text-capitalize child-name-center">
                              {get_lang(child.name, appState.langValue)}
                              </h4> 
                                    <h3
                                      className="text-capitalize px-4 py-4"
                                      style={{
                                        color: `${appState.storeInfo.primaryColor}`,
                                        fontWeight: "600",
                                      }}
                                    >
                                      {get_lang(child.name, appState.langValue)}
                                    </h3>

                                    {childProducts.map(
                                      (item, ind) =>
                                        child?.id == item.category.id && (
                                          <>
                                            {appState.deliveryMode ? (
                                              <Productv2
                                                item={item}
                                                ind={`${item.id}-${ind}`}
                                                parent_id={parent_id}
                                                myRef=""
                                                handleClickShowProductPopup={
                                                  handleClickShowProductPopup
                                                }
                                              />
                                            ) : (
                                              <Product
                                                item={item}
                                                ind={`${item.id}-${ind}`}
                                                parent_id={parent_id}
                                                myRef=""
                                                handleClickShowProductPopup={
                                                  handleClickShowProductPopup
                                                }
                                              />
                                            )}
                                            {<hr />}
                                          </>
                                        )
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                        ))} */}
              </section>
            </>
          }

          {!appState.payTableMode && appState.isAddToCartEnable ? (
            appState.isAddToCartEnable === null ? (
              <section id="reviewOrderButton">
                {appState.deviceId ? (
                  ""
                ) : appState.cart.length <= 0 ? (
                  ""
                ) : (
                  <div
                    id="process-btn"
                    className=" col-md-12 offset-xl-3 mr-auto col-xl-6"
                  >
                    <Button
                      variant="contained"
                      className="col-12  btn-main ProcessBtn text-capitalize"
                      onClick={toCart}
                    >
                      <strong>{t("product.Review-order")}</strong>

                      <span>
                        <span style={{ padding: "0 10px" }}>
                          {t("product.Total")}
                        </span>
                        <b>{appState.total}</b>
                        <span>
                          {" "}
                          &nbsp;
                          {get_lang(
                            appState.storeInfo.storeCurrency,
                            appState.langValue
                          )}
                        </span>
                      </span>
                    </Button>
                  </div>
                )}
              </section>
            ) : appState.cart.length <= 0 ? (
              ""
            ) : (
              <section id="reviewOrderButton">
                <div
                  id="process-btn"
                  className=" col-md-12 offset-xl-3 mr-auto col-xl-6"
                >
                  <Button
                    variant="contained"
                    className="col-12  btn-main ProcessBtn text-capitalize"
                    onClick={toCart}
                  >
                    <strong>{t("product.Review-order")}</strong>

                    <span>
                      {/* <span style={{ padding: "0 10px" }}>{t("product.Total")}</span> */}
                      <b>{parseFloat(appState.total).toFixed(3)}</b>
                      <span>
                        {" "}
                        &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </span>
                    </span>
                  </Button>
                </div>
              </section>
            )
          ) : (
            <>
              {appState.oldInvoice != null && (
                <section
                  id="reviewOrderButton"
                  className="offset-xl-3 mr-auto col-xl-6"
                >
                  <CheckoutBtn
                    label={"Pay-the-Bill"}
                    handleCheckout={() =>
                      history.push(`/checkout${appState.orderQrCode}`)
                    }
                  />
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default withTranslation()(Products);
