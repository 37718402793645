/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useEffect, useState, Suspense, lazy } from "react";
/* eslint-disable */
import "../layout-one/sass/app.scss";
import "../layout-two/sass-react/app.scss";
/* eslint-disable */
import ReactGA from "react-ga";
import { configure } from "mobx";
import DineInMain from "./DineInMain";
import DeliveryMain from "./DeliveryMain";
import EnabillLoadingPage from "../sharedComponent/enabillLoadingPage/enabillLoadingPage";
import { use } from "react";
import { useSelector } from "react-redux";

// const EnabillLoadingPage = lazy(() => import(
//   '../sharedComponent/enabillLoadingPage/enabillLoadingPage'))

configure({
  isolateGlobalState: true,
});
const TRACKING_ID = "UA-243425614-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Main({ t, i18n }) {
  const appState = useSelector((appState) => appState);
  const [appMode, setAppMode] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let initial_mount = true;
    if (initial_mount) {
      let delivery =
        window.location.host == `localhost:3000` || window.location.host == `localhost:3001` ||
        window.location.host == `192.168.100.10:3001` ||
        window.location.host == process.env.REACT_APP_DELIVERY_APP
          ? true
          : false;
      if (delivery) {
        setAppMode(2);
        setIsLoading(false);
      } else {
        setAppMode(1);        
        setIsLoading(false);
      }
    }

    return () => {
      initial_mount = false;
    };

  }, []);
  return appMode == 1 ? <div> <DineInMain />  </div>: appMode == 2  ? <div> <DeliveryMain />  </div>: <div> <EnabillLoadingPage /> </div>;

}

export default Main;
