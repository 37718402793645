import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import "./CollapsFields.scss";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useTranslation, withTranslation } from "react-i18next";
import { get_lang } from "../../../../global-functions/apiGlobal";
import { useDispatch, useSelector } from "react-redux";

const CollapsFields = (props) => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const { value, handleChange, label, name, options } = props;
  const appDispatch = useDispatch();

  const [open, setOpen] = React.useState(0);

  const handleClick = (id) => {
    if (open == id) {
      setOpen(0);
    } else {
      setOpen(id);
    }
  };

  const handleGetCityArea = (city_id, area_id) => {
    let getCity = options.find((item) => item.id == city_id);
    let getArea = getCity
      ? getCity?.areas?.find((item) => item.id == area_id)
      : null;
    appDispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: {
        ...appState.deliveryAddsInfo,
        city_id: getCity?.id,
        city_name: getCity?.name,
        area_id: area_id,
        area_name: getArea?.name,
      },
    });
  };

  return (
    <div className="CollapsFields col-12">
      <p>{label}</p>

      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        //   subheader={
        //     <ListSubheader component="div" id="nested-list-subheader">
        //       Nested List Items
        //     </ListSubheader>
        //   }
      >
        {options.map((item, ind) => (
          <span key={ind}>
            <ListItemButton onClick={() => handleClick(item.id)}>
              <ListItemIcon className={`${ appState.deliveryAddsInfo?.city_id == item.id ? "cityNameSelected " : "CityName"}`}>
                <ListItemText 
                   primary={ `City:  ${get_lang(item.name, appState.langValue)}`}
                />
              </ListItemIcon>
              {open == item.id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open == item.id} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.areas.map((area, areaInd) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleGetCityArea(item.id, area.id)}
                  >
                    <ListItemIcon>
                      <ListItemText style={ appState.deliveryAddsInfo?.area_id == area.id ? {color: '#ec5a5e'}  : {}}
                        primary={`Area: ${get_lang(area.name, appState.langValue)}`}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </span>
        ))}
      </List>
    </div>
  );
};
export default withTranslation()(CollapsFields);
