/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./DiscountOptions.scss";
// material ui
import Button from "@mui/material/Button";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress, Skeleton } from "@mui/material";
import { AppBar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import "../loyalityCards/loyalityCards.scss";
import IconButton from "@mui/material/IconButton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Backdrop, useMediaQuery } from "@mui/material";
import OptionalRewards from "../optionalRewards/OptionalRewards";
import DeleteIcon from "@mui/icons-material/Delete";
import { isNotEmpty } from "../../../../utils";
import { handleChangeTotal } from "../../../../global-functions/customeObj";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#052d72",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
}));

function DiscountOptions(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const classes = useStyles();
  const appDispatch = useDispatch();
  const {
    discountType,
    setTotal,
    setDiscountType,
    currentOrders,
    setDOptionValue,
    loyaltyCardId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [optionalLoading, setOptionalLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [optionalProducts, setOptionalProducts] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [showPromocode, setShowPromocode] = useState(false);

  const [tabValue, setTabValue] = useState("a"); // You need to initialize tabValue to one of the valid values.

  const handleCancleCheckBox = () => {
    setCheckBox(null);
    appDispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: null });
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: [],
    });
    handleChangeTotal([], appState, appDispatch, "add");
  };

  useEffect(() => {
    if (discountType != "Rewards") {
      handleCancleCheckBox();
    }
  }, [discountType]);

  const handleChangeVoucher = (id, discount) => {
    
    if (checkBox == id) {
      handleCancleCheckBox();
      setDiscountType("");
    } else {
      setCheckBox(id);
      setDiscountType("Rewards");
      let res = appState.rewardsDetails.find((val) => val.id == id);
      let type = res?.rewards?.type;
      let reqQty = res?.rewards?.reward?.quantity;
      let rewardObj = res?.rewards?.reward?.products;
      let getReward = res?.rewardDiscount;
      let newOrder = [];
      if (getReward != []) {
        if (type?.name == "Product") {
          getReward.forEach((firstEle) => {
            
            setOptionalLoading(true);
            currentOrders.forEach((secondEle) => {
              if (firstEle == secondEle.product_id) {
                let data = {
                  loyalty_card_id: id,
                  order_id: secondEle.id,
                  order_quantity: secondEle?.quantity,
                  product_id: secondEle?.product_id,
                  product: secondEle?.product,
                  price: ((appState.openBill ? (secondEle?.amount) / secondEle?.quantity : secondEle?.sum_addons > 0 ? (secondEle?.overAllSum ? secondEle?.overAllSum : secondEle?.amount) /  secondEle?.quantity : secondEle?.price ) ),
                  requiredQty: reqQty,
                  free_qty: 0,
                  loyalty_reward: null,
                };
                newOrder.push(data);
                if(appState.openBill){
                  
                }else if(secondEle?.sum_addons > 0){
                  
                }
                else{
                  
                }
                
              }
            });
            setOptionalProducts(newOrder);
            
            
             
            if (newOrder.length == 1) {
              let order = newOrder[0];
              order.free_qty =
              order.order_quantity <= order.requiredQty
              ? order.order_quantity
              : order.requiredQty;
              
              
              if (appState.openBill) {
                let data = [
                  {
                    order_id: order.order_id,
                    quantity: order.free_qty,
                  },
                ];
                appDispatch({
                  type: "setLoyaltyRewardObj",
                  loyaltyRewardObj: { id: id, discount: data },
                });
                let res = handleChangeTotal(
                  newOrder,
                  appState,
                  appDispatch,
                  "product"
                  );
                } else {
                  
                let orderIndex = appState.cart.findIndex(
                  (item) => item.product_id == order.product_id
                );
                if (orderIndex !== -1) {
                  
                  // Clone the found order and update the 'loyalty_reward' property
                  var updatedOrder = {
                    ...appState.cart[orderIndex],
                    loyalty_reward: order.free_qty, // Update the 'loyalty_reward' property as needed
                  };
                }
                
                appState.cart[orderIndex] = updatedOrder;
                
                appDispatch({
                  type: "setLoyaltyRewardObj",
                  loyaltyRewardObj: { id: id, discount: undefined },
                });

                let res = handleChangeTotal(
                  newOrder,
                  appState,
                  appDispatch,
                  "product"
                );
              }
            } else {
              
              appDispatch({
                type: "setLoyaltyRewardObj",
                loyaltyRewardObj: { id: id, discount: undefined },
              });
            }
            appDispatch({
              type: "setOptionalRewardsDetails",
              optionalRewardsDetials: newOrder,
            });
          });
          
        } else {
          let item = {
            id: id,
            discount: discount,
          };
          appDispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: item });
          let res = handleChangeTotal(item, appState, appDispatch, "discount");
          // setTotal(res.totalAmount)
          appDispatch({
            type: "setInvoiceTaxesChargesExtras",
            invoiceTaxesChargesExtras: res,
          });
        }
      } else {
        appDispatch({
          type: "setOptionalRewardsDetails",
          optionalRewardsDetials: [],
        });
      }
    }
  };
  useEffect(() => {
    if (appState.loyaltyRewardObj != null) {
      if (appState.loyaltyRewardObj.length > 0) {
        
        setCheckBox(appState.loyaltyRewardObj.id);
      }
    }
  }, []);

  useEffect(() => {
    if (appState.optionalRewardsDetials.length > 1) {
      setOptionalLoading(false);
      setShowPopUp(true);
      appDispatch({
        type: "setOptionalRewardsPopUp",
        optionalRewardsPopUp: true,
      });
    } else {
      setShowPopUp(false);
      appDispatch({
        type: "setOptionalRewardsPopUp",
        optionalRewardsPopUp: false,
      });
    }
  }, [appState.optionalRewardsDetials]);

  const handleChange = (event, newValue) => {
    setDOptionValue(newValue);
    if (newValue == 2 && loyaltyCardId == null) {
      appDispatch({
        type: "setSelectLoyaltyCardPopup",
        selectLoyaltyCardPopup: true,
      });
    }
  };

    // useEffect(() => {
    //   
    //     appState.rewardsDetails?.length > 0,
    //     appState.rewardsDetails,
    //     appState.optionalRewardsDetials,
    //     currentOrders
    //   );
    // }, [appState.rewardsDetails]);

  return isLoading ? (
    <>
      <div className="row m-0 d-flex flex-row">
        <div className="col-6  ">
          <Skeleton height={50} />
        </div>
        <div className="col-10 ">
          <Skeleton height={80} />
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className={`DiscountOptionsMain ${
          appState.langValue === "ar" ? "DiscountOptionsMainArabic" : " "
        }`}
      >
        {appState.optionalRewardsPopUp && (
          <OptionalRewards
            setTotal={setTotal}
            optionalProducts={optionalProducts}
            currentOrders={currentOrders}
          />
        )}
        {/* <OwlCarousel
                className="owl-theme  "
                items="1"
                // nav={false}
                dots
                margin={0}
                customtransition="2000ms"
                transitionduration={500}
              > */}

        {appState.rewardsDetails?.length > 0 && (
          <div
            className={`row loyaltyTabsParent ${
              appState.langValue === "ar"
                ? "loyaltyTabsParentArabic pr-2 pl-3"
                : "loyaltyTabsParentEn pr-3 pl-2"
            } `}
          >
            <AppBar
              className={`appBar featuredAppBar ${
                loyaltyCardId != null ? "showIndicator" : "dontshowIndicator"
              }`}
              position="static"
            >
              <Tabs
                value={tabValue}
                // onChange={handleChangeTabs}
                aria-label="icon label tabs example"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {appState.rewardsDetails.map((item, ind) => {
                  ind += 1;
                  return (
                    <Tab
                      key={ind}
                      className={`categoryTab`}
                      style={{
                        border: `1px solid #0000 `,
                        background: `#0000`,
                        boxSizing: "border-box",
                      }}
                      onClick={() =>
                        handleChangeVoucher(item.id, item.rewardDiscount)
                      }
                      icon={
                        <div className="w-100 item black " key={ind}>
                          {item?.rewards?.type?.name == "Product" ? (
                            <>
                              <section id="promoCode" className="row m-0">
                                <div className="row w-100 m-0 productsRow">
                                  <div
                                    className={`d-flex py-2 mx-0 w-100 px-0 CardRow radio-item`}
                                  >
                                    <div className="cardContent w-100">
                                      <div className="px-3 d-flex cardContentInner">
                                        <span>
                                          <img
                                            src={`/images/local_drink.svg`}
                                            alt="local_drink"
                                          />
                                        </span>

                                        <div className="mx-3">
                                          <h1
                                            className={`cardProductName text-uppercase familyPoppins300 ${
                                              appState.langValue == "ar"
                                                ? "text-right"
                                                : "text-left"
                                            } `}
                                          >
                                            {item?.rewards?.type?.name}
                                          </h1>
                                          <h4
                                            className={`text-capitalize cardPrice familyPoppins ${
                                              appState.langValue == "ar"
                                                ? "text-right"
                                                : "text-left"
                                            } `}
                                          >
                                            {get_lang(
                                              item?.rewards?.description,
                                              appState.langValue
                                            )}
                                          </h4>

                                          <h1
                                            className={`cardProductName text-uppercase familyPoppins300 ${
                                              appState.langValue == "ar"
                                                ? "text-right"
                                                : "text-left"
                                            } `}
                                          >
                                            {/* {item?.rewards?.reward?.discount_amount} KWD (
                          {item?.rewards?.reward?.discount_percent} %) */}
                                          </h1>

                                          <div
                                            className={`w-100 d-flex CheckboxParent  align-items-center ${
                                              appState.langValue === "ar"
                                                ? "justify-content-start"
                                                : "text-left"
                                            }`}
                                          >
                                            <Checkbox
                                              className="px-0"
                                              value={item.rewardDiscount}
                                              checked={checkBox == item.id}
                                              id={item.id}
                                            />

                                            <p
                                              className={`mb-0 px-2 ${
                                                appState.langValue === "ar"
                                                  ? "text-right "
                                                  : "text-left familyPoppins300"
                                              }`}
                                            >
                                              Use Reward
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </>
                          ) : (
                            <section id="promoCode" className="row m-0">
                              <div className="row w-100 m-0 productsRow">
                                <div
                                  className={`row py-2 mx-0 w-100 px-0 CardRow radio-item`}
                                >
                                  <div className="cardContent w-100">
                                    <div className="px-3 d-flex cardContentInner">
                                      <span>
                                        <img
                                          src={`/images/local_drink.svg`}
                                          alt="local_drink"
                                        />
                                      </span>

                                      <div className="mx-3">
                                        <h1
                                          className={`cardProductName text-uppercase familyPoppins300 ${
                                            appState.langValue == "ar"
                                              ? "text-right"
                                              : "text-left"
                                          } `}
                                        >
                                          {item?.rewards?.type?.name}
                                        </h1>
                                        <h4
                                          className={`text-capitalize cardPrice familyPoppins ${
                                            appState.langValue == "ar"
                                              ? "text-right"
                                              : "text-left"
                                          } `}
                                        >
                                          {/* {get_lang(
                                  item?.rewards?.description,
                                  appState.langValue
                                )} */}
                                        </h4>
                                        <h1
                                          className={`cardProductName text-uppercase familyPoppins300 ${
                                            appState.langValue == "ar"
                                              ? "text-right"
                                              : "text-left"
                                          } `}
                                        >
                                          {
                                            item.rewardDiscount
                                          }{" "}
{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}                                          {(item?.rewards?.reward
                                              ?.discount_percent !=0 || item?.rewards?.reward
                                              ?.discount_percent != "0")  && (
                                               <>
                                               {""}  ({ item?.rewards?.reward
                                                ?.discount_percent})%
                                               </>
                                              )
                                                
                                         
                                                
                                                }
                                        </h1>

                                        <div
                                          className={`w-100 d-flex CheckboxParent  align-items-center ${
                                            appState.langValue === "ar"
                                              ? "justify-content-start"
                                              : "text-left"
                                          }`}
                                        >
                                          <Checkbox
                                            className="px-0"
                                            value={
                                              item.rewardDiscount != null
                                                ? item.rewardDiscount
                                                : ""
                                            }
                                            id={item.id}
                                            checked={checkBox == item.id}
                                          />

                                          <p
                                            className={`mb-0 px-2 ${
                                              appState.langValue === "ar"
                                                ? "text-right "
                                                : "text-left familyPoppins300"
                                            }`}
                                          >
                                            Use Voucher
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                        </div>
                      }
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </div>
        )}
        {/* </OwlCarousel> */}
      </div>
    </>
  );
}

export default withTranslation()(DiscountOptions);
