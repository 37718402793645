/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Material UI
import { Button } from "@material-ui/core";
// Components
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import CircularIndeterminate from "./CircularIndeterminate";
import axios from "axios";
// React-Redux
import { useSelector, useDispatch } from "react-redux";

const SearchList = ({ Done }) => {
  // @States & Variables
  const appState = useSelector((state) => state);;
  const appDispatch = useDispatch();
  const [areas, setAreas] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(
    JSON.parse(localStorage.getItem("fields"))
      ? JSON.parse(localStorage.getItem("fields")).country
      : ""
  );
  const [selectedArea, setSelectedArea] = useState(
    JSON.parse(localStorage.getItem("fields"))
      ? JSON.parse(localStorage.getItem("fields")).area
      : ""
  );
  const [selectedCity, setSelectedCity] = useState(
    JSON.parse(localStorage.getItem("fields"))
      ? JSON.parse(localStorage.getItem("fields")).city
      : ""
  );


 
  useEffect(() => {
    if (selectedArea) {
      let fields = {
        ...appState.fields,
        area: parseFloat(selectedArea),
        country: parseFloat(selectedCountry),
        city: parseFloat(selectedCity),
      };
      let deliveryCharge =
        appState.storeInfo.storeDeliveryCharges.filter((charge) =>
          charge.area_id.toString().includes(selectedArea.toString())
        )[0];
      appDispatch({
        type: "UPDATE_DELIVERY_CHARGES",
        deliveryCharges: parseFloat(deliveryCharge.price),
      });
      appDispatch({ type: "UPDATE_FIELDS", fields: fields });
    }
  }, [selectedArea]);
  useEffect(() => {
    if (areas) {
      let cities = [];
      if (areas.length > 0) {
        console.log(areas);
        // for (let i = 0; i < areas.length; i++) {
        //     cities.push({
        //         name: areas[i].city.name,
        //         id: areas[i].city.id
        //     });
        // }
        const cityResult = [];
        const map = new Map();
        for (const item of cities) {
          if (!map.has(item.id)) {
            map.set(item.id, true); // set any value to Map
            cityResult.push({
              id: item.id,
              name: item.name,
            });
          }
        }
        setCities(cityResult);
      }
    }
  }, [areas]);

  if (isLoading)
    return (
      <div className="p-relative h-100vh">
        {" "}
        {/* changes sajid */}
        <div className="circularContainer">
          <CircularIndeterminate />
        </div>{" "}
      </div>
    );
  return (
    <div id="Self">
      <div className="srch_area mt-3">
        {/* <label>Search for areas</label> */}
        <div className="srch_area_inp">
          <div className="srch_icon mb-1">
            <i className="fa fa-search"></i>
          </div>
          <input
            type="search"
            className="form-control"
            placeholder="Search for areas"
          />
        </div>
      </div>
      <section className="deliverySection col-md-5">
        <ul>
          <li className="MainActive">
            <span className="mainActSpan">
              <strong>Kuwait</strong>
            </span>
            <ul>
              <li className="act">
                <div
                  className="areas"
                  value={selectedArea}
                  // onChange={handleSelectedArea}
                >
                  {cities.map((city, ind) => (
                    <div key={ind}>
                      <span className="city_name">
                        <strong>{city.name}</strong>
                      </span>
                      <ul className="city__list">
                        {areas
                          .filter((area) =>
                            area.area.city_id
                              .toString()
                              .includes(city.id.toString())
                          )
                          .map((area, ind) => (
                            <div className="city_list_group" key={ind}>
                              <input
                                type="radio"
                                name="city_selection"
                                datacountry={area.country.id}
                                datacity={area.city.id}
                                value={area.area.id}
                                id={`city_sel_${area.area.id}`}
                                className="city_list_option"
                              />
                              <label
                                htmlFor={`city_sel_${area.area.id}`}
                                className="city_list_label"
                              >
                                {area.area.name}
                              </label>
                            </div>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <section id="reviewOrderButton">
        <div className="reviewOrdButton  offset-xl-3 mr-auto col-xl-6">
          <Button
            variant="contained"
            // component={Link}
            // to="/"
            onClick={Done}
            className="btn-block btn-main"
          >
            Done
          </Button>
        </div>
      </section>
    </div>
  );
};

export default SearchList;
