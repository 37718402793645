import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Product from '../Product';
import { useEffect } from 'react';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    width: '100%', // Make the accordion full width
    // borderTop: `1px solid ${theme.palette.divider}`, // Only apply border to the top
    '&::before': {
      display: 'none', // Remove the default outline that Material-UI adds
    },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row', // Keep default flex direction to shift icon to the right
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginRight: theme.spacing(1), // Add margin to space the content from the icon
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions(props) {
    const {expanded, setExpanded, handleChange, childProducts, parent_id = null} = props
  
    useEffect(() => {
   console.log(childProducts, "childProducts")
    }, [])
    
  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Child products</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {childProducts?.length > 0 && childProducts.map((item, ind) => (
                <div className='px-2'>
   <Product
   item={item}
   ind={ind}
   parent_id={parent_id}
   myRef=""
 />
 <hr />
 </div>
))}
            </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
