/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
// Global Fucntion
// import { loginCustomer } from "../../../global-functions/authGlobal";

// Materila ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Dialog } from '@mui/material';

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
// import Page from "../Components/Page";
// import { useNavigate} from "react-router"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DispatchContext from "../../../store/DispatchContext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import StateContext from "../../../store/StateContext";
import SignUpDialog from "./SignUpModal";
import ForgetDialog from "./ForgetModal";
import clsx from "clsx";
import fbIcon from "../../../layout-two/js-react/assets/images/quickpay icons/facebook.png";
import googleIcon from "../../../layout-two/js-react/assets/images/quickpay icons/google.png";
import { bounce } from "react-animations";
import Radium from "radium";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { withTranslation, useTranslation, Trans } from "react-i18next";

import TestComponent from "./TestComponent";
import FbLogin from "./FbLogin";
import LoginGoogle from "./LoginGoogle";
import AppleLogin from "./AppleLogin";

// redux 
import { useSelector, useDispatch } from "react-redux";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    marginTop: "1.5rem",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  YOURACCOUNT: {
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: "#9F9F9F",
  },
  greyColor: {
    color: "#696969",
  },

  /////// new checkbox style

  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    // border:"2px solid red",
    marginBottom: "5px",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginDialog = ({ handleClose, open, handleClickOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
  });
  const [setSuccess] = useState(false);
  const [check_status, setCheck_status] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openForget, setOpenForget] = useState(false);
  const [openFb, setOpenFb] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginValues, setLoginValues] = useState({ email: "", password: "" });
  const [openModal, setOpenModal] = useState(true);
  let history = useHistory();

  const handleClickShowPassword = () => {
    setShowPassword(true);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(false);
  };

  const animationStyle = {
    bounce: {
      animation: "x 10s",
      animationName: Radium.keyframes(bounce, "bounce"),
    },
  };

  // const handleLogout = () => {
  //     appDispatch({ type: "logout" });
  // };
  // const handleChange = event => {
  //     setChecked(event.target.checked);
  // };

  const handleClickOpenRegister = () => {
    // console.log("consoling");
    handleClose();
    setOpenRegister(true);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
  };
  const handleClickOpenForget = () => {
    handleClose();
    setOpenForget(true);
  };
  const handleCloseForget = () => {
    setOpenForget(false);
  };
  const handleClickOpenFb = () => {
    handleClose();
    setOpenFb(true);
  };
  const handleCloseFb = () => {
    setOpenFb(false);
  };
  // Delete Cookie
  const erase = (name) => {
    setCookie(name, "", -1);
  };
  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  const setCookie = (name, value, days) => {
    var domain, domainParts, date, expires, host;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    host = window.location.host;
    if (host.split(".").length === 1) {
      // no "." in a domain - it's localhost or something similar
      document.cookie = name + "=" + value + expires + "; path=/";
    } else {
      domainParts = host.split(".");
      domainParts.shift();
      domain = "." + domainParts.join(".");
      document.cookie =
        name + "=" + value + expires + "; path=/; domain=" + domain;
      if (getCookie(name) == null || getCookie(name) != value) {
        domain = "." + host;
        document.cookie =
          name + "=" + value + expires + "; path=/; domain=" + domain;
      }
    }
  };

  // Handle Login Submit OLD
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // setSuccess(false);
    setLoading(true);

    // getting response from auth-global component
    var result = "await loginCustomer(loginEmail, loginPassword);";

    if (result.status == 200) {
      setLoading(false);
      // console.log(result, "login data");
      handleClose();

      appDispatch({
        type: "SET_LOGIN",
        loginDetails: {
          userId: result.userId,
          token: result.token,
        },
      });
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "success",
        message: result.message,
      });

      setCookie("tokenAuth", result.token, 1);
    } else {
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: result.message,
      });
    }
  };

  // const [changeStatus, setChangeStatus] = useState(false);
  // const handleForget = () => {
  //     setChangeStatus(true);
  // };
  const Sam = () => {};

  useEffect(() => {
    setLoading(false);
  }, [handleClose]);

  // sikandar work start
  // console.log(loggedIn)
  const updateField = (e) => {
    setLoginValues({
      ...loginValues,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("fffff", loginValues);
  const fbLogin = () => {
    console.log("sajid");
  };


  // function for change the checkbox status
  const handleCheckbox = () => {
    setCheck_status(!check_status);
  };
  function StyledCheckbox(props) {
    const classes = useStyles();

    return (
      <Checkbox
        className={classes.rootCheckBox}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
    );
  }

  return (
    <div>
      <SignUpDialog
        open={openRegister}
        handleClickOpen={handleClickOpenRegister}
        handleClose={handleCloseRegister}
        openLoginModal={handleClickOpen}
      />

      <ForgetDialog
        open={openForget}
        handleClickOpen={handleClickOpenForget}
        handleClose={handleCloseForget}
      />

      

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
        style={animationStyle.bounce}
        id="Login-Modal"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* Modal title */}
        </DialogTitle>

        <div className={`${loading ? "disableClass" : ""}`}>
          <div className="login-head">
            <h2 className={`${classes.loginHeading} `}> LOGIN</h2>
            <div id="social-icons" className="row m-0">
              <div className="innerdivSocialIcon pb-2 d-flex">
                <FbLogin
                  fbloginHandleclose={handleClose}
                  fbloading={loading}
                  setfbLoading={setLoading}
                />
                <LoginGoogle
                  loginHandleclose={handleClose}
                  loading={loading}
                  setLoading={setLoading}
                />
                <AppleLogin
                  loginHandleclose={handleClose}
                  loading={loading}
                  setLoading={setLoading}
                />
                {/* <Button
                  className="socialLogin mx-1"
                  onClick={() => window.AppleID.auth.signIn()}
                >
                  <i classname="fab fa-stripe-s"></i>
                </Button> */}
              </div>
            </div>
          </div>

          <h6 className={`${classes.YOURACCOUNT} text-center`}>
            {t("Login.useAcc")}{" "}
          </h6>
          <form action="" 
          // onSubmit={handleSubmit}
          >
            <div className="login-fields ">
              <div className="row mx-3 mb-2">
                {/* <div  className="col-12 px-4 "> */}
                <TextField
                  // className={classes.margin}
                  name="email"
                  value={loginValues.email}
                  onChange={updateField}
                  type="text"
                  variant="filled"
                  // fullWidth
                  id="input-with-icon-textfield"
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="mb-3">
                        <EmailIcon className={`${classes.greyColor}`} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* </div> */}
              </div>
              <div className="row mx-3">
                <TextField
                  name="password"
                  value={loginValues.password}
                  onChange={updateField}
                  type={showPassword != true ? "password" : "text"}
                  variant="filled"
                  // fullWidth
                  id="input-with-icon-textfield"
                  placeholder="Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="mb-3">
                        <LockIcon className={`${classes.greyColor}`} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            height: "100%",
                            padding: "20px 14px 20px 13px",
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showPassword ? (
                            <div>
                              <VisibilityOff />
                            </div>
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className=" mx-3 py-0 align-items-center d-flex forget-field">
                <div>
                  <span>
                    <Checkbox
                      checked={check_status}
                      onClick={handleCheckbox}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  </span>
                  {/* <span className="">
                    <StyledCheckbox />
                  </span> */}
                  <span
                    style={{
                      paddingTop: "2px",
                      marginTop: "2px",
                    }}
                  >
                    KEEP ME LOGGED IN
                  </span>
                </div>
                <div className={`ml-auto `}>
                  <Button
                    className="forgetpass"
                    onClick={handleClickOpenForget}
                  >
                    FORGOT PASSWORD?
                  </Button>
                </div>
              </div>
              <div className="row mx-3">
                {/* <div className="col-12 px-4"> */}
                <Button
                  variant="contained"
                  className="btn-main"
                  disabled={loading}
                  type="submit"
                  style={{ fontWeight: "700" }}
                >
                  {loading ? (
                    <React.Fragment>
                      {" "}
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />{" "}
                      Redirecting{" "}
                    </React.Fragment>
                  ) : (
                    `LOGIN`
                  )}
                </Button>

                {/* </div> */}
              </div>
              <div className="row mx-3 mt-2 text d-flex justify-content-center loginOR ">
                <p>
                  <span className="px-4">OR</span>
                </p>
              </div>
              <div className="row mx-3 mb-2">
                <Button
                  onClick={handleClickOpenRegister}
                  className="register-btn text-primary"
                >
                  {/* {t("Login.Register")} */}
                  REGISTER NEW ACCOUNT
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};
export default withTranslation()(LoginDialog);
