// React Components
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import CustomizedDialogs from "../../components/CustomizedDialogs";
import Page from "../../components/Page";
import LoginDialog from "../../components/LoginModal";
import LoginModal from "../../../../loginSignUp/loginModal/loginModal";
// material ui
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import { styled } from "@mui/system";
import { withTranslation, useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { getCookie, setCookie, erase } from "../../Functions/cookieFunction";
import GoSellElementsTrans from "../../components/GoSell";
import EditIcon from "@mui/icons-material/Edit";
import { Edit } from "@material-ui/icons";
import ClearIcon from "@mui/icons-material/Clear";

import { ApplePayButton } from "react-apple-pay-button";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  freezOff,
  getSaveCard,
  cleanRest,
  resetSplitOptions,
  closePayTable,
  handleError,
  getPayTableInvoice,
  getPayTableInvoiceNoOrders,
  pushShopMode,
  popShopMode,
  getLogOut,
} from "../../../../redux-store/action";
import SaveCard from "../../components/SaveCard";
// Global Fucntions
import { get_lang } from "../../../../global-functions/apiGlobal";
import SplitBillOptions from "../../../../sharedComponent/splitBillOptions/splitBillOptions";
import PayForItems from "../../../../sharedComponent/payForItems/payForItems";
import DivideBill from "../../../../sharedComponent/divideBill/divideBill";
import PayCustomAmount from "../../../../sharedComponent/payCustomAmount/payCustomAmount";
import AddTipCustom from "../../../../sharedComponent/AddTipCustom/AddTipCustom";
import TopUpPopUp from "../../../../sharedComponent/TopUpPopUp/TopUpPopUp";
import { getDeliveryApplePayToken } from "../../../../global-functions/deliveryApplePay";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplayIcon from "@mui/icons-material/Replay";
import RestoreIcon from "@mui/icons-material/Restore";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import {
  handleInvoice,
  handleUserInfo,
} from "../../../../global-functions/customeObj";
import PaymentMethods from "../../components/paymentMethods/paymentMethods";
import {
  parseFloatFixed,
  parseNumber,
  roundFixed,
} from "../../../../global-functions/roundFixed";
import CheckoutNoOrder from "../CheckoutNoOrder/CheckoutNoOrder";
import { getLiveData } from "../../../../global-functions/pusher";
import {
  handleCalculatedSubtotal,
  handleTapCharges,
} from "../../../../global-functions/charges";
import { handleOrdersPayload } from "../../../../global-functions/addToCartGlobal";
import SelectLoyaltyCardPopup from "../../components/selectLoyaltyCardPopup/selectLoyaltyCardPopup";
import DiscountOptions from "../../components/promoVocher/DiscountOptions";
import {
  getEstimatedTime,
  getLoyaltyCardDiscount,
} from "../../../../redux-store/action/apiCall";
import { isNotEmpty } from "../../../../utils";
import Vouceher from "../../components/promoVocher/Vouceher";
import { handleSplitPayements, handleStoreDiscount } from ".";
import LoyalitySlider from "../../components/loyalitySlider/loyalitySlider";
import SelectLoyalityCardSlider from "../../components/selectLoyalityCardSlider/selectLoyalityCardSlider";
import { getServiceName } from "../../../../global-functions/variables";
import NewDeliveryPopup from "../../components/deliveryAddsPopup/newDeliveryPopup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../redux-store/store";
import UserAddressPopup from "../../../../sharedComponent/usersAddress/UserAddressPopup";
import DeliveryOptionsBtns from "../../components/deliveryOptionsBtns/DeliveryOptionsBtns";
import DeliveryDateTimePopup from "../../../../sharedComponent/deliveryDateTimePopUp/DeliveryDateTimePopup";
import DeliveryIsNotAvailable from "../../components/deliveryIsNotAvailable/DeliveryIsNotAvailable";

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#052d72",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
}));

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

function DeliveryCheckout({ oldInvoice }) {
  const { t } = useTranslation();
  let history = useHistory();
  const appDispatch = useDispatch();
  const {
    setLoginModal,
    setGuestModal,
    setUserAddressPopup,
    setDeliveryDateTimePopup,
    setDeliveryAddsInfo,
  } = bindActionCreators(actionCreators, appDispatch);
  const appState = useSelector((state) => state);
  const {
    storeInfo: { numberFixed, storeCurrency, roundLevel, roundMethod },
  } = appState;
  const [formData, setFormData] = useState(
    JSON.parse(
      localStorage.getItem(
        `${appState.storeInfo.slug}_${getServiceName(appState)}_form`
      )
    ) != null
      ? JSON.parse(
          localStorage.getItem(
            `${appState.storeInfo.slug}_${getServiceName(appState)}_form`
          )
        )
      : []
  );
  const [saveCardId, setSaveCardId] = useState(null);
  const [orders] = useState(appState.cart != null ? appState.cart : "");
  const [openBillOrders, setOpenBillOrders] = useState([]);
  const payments = appState?.acceptedPayments;

  const classes = useStyles();
  var payArray = [
    "knet",
    "kfast",
    "cash",
    "wallet",
    "card",
    "partial",
    "apple_pay",
  ];
  const childRef = useRef();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pLoading, setPLoading] = useState(false);
  const [promoStatus, setPromoStatus] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [open, setOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoAmount, setPromoAmount] = useState(0);
  const [tapId, setTapId] = useState("");
  const [tapStatus] = useState(!appState.storeInfo.tap);
  // login work
  const [loginOpen] = useState(false);
  const [customeShow, setCustomeShow] = useState(false);
  const [manualTip, setManualTip] = useState(0);
  const [manualTipBool, setManualTipBool] = useState(true);
  const [customeTip, setCustomeTip] = useState(0);
  const [splitBillAmount, setSplitBillAmount] = useState(0);
  const [personWillPay, setPersonWillPay] = useState(1);
  const [totalPerson, setTotalPerson] = useState(2);
  const [partialType, setPartialType] = useState(null);
  const [paySelectedItems, setPaySelectedItems] = useState([]);
  const [billTotal, setBillTotal] = useState(appState.subTotal);
  const [metaObj, setMetaObj] = useState([]);
  const [tipLoading, setTipLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [hangTip, setHangTip] = useState(0);
  const [tips, setTips] = useState([]);
  const [openBillTotal, setOpenBillTotal] = useState(0);
  const ApplePaySession = window.ApplePaySession;
  const [showTableOrder, setShowTableOrder] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [appleToken, setAppleToken] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showDelivery, setShowDelivery] = useState(true);
  const [showEstimateTime, setShowEstimateTime] = useState(true);
  const [showLoyaltyCardDetails, setShowLoyaltyCardDetails] = useState(false);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [checkedSaveCard, setCheckedSaveCard] = useState(false);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [campaignDiscount, setCampaignDiscount] = useState(0);
  const [hotelDiscount, setHotelDiscount] = useState(0);
  const [punchDiscount, setPunchDiscount] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [checkoutByCash, setCheckoutByCash] = useState(false);
  // const [discount, setDiscount] = useState(0);

  const [storeDiscount, setStoreDiscount] = useState(0);
  const [orderUpdateCount, setOrderUpdateCount] = useState(0);
  const [timer, setTimer] = useState(30);
  const [retryAgain, setRetryAgain] = useState(false);
  const [loyaltyCardId, setLoyaltyCardId] = useState(null);
  const [showPromocode, setShowPromocode] = useState(true);
  const [showVoucher, setShowVoucher] = useState(false);
  const [dOptionValue, setDOptionValue] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [voucherObj, setVoucherObj] = useState([]);
  const [rewardedDiscount, setRewardedDiscount] = useState(0);
  const [orderDetails, setOrderDetails] = useState([]);
  const [rewardsLoading, setRewardsLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [availableTime, setAvailableTime] = useState({
    day: "",
    startTime: "",
    endTime: "",
    decidedTime: null,
    decidedDate: null,
    isDeliveryAvailable: true,
    showDecidedTime: "",
    decidedDate: ''
  });
  const [hasDeliverySlots, setHasDeliverySlots] = useState(false);
  const [deliveryIsAvalaible, setDeliveryIsAvalaible] = useState(false);
  const [preparingTime, setPreparingTime] = useState(null);
  const [stopAsapBtn, setStopAsapBtn] = useState(false)
  const [showDeliveryCheckout, setShowDeliveryCheckout] = useState(0)
  const [deliveryOptions, setDeliveryOptions] = useState({
    asap: true,
    laterToday: false,
    muchLater: false,
  });
  const [deliveryOptionsList, setDeliveryOptionsList] = useState([
    {
      title: "Delivery ASAP",
      description: "Your order will be delivered as soon as possible",
      icon: <AccessTimeIcon />,
    },
    {
      title: "Delivery Later Today",
      description: "Book the order, deliver it later",
      icon: <DeliveryDiningIcon />,
    },
    {
      title: "Delivery Much Later",
      description: "Book delivery for a specific date and time",
      icon: <CalendarTodayIcon />,
    },
  ]);
  const {
    totalAmount = 0,
    subTotalAmount = 0,
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    tip = 0,
    charges = [],
    currentInvoiceStatus = 0,
    paidAmount = 0,
    leftToPay = 0,
    fullAmount = 0,
    rewardsDiscount = 0,
  } = appState.invoiceTaxesChargesExtras;
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [expanded, setExpanded] = React.useState(`asap`);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClickLogin = () => {
    // appDispatch({ type: "setLoginModal", loginModal: true });
    var shop_url = window.location.href;
    var auth_url = `${process.env.REACT_APP_AUTH_URL}`;
    setCookie(`redirect`, shop_url);
    window.location.replace(auth_url);
  };
  const handleCloseLogin = () => {
    appDispatch({ type: "setLoginModal", loginModal: false });
  };

  const campaignCheckout = () => {
    orderInvoice();
  };
  useEffect(() => {
    appDispatch({
      type: "setPayTablePopUp",
      payTablePopUp: false,
    });
    setDeliveryDateTimePopup(false);
    appDispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: null });
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: [],
    });
  }, []);

  useEffect(() => {
    if (campaignDiscount >= openBillTotal) {
      setPaymentMethod("cash");
    }
    if (
      appState.shopMode.includes("is_partial") &&
      !appState.formData.openBill
    ) {
      appDispatch(
        popShopMode(appState, ["customer", "ratio", "product", "is_partial"])
      );
    }
  }, [campaignDiscount, formData]);

  // works
  const handleHotleDiscount = () => {
    if (appState.hotelObj != null) {
      let fixed = Number(appState.hotelObj.fixed);
      let percent = Number(appState.hotelObj.percent);
      let totalDiscount = Number((appState.total * percent) / 100 + fixed);
      if (appState.total > totalDiscount) {
        setHotelDiscount(totalDiscount);
      }
    }
  };


  const getPromo = async (e) => {
    e.preventDefault();

    setPromoLoading(true);
    if (promoStatus === false) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/store/store_promo/${appState.storeInfo.storeId}`,
          {
            promo_code: promoCode,
          }
        );
        if (response.data.success === false) {
          setPromoLoading(false);
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
        } else {
          if (response.data.data.type === "fixed") {
            setPromoLoading(false);
            if (response.data.data.amount < total) {
              let promo_amount = roundFixed(
                Number(response.data.data.amount),
                roundLevel,
                roundMethod
              );
              setPromoAmount(promo_amount);
              // setTotal(total - response.data.data.amount);
            } else {
              setPromoAmount(total);
              setDiscountType("promo-code");
            }
          } else if (response.data.data.type === "percentage") {
            setPromoLoading(false);
            var perCalc = (response.data.data.amount / 100) * total;
            if (perCalc <= total) {
              let promo_amount = roundFixed(
                Number(perCalc),
                roundLevel,
                roundMethod
              );
              setPromoAmount(promo_amount);

              // setTotal(total - perCalc);
            } else {
              setPromoAmount(total);
              setDiscountType("promo-code");
            }
          }
          // setPromoStatus(true);
        }
      } catch (e) {
        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
          setPromoLoading(false);
        }
      }
    } else {
      setPromoStatus(false);
      setPromoLoading(false);
      setPromoAmount(0);
      let roundTotal = roundFixed(Number(total), roundLevel, roundMethod);
      setTotal(roundTotal);
      setPromoCode("");
    }
  };

  useEffect(() => {
    if (total > 0) {
      if (promoAmount > 0) {
        setCheckoutByCash(promoAmount >= Number(total));
      } else if (punchDiscount > 0) {
        setCheckoutByCash(punchDiscount >= Number(total));
      } else {
        setCheckoutByCash(false);
      }
    }
  }, [promoAmount, punchDiscount]);

  const handleCampaign = async (arr) => {
    appDispatch(freezOn());
    await axios
      .post(`${process.env.REACT_APP_API_URL}/campaign/get_discount`, {
        customer_id: appState.loggedIn
          ? appState.userInfo != []
            ? appState.userInfo.customerId
            : null
          : null,
        branch_id: appState.qrBranch,
        campaign: appState.campaignState[0],
        orders: arr,
      })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data;
          if (response.status == 200) {
            if (response.data.success == true) {
              let roundTotal = roundFixed(
                Number(response.data.data),
                roundLevel,
                roundMethod
              );
              setCampaignDiscount(roundTotal);
            }
          }
        }
      })
      .catch((error) => {
        if (error == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please check your network.",
          });
        }
        appDispatch({ type: "setCampaignState", campaignState: [] });
        // else {
        //   // appDispatch(handleError(error));
        // }

        appDispatch(freezOff());
      });
    appDispatch(freezOff());
  };

  const setPayTable = () => {
    if (appState.payTableOrders != null) {
      setOrderDetails(appState.payTableOrders);
      setShowTableOrder(true);
    }
    setTotal(totalAmount);
    setSubTotal(subTotalAmount);
    if (appState.storeInfo.allowLogin == 2) {
      appDispatch({ type: "setLoginSkip", loginSkip: true });
    } else {
      appDispatch({ type: "setLoginSkip", loginSkip: false });
    }
  };

  useEffect(() => {
    if (appState.payTableMode) {
      setPayTable();
    }
  }, [appState.total]);

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
    // // consolelog(e.target.value);
    if (!payArray.includes(e.target.value)) {
      setSaveCardId(e.target.value);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getLoyaltyCardId = (appState, appDispatch, setLoyaltyCardId) => {
    if (
      appState.loyaltyRewardObj != undefined ||
      appState.loyaltyRewardObj != null
    ) {
      if (Object.keys(appState.loyaltyRewardObj).length < 1) {
        if (appState.loyaltyObj.length == 1) {
          if (appState.loyaltyObj[0].show) {
            setDiscountType("punch-card");
            let loyaltyId = appState.loyaltyObj[0].id;
            setLoyaltyCardId(loyaltyId != undefined ? loyaltyId : null);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (appState.loyaltyObj != []) {
  //     getLoyaltyCardId(appState, appDispatch, setLoyaltyCardId);
  //   }
  // }, [appState.loyaltyObj]);

  const getLoyaltyRewards = async () => {
    appDispatch(freezOn());
    // eslint-disable-next-line no-unused-vars
    let arr = [];
    let flag = true;
    for (let i = 0; i < appState.loyaltyObj.length; i++) {
      const ele = appState.loyaltyObj[i];
      if (ele?.redeemable) {
        flag = false;
        let result = await getLoyaltyCardDiscount(
          appState,
          appDispatch,
          ele?.id,
          currentOrders
        );
        if (isNotEmpty(result)) {
          arr.push(result);
        }
      }
      if (arr.length > 0 && i + 1 == appState.loyaltyObj.length) {
        setRewardsLoading(true);
      }
    }

    appDispatch({
      type: "setRewardsDetails",
      rewardsDetails: arr,
    });
    setPunchDiscount(arr);
    if (arr.length == 0) {
      appDispatch({
        type: "setOptionalRewardsDetails",
        optionalRewardsDetials: [],
      });
    }
    if (flag) {
      let active = appState.loyaltyObj.some((val) => val.active == true);
      if (active && discountType != "punch-card") {
        // appDispatch({
        //   type: "setSelectLoyaltyCardPopup",
        //   selectLoyaltyCardPopup: true,
        // });
      }
    }
    appDispatch(freezOff());
  };

  useEffect(() => {
    if (currentOrders.length > 0) {
      let res = getLoyaltyRewards();
    }
  }, [currentOrders]);

  // Find chg_id in URL
  useEffect(() => {
    handleCloseAll();
    const queryString = window.location.search;

    setTapId("");
    if (appState.payTableMode) {
      appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      setInvoiceId(appState.tableInvoice);
    } else if (
      // appState.formData.openBill &&
      appState.openBill &&
      !appState.payTableMode &&
      !appState.hotelQrCode &&
      appState.qrTable
    ) {
      // getDineIn();
    } else {
      if (appState.campaignState.length > 0) {
        handleCampaign(orders);
      }
      if (!appState.openBill) {
        let arr = [];
        orders.forEach((ele) => {
          let data = { ...ele, loyalty_reward: null };
          arr.push(data);
        });
        setOrderDetails(arr);
        setCurrentOrders(arr);
        appDispatch({
          type: "updateCart",
          cart: arr,
        });
        setShowTableOrder(true);
        setTotal(appState.subTotal);
        setSubTotal(appState.subTotal);
        handleHotleDiscount();
      }
      // setBillTotal(appState.subTotal);
    }
    // if (appState.loggedIn) {
    //   getWallet();
    // }
    if (appState.payTableMode) {
      if (appState.storeInfo.allowLogin == 2) {
        appDispatch({ type: "setLoginSkip", loginSkip: true });
      } else {
        if (!appState.loggedIn) {
          appDispatch({ type: "setLoginSkip", loginSkip: false });
        } else {
          appDispatch({ type: "setLoginSkip", loginSkip: true });
        }
      }
    } else {
      appDispatch({ type: "setLoginSkip", loginSkip: true });
    }
  }, []);

  const checkPaymentSource = (source) => {
    var ans = payArray.includes(source);
    if (ans) {
      return source;
    } else {
      return "save_card";
    }
  };
  useEffect(() => {
    if (discountType == "") {
      let arr = [];
      orders.forEach((ele) => {
        let data = { ...ele, loyalty_reward: null };
        arr.push(data);
      });
      setOrderDetails(arr);
      setCurrentOrders(arr);
      appDispatch({
        type: "updateCart",
        cart: arr,
      });
    }
  }, [discountType]);

  const orderInvoice = async () => {
    setPLoading(true);
    appDispatch(freezOn());
    const isDeliveryTimeRequired = () =>
      ((appState?.deliveryAddsInfo?.delivery_time_slot_id != null  && availableTime?.decidedDate && appState.storeInfo?.deliveryShecheduled) || appState.storeInfo?.deliveryAsap);
  
    let requiredDatTime = ((appState?.deliveryAddsInfo?.delivery_time_slot_id != null  && availableTime?.decidedDate && appState.storeInfo?.deliveryShecheduled) || appState.storeInfo?.deliveryAsap)  ;
    console.log( requiredDatTime, appState?.deliveryAddsInfo?.delivery_time_slot_id != null  , availableTime?.decidedDate , appState.storeInfo?.deliveryShecheduled) 
    let active =
      appState?.deliveryAddsInfo?.city_id !== null &&
      appState?.deliveryAddsInfo?.block != null &&
      appState?.deliveryAddsInfo?.street != null &&
      appState?.deliveryAddsInfo?.house_no;

    if (
      appState.deliveryAddsInfo?.city_id !== null &&
      appState.deliveryAddsInfo?.block != null &&
      appState.deliveryAddsInfo?.street != null &&
      appState.deliveryAddsInfo?.house_no
    ) {
    }
    let areaSelected = appState.deliveryAddsInfo?.city_id ? true : false;
    if(requiredDatTime) { 
      if (
      active &&
      areaSelected &&
      (appState.loggedIn || appState.guestLoggedIn)
    ) {
      if (formData.order_invoice === "delivery" || appState.deliveryMode) {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/order_invoice/delivery`, {
            loyalty_card_id:
              discountType == "punch-card"
                ? loyaltyCardId
                : discountType == "Rewards"
                ? appState.loyaltyRewardObj.id
                : null,
            loyalty_reward:
              discountType != "punch-card"
                ? appState.loyaltyRewardObj?.discount
                  ? appState.loyaltyRewardObj.discount
                  : undefined
                : null,
            full_name:   appState?.guestLoggedIn && !appState.loggedIn
                ? appState.loginValues?.fullName 
                : !appState.loggedIn
                ? `Customer${Date.now()}`
                : appState.userInfo.userFirstName,
            email:
            appState?.guestLoggedIn && !appState.loggedIn
                ? appState.loginValues?.email
                : !appState.loggedIn
                ? null
                : appState.userInfo.email,
            number:
              appState?.guestLoggedIn && !appState.loggedIn
                ? appState.loginValues?.phoneNumber
                : !appState.loggedIn
                ? null
                : appState.userInfo.number,
            country_code:
              appState?.guestLoggedIn && !appState.loggedIn
                ? appState.loginValues?.phoneCode
                : !appState.loggedIn
                ? null
                : appState.userInfo.countryCode,
            save_card: checkedSaveCard,
            table_id: appState.qrTable,
            branch_id: appState.qrBranch,
            store_id: appState.storeInfo.storeId,
            orders: handleOrdersPayload(currentOrders),
            device_id: appState.deviceId,
            customer_id: appState.loggedIn
              ? appState.userInfo != []
                ? appState.userInfo.customerId
                : null
              : null,
            promo_code: promoCode ? promoCode : null,
            isOpen: false,
            payment_source: null,
            payment_source: appState.splitBill
              ? "partial"
              : checkoutByCash || (appState.storeInfo.buffetMode && total == 0)
              ? "cash"
              : paymentMethod
              ? checkPaymentSource(paymentMethod)
              : null,
            note: formData.note ? formData.note : "N/A",
            token: tapId != "" ? tapId : null,
            tip: manualTip > 0 ? manualTip : customeTip > 0 ? customeTip : 0,
            meta: {
              data:
                partialType == "ratio"
                  ? {
                      person: totalPerson,
                      payer: personWillPay,
                    }
                  : partialType == "product"
                  ? paySelectedItems
                  : null,
              partial_type: partialType,
            },
            payment: appState.splitBill
              ? {
                  payment_source: checkoutByCash
                    ? "cash"
                    : paymentMethod
                    ? checkPaymentSource(paymentMethod)
                    : null,
                  type: partialType,
                  token: tapId != "" ? tapId : "",
                  card_id: saveCardId ? saveCardId : null,
                  meta: {
                    data:
                      partialType == "ratio"
                        ? {
                            person: totalPerson,
                            payer: personWillPay,
                          }
                        : partialType == "product"
                        ? paySelectedItems
                        : null,
                    partial_type: partialType,
                  },
                  amount: Number(splitBillAmount),
                  save_card: checkedSaveCard,
                }
              : [],
            campaign:
              campaignDiscount == 0
                ? null
                : appState.campaignState.length > 0
                ? appState.campaignState[0]
                : null,
            discount:
              storeDiscount < appState?.storeDiscount?.discount_max
                ? appState?.storeDiscount?.discount_percent
                : appState?.storeDiscount?.discount_max,
            discount_type:
              storeDiscount < appState?.storeDiscount?.discount_max
                ? "percent"
                : "fixed",
            delivery_charge_amount: deliveryCharges,
            delivery_date: availableTime?.decidedDate,
            address_type: appState?.deliveryAddsInfo?.formType,
            appartment: appState?.deliveryAddsInfo?.appartment
              ? appState?.deliveryAddsInfo?.appartment
              : null,
            floor: appState?.deliveryAddsInfo?.floor
              ? appState?.deliveryAddsInfo?.floor
              : null,
            block: appState?.deliveryAddsInfo?.block,
            street: appState?.deliveryAddsInfo?.street,
            house_no: appState?.deliveryAddsInfo?.house_no,
            avenue: appState?.deliveryAddsInfo?.avenue,
            rider_note: appState?.deliveryAddsInfo?.rider_note,
            city_id: appState?.deliveryAddsInfo?.city_id,
            area_id: appState?.deliveryAddsInfo?.area_id,
            lat: appState?.deliveryAddsInfo?.lat,
            lng: appState?.deliveryAddsInfo?.lng,
            delivery_time_slot_id:
              expanded == "asap"
                ? null
                : appState?.deliveryAddsInfo?.delivery_time_slot_id,
            delivery_address_id: appState?.deliveryAddsInfo?.delivery_address_id
              ? appState?.deliveryAddsInfo?.delivery_address_id
              : null,
          })
          .then((response) => {
            // consolelog(response);
            if (response.data.success === true) {
              appDispatch({
                type: "setOrderInvoice",
                orderInvoice: response.data.data.service,
              });
              appDispatch(
                cleanRest(appState, response, orders, history, `delivery`)
              );

              setTapId("");
              setPLoading(false);
            }
            if (response.data.success == false) {
              setPLoading(false);
              appDispatch(freezOff());
              appDispatch({
                type: "SHOW_ALERT",
                alertType: "error",
                message: `${response.data.message} : ${response.data.data} `,
              });
            }
          })
          // null wala kam hatana he
          // appState.splitBill
          // ? "partial"
          // : checkoutByCash
          // ? "cash"
          // : paymentMethod
          // ? checkPaymentSource(paymentMethod)
          // : null,

          .catch((error) => {
            if (error == undefined) {
              appDispatch({
                type: "SHOW_ALERT",
                alertType: "error",
                message: "Please check your network.",
              });
            } else {
              appDispatch(handleError(error));
            }
            setPLoading(false);
            appDispatch(freezOff());
          });
      } else {
        appDispatch(freezOff());

        history.push("/");
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `Some thing is wrong please try agian.`,
        });
      }
    } else if (!appState.loggedIn && !appState.guestLoggedIn) {
      setPLoading(false);
      appDispatch(freezOff());
      appDispatch({
        type: "setLoginModal",
        loginModal: true,
      });
    } else {
      setPLoading(false);
      appDispatch(freezOff());

      if (!areaSelected) {

        appDispatch({
          type: "setDeliveryAddsPopup",
          deliveryAddsPopup: true,
        });
      } else {
        if (!active) {

          if (appState.loggedIn) {
            setUserAddressPopup(true);
          } else if (appState.guestLoggedIn) {
            history.push("/address-form?addtype=1");
          }
        }

        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: availableTime?.isDeliveryAvailable
            ? `Address Details are required.2`
            : `Selected Delievery time is not within available range`,
        });
      }
    }}else{
      setPLoading(false);
      appDispatch(freezOff());
      setDeliveryDateTimePopup(true)
      appDispatch({ type: "setPaymentPopUp", paymentPopUp: false });
      setExpanded ('later')
    }
  };

  useEffect(() => {
    if (paymentMethod == `cash` && appState.loggedIn) {
      orderInvoice();
    } else if (
      paymentMethod == `` &&
      appState.loggedIn &&
      !appState.UserAddressPopup
    ) {
      setUserAddressPopup(true);
      setDeliveryDateTimePopup(false);
    }
  }, [appState.loggedIn]);

  const handlCheckoutWithApplePay = () => {
  setPLoading(true);
  appDispatch(freezOn());
  setPaymentMethod("apple_pay");

  const isDeliveryTimeRequired = () =>
    ((appState?.deliveryAddsInfo?.delivery_time_slot_id != null  && availableTime?.decidedDate && appState.storeInfo?.deliveryShecheduled) || appState.storeInfo?.deliveryAsap);

  const isAreaSelected = () => Boolean(appState?.deliveryAddsInfo?.city_id);

  const isAddressActive = () =>
    appState?.deliveryAddsInfo?.city_id &&
    appState?.deliveryAddsInfo?.block &&
    appState?.deliveryAddsInfo?.street &&
    appState?.deliveryAddsInfo?.house_no;

  if (!isDeliveryTimeRequired()) {
    setPLoading(false);
    appDispatch(freezOff());
    setDeliveryDateTimePopup(true);
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: false });
    setExpanded("later");
    return;
  }

  if (!isAreaSelected() || !isAddressActive()) {
    appDispatch({
      type: "setDeliveryAddsPopup",
      deliveryAddsPopup: true,
    });
    return;
  }

  const checkoutData = {
    paymentMethod: "apple_pay",
    checkPaymentSource: checkPaymentSource(),
    totalPerson,
    personWillPay,
    paySelectedItems,
    formData,
    promoCode,
    saveCardId,
    manualTip,
    customeTip,
    splitBillAmount: Number(splitBillAmount),
    orders: handleOrdersPayload(orders),
    invoiceId,
    orderInvoice: formData.order_invoice,
    total,
    openBillTotal,
  };

  getDeliveryApplePayToken(
    history,
    appState,
    appDispatch,
    setPLoading,
    setTapId,
    closePayTable,
    cleanRest,
    checkoutData,
    ApplePaySession,
    handleCloseAll,
    expanded,
    availableTime,
    deliveryCharges
  );
};


  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else if (appState.payTableMode && appState.tableInvoice) {
      setPayTable();
    }
  }, [appState.tableInvoice]);

  const handleCheckout = () => {
    setPLoading(true);
    appDispatch(freezOn());
    let checkoutData = {
      paymentMethod: paymentMethod,
      checkPaymentSource: checkPaymentSource(),
      totalPerson: totalPerson,
      personWillPay: personWillPay,
      paySelectedItems: paySelectedItems,
      formData: formData,
      promoCode: promoCode,
      saveCardId: saveCardId,
      manualTip: manualTip,
      customeTip: customeTip,
      splitBillAmount: Number(splitBillAmount),
      orders: handleOrdersPayload(orders),
      invoiceId: invoiceId,
      orderInvoice: formData.order_invoice,
    };

    if (paymentMethod == "card") {
      childRef.current.submitRequest();
    } else {
      orderInvoice();
    }
  };

  const submitCheckout = (e) => {
    e.preventDefault();

    if (
      appState.loggedIn == true ||
      appState.storeInfo.allowLogin == 2 ||
      appState.loginSkip ||
      appState.guestLoggedIn
    ) {
      if (checkoutByCash || (appState.storeInfo.buffetMode && total == 0)) {
        // orderInvoice();
      } else {
        let active =
          appState?.deliveryAddsInfo?.city_id !== null &&
          appState?.deliveryAddsInfo?.block != null &&
          appState?.deliveryAddsInfo?.street != null &&
          appState?.deliveryAddsInfo?.house_no;

        let areaSelected = appState.deliveryAddsInfo?.city_id ? true : false;
        let availableTimeStatus = availableTime?.isDeliveryAvailable
          ? true
          : false;

        let flag = true;
        if (flag && active && areaSelected && availableTimeStatus) {
          if (
            appState.shopMode.includes("is_partial") &&
            (partialType == null || partialType == "")
          ) {
            setPartialType("customer");
            setSplitBillAmount(totalAmount);
          }
          appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
        } else {
          appDispatch(freezOff());
          if (!areaSelected) {
            appDispatch({
              type: "setDeliveryAddsPopup",
              deliveryAddsPopup: true,
            });
          } else {
            if (!active) {
              if (appState.loggedIn) {
                setUserAddressPopup(true);
              } else {
                history.push("/address-form?addtype=1");
              }
            } else if (!availableTimeStatus) {
              setDeliveryDateTimePopup(true);
            }
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: availableTime?.isDeliveryAvailable
                ? `Address Details are required.1`
                : `Selected Delievery time is not within available range1`,
            });
          }
        }
      }
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
  };

  const goBackBtn = () => {
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: [],
    });

    history.push(`/${appState.orderQrCode}`);
  };

  const handleClickShowCustomeTipField = () => {
    setManualTip(0);
    setCustomeShow(true);
  };
  const handleViewMenu = () => {
    if (appState.storeInfo.iframeUrl != null) {
      history.push("/external");
    } else {
      history.push("/");
      appDispatch({
        type: "setPayTablePopUp",
        payTablePopUp: false,
      });
    }
  };
  useEffect(() => {
    if (manualTip > 0) {
      if (customeShow) {
        setCustomeShow(!customeShow);
      }
    }
    // consolelog(customeShow, manualTip);
  }, [manualTip]);
  useEffect(() => {
    const getTips = async () => {
      setTipLoading(true);
      let defaultStatus = true;
      let arry = [];
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tip?branch=${appState.qrBranch}&store=${appState.storeInfo?.storeId}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        );
        let res = response.data.data;
        if (response.data.success) {
          res.map((val, ind) => {
            let data = {
              id: val.id,
              name: val.name,
              value: val.amount,
              amount:
                val.type == "fixed"
                  ? parseFloat(val.amount).toFixed(3)
                  : parseFloat((val.amount / 100) * total).toFixed(3),
              type: val.type,
              default: val?.default ?? false,
            };
            arry.push(data);
            if (data.default && defaultStatus) {
              let tipAmounts =
                val.type == "fixed"
                  ? parseFloat(val.amount).toFixed(3)
                  : parseFloat((val.amount / 100) * total).toFixed(3);
              setManualTip(tipAmounts);
              setHangTip(data.id);
              defaultStatus = false;
            }
          });
          arry.push({
            id: res.length + 1,
            name: { en: "custom tip", ar: "مبلغ آخر" },
            value: null,
            amount: null,
          });

          setTips(arry);
          setTipLoading(false);
        }
      } catch (e) {
        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
        setTipLoading(false);
      }
    };
    if (total) {
      getTips();
    }
  }, [total]);

  const handleClickShowOption = () => {
    if (
      appState.loggedIn == true ||
      appState.storeInfo.allowLogin == 2 ||
      appState.loginSkip ||
      appState.guestLoggedIn
    ) {
      // handleCheckout();
      if (!appState.shopMode.includes("is_partial")) {
        appDispatch({
          type: "setSplitBillOptions",
          splitBillOptions: true,
        });
        appDispatch({
          type: "setSplitBill",
          splitBill: true,
        });
      } else {
        handleSplitPayements(appState, appDispatch);
      }
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
  };
  const handleCloseAll = () => {
    appDispatch(resetSplitOptions());
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: false });
    setCustomeShow(false);
    setSplitBillAmount(0);
    setPersonWillPay(1);
    setTotalPerson(2);
    setPartialType(null);
    setPaySelectedItems([]);
    setPaymentMethod("");
  };

  const handleChangeTips = (e) => {
    if (e.target.name === "custom tip" || e.target.name === "مبلغ آخر") {
      handleClickShowCustomeTipField();
      setHangTip(Number(e.target.id));
    } else {
      if (e.target.id == hangTip) {
        setHangTip(0);
        setManualTip(Number(0));
      } else {
        setHangTip(Number(e.target.id));
        setManualTip(Number(e.target.value));
        setCustomeTip(0);
      }
    }
  };

  useEffect(() => {
    // calculate total
    let tempTotal =
      appState.payTableMode || appState.openBill ? totalAmount : total;
    let getStoreDiscount = 0;
    if (formData.order_invoice == "dine_in") {
      getStoreDiscount =
        appState.storeDiscount != null
          ? handleStoreDiscount(appState, appDispatch, tempTotal)
          : 0;
      setStoreDiscount(getStoreDiscount);
    }

    let roundTotal =
      tempTotal -
      promoAmount +
      Number(manualTip) +
      Number(customeTip) -
      campaignDiscount -
      hotelDiscount -
      rewardsDiscount -
      getStoreDiscount +
      deliveryCharges;
    roundTotal = roundFixed(Number(roundTotal), roundLevel, roundMethod);
    roundTotal = roundTotal >= 0 ? roundTotal : 0;

    // console.log("tempTotal",tempTotal,"promoAmount",promoAmount,"manualTip",manualTip,"customeTip",customeTip,"campaignDiscount",campaignDiscount,"hotelDiscoun",hotelDiscount,"rewardedDiscount",rewardedDiscount,"roundTotal",roundTotal);
   
    if (discountType == "Rewards") {
      setPunchDiscount(rewardedDiscount ? rewardedDiscount : 0);
    } else {
      setPunchDiscount(0);
    }
    setRewardedDiscount(rewardsDiscount);
    if (roundTotal == 0) {
      setCheckoutByCash(true);
    }
    setOpenBillTotal(roundTotal);
  }, [
    handleChangeTips,
    manualTip,
    customeTip,
    promoAmount,
    total,
    campaignDiscount,
    appState.subTotal,
    appState.invoiceTaxesChargesExtras,
    hotelDiscount,
    appState.storeDiscount,
    deliveryCharges,
  ]);

  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  const handleRetry = () => {
    setRetryAgain(true);
    getPayTableInvoiceNoOrders(appState, appDispatch);

    var timeleft = 29;
    var downloadTimer = setInterval(function () {
      if (timeleft <= -1) {
        clearInterval(downloadTimer);
        setRetryAgain(false);
        setTimer(2);
      } else {
        setTimer(timeleft);
        timeleft -= 1;
      }
    }, 1000);
  };
  const handleClosedBuffetOrder = () => {
    let response = null;
    appDispatch(cleanRest(appState, response, orders, history));
  };

  const handleShowLoyaltyCards = () => {
    if (loyaltyCardId) {
      setShowLoyaltyCardDetails(!showLoyaltyCardDetails);
    } else {
      appDispatch({
        type: "setSelectLoyaltyCardPopup",
        selectLoyaltyCardPopup: true,
      });
    }
  };

  useEffect(() => {
    if (appState?.deliveryAddsInfo?.area_id) {
      if (appState?.deliveryPreparingTime?.length > 0) {
        let findPreparingTime = appState?.deliveryPreparingTime.find((item) =>
          item?.areas?.includes(appState?.deliveryAddsInfo?.area_id)
        );
        if (findPreparingTime) {
          setPreparingTime(findPreparingTime?.minutes);
        } else {
          setPreparingTime(null);
        }
      }
    }
  }, [appState.deliveryAddsInfo]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    appDispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: { ...appState.deliveryAddsInfo, [name]: value },
    });
  };
  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else if (!appState.openBill) {
      if (appState.loginSkip) {
        // appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
      }
    }
  }, [appState.loginSkip]);

  useEffect(() => {
    if (tapId != null && tapId != "") {
      orderInvoice();
    }
  }, [tapId]);
  useEffect(() => {
    if (
      !appState.payTableMode &&
      appState.qrTable &&
      openBillTotal <= 0 &&
      formData.order_invoice == undefined &&
      !isInitialMount
    ) {
      goBackBtn();
    }
  }, [openBillTotal]);

  useEffect(() => {
    const handleFindDeliveryCharges = () => {
      let hasCharges = false;
      let chargesAmount = 0;
      appState.deliveryChargesDetails.forEach((ele) => {
        if (
          ele?.from?.type == "area" &&
          ele?.from?.id == appState?.deliveryAddsInfo?.area_id &&
          !hasCharges
        ) { 
          chargesAmount = Number(ele?.amount);
          hasCharges = true;
        } else if (
          ele?.from?.type == "city" &&
          ele?.from?.id == appState.deliveryAddsInfo?.city_id &&
          !hasCharges
        ) {
          chargesAmount = Number(ele?.amount);
          hasCharges = true;
        }
      });
      if (!hasCharges && appState.storeInfo.defaultDeliveryCharges != null) {
        chargesAmount = Number(appState.storeInfo.defaultDeliveryCharges);
      }
      setDeliveryCharges(chargesAmount);
    };

    if (appState?.deliveryAddsInfo?.city_id > 0) {
      handleFindDeliveryCharges();
    }
  }, [appState.deliveryAddsInfo?.city_id, appState.storeInfo.defaultDeliveryCharges]);

  useEffect(() => {
    if (hasMounted) {
      if (appState.pusherActive > 0 && appState.payTableMode) {
        appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      } else if (appState.pusherActive > 0 && appState.openBill) {
        // getDineIn();
      }
    } else {
      setHasMounted(true);
    }
  }, [appState.pusherActive]);
  useEffect(() => {
    if (appState.invoiceTaxesChargesExtras.tip > 0) {
      let showTip = appState.invoiceTaxesChargesExtras.tip;
      setCustomeTip(showTip);
    }
  }, [appState.invoiceTaxesChargesExtras]);

  useEffect(() => {
    if (invoiceId != null) {
      if (!appState.pusherActive) {
        getLiveData(
          invoiceId,
          appState,
          appDispatch,
          orderUpdateCount,
          setOrderUpdateCount
        );
      }
    }
  }, [invoiceId]);

  useEffect(async () => {
    let { inRange, start_time, hasSlots, stopAsapDueToShechedule } = await getEstimatedTime(
      appState,
      setDeliveryAddsInfo
    );
    setShowDeliveryCheckout(inRange ? 1 : 2)
    setStopAsapBtn(stopAsapDueToShechedule)
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo,  inRange, start_time });
    setDeliveryIsAvalaible(true);
    setHasDeliverySlots(hasSlots);

    if (!appState.loggedIn && !appState.guestLoggedIn) {
      appDispatch({
        type: "setLoginModal",
        loginModal: true,
      });
      appDispatch({ type: "setLoginSkip", loginSkip: false });
    } else if (appState.loggedIn) {
      setUserAddressPopup(true);
    }
  }, [appState.storeInfo?.deliveryShecheduled]);

  useEffect(() => {
    if (appState.guestLoggedIn) {
      appDispatch({ type: "setLoginSkip", loginSkip: false });
    }
  }, [appState.loginSkip]);
  useEffect(() => {
   if(appState.deliveryDateTimePopup) {
      document.body.style.overflow = "hidden";
      console.log("scrolling off");
    } else {
      document.body.style.overflow = "visible";
      console.log("scrolling start");
    }
  }, [appState.deliveryDateTimePopup])
  

  if (!deliveryIsAvalaible) {
    // if(true){


    return (
      <div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-2 px-2">
            <Skeleton
              variant="rect"
              height={100}
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className=" col-md-8 px-2">
            <Skeleton
              height={50}
              style={{
                transform: "translate(2px, 0)",
                marginBottom: "10px",
              }}
            />
            <Skeleton height={25} />
            <Skeleton height={25} />
          </div>
          <div className=" col-md-2 px-2">
            <Skeleton
              variant="rect"
              height={50}
              style={{ borderRadius: "50px" }}
            />
          </div>
          {/* <div className=" col-md-3 px-2">
    <Skeleton height={100} />
    <Skeleton height={25} />
    <Skeleton height={25} />
    </div> */}
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-12 px-2">
            <Skeleton
              variant="rect"
              height={50}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-12 px-2">
            <Skeleton
              variant="rect"
              height={100}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-6 px-2">
            <Skeleton
              variant="rect"
              height={70}
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className=" col-md-6 px-2">
            <Skeleton
              variant="rect"
              height={70}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-12 px-2">
            <Skeleton
              variant="rect"
              height={200}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-12 px-2">
            <Skeleton
              variant="rect"
              height={80}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3">
          <div className=" col-md-12 px-2">
            <Skeleton
              variant="rect"
              height={80}
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    showDeliveryCheckout == 1 ? (
      <React.Fragment>
        {(appState.splitBill ||
          customeShow ||
          appState.paymentPopUp ||
          appState.selectLoyaltyCardPopup ||
          appState.optionalRewardsPopUp ||
          appState.userAddressPopup ||
          appState.deliveryDateTimePopup) && (
          <Backdrop
            onClick={handleCloseAll}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 4,
            }}
            open={
              appState.splitBill ||
              customeShow ||
              appState.paymentPopUp ||
              appState.selectLoyaltyCardPopup ||
              appState.optionalRewardsPopUp ||
              appState.userAddressPopup ||
              appState.deliveryDateTimePopup
            }
          />
        )}
        <Page title="CheckOut">
          {appState.tableInvoice != null || !appState.payTableMode ? (
            <>
              {open ? (
                <TopUpPopUp
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  topUpOpen={open}
                />
              ) : (
                ""
              )}

              <div
                id="wrapper"
                className={`${pLoading ? "disableClass" : ""}  ${
                  appState.langValue === "ar" ? "directionRtl" : ""
                }  ${
                  appState.paymentPopUp || appState.splitBillOptions
                    ? "paddingForPopup"
                    : "paddingFirstTime"
                }`}
              >
                <section id="topNav ">
                  <div className="row m-0 checkOut_Nav justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <IconButton
                        aria-label="back"
                        className={
                          appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                        }
                        // orderbckBtn"
                        onClick={goBackBtn}
                      >
                        {appState.langValue === "ar" ? (
                          <ArrowForwardIosIcon
                            style={{ padding: 0 }}
                            fontSize="inherit"
                          />
                        ) : (
                          <ArrowBackIosIcon fontSize="inherit" />
                        )}
                      </IconButton>
                      <div className="pl-1 mt-2">
                        <h4>
                          <strong>{t("navBar.Checkout")}</strong>
                        </h4>
                      </div>
                    </div>

                    {appState.langValue == "ar" ? (
                      <IconButton
                        id="headerBtn"
                        onClick={(e) => enLanguageHandle(e)}
                      >
                        <span className={`lang-textScroll p-0`}>EN</span>
                      </IconButton>
                    ) : (
                      <IconButton
                        id="headerBtn"
                        onClick={(e) => arLanguageHandle(e)}
                        className="arabicBtn"
                      >
                        <span className={`arabicText lang-textScroll p-0 mb-1`}>
                          عربي
                        </span>
                      </IconButton>
                    )}
                  </div>
                </section>

                {/* {appState.loggedIn && appState.loyaltyObj.length > 0 && (
                <SelectLoyalityCardSlider
                  setDiscountType={setDiscountType}
                  discountType={discountType}
                  loyaltyCardId={loyaltyCardId}
                  setLoyaltyCardId={setLoyaltyCardId}
                  setShowLoyaltyCardDetails={setShowLoyaltyCardDetails}
                />
              )} */}

                {/* {rewardsLoading && (
                <DiscountOptions
                  discountType={discountType}
                  setDiscountType={setDiscountType}
                  setVoucherObj={setVoucherObj}
                  voucherObj={voucherObj}
                  currentOrders={currentOrders}
                  dOptionValue={dOptionValue}
                  setDOptionValue={setDOptionValue}
                  loyaltyCardId={loyaltyCardId}
                  setLoyaltyCardId={setLoyaltyCardId}
                  punchDiscount={punchDiscount}
                  showLoyaltyCardDetails={showLoyaltyCardDetails}
                />
              )} */}

                {/* <section className="storeInfo  px-4">
                {appState.deliveryMode && (
                  <div className="row m-0   py-2  tableShow d-flex align-items-center  justify-content-between ">
                    <span>
                    <p className="m-0 ">
                      <spa>
                        <DeliveryDiningIcon />
                      </spa>
                      {t("Delivery")}
                    </p>
                    <p>
                       <span className="mx-1 text-muted" >
                      {t('Arriving in approx.')}
                      </span>
                     {preparingTime} {t('minutes')}

                    </p>

                    </span>
                    
                    <p className={`m-0  DeliveryTitle  ${appState.langValue == "ar" ? "text-right pr-3" : "text-left pl-3"}`}>
                      {appState.deliveryAddsInfo?.area_name ? (
                        <h1
                          className={` DeliveryTitle m-0 d-flex align-items-center   ${appState.langValue === "ar"
                              ? "text-right familyGraphikArabic"
                              : "text-left familyPoppins300"
                            }`}
                        >
                          <span>
                            {appState.deliveryAddsInfo?.city_name
                              ? get_lang(
                                appState.deliveryAddsInfo?.city_name,
                                appState.langValue
                              )
                              : `City`}
                          </span>
                          <span
                            className={` px-1 ${appState.langValue === "ar"
                                ? "text-right familyGraphikArabic"
                                : "text-left familyPoppins300"
                              }`}
                          >
                            {appState.deliveryAddsInfo?.area_name
                              ? `>  ${get_lang(
                                appState.deliveryAddsInfo?.area_name,
                                appState.langValue
                              )}`
                              : ` > Area`}{" "}
                          </span>
                          <span>
                            <Button variant="text" className="primary-text-btn"
                              onClick={() =>
                                appDispatch({
                                  type: "setDeliveryAddsPopup",
                                  deliveryAddsPopup: true,
                                })
                              }>{t('Edit')}</Button>

                          </span>
                        </h1>
                      ) : (
                        <span>
                          {t("Select Delivery Address")}
                          <span style={{ color: "red" }}>*</span>
                          <span>
                            <IconButton
                              onClick={() =>
                                appDispatch({
                                  type: "setDeliveryAddsPopup",
                                  deliveryAddsPopup: true,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </span>
                        </span>
                      )}
                    </p>
                    
                  </div>
                )}
              </section> */}

                <section className="storeInfo  px-4">
                  {appState.deliveryMode && (
                    <div className="row m-0   py-2  tableShow  ">
                      <div className="col-md-12 p-0 d-flex align-items-center justify-content-between w-100">
                        <p className="m-0 d-flex align-items-center">
                          <spa>
                            <DeliveryDiningIcon />
                          </spa>
                          <span className="px-1">{t("Delivery")}</span>
                        </p>

                        <p
                          className={` DeliveryTitle m-0 d-flex align-items-center   ${
                            appState.langValue === "ar"
                              ? "text-right familyGraphikArabic"
                              : "text-left familyPoppins300"
                          }`}
                        >
                          <span>
                            {appState.deliveryAddsInfo?.city_name
                              ? get_lang(
                                  appState.deliveryAddsInfo?.city_name,
                                  appState.langValue
                                )
                              : `City`}
                          </span>
                          <span
                            className={` px-1 ${
                              appState.langValue === "ar"
                                ? "text-right familyGraphikArabic"
                                : "text-left familyPoppins300"
                            }`}
                          >
                            {appState.deliveryAddsInfo?.area_name
                              ? `>  ${get_lang(
                                  appState.deliveryAddsInfo?.area_name,
                                  appState.langValue
                                )}`
                              : ` > Area`}{" "}
                          </span>
                          {preparingTime == null && (
                            <span>
                              {/* <Button
                                variant="text"
                                className="primary-text-btn"
                                onClick={() =>
                                  appDispatch({
                                    type: "setDeliveryAddsPopup",
                                    deliveryAddsPopup: true,
                                  })
                                }
                              >
                                {t("Change")}
                              </Button> */}
                            </span>
                          )}
                        </p>
                      </div>
                      {preparingTime && (
                        <div className="col-md-12 p-0 d-flex align-items-center justify-content-between w-100">
                          <p>
                            <span
                              className={`mx-1 text-muted ${
                                appState.langValue == "ar" ? "mr-4" : "ml-4"
                              }`}
                            >
                              {t("Arriving in approx.")}
                            </span>
                            {preparingTime} {t("minutes")}
                          </p>
                          <span>
                            {/* <Button
                              variant="text"
                              className="primary-text-btn"
                              onClick={() =>
                                appDispatch({
                                  type: "setDeliveryAddsPopup",
                                  deliveryAddsPopup: true,
                                })
                              }
                            >
                              {t("Change")}
                            </Button> */}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </section>

                <section className="storeInfo  px-4">
                  {appState.deliveryMode && (
                    <div className="row m-0  mt-3 py-2  tableShow d-flex align-items-center  justify-content-between ">
                      <p className="m-0 tableNo w-100">
                        <div className="col-md-12 p-0 m-0 d-flex align-items-center justify-content-between w-100">
                          <span className="">
                            <span>
                              {t("Welcome!")}
                              <span className="px-1">
                                {" "}
                                {appState.loggedIn
                                  ? appState.userInfo.userFirstName
                                  : appState.guestLoggedIn
                                  ? appState.loginValues?.fullName
                                  : "Guest"}
                              </span>
                            </span>
                            <p className="primary-text-btn mb-0">
                              {" "}
                              <strong>{t("Phone")} :</strong>{" "}
                              {appState.loggedIn
                                ? `+${appState.userInfo.countryCode} ${appState.userInfo.number}`
                                : appState.guestLoggedIn
                                ? `+${appState.loginValues?.phoneCode} ${appState.loginValues?.phoneNumber}`
                                : ""}{" "}
                            </p>
                            {((appState.loggedIn && appState.userInfo.email) || (appState.guestLoggedIn && appState.loginValues?.email)) && <p className="primary-text-btn mb-0">
                              {" "}
                              <strong>{t("Email")} :</strong>{" "}
                              {appState.loggedIn
                                ? `${appState.userInfo.email} `
                                : appState.guestLoggedIn
                                ? `${appState.loginValues?.email} `
                                : ""}{" "}
                            </p>}
                          </span>

                          <span>
                            {/* <IconButton onClick={() => history.push("/map")}>
                            {" "}
                            <Edit />{" "}
                          </IconButton> */}
                            {appState.loggedIn ? (
                              <Button
                                variant="text"
                                className="primary-text-btn"
                                onClick={() =>
                                  appDispatch(
                                    getLogOut(appState.userInfo.userToken)
                                  )
                                }
                              >
                                {t("Logout")}
                              </Button>
                            ) : (
                              <Button
                                variant="text"
                                className="primary-text-btn"
                                onClick={() =>
                                  appState.storeInfo.allowLogin != "1"
                                    ? setLoginModal(true)
                                    : setGuestModal(true)
                                }
                              >
                                {t("Edit")}
                              </Button>
                            )}
                          </span>
                        </div>

                        <p className="d-flex align-items-center m-0 justify-content-between">
                          {" "}
                          <span>
                            <span>{t("Address")}: </span>{" "}
                            {appState.deliveryAddsInfo?.fullAddress}
                          </span>
                          <span>
                            <Button
                              variant="text"
                              className="primary-text-btn"
                              onClick={() =>
                                appState.loggedIn
                                  ? setUserAddressPopup(true)
                                  : !appState.guestLoggedIn
                                  ? setGuestModal(true)
                                  : history.push("/address-form?addtype=1")
                              }
                            >
                              {t("Edit")}
                            </Button>
                          </span>
                        </p>
                      </p>
                    </div>
                  )}
                </section>

                {/* <section className="pay_at_table mt-2">
                <div className="row m-0 your_order">
                  <div className="col-12 p-0">
                    <div className="row m-0">
                      <Button
                        onClick={() => setShowEstimateTime(!showEstimateTime)}
                        className="col-12 px-3 pt-4  viewEstimateTime-content  "
                      >
                        {/* <h2 className="m-0">{t("checkOut.Payment-Summary")}</h2> 
                        <div className="d-flex align-items-start  flex-column">
                          <h2 className="m-0">{t("Delivery Time")}</h2>
                          <p>
                            {availableTime?.day} start time{" "}
                            <span style={{ color: "green" }}>
                              {availableTime?.startTime}
                            </span>{" "}
                            to end time{" "}
                            <span style={{ color: "red" }}>
                              {availableTime?.endTime}
                            </span>
                          </p>
                        </div>
                        <div className="icons mx-2">
                          {showDelivery ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </section> */}

                {((appState.deliveryMode &&
                  appState.storeInfo?.deliveryShecheduled )|| appState.storeInfo?.deliveryAsap) && (
                    <>
                      <DeliveryOptionsBtns
                        stopAsapBtn={stopAsapBtn}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        availableTime={availableTime}
                        setAvailableTime={setAvailableTime}
                      />
                    </>
                  )}

                {appState.deliveryMode &&
                  appState.deliveryAddsPopup &&
                  appState.countryCityArea && <NewDeliveryPopup />}
                {payments.includes("tip") && (
                  <section id="tip" className="row m-0 mt-2 pb-3">
                    <div className="col-12 p-0">
                      <div className="row m-0">
                        <h3 className="col-12 p-0 d-flex">
                          {t("Thank them with a tip")}{" "}
                          <span className="px-1"> 🤩</span>
                        </h3>
                        <h4
                          className={` col-12 p-0 text-muted d-flex ${
                            appState.langValue === "ar"
                              ? "text-right familyGraphikArabic800 "
                              : " familyPoppins300"
                          }`}
                        >
                          {t("Your tips go directly to the team’s tip jar")}
                        </h4>
                      </div>
                      {tipLoading ? (
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-3 px-2">
                            <Skeleton height={150} />
                          </div>
                          <div className="col-md-3 px-2">
                            <Skeleton height={150} />
                          </div>
                          <div className="col-md-3 px-2">
                            <Skeleton height={150} />
                          </div>
                          <div className="col-md-3 px-2">
                            <Skeleton height={150} />
                          </div>
                        </div>
                      ) : (
                        <div id="TipCheckBoxParent">
                          <div
                            className="row m-0 TipselectPaymentRow TipselectPaymentRowWallet"
                            onChange={handleChangeTips}
                          >
                            {tips.length > 0 &&
                              tips.map((val, ind) => {
                                return (
                                  <>
                                    <div
                                      className="col-3 tipManinCol direction-ltr"
                                      key={ind}
                                    >
                                      <Button
                                        className="w-100 h-100 p-0"
                                        variant="outlined"
                                      >
                                        <div className="paymentContainer w-100">
                                          <input
                                            type="checkbox"
                                            value={val.amount}
                                            name={get_lang(
                                              val.name,
                                              appState.langValue
                                            )}
                                            id={val.id}
                                            checked={hangTip == val.id}
                                          />
                                          <label htmlFor={val.id}>
                                            <span>
                                              <strong className="d-flex tiptext justify-content-center textOver">
                                                {get_lang(
                                                  val.name,
                                                  appState.langValue
                                                )}
                                              </strong>
                                            </span>

                                            {val.name.en === "custom tip" ? (
                                              <>
                                                <p className="">
                                                  <EditIcon />
                                                </p>
                                              </>
                                            ) : (
                                              <p className="textBlack mb-0 d-flex flex-wrap justify-content-center familyManrope800">
                                                <span>
                                                  {val?.type == "fixed"
                                                    ? val?.value
                                                    : `${Math.round(
                                                        Number(val?.value)
                                                      )} %`}
                                                </span>
                                              </p>
                                            )}
                                            {hangTip == val.id && (
                                              <span className="tipCross">
                                                <IconButton className="pe-none">
                                                  <ClearIcon />
                                                </IconButton>
                                              </span>
                                            )}
                                          </label>
                                        </div>
                                      </Button>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                )}

                <Vouceher
                  promoCode={promoCode}
                  setPromoCode={setPromoCode}
                  promoStatus={promoStatus}
                  getPromo={getPromo}
                  promoLoading={promoLoading}
                  setPromoAmount={setPromoAmount}
                />

                <section className="pay_at_table mt-2">
                  <div className="row m-0 your_order">
                    <div className="col-12 p-0">
                      <div className="row m-0">
                        <Button
                          onClick={() =>
                            setShowPaymentDetails(!showPaymentDetails)
                          }
                          className="col-12 px-3 py-4  viewMenu-content  align-items-center"
                        >
                          <h2 className="m-0">
                            {t("checkOut.Payment-Summary")}
                          </h2>

                          <div className="icons mx-2">
                            {showPaymentDetails ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </section>

                {showPaymentDetails && (
                  <>
                    <section id="paymentSection" className="row m-0 py-2">
                      <div className="col-12 p-0">
                        <div className="row m-0">
                          <div className="payCart col-12 p-0">
                            <h4 className="payText">
                              {t("checkOut.Subtotal")}
                            </h4>
                            <h4>
                              {subTotal
                                ? parseFloat(subTotal).toFixed(3)
                                : parseFloat(appState.subTotal).toFixed(3)}
                              &nbsp;
                              {get_lang(storeCurrency, appState.langValue)}
                            </h4>
                          </div>
                        </div>
                        {deliveryCharges > 0 && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {appState.storeInfo?.deliveryChargesName != null
                                  ? get_lang(
                                      appState.storeInfo?.deliveryChargesName,
                                      appState.langValue
                                    )
                                  : t("Delivery Charges")}
                              </h4>
                              <h4>
                                {""}
                                {parseNumber(deliveryCharges, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}
                        {(discount > 0 ||
                          hotelDiscount > 0 ||
                          campaignDiscount > 0 ||
                          storeDiscount > 0) && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {t("checkOut.discount-amount")}
                              </h4>
                              <h4>
                                {hotelDiscount > 0
                                  ? parseNumber(hotelDiscount, numberFixed)
                                  : campaignDiscount > 0
                                  ? parseNumber(campaignDiscount, numberFixed)
                                  : storeDiscount > 0
                                  ? parseNumber(storeDiscount)
                                  : parseNumber(discount, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}
                        {rewardedDiscount > 0 && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {t("loyalty.rewards-discount")}
                              </h4>
                              <h4>
                                {parseNumber(rewardedDiscount, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}
                        {/* this */}
                        {charges != [] &&
                          charges.length > 0 &&
                          charges.map((item, ind) => {
                            return (
                              <div className="row m-0" key={ind}>
                                <div className="payCart col-12 p-0">
                                  <h4 className="payText text-capitalize">
                                    {/* {t("checkOut.charges")} */}
                                    {get_lang(item.name, appState.langValue)}
                                  </h4>

                                  <h4>
                                    {item.tax > 0 && (
                                      <span>
                                        {t("checkOut.Tax")}({item.tax}
                                        &nbsp;
                                        {get_lang(
                                          storeCurrency,
                                          appState.langValue
                                        )}
                                        ) &nbsp; &nbsp;
                                      </span>
                                    )}

                                    <span>
                                      {parseNumber(
                                        item.calculateAmount,
                                        numberFixed
                                      )}
                                      &nbsp;
                                      {get_lang(
                                        storeCurrency,
                                        appState.langValue
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            );
                          })}

                        {/* <ApplePayButton theme="light">{"Subscribe with"}</ApplePayButton> */}

                        {totalCharges > 0 && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {t("checkOut.total-charges")}
                              </h4>
                              <h4>
                                {parseNumber(totalCharges, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}

                        {totalTax > 0 && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {t("checkOut.total-tax")}
                              </h4>
                              <h4>
                                {parseNumber(totalTax, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}

                        {(manualTip > 0 || customeTip > 0) && (
                          <div className="row m-0">
                            <div className="payCart col-12 p-0">
                              <h4 className="payText">
                                {t("checkOut.Tip-Total")}
                              </h4>
                              <h4 style={{ color: "green" }}>
                                {manualTip > 0
                                  ? parseNumber(manualTip, numberFixed)
                                  : parseFloat(customeTip).toFixed(3)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h4>
                            </div>
                          </div>
                        )}

                        {( 
                          promoAmount == 0 ? (
                            ''
                          ) : (
                            <div className="row m-0">
                              <div className="payCart col-12 p-0">
                                <h4 className="payText d-flex">
                                  {t("checkOut.Promo-Discount")}
                                </h4>
                                <h5 style={{ color: "red" }}>
                                  -{parseNumber(promoAmount, numberFixed)}&nbsp;
                                  {get_lang(storeCurrency, appState.langValue)}
                                </h5>
                              </div>
                            </div>
                          )
                         
                        )}
                        {/* {( promoAmount > 0 ?
                          promoAmount => 0 ? (
                            <div className="row m-0">
                              <div className="payCart col-12 p-0">
                                <h4 className="payText d-flex">
                                  {t("checkOut.Promo-Discount")}
                                </h4>
                                <h6 style={{ color: "red" }}>
                                  This Promo Discount can not use to this
                                  payment.
                                </h6>
                              </div>
                            </div>
                          ) : (
                            <div className="row m-0">
                              <div className="payCart col-12 p-0">
                                <h4 className="payText d-flex">
                                  {t("checkOut.Promo-Discount")}
                                </h4>
                                <h5 style={{ color: "red" }}>
                                  -{parseNumber(promoAmount, numberFixed)}&nbsp;
                                  {get_lang(storeCurrency, appState.langValue)}
                                </h5>
                              </div>
                            </div>
                          )
                          :""
                        )} */}

                        {/* <div className="row m-0">
           <div className="payCart col-12 p-0">
               <h4 className="payText">Delivery Fee</h4>
               <h4>{appState.deliveryCharges} &nbsp;{t(`${storecurrency}`)}</h4>
           </div>
       </div> */}
                        <hr className="my-1" />

                        <div className="row m-0">
                          <div className="payCart col-12 p-0">
                            <h3>{t("checkOut.Total")}</h3>
                            {/* changes by sajid */}

                            {currentInvoiceStatus == "Partial" ? (
                              <h3 className="totalAmount">
                                {parseNumber(fullAmount, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h3>
                            ) : (
                              <h3 className="totalAmount">
                                {promoAmount <= parseNumber(total, numberFixed)
                                  ? parseNumber(openBillTotal, numberFixed)
                                  : parseNumber(appState.subTotal, numberFixed)}
                                &nbsp;
                                {get_lang(storeCurrency, appState.langValue)}
                              </h3>
                            )}
                            {/*  */}
                          </div>
                        </div>
                        {/* <hr /> */}
                        {currentInvoiceStatus == "Partial" && (
                          <>
                            <div className="row m-0">
                              <div className="payCart col-12 p-0">
                                <h3>{t("checkOut.You-Paid")}</h3>
                                {/* changes by sajid */}

                                <h3 className="totalAmount">
                                  {parseNumber(paidAmount, numberFixed)}
                                  &nbsp;
                                  {get_lang(storeCurrency, appState.langValue)}
                                </h3>
                                {/*  */}
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="payCart col-12 p-0">
                                <h3>{t("checkOut.Left-to-Pay")}</h3>
                                {/* changes by sajid */}

                                <h3 className="totalAmount">
                                  {parseNumber(leftToPay, numberFixed)}
                                  &nbsp;
                                  {get_lang(storeCurrency, appState.langValue)}
                                </h3>
                                {/*  */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </section>
                  </>
                )}

                <>
                  {appState.payTableMode && (
                    <section className="pay_at_table">
                      <div className="row m-0 view_menu">
                        <div className="col-12 p-0">
                          <div className="row m-0">
                            <Button
                              onClick={handleViewMenu}
                              className="col-12 px-3 py-3  viewMenu-content  align-items-center"
                            >
                              <h2 className="m-0">{t("checkOut.view-menu")}</h2>

                              <div className="icons">
                                <img
                                  src={`/images/BookOpen.svg`}
                                  className="img-pr"
                                  width="24px"
                                  alt=""
                                />
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  <section
                    className={`pay_at_table my-3 ${
                      !payments.includes("partial") &&
                      !appState.storeInfo.buffetMode &&
                      total != 0 &&
                      "mb-5"
                    }`}
                  >
                    <div className="row m-0 your_order">
                      <div className="col-12 p-0">
                        {showTableOrder ? (
                          <div className="row m-0">
                            <Button
                              onClick={() => setShowOrders(!showOrders)}
                              className="col-12 px-3 py-4  viewMenu-content  align-items-center"
                            >
                              <h2 className="m-0">{t("checkOut.my-order")}</h2>

                              <div className="icons mx-2">
                                {showOrders ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </div>
                            </Button>
                          </div>
                        ) : (
                          <Skeleton height={100} />
                        )}
                      </div>
                    </div>
                  </section>
                </>
                {showOrders && (
                  <section className="pay_at_table_orders">
                    {orderDetails != []
                      ? orderDetails.map((order, i) => {
                          return (
                            <span key={i}>
                              <div className="row m-0  your_order mt-3">
                                <div className="col-12 px-0 d-flex justify-content-between align-items-center">
                                  <h3>
                                    {order.product != null
                                      ? get_lang(
                                          order.product?.name,
                                          appState.langValue
                                        )
                                      : "Name is not available"}
                                  </h3>

                                  <div className="d-flex">
                                    <h3 className="mx-3 priceColor">
                                      {parseNumber(order.price, numberFixed)}
                                      &nbsp;
                                      {get_lang(
                                        storeCurrency,
                                        appState.langValue
                                      )}
                                    </h3>
                                    {order?.loyalty_reward != null &&
                                    order?.loyalty_reward > 0 ? (
                                      <span>
                                        <h3 className="priceColor">
                                          Paid Qty
                                          {Number(order.quantity) -
                                            Number(order?.loyalty_reward)}
                                        </h3>
                                        <h3 className="priceColor">
                                          Free Qty{" "}
                                          {Number(order?.loyalty_reward)}
                                        </h3>
                                      </span>
                                    ) : (
                                      <h3 className="priceColor">
                                        Qty {order.quantity}
                                      </h3>
                                    )}

                                    {/* 
                                  <div className="d-flex">
                                    {
                                      <h3 className="mx-3 priceColor">
                                        {itemzAddon.price > 0 ? (
                                          <>
                                            {itemAddon.price ||
                                              (!itemAddon.price &&
                                                parseNumber(
                                                  (Number(itemAddon.price) *
                                                  Number(
                                                    itemAddon.quantity
                                                  )),
                                                  numberFixed
                                                ))}
                                  &nbsp;

                                            {get_lang(
                                              storeCurrency,
                                              appState.langValue
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </h3>
                                    }
                                    <h3 className="priceColor">
                                      
                                      <span>Qty:</span>
                                      <span>{itemAddon.quantity}</span>
                                    </h3>
                                  </div> */}
                                  </div>
                                </div>
                                <span className="border-bottom-pay_table w-100"></span>
                                {order?.note && (
                                  <div
                                    className={` addonsh4  ${
                                      appState.langValue === "ar"
                                        ? "text-right "
                                        : "text-left familyPoppins300 familyManrope40"
                                    }  text-capitalize`}
                                  >
                                    {t("checkOut.Notes")} :
                                    <span className="text-muted">
                                      {order.note}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="row m-0 your_order">
                                {!order.addons.length ? (
                                  ""
                                ) : (
                                  <div className="col-12 px-0 d-flex justify-content-between align-items-center text-capitalize">
                                    <h3>Extras:</h3>
                                  </div>
                                )}
                              </div>

                              {order.addons.map((itemAddon, i) => {
                                return (
                                  <div
                                    className="row m-0 your_order text-capitalize"
                                    key={i}
                                  >
                                    <div className="col-12 px-0 d-flex justify-content-between align-items-center">
                                      <h3>
                                        {itemAddon.product_addon.addon.name.en}
                                      </h3>

                                      <div className="d-flex">
                                        {
                                          <h3 className="mx-3 priceColor">
                                            {itemAddon.price > 0 ? (
                                              <>
                                                {itemAddon.price ||
                                                  (!itemAddon.price &&
                                                    parseNumber(
                                                      Number(itemAddon.price) *
                                                        Number(
                                                          itemAddon.quantity
                                                        ),
                                                      numberFixed
                                                    ))}
                                                {get_lang(
                                                  storeCurrency,
                                                  appState.langValue
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </h3>
                                        }
                                        <h3 className="priceColor">
                                          <span>Qty:</span>
                                          <span>{itemAddon.quantity}</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              <hr />
                            </span>
                          );
                        })
                      : ""}
                    <div className="row m-0 your_order">
                      {!appState.combos.length ? (
                        ""
                      ) : (
                        <div className="col-12 px-0 d-flex justify-content-between align-items-center">
                          <h3>Combos:</h3>
                        </div>
                      )}
                    </div>
                    {appState.combos != null
                      ? appState.combos.map((combo, i) => {
                          return (
                            <span key={i}>
                              <div className="row m-0  your_order">
                                <div className="col-12 px-0 d-flex justify-content-between align-items-center text-capitalize">
                                  <h3>
                                    {combo.combo?.name != null
                                      ? get_lang(
                                          combo.combo?.name,
                                          appState.langValue
                                        )
                                      : "Name is not available"}
                                  </h3>

                                  <div className="d-flex text-capitalize">
                                    {combo.total_tax > 0 && (
                                      <h3 classname="priceColor">
                                        {t("checkOut.Tax")} (
                                        {combo.total_tax.toFixed(3)}
                                        {get_lang(
                                          storeCurrency,
                                          appState.langValue
                                        )}
                                        )
                                      </h3>
                                    )}
                                    <h3 className="mx-3 priceColor">
                                      {combo?.amount > 0
                                        ? parseNumber(
                                            Number(combo.amount),
                                            numberFixed
                                          )
                                        : ""}
                                      &nbsp;
                                      {combo?.amount > 0 &&
                                        get_lang(
                                          storeCurrency,
                                          appState.langValue
                                        )}
                                    </h3>
                                    <h3 className="priceColor">
                                      Qty {combo.quantity}
                                    </h3>
                                  </div>
                                </div>
                                <span className="border-bottom-pay_table w-100"></span>
                              </div>
                            </span>
                          );
                        })
                      : ""}
                  </section>
                )}

                <h1
                  className={`familyManrope800 ${
                    appState.langValue === "ar" ? "text-right" : "text-left"
                  }`}
                ></h1>
                <div
                  className={`px-3 ${
                    appState.langValue === "ar"
                      ? "classforArabiccustomField "
                      : ""
                  }`}
                ></div>

                {appState.splitBillOptions && <SplitBillOptions />}

                {appState.paymentPopUp && (
                  <PaymentMethods
                    handlCheckoutWithApplePay={handlCheckoutWithApplePay}
                    handleCheckout={handleCheckout}
                    payments={payments}
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                    tapStatus={tapStatus}
                    setTapId={setTapId}
                    setCheckedSaveCard={setCheckedSaveCard}
                    checkedSaveCard={checkedSaveCard}
                    setLoading={setLoading}
                    handleCloseAll={handleCloseAll}
                    campaignDiscount={campaignDiscount}
                    discount={storeDiscount > 0 ? storeDiscount : discount}
                    childRef={childRef}
                    type="delivery"
                  />
                )}

                {!payments.includes("partial") &&
                  !appState.storeInfo.buffetMode &&
                  total != 0 &&
                  appState?.storeInfo?.hasPayMethods && (
                    <PaymentMethods
                      handlCheckoutWithApplePay={handlCheckoutWithApplePay}
                      handleCheckout={handleCheckout}
                      payments={payments}
                      setPaymentMethod={setPaymentMethod}
                      paymentMethod={paymentMethod}
                      tapStatus={tapStatus}
                      setTapId={setTapId}
                      setCheckedSaveCard={setCheckedSaveCard}
                      checkedSaveCard={checkedSaveCard}
                      setLoading={setLoading}
                      handleCloseAll={handleCloseAll}
                      campaignDiscount={campaignDiscount}
                      discount={storeDiscount > 0 ? storeDiscount : discount}
                      childRef={childRef}
                    />
                  )}
                {appState.payForItems && (
                  <PayForItems
                    orders={
                      appState.payTableMode
                        ? appState.payTableOrders
                        : formData.order_invoice === "open_bill"
                        ? openBillOrders
                        : orders
                    }
                    subTotal={total}
                    promoAmount={promoAmount}
                    paySelectedItems={paySelectedItems}
                    setPaySelectedItems={setPaySelectedItems}
                    setPartialType={setPartialType}
                    splitBillAmount={splitBillAmount}
                    setSplitBillAmount={setSplitBillAmount}
                    submitCheckout={submitCheckout}
                    campaignDiscount={campaignDiscount}
                    discount={storeDiscount > 0 ? storeDiscount : discount}
                    deliveryCharges={deliveryCharges}
                  />
                )}
                {appState.divideBill && (
                  <DivideBill
                    subTotal={
                      formData.order_invoice == "close-bill" ? total : total
                    }
                    fullAmount={fullAmount}
                    orders={
                      appState.payTableMode
                        ? appState.payTableOrders
                        : formData.order_invoice === "open_bill"
                        ? openBillOrders
                        : orders
                    }
                    promoAmount={promoAmount}
                    setPartialType={setPartialType}
                    splitBillAmount={splitBillAmount}
                    setSplitBillAmount={setSplitBillAmount}
                    totalPerson={totalPerson}
                    setTotalPerson={setTotalPerson}
                    personWillPay={personWillPay}
                    setPersonWillPay={setPersonWillPay}
                    submitCheckout={submitCheckout}
                    campaignDiscount={campaignDiscount}
                    discount={storeDiscount > 0 ? storeDiscount : discount}
                    tip={
                      manualTip > 0
                        ? manualTip
                        : customeTip > 0
                        ? customeTip
                        : 0
                    }
                    deliveryCharges={deliveryCharges}
                  />
                )}
                {appState.userAddressPopup && <UserAddressPopup />}
                {appState.deliveryDateTimePopup && (
                  <DeliveryDateTimePopup
                    availableTime={availableTime}
                    setAvailableTime={setAvailableTime}
                    setExpanded={setExpanded}
                  />
                )}
                {appState.payCustomAmount && (
                  <PayCustomAmount
                    subTotal={total}
                    promoAmount={promoAmount}
                    setPartialType={setPartialType}
                    splitBillAmount={splitBillAmount}
                    setSplitBillAmount={setSplitBillAmount}
                    submitCheckout={submitCheckout}
                    campaignDiscount={campaignDiscount}
                    discount={storeDiscount > 0 ? storeDiscount : discount}
                    tip={
                      manualTip > 0
                        ? manualTip
                        : customeTip > 0
                        ? customeTip
                        : 0
                    }
                    deliveryCharges={deliveryCharges}
                  />
                )}
                {customeShow && (
                  <AddTipCustom
                    subTotal={total}
                    setCustomeTip={setCustomeTip}
                    customeTip={customeTip}
                    setCustomeShow={setCustomeShow}
                    handleChangeTips={handleChangeTips}
                  />
                )}

                {/* { 4(campaignDiscount , openBillTotal)} */}
                {appState.storeInfo.buffetMode && total == 0 ? (
                  <section
                    id="reviewOrderButton"
                    className="col-xl-6 offset-xl-3"
                  >
                    <div id="process-btn" className="reviewOrdButton   m-0">
                      <Button
                        className="col-12 text-capitalize btn-main align-item-center "
                        // onClick={newOrder}
                        onClick={submitCheckout}
                        disabled={apiLoading}
                      >
                        {/* {t("checkOut.Confirm-and-pay")} */}
                        {t("checkOut.close-order")}
                      </Button>
                    </div>
                  </section>
                ) : (
                  payments.includes("partial") &&
                  total != 0 && (
                    <>
                      {" "}
                      {campaignDiscount < total ? (
                        <section
                          id="reviewOrderButtonCheckout"
                          className=" offset-xl-3 mr-auto col-xl-6 col-md-12 "
                        >
                          {payments.includes("partial") && !customeShow ? (
                            <React.Fragment>
                              <div
                                id="process-btn"
                                className="reviewOrdButton  col-md-12 m-0"
                              >
                                <Button
                                  className="col-12 px-4 py-3 text-capitalize d-flex  justify-content-between    btn-main align-item-center "
                                  // onClick={newOrder}
                                  onClick={submitCheckout}
                                >
                                  <span>
                                    {t("checkOut.pay-all")} &nbsp; (
                                    {openBillTotal.toFixed(3)}
                                    {get_lang(
                                      storeCurrency,
                                      appState.langValue
                                    )}{" "}
                                    )
                                  </span>
                                  <div className="iconsParent d-flex justify-content-center">
                                    {appState.acceptedPayments.map(
                                      (item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {item === "knet" ? (
                                              <span className=" iconsBg ">
                                                <img
                                                  className="img-pr"
                                                  // width="25"
                                                  src={`/images/pat-knet.svg`}
                                                  alt=""
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {item === "card" ? (
                                              <span className="iconsBg">
                                                <img
                                                  className="img-pr"
                                                  // width="25"
                                                  src={`/images/visa-small.svg`}
                                                  alt=""
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {item == "apple_pay" ? (
                                              <span
                                                key={index}
                                                className="iconsBg"
                                              >
                                                <img
                                                  className="img-pr"
                                                  width="50"
                                                  src={`/images/pat-apple-n.svg`}
                                                  alt=""
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </Button>
                              </div>
                              {!checkoutByCash && (
                                <div
                                  id="process-btn"
                                  className="reviewOrdButton   col-md-12 m-0"
                                >
                                  <Button
                                    className="col-12 entext-capitalize splitBtn align-item-center "
                                    onClick={handleClickShowOption}
                                  >
                                    {t("checkOut.Split-Bill-2")}
                                  </Button>
                                </div>
                              )}

                              {/* {true && (
                            // {appState.payTableMode && (
                            <div
                              id="process-btn"
                              className="reviewOrdButton  mr-auto col-md-12 m-0"
                            >
                              <div id="cartItemsNoOrder">
                                {!retryAgain ? (
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      onClick={handleRetry}
                                      className="retryButton"
                                    >
                                      {" "}
                                      <ReplayIcon className="mx-2" />
                                      {t("checkOut.Retry")}
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <RestoreIcon className="mx-2" />
                                    <p className="retryText">
                                      {" "}
                                      {t("checkOut.Retry-in")} 00:{timer}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )} */}
                            </React.Fragment>
                          ) : (
                            <div
                              id="process-btn"
                              className="reviewOrdButton  mr-auto  col-md-12 m-0"
                            >
                              <Button
                                className="col-12 text-capitalize btn-main align-item-center "
                                // onClick={newOrder}
                                onClick={submitCheckout}
                              >
                                {/* {t("checkOut.Confirm-and-pay")} */}
                                {t("navBar.Checkout")}
                              </Button>
                            </div>
                          )}
                        </section>
                      ) : (
                        <section
                          id="reviewOrderButton"
                          className="col-xl-6 offset-xl-3"
                        >
                          <div
                            id="process-btn"
                            className="reviewOrdButton   m-0"
                          >
                            <Button
                              className="col-12 text-capitalize btn-main align-item-center "
                              // onClick={newOrder}
                              onClick={campaignCheckout}
                              disabled={apiLoading}
                            >
                              {/* {t("checkOut.Confirm-and-pay")} */}
                              {t("navBar.Checkout")}
                            </Button>
                          </div>
                        </section>
                      )}
                    </>
                  )
                )}
              </div>
            </>
          ) : (
            <CheckoutNoOrder />
          )}
        </Page>
      </React.Fragment>
    ) :
    showDeliveryCheckout == 2 ?    
     ( <DeliveryIsNotAvailable />)
     :   (<div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-2 px-2">
         <Skeleton
           variant="rect"
           height={100}
           style={{ borderRadius: "5px" }}
         />
       </div>
       <div className=" col-md-8 px-2">
         <Skeleton
           height={50}
           style={{
             transform: "translate(2px, 0)",
             marginBottom: "10px",
           }}
         />
         <Skeleton height={25} />
         <Skeleton height={25} />
       </div>
       <div className=" col-md-2 px-2">
         <Skeleton
           variant="rect"
           height={50}
           style={{ borderRadius: "50px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-12 px-2">
         <Skeleton
           variant="rect"
           height={50}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-12 px-2">
         <Skeleton
           variant="rect"
           height={100}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-6 px-2">
         <Skeleton
           variant="rect"
           height={70}
           style={{ borderRadius: "5px" }}
         />
       </div>
       <div className=" col-md-6 px-2">
         <Skeleton
           variant="rect"
           height={70}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-12 px-2">
         <Skeleton
           variant="rect"
           height={200}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-12 px-2">
         <Skeleton
           variant="rect"
           height={80}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
     <div className="row m-0 mb-4 mt-3">
       <div className=" col-md-12 px-2">
         <Skeleton
           variant="rect"
           height={80}
           style={{ borderRadius: "5px" }}
         />
       </div>
     </div>
   </div>)
 
  );
}

export default withTranslation()(DeliveryCheckout);