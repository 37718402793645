/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "./GoogleLocation.scss";
// Components

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// redux
import { useSelector, useDispatch } from "react-redux";
import { Button, Skeleton, TextField } from "@mui/material";
import Page from "../Page";
import { get_lang } from "../../../../global-functions/apiGlobal";

import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GoogleMap from "../googleMap/googleMap";
import GoogleMApp from "../googleMap/googleMap";
import DeliveryGoogleMapPopup from "../deliveryAddsPopup/deliveryGoogleMapPopup";
import GoogleMapLocations from "../googleMap/googleMapLocation";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
// TabPanel

function GoogleLocation() {
  // @States & Variables
  const { t } = useTranslation();

  const history = useHistory();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(
    history.location.state ? history.location.state : 1
  );
  let services = appState.serviceMode;

  const [toggleIconConditionRender, setToggleIconConditionRender] =
    useState(true);

  const [loading, setLoading] = useState(true);
  const [serviceName, setServiceName] = useState([]);
  const [formType, setFormType] = useState(
    appState.deliveryAddsInfo?.formType || 1
  );
  const btnTabs = [
    { type: 1, name: `Home`, icon: <HomeIcon /> },
    { type: 2, name: `appartment`, icon: <ApartmentIcon /> },
    { type: 3, name: `Office`, icon: <BusinessCenterIcon /> },
  ];
  const addressFields = [
    {
      show: true,
      name: `block`,
      value: appState.deliveryAddsInfo?.block || ``,
      type: `text`,
      label: `Block`,
      required: true,
    },
    {
      show: true,
      name: `street`,
      value: appState.deliveryAddsInfo?.street || ``,
      type: `text`,
      label: `Street`,
      required: true,
    },
    {
      show: true,
      name: `floor`,
      value: appState.deliveryAddsInfo?.floor || ``,
      type: `text`,
      label: `Floor`,
      required: true,
    },
    {
      show: formType != 1,
      name: `appartment`,
      value: appState.deliveryAddsInfo?.appartment || ``,
      type: `text`,
      label: `appartment`,
      required: true,
    },
    {
      show: true,
      name: `house_no`,
      value: appState.deliveryAddsInfo?.house_no || ``,
      type: `text`,
      label: `House No`,
      required: true,
    },
    {
      show: true,
      name: `avenue`,
      value: appState.deliveryAddsInfo?.avenue || ``,
      type: `text`,
      label: `Avenue`,
      required: false,
    },
    {
      show: true,
      name: `rider_note`,
      value: appState.deliveryAddsInfo?.rider_note || ``,
      type: `text`,
      label: `Rider Note`,
      required: false,
    },
  ];

  const handleChangeFormType = (type) => {
    appDispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: { ...appState.deliveryAddsInfo, formType: type },
    });
    setFormType(type);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    appDispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: { ...appState.deliveryAddsInfo, [name]: value },
    });
  };
  useEffect(() => {
    console.log(appState.deliveryAddsInfo?.formType);
  }, [appState.deliveryAddsInfo?.formType]);

  useEffect(() => {
    const handleServicesName = () => {
      let arr = [];
      let flag = true;

      if (!appState.hotelQrCode) {
        for (const ele of services) {
          if (ele === "dine-in") {
            arr.push("Dine In");
          }
          if (ele === "pick-up") {
            arr.push("Pick Up");
          }
          if (
            (ele === "open-bill" || ele === "group-bill") &&
            !appState.storeInfo.buffetMode
          ) {
            arr.push("Open Bill");
          }
        }
      }

      if (
        appState.hotelQrCode ||
        !arr.includes("Open Bill") ||
        appState.storeInfo.buffetMode
      ) {
        arr.push("Close Bill");
      }
      if (appState.storeInfo.buffetMode) {
        arr.push("Buffet");
      }
      if (appState.deliveryMode) {
        arr.push("Delivery");
      }

      setServiceName(arr);
      setLoading(false);
    };
    handleServicesName();
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      console.log("scrolling off");
    } else {
      document.body.style.overflow = "visible";
      console.log("scrolling start");
    }
  }, [open]);

  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  return (
    <Page title="Map">
      <div id="wrapper">
        <div id="address-form">
          <section id="topNav">
            <div className="row m-0 checkOut_Nav justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center">
                <Link to={`/checkout`}>
                  <IconButton
                    aria-label="back"
                    className={
                      appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                    }
                    // orderbckBtn"
                  >
                    {appState.langValue === "ar" ? (
                      <ArrowForwardIosIcon
                        style={{ padding: "0px !important" }}
                        fontSize="inherit"
                      />
                    ) : (
                      <ArrowBackIosIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Link>
                <div className="pl-1 mt-2">
                  <h4>
                    <strong>{t("Google Location")}</strong>
                  </h4>
                </div>
              </div>

              {appState.langValue == "ar" ? (
                <IconButton id="headerBtn" onClick={(e) => enLanguageHandle(e)}>
                  <span className={`lang-textScroll p-0`}>EN</span>
                </IconButton>
              ) : (
                <IconButton
                  id="headerBtn"
                  onClick={(e) => arLanguageHandle(e)}
                  className="arabicBtn"
                >
                  <span className={`arabicText lang-textScroll p-0 mb-1`}>
                    عربي{" "}
                  </span>
                </IconButton>
              )}
            </div>
          </section>

          <div className={`px-3`}>
            <GoogleMapLocations />
          </div>
          <div className="row">
            <section
              id="btnSection"
              className={` mr-auto col-12 col-xl-6 offset-xl-3 bottomFixedBtn py-2`}>
              <div className="row">
                <div className="col-12 ">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="btn-main w-100 text-capitalize"
                    component={Link}
                    to="/address-form"
                  >
                    {t("done")}
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(GoogleLocation);
