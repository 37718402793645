import React, { useState, useRef, useEffect } from 'react';
import './googleMapLocation.scss'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { IconButton } from '@material-ui/core';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';

// const mapStyles = {        
//   height: "70vh",
//   width: "100%",
//   position: 'relative',
//   borderRadius: '10px', // Set border-radius here
//   overflow: 'hidden'
  
// };

const libraries = ['places'];

const GoogleMapLocations = () => {
  const [mapStyles, setMapStyles] = useState({
    height: "70vh",
    width: "100%",
    position: 'relative',
    borderRadius: '10px',
    overflow: 'hidden'
  });
  const {t} = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const defaultCenter = {
    lat: Number(appState.deliveryAddsInfo?.lat) || 29.3759,
    lng: Number(appState.deliveryAddsInfo?.lng) || 47.9774, // Coordinates for Kuwait City, Kuwait
  };
  const [selectedPlace, setSelectedPlace] = useState(defaultCenter);
  const [searchValue, setSearchValue] = useState(appState.deliveryAddsInfo?.fullAddress || '');
  const [addressComponents, setAddressComponents] = useState({
    street: appState.deliveryAddsInfo?.street || '',
    block: appState.deliveryAddsInfo?.block || '',
    area: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  });

  const mapRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const geocoder = useRef(null);

  const onLoad = (ref) => {
    mapRef.current = ref;
    geocoder.current = new window.google.maps.Geocoder();
  };

  const onPlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      const lat = location?.lat();
      const lng = location?.lng();
      
      setSelectedPlace({ lat, lng });
      setSearchValue(place.formatted_address);
      extractAddressComponents(place.address_components, place.formatted_address, lat, lng);
      mapRef.current.panTo(location);
      mapRef.current.setZoom(14);
    } else {
      console.error('Place contains no geometry');
    }
  };

  const onMapIdle = () => {
    const center = mapRef.current.getCenter();
    const location = {
      lat: center?.lat(),
      lng: center?.lng(),
    };
    setSelectedPlace(location);
    getGeocode(location);
  };

  const getGeocode = (location) => {
    geocoder.current.geocode({ location }, (results, status) => {
      if (status === "OK" && results[0]) {
        const fullAddress = results[0].formatted_address;
        const lat = location.lat;
        const lng = location.lng;

        // setSearchValue(fullAddress);
        appDispatch({ 
          type: "setDeliveryAddsInfo", 
          deliveryAddsInfo: {
            ...appState.deliveryAddsInfo, 
            fullAddress: fullAddress,
            lat: lat,
            lng: lng
          }
        });
        extractAddressComponents(results[0].address_components, fullAddress, lat, lng);
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const extractAddressComponents = (components, fullAddress, lat, lng) => {
    const address = {
      street: '',
      block: '',
      area: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    };

    components.forEach(component => {
      const types = component.types;
      if (types.includes('street_number')) {
        address.street = `${component.long_name} ${address.street}`;
      }
      if (types.includes('route')) {
        address.street += component.long_name;
      }
      if (types.includes('sublocality_level_1') || types.includes('political')) {
        address.block = component.long_name;
      }
      if (types.includes('locality')) {
        address.area = component.long_name;
      }
      if (types.includes('administrative_area_level_2')) {
        address.city = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        address.state = component.long_name;
      }
      if (types.includes('country')) {
        address.country = component.long_name;
      }
      if (types.includes('postal_code')) {
        address.postalCode = component.long_name;
      }
    });

    setAddressComponents(address);
    appDispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: {
        ...appState.deliveryAddsInfo,
        street: address.street,
        block: address.block,
        fullAddress: fullAddress,
        lat: lat,
        lng: lng
      }
    });
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setSelectedPlace(location);
          mapRef.current.panTo(location);
          mapRef.current.setZoom(14);
        },
        (error) => {
          console.error("Error getting the current location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setMapStyles({
        height: window.innerWidth <= 768 ? "55vh" : "70vh",
        width: "100%",
        position: 'relative',
        borderRadius:  '10px',
        overflow: 'hidden'
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the styles based on the current window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <span id='google-map-locations'>
    <LoadScript
      googleMapsApiKey="AIzaSyABahfPxb8GWRHM731v5QfZesxyEypXuGc"
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        // className={`map-styles`}
        zoom={13}
        center={selectedPlace}
        onLoad={onLoad}
        onIdle={onMapIdle}
      >
        <Autocomplete
          onLoad={(ref) => (autoCompleteRef.current = ref)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Search for places..."
            value={searchValue}
            onChange={handleInputChange}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `50px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              top: "10px",
              transform: "translateX(-50%)",
              zIndex: "10"
            }}
          />
        </Autocomplete>

        {/* Sticky Marker */}
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '10',
          pointerEvents: 'none'
        }}>
          <LocationOnIcon style={{ fontSize: '70px', color: appState.storeInfo?.primaryColor }} />
        </div>

      </GoogleMap>

      <div className='w-100' style={{ marginTop: '10px', textAlign: 'center' }}>
        <div style={{color: appState.storeInfo?.primaryColor}} >
            <strong> {t(`Get your current Location`)} </strong> 
            <IconButton aria-label="get-current-location" onClick={handleGetCurrentLocation}>
              <MyLocationIcon />
            </IconButton>
        </div>
          
        <strong style={{color: appState.storeInfo?.primaryColor}}>{t(`Selected Address`)}: </strong> 
        <strong>{appState.deliveryAddsInfo?.fullAddress}</strong> 
        {/* <br />Lat: {appState.deliveryAddsInfo?.lat}, Lng: {appState.deliveryAddsInfo?.lng}
        <br /> block: {appState.deliveryAddsInfo?.block}
        <br /> street: {appState.deliveryAddsInfo?.street} */}
      </div>
    </LoadScript>
    </span>
  );
}

export default GoogleMapLocations;
