/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable */
import React, {  useState, useEffect, useMemo } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "./AddressForm.scss";
// Components

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// redux
import { useSelector, useDispatch } from "react-redux";
import { Button, Skeleton, TextField } from "@mui/material";
import Page from "../Page";
import { get_lang } from "../../../../global-functions/apiGlobal";

import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GoogleMap from "../googleMap/googleMap";
import GoogleMApp from "../googleMap/googleMap";
import DeliveryGoogleMapPopup from "../deliveryAddsPopup/deliveryGoogleMapPopup";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../redux-store/store";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { freezOff, freezOn, handleError } from "../../../../redux-store/action";
import axios from 'axios'
import { Backdrop, TextareaAutosize } from "@material-ui/core";
import CircularIndeterminate from "../CircularIndeterminate";
// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
// TabPanel

function AddressForm() {
  // @States & Variables
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const {setDeliveryAddsInfo, setLangValue} = bindActionCreators(actionCreators, appDispatch);
  const history = useHistory();
  const appState = useSelector((state) => state);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlType = searchParams.get('addtype')
  const [value, setValue] = React.useState(
    history.location.state ? history.location.state : 1
  );
  let services = appState.serviceMode;
  const getFieldValue = (field) => {
    if (Number(urlType) === 1) return appState.deliveryAddsInfo?.[field] || '';
    if (Number(urlType) === 2) return '';
    return appState.deliveryAddsInfo?.[field] || '';
};
 
const [formFields, setFormFields] = useState({
     block: getFieldValue('block') ,
     street: getFieldValue('street') ,
     floor: getFieldValue('floor') ,
     appartment: getFieldValue('appartment') ,
     house_no: getFieldValue('house_no') ,
     avenue: getFieldValue('avenue') ,
     rider_note: getFieldValue('rider_note') ,
     fullAddress: getFieldValue('fullAddress') ,
     lat: appState.deliveryAddsInfo?.lat || 29.3759,
    lng: appState.deliveryAddsInfo?.lng || 47.977}
);


  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const [formType, setFormType] = useState( appState.deliveryAddsInfo?.formType && urlType != 2 ? appState.deliveryAddsInfo?.formType : 1 );
  const btnTabs = [
    { type: 1, name: `Home`, icon: <HomeIcon /> },
    { type: 2, name: `Apartments`, icon: <ApartmentIcon /> },
    { type: 3, name: `Office`, icon: <BusinessCenterIcon /> },
  ];
  const addressFields = [
    {
      show: true,
      name: `block`,
      value:  formFields?.block ,
      type: `text`,
      label: `Block`,
      required: true,
      col: `col-md-6 col-sm-12`
    },
    {
      show: true,
      name: `street`,
      value:  formFields?.street ,
      type: `text`,
      label: `Street`,
      required: true,
      col: `col-md-6 col-sm-12`
    },
    {
      show: true ,
      name: `house_no`,
      value:  formFields?.house_no ,
      type: `text`,
      label: (formType == 2  || formType == 3) ?   `Building Name/No` : `House No`, 
      required: true,
      col: `col-md-6 col-sm-12`
    },
    {
      show: formType != 1,
      name: `floor`,
      value:  formFields?.floor,
      type: `text`,
      label: `Floor`,
      required: true,
      col: `col-md-6 col-sm-12`
    },
    {
      show: formType != 1,
      name: `appartment`,
      value:  formFields?.appartment ,
      type: `text`,
      label: formType == 3 ? `Office No` : `Apartment`,
      required: formType == 3 ? false : true,
      col: `col-md-6 col-sm-12`
    },
    {
      show: true,
      name: `avenue`,
      value:  formFields?.avenue ,
      type: `text`,
      label: `Avenue`,
      required: false,
      col: `col-md-6 col-sm-12`
    },
    {
      show: false,
      name: `rider_note`,
      value:  formFields?.rider_note ,
      type: `textArea`,
      label: `Rider Note`,
      required: false,
      col: `col-12`,
    },
  ];

  const handleChangeFormType = (type) => {
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, formType: type });
    setFormType(type);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormFields({ ...formFields, [name]: value });
    // setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

  };
  useEffect(() => {
    let full_address = `${
      formFields?.block
        ? `${formFields?.block},`
        : ""
    } ${
      formFields?.street
        ? `${formFields?.street},`
        : ""
    }  ${
      formFields?.floor
        ? `${formFields?.floor},`
        : ""
    } ${
      formFields?.appartment &&
      formFields?.formType != 1
        ? `${formFields?.appartment},`
        : ""
    } ${
      formFields?.house_no
        ? `${formFields?.house_no},`
        : ""
    } ${
      formFields?.avenue
        ? `${formFields?.avenue}`
        : ""
    }`;
    // setDeliveryAddsInfo({
    //   ...formFields,
    //   fullAddress: full_address,
    // });
    setFormFields({
      ...formFields, fullAddress: full_address})
  }, [
    formFields?.formType,
    formFields?.block,
    formFields?.street,
    formFields?.floor,
    formFields?.avenue,
    formFields?.appartment,
    formFields?.house_no,
  ]);


  const handleNext = () => {
    const newErrors = {};
    
    // Validate each required field
    addressFields.forEach((field) => {
      if (field.required && !formFields[field.name] && field.show) {
        newErrors[field.name] = `${t(`${field.label}`)} is required`;
      }
    });
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    else{
        history.push('/checkout')
        
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, ...formFields });

    }
  
    // Proceed to next step if validation passes
    // ...
  };

  const handleAddAddress =async () => {
    const newErrors = {};
    
    // Validate each required field
    addressFields.forEach((field) => {
      if (field.required && !formFields[field.name] && field.show) {
        newErrors[field.name] = `${t(`${field.label}`)} is required`;
      }
    });
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    else{
  
      if(appState?.guestLoggedIn && !appState?.loggedIn){
        history.push('/checkout')
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, ...formFields });

      }else{
        appDispatch(freezOn());
        setLoading(true)
      try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/delivery/address/add`,
        {
          name:  formFields.fullAddress != null  ? formFields.fullAddress : '',
          customer_id: appState.userInfo?.customerId ? appState.userInfo.customerId : null,
          type: appState.deliveryAddsInfo?.formType ? appState.deliveryAddsInfo.formType : formType,
          block: formFields?.block ? formFields.block : null,
          street: formFields?.street ? formFields.street : null,
          house_no: formFields?.house_no ? formFields.house_no : null,
          avenue: formFields?.avenue ? formFields.avenue : null,
          appartment: formFields?.appartment ? formFields.appartment : null,
          floor: formFields?.floor ? formFields.floor : null,
          lat: appState.deliveryAddsInfo?.lat ? `${appState.deliveryAddsInfo.lat}` : null,
          lng: appState.deliveryAddsInfo?.lng ? `${appState.deliveryAddsInfo.lng}` : null,
          area_id: appState.deliveryAddsInfo?.area_id ? appState.deliveryAddsInfo.area_id : null,
          city_id: appState.deliveryAddsInfo?.city_id ? appState.deliveryAddsInfo.city_id : null,
        },{
          headers: {
            ContentType: 'application/json',
            Authorization: `Bearer ${appState.userInfo.userToken}`
          }
        }
      );
      if (response.data.success === true ) {
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });

        history.push('/checkout')
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, ...formFields });


      } else
      // if (response.data.success === false) 
      {
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: response.data.message,
        });
      }
    } catch (e) {
      if (e == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "warning",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(e));
      }
      setLoading(false);
      appDispatch(freezOff());
    }  
      }
  
  }
      
    

  }

  const handleUpdateAddress =async () => {
    const newErrors = {};
    
    // Validate each required field
    addressFields.forEach((field) => {
      if (field.required && !formFields[field.name] && field.show) {
        newErrors[field.name] = `${t(`${field.label}`)} is required`;
      }
    });
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    else{
      if(appState?.guestLoggedIn && !appState?.loggedIn){
        history.push('/checkout')
    setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, ...formFields });

      }else{

        appDispatch(freezOn());
        setLoading(true)

      try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/delivery/address/update/${appState.deliveryAddsInfo?.saveAddId}`,
        {
          // name:  appState.loginValues?.fullName ? appState.loginValues.fullName : null,
          // name:  appState.deliveryAddsInfo?.fullAddress ?? '',
          name:  formFields.fullAddress != null  ? formFields.fullAddress : '',

          customer_id: appState.userInfo?.customerId ? appState.userInfo.customerId : null,
          type: appState.deliveryAddsInfo?.formType ? appState.deliveryAddsInfo.formType : null,
          block: formFields?.block ? formFields.block : null,
          street: formFields?.street ? formFields.street : null,
          house_no: formFields?.house_no ? formFields.house_no : null,
          avenue: formFields?.avenue ? formFields.avenue : null,
          appartment: formFields?.appartment ? formFields.appartment : null,
          floor: formFields?.floor ? formFields.floor : null,
          lat: appState.deliveryAddsInfo?.lat ? `${appState.deliveryAddsInfo.lat}` : null,
          lng: appState.deliveryAddsInfo?.lng ? `${appState.deliveryAddsInfo.lng}` : null,
          area_id: appState.deliveryAddsInfo?.area_id ? appState.deliveryAddsInfo.area_id : null,
          city_id: appState.deliveryAddsInfo?.city_id ? appState.deliveryAddsInfo.city_id : null,
        },{
          headers: {
            ContentType: 'application/json',
            Authorization: `Bearer ${appState.userInfo.userToken}`
          }
        }
      );
      if (response.data.success === true ) {
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });

        history.push('/checkout')
        setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, ...formFields });

      } else
      // if (response.data.success === false) 
      {
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: response.data.message,
        });
      }
    } catch (e) {
      if (e == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "warning",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(e));
      }
      setLoading(false);
      appDispatch(freezOff());
    }  
      }
  
    }
      
    

  }

  return (
    <Page title="Address">
      <div id="wrapper">
        <div id="address-form">
          <section id="topNav">
            <div className="row m-0 checkOut_Nav justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center">
                <Link to={`/checkout`}>
                  <IconButton
                    aria-label="back"
                    className={
                      appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                    }
                    // orderbckBtn"
                  >
                    {appState.langValue === "ar" ? (
                      <ArrowForwardIosIcon
                        style={{ padding: "0px !important" }}
                        fontSize="inherit"
                      />
                    ) : (
                      <ArrowBackIosIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Link>
                <div className="pl-1 mt-2">
                  <h4>
                    <strong>{t(`Address`)}</strong>
                  </h4>
                </div>
              </div>

              {appState.langValue == "ar" ? (
                <IconButton id="headerBtn" onClick={(e) => setLangValue("en")}>
                  <span className={`lang-textScroll p-0`}>EN</span>
                </IconButton>
              ) : (
                <IconButton
                  id="headerBtn"
                  onClick={(e) => setLangValue("ar")}
                  className="arabicBtn"
                >
                  <span className={`arabicText lang-textScroll p-0 mb-1`}>
                    عربي{" "}
                  </span>
                </IconButton>
              )}
            </div>
          </section>
          <section id="addressSection">
            <div className={`inner-contact_social_icons  `}>
              <p
                className={`d-flex align-items-center justify-content-between  familyManrope600 m-0 px-4 mb-2 ${
                  appState.langValue === "ar" ? "text-right" : "text-left"
                }`}
              >
                {/* <span>{t("Address ")} : {appState.deliveryAddsInfo?.fullAddress}</span> <Button variant="contained" className={`active-btn p-2`} onClick={()=> history.push('/map')}>Edit</Button> */}
                <span className="d-flex align-items-center address-content">
                  {" "}
                  <span style={{ color: appState.storeInfo?.primaryColor }}>
                    <LocationOnIcon />
                  </span>
                  {t("Address")} :   {formFields.fullAddress}
                </span>
              </p>
                <div className={`px-3`}>
                <GoogleMApp />
                </div>
              <hr />
              <div className="row m-0 text-center">
                <div className="col p-0 d-flex align-items-centre justify-content-evenly">
                  {btnTabs.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        variant="contained"
                        className={`${
                          formType === item.type
                            ? "active-btn"
                            : "non-active-btn"
                        } p-2 mb-2 btn-padding`}
                        startIcon={item.icon}
                        onClick={() => handleChangeFormType(item.type)}
                      >
                        <span
                          className={`${
                            appState?.langValue == "ar" ? "px-2" : ""
                          }`}
                        >
                          {" "}
                          {t(`${item.name}`)}{" "}
                        </span>
                      </Button>
                    );
                  })}
                </div>
              </div>
              <hr />
            </div>
          </section>

          <section
            id=""
            className={`row m-0  ${
              appState.langValue == "ar" ? "arabic-class" : ""
            }`}
          >
            {/* <div className="col-12 p-0">
            {t(`Address`)}: {appState.deliveryAddsInfo?.fullAddress}
            </div> */}

            <div className="col-12 p-0">
              <div className="row m-2 m-0 ">
                {addressFields.map((item, index) => {
                  return (
                    item?.show && (
                      <div className={`${item.col} p-2`}>
                        <TextField
                          onChange={handleChangeText}
                          name={item.name}
                          // value={getFieldValue(item.name)}
                          value={item.value}
                          id="outlined-basic"
                          label={`${t(`${item.label}`)} ${
                            item.required ? `*` : ``
                          }`}
                          variant="outlined"
                          error={!!errors[item.name]} // Highlight field if there's an error
                          helperText={errors[item.name]} // Show error message
                          
                          inputProps={{
                            autoComplete: "nope" // Disable autofill
                          }}
                        />
                      </div>
                    )
                  );
                })}
                <div className="col-12 p-2 rider-note-field">
                  <TextareaAutosize
                    className="textArea"
                    onChange={handleChangeText}
                    name={`rider_note`}
                    value={
                      appState.deliveryAddsInfo?.rider_note
                        ? appState.deliveryAddsInfo?.rider_note
                        : ""
                    }
                    id="outlined-basic"
                    label={t(`Rider Note`)}
                    variant="outlined"
                    aria-label="minimum height"
                    width="100%"
                    minRows={5}
                    placeholder={t("Add note for Rider.")}
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <section
              id="btnSection"
              className={`${
                appState.langValue === "ar" ? "ml-auto" : "mr-auto"
              } col-12 col-xl-6 offset-xl-3 bottomFixedBtn py-2`}
            >
              <div className="row">
                <div className="col-12 ">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="btn-main w-100 text-capitalize"
                    onClick={appState.loggedIn  && urlType == 3 ? handleUpdateAddress : appState.loggedIn  ? handleAddAddress : handleNext}
                    // component={Link}
                    // to="/checkout"
                  >
                    {t("Done")}
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(AddressForm);
