import React, { useContext, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "./loyalityDiscountAmount.scss";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import noImg from "../../assets/images/no-image.svg";
import Chip from "@mui/material/Chip";
// redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";
// for Localization
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const LoyalityDiscountAmount = ({ type, desc, name, amount, callingFrom, percent, objType }) => {
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const history = useHistory();

  const handleScroll = () => {
    // appDispatch({
    //   type: "setScrollKeyword",
    //   scrollKeyword: Number(ind),
    // });
  };
  const handleRedirect = () => {

    // history.push(`/${appState.storeInfo.slug}${appState.storeInfo.progressive
    //   ? `/${item.category.slug}/${item.slug
    //   }?parent=${item.category.id}&prodid=${item.id}`
    //   : `/${item.category.slug}/${item.slug
    //   }?parent=${item.category.id}&prodid=${item.id}`
    //   }`);
    // appDispatch({
    //   type: "setLoyalityPopupMode",
    //   loyalityPopupMode: false
    // })

  }
  return (
    <>
      <div
        // onClick={handleScroll}
        // id={ind}
        className="col-12 py-2 px-1 inner_prod prod_main_div"
      >
        <div onClick={handleRedirect} className="d-flex prod_content_row m-0">
          <div

            className={`d-flex justify-content-center align-items-center prod_categ_img px-0 ${callingFrom === "search"
              ? "col-3 col-md-2 prod_categ_img_search_inhance"
              : ""
              } ${!appState.storeInfo?.categoryProductImage ? "hideProductImage" : ""}`}
            style={{
              // background: `url(/images/presentLoyal.svg)no-repeat center center / cover`,
              // width: "",
              backgroundColor: "#FFF6F7"
              // center top/100% 100% no-repeat
            }}
          >
            <img
              src={`/images/presentLoyal.svg`}
              alt="presentLoyal"
            // className="img-fluid "
            />
          </div>
          <div className={`${!appState.storeInfo?.categoryProductImage ? "col-12" : "col-8 col-md-8"}  prod_content ${callingFrom === "search"
            ? "col-9 col-md-10"
            : ""
            }`} >
            <div>
              {console.log("name", name)}


              {amount > 0 && <h4 className={`familyPoppins300 ${appState.langValue === "ar"
                ? "text-right"
                : "text-left"}`}>
                <strong className="  text-capitalize">
                  {amount}   &nbsp;
                  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)} &nbsp;
                  {`${name != null ? name
                    : "Not Available"
                    }`}!

                </strong>
              </h4>}
              {percent > 0 && <h4 className={`familyPoppins300 ${appState.langValue === "ar"
                ? "text-right"
                : "text-left"}`}>
                <strong className="  text-capitalize">
                  {percent}   &nbsp;
                  %
                  {`${name != null ? name
                    : "Not Available"
                    }`}!

                </strong>
              </h4>}
              {(desc != null) && (get_lang(desc, appState.langValue) != null)
                ? get_lang(desc, appState.langValue) != "N/A"
                  ? get_lang(desc, appState.langValue)
                    .split("<br>")
                    .map(
                      (item, i) =>
                        i == 0 && (
                          <p
                            style={{ overflow: "hidden" }}
                            key={i}
                            className={`familyPoppins m-0 d-flex ${appState.langValue === "ar"
                              ? "text-right"
                              : "text-left"
                              }`}
                          >
                            {item != "" && `${item.slice(0, 70)}...`}
                          </p>
                        )
                    )
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default withTranslation()(LoyalityDiscountAmount);
