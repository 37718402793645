/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./groupOrderPopup.scss";
// material ui
import Button from "@mui/material/Button";
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import QRCodeStyling from "qr-code-styling";
import { RWebShare } from "react-web-share";
import ShareNetwork from "../../assets/images/ShareNetwork.svg"
import { useHistory } from "react-router-dom";
import { createGroupBill } from "../../../../redux-store/action";
import CopyText from "../copyText/copyText";


const qrCode = new QRCodeStyling({
  width: 297,
  height: 297,

  dotsOptions: {
    color: "#000",
    type: "dot"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});
function GroupOrderPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(process.env.REACT_APP_SHOP_URL);
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  const handleClickOpenQrCode = () => {
    setOpen(true);
  };
  useEffect(() => {
    qrCode.append(ref.current);
  }, [handleClickOpenQrCode]);

  useEffect(() => {
    qrCode.update({
      data: url
    });
    console.log(url);
  }, [url]);

  useEffect(() => {
    const handleGroupId = () => {
      let store_slug = appState.storeInfo.slug
      let link = `${process.env.REACT_APP_SHOP_URL}/${store_slug}?gb_id=${appState.groupId}`
      setUrl(link);
    }
    handleGroupId();
  }, []);
  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`groupOrderPopupMain col-xl-5  ${appState.langValue === "ar" ? "groupOrderPopupMainArabic" : " "
          }`}
      >
        <div className="container-fluid content px-1 ">
          <div className="d-flex justify-content-between align-items-center mt-3">
          <h1
              className={` groupOrderHead m-0 text-center ${appState.langValue === "ar"
                ? " familyGraphikArabic800 "
                : " familyPoppins300"
                }`}
            >
              {" "}{t("group-order.Group-Order")}

            </h1>
            <span>
              <IconButton onClick={() => appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: false })} className="classForSvgColor " >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7 5.93934L12.4697 0.46967C12.7626 0.176777 13.2374 0.176777 13.5303 0.46967C13.8232 0.762563 13.8232 1.23744 13.5303 1.53033L8.06066 7L13.5303 12.4697C13.8232 12.7626 13.8232 13.2374 13.5303 13.5303C13.2374 13.8232 12.7626 13.8232 12.4697 13.5303L7 8.06066L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#22282F" />
                </svg>

              </IconButton>
            </span>

        
          </div>

          <hr className="my-1" />

          <div className=" pt-2" >
            <div className="App mt-3">
              <div className="d-flex justify-content-center" ref={ref} />
              <div className=" d-flex  mt-3 inputWrapper" style={styles.inputWrapper}>
              </div>
            </div>

            <div className="conatiner">
              <div className="row d-flex justify-content-center">
                <div className="col-8">
                <h1
              className={` mt-2 text-center ${appState.langValue === "ar"
                ? " familyGraphikArabic800 "
                : " familyPoppins300"
                }`}
            >
              {" "}{t("group-order.Share-this-QR-Code-with-your-table-to-join-in")}
            </h1>        
                </div>
              </div>
            </div>
            <div className="conatiner">
              <div className="row d-flex justify-content-center">
                <div className="col-9">
            <p className={`displayLine mb-0 text-center ${appState.langValue === "ar"
              ? " familyGraphikArabic800 "
              : " familyPoppins300"
              }`}> {" "}{t("group-order.Invite-everyone-to-get-in-and-see-what-being-delivered-to-the-table")}</p>
          
                </div>
              </div>
            </div>
            <div className="App mt-3  ">
              <div className="d-flex justify-content-center" >
               <CopyText textToCopy={url} />
              </div>
            </div>
       

            <div className="shareBtnParent d-flex justify-content-center">
              <RWebShare
                data={{
                  text: "",
                  url: url,
                  title: t("group-order.Invite-everyone-to-get-in-and-see-what-being-delivered-to-the-table"),
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <Button className={`  px-4 shareBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                  }`}

                > <img className="mx-1" src={ShareNetwork} alt="ShareNetwork" /> <span >{t("checkOut.share")}</span>  </Button>
              </RWebShare>
            </div>
          </div>

          <div className="bottomBtn mt-3 ">
            <div className="col-md-12">
              <Button
                className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                  }`}
                  onClick={() => appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: false })}
              >
                {" "}
                <span>{t("group-order.Start-ordering")}</span>{" "}
              </Button>
              <Button
                onClick={() => appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: false })}
                className={` py-3 px-4 mt-1 closeBtn justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                  }`}
              >
                {" "}
                <span>{t("checkOut.close")}</span>{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputBox: {
    width: "100%",
    margin: "0px"

  }
};
export default withTranslation()(GroupOrderPopup);
