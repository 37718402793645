/* eslint-disable */
import React, { useState } from "react";
import "./reviewModalv2.scss";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Redux
import { useSelector, useDispatch } from "react-redux";
// External Components
import Rating from '@mui/material/Rating';
import { TextareaAutosize } from "@material-ui/core";
import { handleCustomerReview } from "../../../../redux-store/action"
import CircularIndeterminate from "../CircularIndeterminate";
import { get_lang } from "../../../../global-functions/apiGlobal";

function ReviewModalv2(props) {
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [value, setValue] = useState(5);
  const [review, setReview] = useState('')
  const [loading, setLoading] = useState(false)
  const starStyle = {
    width: 30,
    height: 30,
    padding: 100
  }
  const handleClose = () => {
    appDispatch({
      type: "setReviewModal",
      reviewModal: false
    });
  }
  const handleLoading = () => {
    setLoading(!loading)
  }
  const handleSubmit = () => {
    appDispatch(handleCustomerReview(appState, value, review));
    setLoading(true)
  }

  const handleReview = (e) => {
    setReview(e.target.value)
  }

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  const [hover, setHover] = React.useState(-1);
  const labels = {
    0: "wao",
    1: 'Terrible',
    2: 'Bad',
    3: 'Ok',
    4: 'Good',
    5: 'Great',
  };




  return (
    <>


      <div id="mySidenav" className={` px-3 reviewModalv2l1   ${appState.langValue == "ar" ? "reviewModalv2l1Arabic" : " "
        }`}>
             {appState.freezScreen && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={appState.freezScreen}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}
        <a href="javascript:void(0)" classname="closebtn" onclick="closeNav()">&times;</a>


        <div
          className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
          id="reviewModalMainl1"

        >
          {/* <IconButton className="ml-auto my-1 d-flex" onClick={handleClose}>
            <CloseIcon />
          </IconButton> */}
          <h1 className="text-center mt-5 px-2"> {t("contact.How-was-your-experience-at")} {get_lang(appState.storeInfo.businessName, appState.langValue)}?</h1>
          <div className="px-1">
            <div className="container-fluid mt-3">

              <div className="row d-flex justify-content-center store-tilte-card py-2">
                <div className="col-md-2 col-6 px-0 d-flex justify-content-center  align-items-center">
                  <Link to="/">
                    <div className="imgDiv d-flex align-items-center w-100  px-1">
                      <img
                        src={
                          appState.storeInfo.logo
                            ? `${appState.storeInfo.logo}`
                            : `https://api.enabill.dev/storage/app/public/stores/logo/no_image_logo.png`
                        }
                        className="w-100 h-100 img-fluid"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>

              </div>
            </div>
            <div className="container-fluid px-0">

              <h3 className={`familyManrope800 mt-2 ${appState.langValue === "ar"
                ? "text-center"
                : "text-center"
                }`}
              >
                {t("contact.Rate")}:</h3>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-center ">
                  <Rating name="size-medium" defaultValue={4} value={value} getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue)
                    }}
                    size="medium"
                    itemStyle={starStyle} />

                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">

                  {value !== null && (
                    <h4 className="familyManrope800" >{labels[hover !== -1 ? hover : value]}</h4>
                  )}
                  {value == null && (
                    <h4 >&nbsp;</h4>
                  )}
                </div>
              </div>
              <div className="addInstructions  py-md-2">
                <p className={`familyManrope800 textBlack pt-md-2 pb-1 ${appState.langValue === "ar"
                  ? "text-right"
                  : "text-left"
                  }`}
                >{t("checkOut.your-comment")}</p>
                <TextareaAutosize
                  className="textArea familyManrope800"
                  aria-label="minimum height"
                  width="100%"
                  minRows={5}
                  maxRows={4}
                  placeholder={t("checkOut.Your-Comment-(Optional)")}
                  onChange={handleReview}
                // onChange={handlechange("instructions")}
                // value={
                //   !appState.loggedIn
                //     ? formData.instructions
                //     : ""
                // }
                // disabled={
                //   appState.loggedIn
                //     ? appState.userInfo !== []
                //       ? true
                //       : false
                //     : false
                // }
                />
              </div>
              <div className="row mx-0 d-flex justify-content-center pb-1 pt-2 submitBtn">
                <Button className=" col-12 blackBtn py-3 text-capitalized" onClick={handleSubmit} >{t("contact.submit")}</Button>
              </div>
             
            <div className="d-flex justify-content-center ">
            <IconButton  onClick={handleClose}>
             <p onClick={handleClose} className="text-center cursorPointer color-primary familyManrope800">{t("checkOut.Skip")}</p>
          </IconButton>
            </div>
          
            </div>
          </div>
        </div>

      </div>
    </>


  );
}



export default withTranslation()(ReviewModalv2);