/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./reOrderV2.scss";
import { useSelector, useDispatch } from "react-redux";
// cookie functions
import { erase, getCookie } from "../../../../global-functions/cookieFunction";
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Button } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
// axios for api
import axios from "axios";
// material ui
import Backdrop from "@mui/material/Backdrop";
import CircularIndeterminate from "../../../../layout-one/js/components/CircularIndeterminate";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { orderAgain } from "../../../../global-functions/reOrder";
import { freezOff, freezOn, handleError, handleLogoutConditons } from "../../../../redux-store/action";
import { roundFixed } from "../../../../global-functions/roundFixed";
import { getServiceName } from "../../../../global-functions/variables";
import { handleStockQuantity } from "../../../../global-functions/addToCartGlobal";

import AppBar from "@material-ui/core/AppBar";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@material-ui/core/Tab";
import noImg from "../../assets/images/no-image.svg";
import { handleMealType } from "../../../../global-functions/mealType";

const ReOrder = ({ item }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);
  const [value, setValue] = useState(-1);
  const [activeVTabs, setActiveVTabs] = useState(0);

  const handleLogout = () => {
    appDispatch({
      type: "SET_LOGOUT",
      loggedIn: false,
    });
    erase("tokenAuth");
    erase("Profile");

    localStorage.removeItem("userInfo");
    localStorage.setItem("loggedIn", false);
    appDispatch({
      type: "SET_USERINFO",
      userInfo: [],
    });
    appDispatch({
      type: "SHOW_ALERT",
      alertType: "success",
      message: "Successfully LogOut",
    });
  };

  const fetchData = async () => {
    let arry = [];
    let id = [];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/products?paginate=10&limit=10&offset=`,
        { branch_id: appState.qrBranch },
        {
          headers: {
            Authorization: `Bearer ${appState.loginToken}`,
            Response: `minify`,
          },
        }
      );

      if (response.data.success) {
        response?.data?.data?.data.forEach((data) => {
          if(data.status){
          const categoryId = data?.category?.id;
          const qrBranch = appState.qrBranch;
          const getCategoryObj = appState.categories?.find((item) => item.id === categoryId);
        
          if (!getCategoryObj) return; // Skip if no category object is found
        
          let prodShow = !getCategoryObj.inactive_branches?.includes(qrBranch);
        
          // Check category-level meal types if prodShow is true
          if (prodShow && getCategoryObj.mealTypes?.length > 0 && qrBranch) {
            prodShow = handleMealType(getCategoryObj.mealTypes, qrBranch);
          }
        
          if (!prodShow) return; // Skip if not to be shown at this point
        
          // Check product-level inactive branches and meal types
          const inActiveBranches = data?.inactive_branches ?? [];
          prodShow = !inActiveBranches.includes(qrBranch);
        
          if (prodShow && data?.mealTypes?.length > 0 && qrBranch) {
            prodShow = handleMealType(data.mealTypes, qrBranch);
          }
        
          if (!prodShow) return; // Skip if not to be shown at this point
        
          // Push data to the array if prodShow is true
          arry.push({
            id: data?.id,
            name: data?.name,
            image: data?.image,
            price: data?.price,
            prodSlug: data?.slug,
            cateId: categoryId,
            cateName: data?.category?.name,
            cateSlug: data?.category?.slug,
            urlLink: `${data?.category?.slug}/${data?.slug}?parent=null&prodid=${data?.id}&catid=${categoryId}&reOrder`,
          });
        }});
        
        setOrders(arry);
      }
      setIsLoading(false);
    } catch (e) {
      if (e.code == "ERR_NETWORK") {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      } else if (e == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }else if(e.response.status == 401){

        appDispatch(handleLogoutConditons());
      }  else {
        appDispatch(handleError(e));
      }
      setIsLoading(false);
      Promise.reject(e);
    }
  };

  useEffect(() => {
    if (appState.loggedIn) {
      fetchData();
    }
  }, [appState.loggedIn]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (link) => {
    history.push(link);
  };
  return (
    <>
      {isLoading ? (
        <>
             <div className="row m-0 ">
            {[1,2,3,4,5,6].map((item, index) => (
              <div className="col-2" key={index}>
              <Skeleton height={200} />
            </div>
            ))}
           
          </div>
        </>
      ) : (
        orders.length > 0 && (
          <div
            id="reOrderMainV2"
            className={` pt-3 ${
              appState.langValue === "ar"
                ? "directionRt classForArabicReOrderMain"
                : ""
            } ${appState.langValue == "ar" ? "arabicFamilyParent" : " "}`}
          >
            <h1
              className={`reOrderheading familyManrope800 px-3 m-0  ${
                appState.langValue === "ar" ? "text-right" : "text-left"
              }`}
            >
              {" "}
              {t("open-order.Previously-ordered")}
            </h1>

            <div id="tabs_sec" className="tabsSec">
              <AppBar className="appBar" position="static">
                

                {
             <Tabs
             value={value}
             onChange={handleChange}
             variant="scrollable"
             scrollButtons="auto"
             allowScrollButtonsMobile={true}
             aria-label="scrollable auto tabs example"
             className={`pt-2 re-order-layout ${
               appState.langValue === "ar" ? "direction-ltr" : ""
             }`}
             style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             {orders.map((order, index) => (
             <Tab
             label={
               <div className="tabLabel">
                 <span className="productName" title={order.name}>
                   {order.name ? get_lang(order.name, appState.langValue) : "Not Available"}
                 </span>
                 <br />
                 <span className={`productPrice ${
               appState.langValue === "ar" ? "direction-rtl" : ""
             }`}>
                   {order.price ? `${roundFixed(order.price)} ${get_lang(appState.storeInfo.storeCurrency, appState.langValue)} ` : "No Price"}
                 </span>
               </div>
             }
             onClick={(e) => handleClick(order.urlLink)}
             key={index}
             id={`tab_section_${index}`}
             className="familyManrope700 text-capitalize"
             disabled={index === value}
             icon={<img src={order.image || noImg} alt={order.name} />}
             style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
             }}
           />
             ))}
           </Tabs>
                }
                {/* </span> */}
              </AppBar>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default withTranslation()(ReOrder);
