/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../../store/StateContext";
import noImg from "../../assets/images/no-image.svg";
import usermar from "../../assets/images/usermar2.svg";
import clockImage from "../../assets/images/clockOpenBill.svg";

// Redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang, get_lang_status } from "../../../../global-functions/apiGlobal";
import { withTranslation, useTranslation } from "react-i18next";
import "./OrderProductv3.scss";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { parseNumber } from "../../../../global-functions/roundFixed";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const OrderProductv3 = ({ item, index, groupBillDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const appState = useSelector((state) => state);
  const isAddons = item.sum_addons;
  return (
    <div className="orderProductv3Parent" id={item.prod_id}>
      {appState.serviceMode.includes("group-bill") &&
        appState.qrTable &&
        appState.groupBillMode && (
          <div className="row d-flex justify-content-end m-0">
            {item.orderOwner == "my_order" ? (
              <div
                className={` familyPoppins my-1    ${appState.langValue === "ar"
                  ? "d-flex justify-content-end  direction-ltr pl-1 "
                  : "pr-1"
                  }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins300   ${index == 0
                    ? "chipOne"
                    : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                    }`}
                  label={`your order`}
                  icon={<img src={usermar} />}
                  variant="outlined"
                />
              </div>
            ) : item.orderOwner == null ? (
              <div
                className={` familyPoppins my-1    ${appState.langValue === "ar"
                  ? "d-flex justify-content-end  direction-ltr pl-1 "
                  : "pr-1"
                  }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins300   ${index == 0
                    ? "chipOne"
                    : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                    }`}
                  label={`Anonimus`}
                  icon={<img src={usermar} />}
                  variant="outlined"
                />
              </div>
            ) : (
              <div
                className={` familyPoppins my-1    ${appState.langValue === "ar"
                  ? "d-flex justify-content-end  direction-ltr pl-1 "
                  : "pr-1"
                  }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins300   ${index == 0
                    ? "chipOne"
                    : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                    }`}
                  label={item.orderOwner}
                  avatar={<Avatar>{item.orderOwner[0]}</Avatar>}
                  variant="outlined"
                />
              </div>
            )}
          </div>
        )}

      <div className="row m-0 d-flex align-items-start">

        <div
          className={` col-12 px-0 ${appState.langValue === "ar" ? " " : ""
            }`}
        >
          <div className="row m-0">
            <div className="col-12 px-0 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center ">
              
                <span className={` ${appState.langValue === "ar" ? "text-right" : "text-left familyPoppins300 familyManrope40"}  numberListing d-flex align-items-center justify-content-center`}>
                  { appState.langValue === "ar" ? <> x{item.quantity}  </>  :<> {item.quantity}x  </> }  
                </span>
                <Typography className="mx-2 my-0" gutterBottom variant="h5" component="h2">
                  <strong className="toCapitialize d-flex familyPoppins300">
                    {item.product != null
                      ? get_lang(item.product?.name, appState.langValue)
                      : "Name is not available"}
                  </strong>
                </Typography>
              </div>

              {item.status.includes("ROS") ? (
                <Chip
                  className="ChipClass px-2 ready familyPoppins300"
                  label={t("open-order.Ready")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#4ac53b"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#4ac53b"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Processing") ? (
                <Chip
                  className="ChipClass px-2 process familyPoppins300"
                  label={t("open-order.Processed")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#61adff"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#61adff"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Pending") ? (
                <Chip
                  className="ChipClass px-2 pending familyPoppins300"
                  label={t("open-order.Placed")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#FFBA00"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#FFBA00"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Completed") ? (
                <Chip
                  className="ChipClass px-2 ready familyPoppins300"
                  label={t("open-order.Ready")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#4ac53b"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#4ac53b"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : (
                <Chip
                  className="ChipClass px-2 rejected familyPoppins300"
                  label={t("open-order.Rejected")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#f74e4e"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#f74e4e"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              )}
            </div>

          </div>




          <div
            className={` d-flex  ${appState.langValue === "ar" ? "addonsWrapAr" : "addonsWrapEn"
              }`}
          >
            {!item.addons.length ? (
              ""
            ) : (
              <span
                className={`addonsh3 familyPoppins300 ${appState.langValue == "ar" ? "text-right" : "text-left"
                  }`}
                style={{ textAlign: appState.langValue === "ar" ? "right" : "left", fontFamily: "Poppins, sans-serif", fontSize: "1.25rem", wordWrap: 'break-word', }}
              >
                {" "}
                {t("single-product.Extras")}<span className="mx-1">:</span>
              </span>
            )}
            <span className="" style={{textAlign: appState.langValue === "ar" ? "right" : "left",fontFamily: "Poppins, sans-serif",fontSize: "1.25rem",wordWrap: 'break-word',}}>
              {item.addons &&
                item.addons.map((itemAddon, ind) => {
                  const langValue = appState.langValue;
                  const addonName = get_lang(itemAddon.product_addon.addon.name, langValue);
                  const langStatus = get_lang_status(itemAddon.product_addon.addon.name, langValue);
                  const quantityText = langStatus ? `x${itemAddon.quantity} ` : `${itemAddon.quantity}x`;

                  return (
                    <span key={ind} className={`addonsh3 ${langValue === "ar" ? "text-right" : "text-left familyPoppins300 familyManrope40"}`}>
                      <span>
                        <span>{quantityText}</span>
                        <span className="text-capitalize">{addonName}, </span>
                      </span>
                    </span>
                  );
                })
              }
            </span>
          </div>
        </div>
        {/* <div className="col-2 px-0 statusContent">
          <span className={`${item.status == "Pending"
            ? "pendingColor-L1"
            : item.status == "Rejected"
              ? "rejectedColor-L1"
              : item.status == "Processing"
                ? "processColor-L1"
                : item.status == "ROS"
                  ? "readyColor-L1"
                  : ""
            } `}>
         
            {item.status.includes("Pending") && <i className="fas fa-exclamation-circle"></i>}
            {item.status.includes("ROS") && <i className="fas fa-check-circle"></i>}
            {item.status.includes("Processing") && <i className="fas fa-spinner"></i>}
            {item.status.includes("Rejected") && <i className="fas fa-times-circle"></i>}
            <p>
              {item.status.includes("ROS") ? t("open-order.Ready")
                : item.status.includes("Processing") ? t("open-order.Processed")
                  : item.status.includes("Pending") ? t("open-order.Placed")
                    : t("open-order.Rejected")
              }
            </p>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default withTranslation()(OrderProductv3);
