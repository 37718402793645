/* eslint-disable */
import React, { useEffect, Suspense, lazy } from "react";
import "./enabillLoadingPage.scss";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Button from "react-bootstrap/Button";
import CloseIcon from "../../layout-one/js/assets/images/closeBtn.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// palestine.svg
import EnabillLogo2 from "../../layout-one/js/assets/images/loadingEnabill2.svg";
import EnabillLogo1 from "../../layout-one/js/assets/images/loadingEnabill.svg";

import ReactLoading from "react-loading";

function EnabillLoadingPage(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [somImages, setSomImages] = React.useState([]);

  const onLoad = (i) => {
    if (i === somImages.length - 1) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const getImages = async () => {
      await setSomImages([...somImages, EnabillLogo2]);
    };
    getImages();
  }, []);
  return (
    <div
      id="mySidenav"
      className={`promotionBannerPopup col-xl-5 py-3   ${
        appState.langValue == "ar" ? "promotionBannerPopupArabic" : " "
      }`}
    >
      {
        <Dialog fullScreen className="classToTargetEnLoadModal" open={true}>
          <DialogContent className="p-0">
            {somImages.length > 0 &&
              somImages.map((banner, i) => {
                return (
                  <div
                    className={`promotionBannerParent d-flex flex-column justify-content-between align-items-center ${
                      appState.langValue === "ar" ? "directionRtl" : ""
                    }`}
                    key={i}
                  >
                    {/* <img
                      className="promotionBannerImage"
                      src={somImages}
                      alt=""
                      onLoad={() => onLoad(i)}
                      key={banner}
                    /> */}

                    <div className="loadingContent d-flex flex-column justify-content-between align-items-center">
                      <div className="mb-2 pb-2">
                        <div className="snippet" data-title="dot-bricks">
                          <div className="stage">
                            <div className="dot-bricks"></div>
                           
                          </div>
                        </div>
                      </div>
                      <p className="loadingText mb-2 familyPoppins" >Pay securely with</p>
                      <img className="loadingImage" src={EnabillLogo1} alt="loadingImage" />
                    </div>
                  </div>
                );
              })}
          </DialogContent>
        </Dialog>
      }
    </div>
  );
}

export default withTranslation()(EnabillLoadingPage);
