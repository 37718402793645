/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./ChildCategory.scss"
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
// import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../../store/StateContext";
import noImg from "../../assets/images/no-image.svg";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";
// for Localization
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { Chip } from "@material-ui/core";
import CustomizedAccordions from "../Accordion/Accordion";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const ChildCategory = ({ item, ind, parent_id, callingFrom, childProducts }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  let { slug, islug } = useParams();
  const [cartBorderShow, setCartBorderShow] = useState(false);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    if (slug == undefined) {
      slug = item.category.slug;
    }
    let flag = true;
    if (appState?.cart?.length > 0) {
      appState?.cart?.map((ele) => {
        if (ele?.productv2_id == item.id && flag) {
          setCartBorderShow(flag)
          flag = false
        }
      })
    }
    console.log(item,'categroy child');

  }, []);
  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current?.id == appState.scrollKeyword - 1) {
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
    const swing = () => {
      if (myRef.current?.id == appState.scrollKeyword) {
        myRef.current.classList.add("swing");
        appDispatch({
          type: "setScrollKeyword",
          scrollKeyword: "",
        });
      }
    };

    // setTimeout(swing, 500);
  }, [myRef]);

  const handleScroll = () => {
    // appDispatch({
    //   type: "setScrollKeyword",
    //   scrollKeyword: Number(ind),
    // });
  };

  return (
    <>
      <div
        onClick={handleScroll}
        ref={myRef}
        id={ind}
        className="col-12 p-1 px-1  child-category prod_main_div_v2"
      >
          <a>
            <div className={`row prod_content_row  m-0 ${appState.langValue == 'ar' ? "" : ""}  `}>
              <div

                className={`prod_categ_img  px-0 ${callingFrom === "search"
                  ? "col-3 col-md-2 prod_categ_img_search_inhance"
                  : ""
                  }   `}
                style={{
                  background: `url(${item.image ? `${item.image}` : `${noImg}`})no-repeat center center / cover`,
                  width: "",
                }}
              >
             
              </div>
              <div className={`${appState.langValue == 'ar' ? "pl-0 pr-3" : "pr-0 pl-2"} ${!appState.storeInfo?.categoryProductImage ? "col-12" : "col-6 col-md-6"}  prod_content ${callingFrom === "search"
                ? "col-9 col-md-10"
                : ""
                }`} >
                <div className="d-flex h-100 flex-column justify-content-between">
                  <h4 className={` ${appState.langValue == 'ar' ? "direction-rtl" : "direction-ltr"} ${appState.langValue === "ar"
                    ? "text-right"
                    : "text-left"}`}>
                    <strong className="d-flex text-capitalize">
                      {item.name != null
                        ? get_lang(item.name, appState.langValue)
                        : "Not Available"}
                    </strong>
                  </h4>

                </div>
              </div>
            </div>
          </a>
          <button onClick={()=>handleChange(('panel1'))}>sdfkjsdlkfj</button>
<CustomizedAccordions expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} childProducts={childProducts}/>

      </div>
    </>
  );
};

export default withTranslation()(ChildCategory);
