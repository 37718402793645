/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./tipPopup.scss";

// localizations
import { withTranslation, useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  handleError,
} from "../../redux-store/action";
import CheckOut from "../../layout-one/js/Pages/Checkout/CheckOut";

function TipPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const [tipLoading, setTipLoading] = useState(false);
  const [hangTip, setHangTip] = useState(0);
  const [manualTip, setManualTip] = useState(0);
  const [tips, setTips] = useState([]);
  const appDispatch = useDispatch();
  const [loading, setLoading] = useState(false);


  const handleShowPayForItems = () => {
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
    appDispatch({
      type: "setPayForItems",
      payForItems: true,
    });
  };

  const handleShowDivideBill = () => {
    appDispatch({
      type: "setDivideBill",
      divideBill: true,
    });
  };

  const handleShowPayCustomAmount = () => {
    appDispatch({
      type: "setPayCustomAmount",
      payCustomAmount: true,
    });
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
  };

  const handleChangeTips = (e) => {
    if (e.target.name === "custom" || e.target.name === "أختيار") {
      handleClickShowCustomeTipField();
      setHangTip(Number(e.target.id));
    } else {
      if (e.target.id == hangTip) {
        setHangTip(0);
        setManualTip(Number(0));
      } else {
        setHangTip(Number(e.target.id));
        setManualTip(Number(e.target.value));
        setCustomeTip(0);
      }
    }
  };

  useEffect(() => {
    const getTips = async () => {
      setTipLoading(true);
      let arry = [];
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tip`
        );
        let res = response.data.data;
        console.log("first", response)
        if (response.data.success) {
          res.map((val) => {
            let data = {
              id: val.id,
              name: val.name,
              value: val.amount,
              amount:
                val.type == "fixed"
                  ? parseFloat(val.amount).toFixed(3)
                  : parseFloat((val.amount / 100) * appState.total).toFixed(3),
              type: val.type,
            };
            arry.push(data);
          });
          arry.push({
            id: res.length + 1,
            name: { en: "custom", ar: "أختيار" },
            value: null,
            amount: null,
          });
          setTips(arry);
          setTipLoading(false);
        }
      } catch (e) {
        console.log("catch", e)
        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
        setTipLoading(false);
      }
    };
    if (true) {
      getTips();
    }
  }, [appState.total]);

  return (
    <div
      id="mySidenav"
      className={`tipPopup  py-5   ${appState.langValue === "ar" ? "" : " "
        }`}
    >
      <CheckOut />
      {/* {payments.includes("tip") && (
        <section id="tip" className="row m-0 mt-2 pb-3">
          <div className="col-12 p-0">
            <div className="row m-0">
              <h3 className="col-12 p-0 d-flex">
                {t("checkOut.Add-a-tip")}{" "}
                <span className="px-1">&#128079;</span>
              </h3>
              <h4
                className={` col-12 p-0 text-muted d-flex ${appState.langValue === "ar"
                  ? "text-right familyGraphikArabic800 "
                  : " familyPoppins300"
                  }`}
              >
                {t("checkOut.Add-a-tip-desc")}
              </h4>
            </div>
            {console.log("tipLoading", tips.length)}
            {tipLoading ? (
              <div className="row d-flex justify-content-center">
                <div className="col-md-3 px-2">
                  <Skeleton height={150} />
                </div>
                <div className="col-md-3 px-2">
                  <Skeleton height={150} />
                </div>
                <div className="col-md-3 px-2">
                  <Skeleton height={150} />
                </div>
                <div className="col-md-3 px-2">
                  <Skeleton height={150} />
                </div>
              </div>
            ) : (
              <div id="TipCheckBoxParent">
                <div
                  className="row m-0 TipselectPaymentRow TipselectPaymentRowWallet"
                  onChange={handleChangeTips}
                >
                  {tips.length > 0 &&
                    tips.map((val, ind) => {
                      return (
                        <div
                          className="col-3 tipManinCol direction-ltr"
                          key={ind}
                        >
                          <Button
                            className="w-100 h-100 p-0"
                            variant="outlined"
                          >
                            <div className="paymentContainer w-100">
                              <input
                                type="checkbox"
                                value={val.amount}
                                name={get_lang(
                                  val.name,
                                  appState.langValue
                                )}
                                id={val.id}
                                checked={hangTip == val.id}
                              />
                              <label htmlFor={val.id}>
                                <span>
                                  <strong className="d-flex tiptext mt-2 justify-content-center textOver">
                                    {val.name.en != "custom" ?  get_lang(
                                      val.name,
                                      appState.langValue
                                    ) : <img src={pencil} alt="pencil"/>
                                  }
                                  </strong>
                                </span>

                                {val.name.en === "custom" ? (
                                  <>
                                    <p className="textBlack mb-0 mt-1 customText familyManrope800">
                                    {get_lang(
                                      val.name,
                                      appState.langValue
                                    )}
                                    </p>
                                  </>
                                ) : (
                                  <p className="textBlack mb-0 d-flex flex-wrap justify-content-center familyManrope800">
                                    <span className="">{get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</span>
                                    &nbsp;
                                    <span className="">
                                      {val.amount}
                                    </span>
                                  </p>
                                )}
                              </label>
                            </div>
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      <div className="container-fluid content px-4 pb-3 my-5">
        <h1
          className={` ${appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins300"
            }`}
        >
          {t("checkOut.Split-Bill")}
        </h1>
        <div className="row mt-3 px-2">
          {(appState.shopMode.includes('is_partial') ? appState.shopMode.includes('product') ? true : false : true) &&
            <Button
              onClick={handleShowPayForItems}
              className={`py-3 px-4 d-flex justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600 " : ""
                }`}
            >
              <span className="text-capitalize">
                {t("checkOut.Pay-for-your-items")}
              </span>{" "}
            </Button>
          }
        </div>
      </div> */}
    </div>
  );
}

export default withTranslation()(TipPopup);
