import { getLoyaltyCardDiscount } from "../../../../redux-store/action/apiCall";
import { capitalizeString } from "../../../../utils/genral-functions";

export const handleSplitPayements = (state, dispatch) => {
  if (state.shopMode.includes("is_partial")) {
    dispatch({ type: "setSplitBillOptions", splitBillOptions: false });
    if (state.shopMode.includes("product")) {
      dispatch({ type: "setPayForItems", payForItems: true });
    } else if (state.shopMode.includes("ratio")) {
      dispatch({ type: "setDivideBill", divideBill: true });
    } else if (state.shopMode.includes("customer")) {
      dispatch({ type: "setPayCustomAmount", payCustomAmount: true });
    }
  } else {
    dispatch({ type: "setSplitBillOptions", splitBillOptions: true });
    dispatch({ type: "setSplitBill", splitBill: true });
  }
};

export const handleDeleteOption = (state, dispatch, mode) => {
  let setMode = `set${capitalizeString(mode)}`;
  if (state.shopMode.includes("is_partial")) {
    dispatch({ type: "setSplitBillOptions", splitBillOptions: false });
    dispatch({ type: setMode, mode: false });
  } else {
    dispatch({ type: setMode, mode: false });
    dispatch({ type: "setSplitBillOptions", splitBillOptions: true });
    dispatch({ type: "setSplitBill", splitBill: true });
  }
};

export const handleStoreDiscount = (state, dispatch, total) => {
  let {discount_max = 0, discount_amount = 0, discount_percent = 0} = state.storeDiscount;
  let maxDiscount = Number(discount_max);
  let amountDiscount = Number(discount_amount);
  let percentDiscount = Number(discount_percent);
  let percentageCalculate = total * (percentDiscount / 100);
  let calculatedDiscount = percentageCalculate + amountDiscount;
  if (calculatedDiscount > discount_max) {
    return maxDiscount;
  } else {
    return calculatedDiscount;
  }
};
