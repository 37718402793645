export const setGuestLoggedIn = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "setGuestLoggedIn",
      guestLoggedIn: mode,
    });
  };
};


export const setLoginModal = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "setLoginModal",
      loginModal: mode,
    });
  };
}

export const setLoginSkip = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "setLoginSkip",
      loginSkip: mode,
    });
  }
}

export const setLoginState = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "setLoginValues",
      loginValues: data,
    });
  };
}

export const setGuestModal = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_GUEST_MODAL_SHOW",
      guestModalShow: mode,
    });
  };
}

export const setFreezScreen = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: mode,
    });
  };
}

export const setLangValue = (type) =>{
  return async (dispatch) => {
    dispatch({
      type: "LangChange",
      langValue: type,
    });
  };
}

export const setDeliveryAddsInfo = (data) =>{
  return async (dispatch) => {
    dispatch({
      type: "setDeliveryAddsInfo",
      deliveryAddsInfo: data,
    });
  };
}
export const setPickupAddsInfo = (data) =>{
  return async (dispatch) => {
    dispatch({
      type: "setPickupAddsInfo",
      pickupAddsInfo: data,
    });
  };
}

export const setUserAddressPopup = (mode) =>{
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_ADDRESS_POPUP",
      userAddressPopup: mode,
    });
  };
}

export const setUserSaveDeliveryAdds = (data) =>{
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_SAVE_DELIVERY_ADDS",
      userSaveDeliveryAdds: data,
    });
  };
}


export const setDeliveryDateTimePopup = (mode) =>{
  return async (dispatch) => {
    dispatch({
      type: "SET_DELIVERY_DATE_TIME_POPUP",
      deliveryDateTimePopup: mode,
    });
  };
}



export const setDeliveryPreparingTime = (data) =>{
  return async (dispatch) => {
    dispatch({
      type: "SET_DELIVERY_PREPARING_TIME",
      deliveryPreparingTime: data,
    });
  };
}
export const setPickupDateTimePopup = (mode) =>{
  return async (dispatch) => {
    dispatch({
      type: "SET_PICKUP_DATE_TIME_POPUP",
      pickupDateTimePopup: mode,
    });
  };
}

export const setCurrentServices = (data) =>{
  console.log(`setcurrentsetv`,data,)
  return async (dispatch) => {
    dispatch({
      type: "SET_CURRENT_SERVICES",
      currentServices: data,
    });
  };
}

export const setIsClosed = (mode) =>{
  console.log('setIsClosed', mode)
  return async (dispatch) =>{
    dispatch({
      type: 'SET_ISCLOSED',
      isClosed: mode
    })
  }
}