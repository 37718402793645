/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Button } from '@material-ui/core';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckoutBtn from './checkoutBtn/CheckoutBtn';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { withTranslation, useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";


function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function IFrameUrl() {
  const history = useHistory();
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleClick = () => {
    appDispatch({
      type: "setPayTablePopUp",
      payTablePopUp: true,
    })
    history.push("/");

  }
  return (
    <div id="iFrameCompo">
      <span className="header d-flex align-items-center justify-content-between ">
        <Button
          aria-label="delete"
          onClick={handleClick}
        >
          {appState.langValue === "ar" ? (
            <ArrowForwardIosIcon

              fontSize="inherit"
            />
          ) : (
            <ArrowBackIosIcon className='ml-2' fontSize="inherit" />
          )}
        </Button>
        {/*  w-100 d-flex justify-content-center align-items-center */}
        <div
          className=" headingDiv "
        >

          <h3 className={` m-0 ${appState.langValue === "ar" ? "" : "familyManrope700"
            }`} >{t("checkOut.menu")}</h3>
        </div>

      </span>

      <iframe
        src={appState.storeInfo.iframeUrl}
        width={windowSize.innerWidth}
        height={windowSize.innerHeight}
        title="A youtube video on React hooks"
      ></iframe>
      <section id="reviewOrderButton" className="m-0 p-0 mt-5 col-12 col-xl-5">
        <CheckoutBtn label={`CheckOut`}/>
      </section>
    </div>
  );
}

export default withTranslation()(IFrameUrl);




