/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./DeliveryDateTime.scss";
// material ui
import Button from "@mui/material/Button";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { get_lang } from "../../global-functions/apiGlobal";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux-store/store";
import axios from "axios";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { formatValue } from "../../utils/genral-functions";
import moment from "moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";

function DeliveryDateTimePopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { setUserAddressPopup, setDeliveryDateTimePopup, setDeliveryAddsInfo } =
    bindActionCreators(actionCreators, appDispatch);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  const { availableTime, setAvailableTime, setExpanded } = props;
  const [enabledDays, setEnabledDays] = useState([]);
  const date = new Date();
  const [hours, setHours] = useState(availableTime?.selectedHours || 0);
  const [minutes, setMinutes] = useState(
    availableTime?.selectedMinutes || formatValue(date.getMinutes())
  );
  const [period, setPeriod] = useState(availableTime?.selectedPeriod || "AM");
  const [newValue, setNewValue] = useState(
    availableTime?.selectedDate || new Date()
  );
  const [nextBackBtn, setNextBackBtn] = useState(true);
  const [asaStatus, setAsaStatus] = useState(true);
  const currentTime = moment();
  const [slotsTime, setSlotsTime] = useState([])
  const [availabeSlots, setAvailabeSlots] = useState([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')

  const handleClickConfirm = () => {
    if (nextBackBtn) {
      setNextBackBtn(false);
    } else {
      if(slotsTime?.length > 0){
        var decidedDate = moment(newValue).format("YYYY-MM-DD");
        let findTime = slotsTime?.find((item) => item.id == appState.deliveryAddsInfo?.delivery_time_slot_id)
        console.log(findTime.start.format("hh:mm A"),'findTime')
        setAvailableTime({
          ...availableTime,
          // showDecidedTime: `${decidedDate} ${hours}:${minutes} ${period}`,
          showDecidedTime: `${decidedDate} ${findTime.start.format("hh:mm A")}`,
          decidedDate: `${decidedDate}`,
  
        });
        setDeliveryDateTimePopup(false);
      }else{
        setNextBackBtn(true)
      }
     
  }}

  const [isInRange, setIsInRange] = useState(false);

  const handleChangeHours = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 12) value = 0;
    if (value < 0) value = 12;
    setHours(formatValue(value));
  };

  const handleChangeMinutes = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 59) value = 0;
    if (value < 0) value = 59;
    setMinutes(formatValue(value));
  };
  const handleClickTime = (id) => {
    if(appState.deliveryAddsInfo.delivery_time_slot_id == id){
      setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, delivery_time_slot_id: null });
     
    }else{
      setDeliveryAddsInfo({ ...appState.deliveryAddsInfo, delivery_time_slot_id: id });
    }
  }

  const formatTime = (time) => {
    return moment(time, "HH:mm").format("hh:mm A");
  };

  const isTimeInRange = (currentTime, startTime, endTime) => {
    const format = "HH:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);

    // If the end time is before the start time, it means the range goes over midnight
    if (end.isBefore(start)) {
      return (
        currentTime.isBetween(start, moment("23:59", format)) ||
        currentTime.isBetween(moment("00:00", format), end)
      );
    } else {
      return currentTime.isBetween(start, end);
    }
  };

  const handleDateChange = (newDate) => {
    const currentTime = moment(); 
    const currentDayName = moment(newDate).format("dddd");
    const decidedDate = moment(newDate).format("YYYY-MM-DD"); // Format the input date
    
    // Compare decidedDate with the current date
    const isToday = decidedDate === moment().format("YYYY-MM-DD");
    console.log(isToday);

    let findAvailableSlots = [];

    slotsTime?.forEach(slot => {
      if (slot.dayName === currentDayName) { 
        console.log(slot.dayName, currentDayName);

        const startTime = moment(slot.start, "HH:mm");
        const endTime = moment(slot.end, "HH:mm");

        // Check if the slot's start time is after the current time
        const isPassedEndTime = isToday ? currentTime.isBefore(startTime) : true;
        
        console.log(isPassedEndTime, isToday);
        if (isPassedEndTime) {
          findAvailableSlots.push({
            ...slot,
            slotStart: moment(slot.start, "hh:mm A"),
            slotEnd: moment(slot.end, "hh:mm A"),
          });
        }
      }
    });

    // Sort slots by `start` time in ascending order
    findAvailableSlots.sort((a, b) => moment(a.start, "HH:mm").diff(moment(b.start, "HH:mm")));

    console.log(findAvailableSlots);
    
    // Update state with sorted slots
    setAvailabeSlots(findAvailableSlots);
    
    console.log(currentDayName);
    setNewValue(newDate);
};



const [excludedDates, setExcludedDates] = useState([])
  useEffect(() => {
    const currentDayName = moment().format("dddd");
    let start_time = "";
    let end_time = "";
    let flag = false;
    let flag2 = false;
    let activeDays = [];
    let time_slots = []
    let disableDates=[]
    const getEstimatedTime = async () => {
      console.log(`get getEstimatedTime getEstimatedTimeEstimatedTime  getEstimatedTime`)
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/delivery/timing?branch=${appState?.qrBranch}`
        );
        let res = response.data.data;
        if (response.data.success) {
        
          res.forEach((ele) => {
            activeDays.push(Number(ele.day?.id) - 1);
            if (currentDayName === ele.day?.name && !flag2) {
              let currentTime2 = moment(); // ✅ Fix: Call moment() correctly
              const endTime = moment(ele.ending_time, "HH:mm"); // ✅ Convert to moment object
              const startTime = moment(ele.starting_time, "HH:mm"); // ✅ Convert to moment object
                     
              // 🔹 If endTime is before startTime, add 1 day to endTime
              if (endTime.isBefore(startTime)) {
                endTime.add(1, "days"); // Move endTime to the next day
              }
              const isPassedEndTime = currentTime2.isSameOrAfter(endTime);

              console.log("Time Check:", isPassedEndTime, "Start:", startTime.format("HH:mm"), "End:", endTime.format("HH:mm"));          
              if (isPassedEndTime) {
                const currentDate = moment().format("YYYY-MM-DD"); // ✅ Format the current date
                disableDates.push(currentDate);
                console.log("Disabled Dates:", disableDates);
              }
          
              flag2 = true;
              setExcludedDates(disableDates)
            }
        

            if(ele?.delivery_time_slots?.length>0){
              ele?.delivery_time_slots.forEach((slot) => {
                console.log('first')
                time_slots.push({
                  dayName: ele?.day?.name,
                  id: slot.id,
                  start: moment(slot.starting_time, "hh:mm A"),
                  end: moment(slot.ending_time, "hh:mm A"),
                })
              })
          }



            if (currentDayName == ele.day?.name && !flag) {

                start_time = ele.starting_time;
                end_time = ele.ending_time;
              
                const inRange = isTimeInRange(
                  currentTime,
                  formatTime(moment(ele.starting_time, "HH:mm")),
                  formatTime(moment(ele.ending_time, "HH:mm"))
                );
                setIsInRange(inRange);
          


              setAvailableTime({
                ...availableTime,
                day: currentDayName,
                startTime: formatTime(moment(ele.starting_time, "HH:mm")),
                endTime: formatTime(moment(ele.ending_time, "HH:mm")),
              });
          
              const time = moment(ele.starting_time, "hh:mm A");
              setHours(time.format("hh"));
              setMinutes(time.format("mm"));
              setPeriod(time.format("A"));
              flag = true;
            }
          });
          setEnabledDays(activeDays);
          console.log(time_slots)
          setSlotsTime(time_slots)
        setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)

      }
    };
    getEstimatedTime();
    if (!flag) {
      const currentHour = currentTime.format("hh");
      const currentMinute = currentTime.format("mm");
      const currentPeriod = currentTime.format("A");

      setHours(currentHour);
      setMinutes(currentMinute);
      setPeriod(currentPeriod);
    }

    console.log(availableTime)

    handleDateChange()
  }, []);

  useEffect(() => {
    console.log('availableTime',availableTime)
  }, [availableTime])
  
  useEffect(() => {
    if (hours > 0 || minutes > 0 || newValue) {

      // this is selected time by customer
      let selectedTime = moment(`${hours}:${minutes} ${period}`, "HH:mm A");
      // this is selected data by customer
      var decidedDate = moment(newValue).format("YYYY-MM-DD");
      var decidedTime = moment(selectedTime).format("HH:mm");
      // check that the selected time and date are avalaible or not
      var is_inRanpge = isTimeInRange(
        selectedTime,
        availableTime?.startTime,
        availableTime?.endTime
      );

      setIsInRange(is_inRanpge);
    } else {
    }
  }, [hours, minutes, period, newValue]);

   // Calculate the max date (9 days from the current date)
   const maxDate = moment().add(appState.storeInfo?.deliveryDays, "days").toDate();
   // Function to disable tiles based on the day of the week
  // const tileDisabled = ({ date, view }) => {
  //   // Disable dates based on day of the week
  //   if (view === "month") {
  //     const day = date.getDay();
  //     return !enabledDays.includes(day);
  //   }
  //   return false;
  // };
  
  const tileDisabled = ({ date, view }) => {
    if (view !== "month") return false; // Only disable tiles in month view
  
    const day = date.getDay();
    const isDayDisabled = !enabledDays.includes(day);
  
    // Convert date to string format (YYYY-MM-DD)
    const formattedDate = moment(date).format("YYYY-MM-DD");
  
    // Check if the date is in the excludedDates array
    if (excludedDates.includes(formattedDate)) {
      return true;
    }
  
    // Get today's date and disable it if the time has passed
    const currentDate = moment().format("YYYY-MM-DD");
    // if (formattedDate === currentDate) {
    //   const todaySlot = slotsTime.find(slot => slot.dayName === moment().format("dddd"));
    //   if (todaySlot) {
    //     const currentTime = moment();
    //     const endTime = moment(todaySlot.end, "hh:mm A");
    //     if (currentTime.isSameOrAfter(endTime)) {
    //       return true;
    //     }
    //   }
    // }
  
    return isDayDisabled;
  };
  

   const handleClosePopup = () => { 
    console.log(appState.deliveryAddsInfo.delivery_time_slot_id, 'appState.deliveryAddsInfo.delivery_time_slot_id')
    if( !appState.deliveryAddsInfo.delivery_time_slot_id){
      setExpanded(``)
      setDeliveryDateTimePopup(false)
    }
    else{
      setDeliveryDateTimePopup(false)
    }
   }
  return (
    <>
      <div id="mySidenav" className={`DeliveryDateTime ${   appState.langValue === "ar" ? "divideBillArabic" : " " }`}>
        <div className="top-header">
      <div className="top-bar"></div>
      <div className="popup-header container-fluid content pt-4">
            <h1 className="popup-title">
                        {nextBackBtn ? t("Select Delivery Date") : t("Select Delivery Time")} 
            </h1>
            <IconButton onClick={handleClosePopup} className="close-icon mr-2" >
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          </div>

        <div className="container-fluid content px-4 pb-3 JF">
          {nextBackBtn ? (
            <div className="  ">
              <Calendar
                onChange={handleDateChange}
                value={newValue}
                minDate={new Date()} // Disable previous dates
                maxDate={maxDate} // Allow only up to 9 days from today
                className="custom-calendar"
                tileDisabled={tileDisabled} // Disable unwanted dates
                locale="en-US" // Ensure calendar starts on Sunday
              />
            </div>
          ) : (
            <>
              {/* <div className="">
                <p>
                  {availableTime?.day} start time
                  <span style={{ color: "green" }}>
                    {availableTime?.startTime}
                  </span>
                  to end time
                  <span style={{ color: "red" }}>{availableTime?.endTime}</span>
                </p>
              </div> */}
              <div className=" radio-btn-main-container ">
              <div className="row text-center " >
                  {availabeSlots?.length > 0 ? (
                    availabeSlots.map((item, ind) => (
                      <div
                        className={`col-lg-6 col-md-12 col-sm-12 col-12  p-2 ${availabeSlots?.length - 1 == ind && ``}`}
                        key={ind}
                        style={{ direction: "ltr" }}
                      >
                        <label className="radio-container">
                          <input
                            type="radio"
                            name="timeSlot"
                            value={item.id}
                            checked={appState.deliveryAddsInfo?.delivery_time_slot_id === item.id}
                            onChange={() => handleClickTime(item.id)}
                          />
                          <span className="custom-radio"></span>
                          {item?.start.format("hh:mm A")} - {item?.end.format("hh:mm A")}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                      <h4>{t("No time slot available")}</h4>
                      <h6>{t("go back and change the date.")}</h6>
                      <Button
                        variant="outlined"
                        onClick={() => setNextBackBtn(true)}
                        className=""
                      >
                        {t("back")}
                      </Button>
                    </div>
                  )}
                </div>

                {/* <div
                  className="d-flex align-items-center justif y-content-center mt-70"
                  style={{ width: `80%`, textAlign: `center` }}
                >
                  <TextField
                    className="timeClass timeInputContainer"
                    placeholder="hh"
                    margin="no-margin"
                    value={hours}
                    type="number"
                    onChange={handleChangeHours}
                    inputProps={{ min: 1, max: 13 }}
                  />
                  <h1>:</h1>
                  <TextField
                    className="timeClass"
                    placeholder="mm"
                    margin="no-margin"
                    value={minutes}
                    type="number"
                    onChange={handleChangeMinutes}
                    inputProps={{ min: 0, max: 60 }}
                  />

                  <div className="d-flex align-items-center justify-content-center flex-column px-2 am-pm">
                    <Button
                      variant={period === "AM" ? "contained" : "outlined"}
                      className={`mb-2 ${
                        period === "AM" ? "active" : "non-active "
                      }`}
                      onClick={() => setPeriod("AM")}
                    >
                      AM
                    </Button>
                    <Button
                      variant={period === "PM" ? "contained" : "outlined"}
                      className={period === "PM" ? "active" : "non-active"}
                      onClick={() => setPeriod("PM")}
                    >
                      PM
                    </Button>
                  </div>
                </div> */}
              </div>

              {/* {!isInRange && (
                <p style={{ color: "red" }}>
                  Selected time is not within available range
                </p>
              )} */}
            </>
          )}

          <div
            className={`btn-div d-flex align-items-center justify-content-center ${
              !nextBackBtn ? "mt-70" : ""
            }`}
          ></div>

          <div className="bottomBtn col-md-12 justify-content-center">
            <Button
              onClick={ handleClickConfirm}
              disabled={!nextBackBtn && !appState.deliveryAddsInfo.delivery_time_slot_id && availabeSlots?.length > 0}
              className={` ${
                !nextBackBtn && !appState.deliveryAddsInfo.delivery_time_slot_id && availabeSlots?.length > 0  ? "disabled-btn" : ""
              }  py-3 px-4 mt-3 confirmBtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {nextBackBtn ? t(`Confirm Date`) : availabeSlots?.length > 0 ?  t(`Confirm Time`) : t(`Back`)}
            </Button>
           {(!nextBackBtn  && availabeSlots?.length > 0 ) && <Button
              onClick={ () => setNextBackBtn(true)}
              className={`  py-3 px-4 mt-3  m-0 backBtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {t(`Back`)}
            </Button>}
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(DeliveryDateTimePopup);
