import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ProductNewV3 from "./ProductNewV3";
import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import './CategoryProductsV2.scss'
import { get_lang } from "../../../../global-functions/apiGlobal";

// Categories and Products data
const categories = [
  { slug: "pizza", name: "Pizza" },
  { slug: "burger", name: "Burgers" },
  { slug: "sushi", name: "Sushi" },
];

const products = [
  {
    id: 1,
    name: "Margherita Pizza",
    description: "Classic pizza with cheese and tomato",
    price: "$10",
    categorySlug: "pizza",
  },
  {
    id: 2,
    name: "Pepperoni Pizza",
    description: "Spicy pepperoni pizza",
    price: "$12",
    categorySlug: "pizza",
  },
  {
    id: 3,
    name: "Cheeseburger",
    description: "Juicy burger with cheese",
    price: "$8",
    categorySlug: "burger",
  },
  {
    id: 4,
    name: "Veggie Burger",
    description: "Healthy veggie burger",
    price: "$9",
    categorySlug: "burger",
  },
  {
    id: 5,
    name: "California Roll",
    description: "Sushi with avocado and cucumber",
    price: "$15",
    categorySlug: "sushi",
  },
  {
    id: 6,
    name: "Tuna Roll",
    description: "Fresh tuna sushi",
    price: "$16",
    categorySlug: "sushi",
  },
  {
    id: 7,
    name: "Dragon Roll",
    description: "Sushi with eel and avocado",
    price: "$17",
    categorySlug: "sushi",
  },
];

const CategoryProductsV2 = ({ categories, products, isLoading, slug, handleClickShowProductPopup }) => {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();

  const [value, setValue] = useState("0"); // Track active category tab
  const productRefs = useRef({});
  const myRef = useRef(null);

  const [renderCount, setRenderCount] = useState(0);
  useEffect(() => {
    if (myRef.current?.id == appState.scrollKeyword) {
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [myRef]);

  const handleCategroyScroll = (newValue) => {
    

    if (appState.scrollKeyword !== "") {
      // Scroll to the product if scrollKeyword exists
      const productElement = document.getElementById(appState.scrollKeyword); // Assuming scrollKeyword is the product ID
      if (productElement) {
        const offset = 160; // Desired offset in pixels
        const productPosition =
          productElement.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
          top: productPosition,
          behavior: "smooth",
        });

        // // Trigger pulse animation after scroll
        // const triggerAnimation = () => {
        //   // Add pulse class to product element
        //   productElement.classList.add("pulse");

        //   // Reset scrollKeyword to stop further actions
        //   appDispatch({
        //     type: "setScrollKeyword",
        //     scrollKeyword: "",
        //   });
        // };

        // // Wait a bit after scrolling, then trigger the animation
        // setTimeout(triggerAnimation, 700); // Adjust timing as necessary
      }
    } else {
      // Scroll to the category section if scrollKeyword doesn't exist                 bv                                   
      const section = productRefs.current[newValue]; // Scroll to the selected category section
      

      if (section) {
        const offset = 160; // Desired offset in pixels
        const sectionPosition =
          section.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
          top: sectionPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleCategroyScroll(newValue);
    var theURL = window.location.pathname;
    
    const newURL = theURL.replace(/\/[^/]+$/, `/${newValue}`);

    // Update the URL without reloading the page
    window.history.replaceState(null, "", newURL);
  };

  useEffect(() => {
    if (slug) {
      const CategoryIndex = categories.findIndex((item) => item.slug === slug);
      if (CategoryIndex > -1) {
        setValue(slug);
        setTimeout(() => {
          handleCategroyScroll(slug);
        }, 100); // delay to ensure DOM is ready
        setRenderCount(1);
      }
    }
  }, [slug, categories]);

  useEffect(() => {
    
  }, [value]);

  // Scroll to category tab when the category section comes into view
  useEffect(() => {
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const categorySlug = entry.target.getAttribute("data-category");
            if (categorySlug) {
              setValue(categorySlug); // Update the tab when category section is visible
            }
          }
        });
      },
      {
        root: null, // Observe with respect to the viewport
        rootMargin: "-10% 0px -82% 0px", // Trigger when the top of the section is at the top of the viewport
        threshold: 0, // Trigger as soon as top edge is in view
      }
    );

    // Observe all product sections for categories
    const productSections = document.querySelectorAll(".category-section");
    productSections.forEach((section) => observer.observe(section));

    // Cleanup observer on unmount
    return () => {
      productSections.forEach((section) => observer.unobserve(section));
    };
  }, [categories]);
  

  return (
    <Box
      sx={{ width: "100%", typography: "body1", marginBottom: "100px" }}
      id="category-product-v2"
    >
      <TabContext value={value}>
        {/* Sticky Tabs Container */}
        <Box
          sx={{
            position: "sticky",
            top: 50,
            zIndex: 1100,
            background: "#fff", // Ensure background is white so content below is not visible behind tabs
            marginBottom: "10px",
            overflowX: "auto", // Allow horizontal scrolling
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable category tabs"
            sx={{
              display: "flex",
              justifyContent: "center",
              borderBottom: 1,
              borderColor: "divider",
              
            }}
          >
            {categories.map((category) => (
              <Tab
                key={category.slug}
                label={get_lang(category.name, appState.langValue)}
                value={category.slug} // Each category has a unique slug
                onClick={() => setValue(category.slug)} // Change active tab
              />
            ))}
          </TabList>
        </Box>

        {/* Render all products but group them under their respective categories */}
        {categories.map((category) => (
          <Box key={category.slug}>
            <h3 className="mx-3">{get_lang(category.name, appState.langValue)}</h3>

            <div
              className="category-section"
              data-category={category.slug}
              ref={(el) => (productRefs.current[category.slug] = el)} // Add observer to product section
            >
              {/* Filter products by the current category */}
              {products
                .filter((product) => product.category?.slug === category.slug) // Filter products by categorySlug
                .map((product, ind) =>
                  isLoading ? (
                    [0, 1, 2, 3, 4].map((a) => (
                      <div
                        key={a}
                        className={`row m-0 mb-4 mt-3 ${
                          appState.langValue == "ar" ? "" : ""
                        }`}
                      >
                        <div className="col-5 col-md-4 px-2">
                          <Skeleton
                            variant="rect"
                            height={120}
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <div className="col-7 col-md-8 px-2">
                          <Skeleton
                            height={50}
                            style={{
                              transform: "translate(2px, 0)",
                              marginBottom: "10px",
                            }}
                          />
                          <Skeleton height={30} />
                          <Skeleton height={30} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="prodContainer ani-boxes w-100 px-2 product-item"
                      key={product}
                      data-id={product?.category?.slug} // Unique identifier for each product
                      // ref={(el) => (productRefs.current[ind] = el)} // Store ref to each product
                      ref={myRef}
                      id={`${ind}-${product.id}`}
                    >
                      {ind === 0 ? "" : <hr />}
                      {
                        <ProductNewV3
                          item={product}
                          ind={ind}
                          parent_id={null}
                          myRef=""
                          handleClickShowProductPopup={handleClickShowProductPopup}
                        />
                      }
                    </div>
                  )
                )}
            </div>
          </Box>
        ))}
      </TabContext>
    </Box>
  );
};

export default CategoryProductsV2;
