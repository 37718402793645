/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useEffect, useState, Suspense, lazy } from "react";
/* eslint-disable */
import ReactDOM from "react-dom";
import "../layout-one/sass/app.scss";
import "../layout-two/sass-react/app.scss";
// React Redux
import { useSelector, useDispatch } from "react-redux";
import {
  MainApi,
  getDeviceId,
  StoreNotFound,
  freezOff,
  checkOpenBill,
  cleanCache,
  freezOn,
  resetSplitOptions,
  handleCheckCreatedAt,
  handlePromotionBanner,
  checkInvoice,
  handleError,
  tagsAndDiets,
  closeGroupBill,
  getDineInOrder,
  handleIsAddToCartEnable,
  handleSourceOfMeetBanner,
  cleanLimitedState,
  pushShopMode,
  popShopMode,
  handlepaymentMethods,
  getClosePayments,
} from "../redux-store/action";
import env from "react-dotenv";
import axios from "axios";
import {
  BrowserRouter,
  Route,
  Switch,
  useParams,
  useLocation,
  withRouter,
  useHistory,
} from "react-router-dom";
import HomeOne from "../layout-one/js/Pages/Home";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CartOne from "../layout-one/js/Pages/Cart";
// import CartTwo from "../layout-two/js/Pages/Cart";
import StoreBusy from "../layout-one/js/Pages/StoreBusy";
import CircularIndeterminate from "../layout-one/js/components/CircularIndeterminate";
import Branch from "../layout-one/js/Pages/Branch";
import OpenOrder from "../layout-one/js/Pages/OpenOrder";
// import CheckOutTwoNew from "../layout-two/js-react/Pages/CheckoutNew";
import CheckOutOne from "../layout-one/js/Pages/Checkout/CheckOut";
import SingleProduct from "../layout-one/js/Pages/SingleProduct";
import Contact from "../layout-one/js/Pages/Contact";
import Search from "../layout-one/js/Pages/Search";
import Detail from "../layout-one/js/Pages/Detail";
import Status from "../layout-one/js/Pages/Status";
import Alert from "../layout-one/js/components/Alert";
import SideComponent from "../layout-one/js/components/SideComponent";
import NotFound from "./NotFound";

import { withTranslation, Trans } from "react-i18next";
import IsClosed from "../layout-one/js/Pages/IsClosed";
import UserInfo from "../layout-one/js/components/UserInfo";
import {
  setCookie,
  getCookie,
  erase,
} from "../global-functions/cookieFunction";
import Images from "../layout-one/js/assets/images/images";

/* eslint-disable */
import ReactGa from "react-ga";
import useGaTracker from "./useGaTracker";
import Backdrop from "@mui/material/Backdrop";
import LoginModalv2Popup from "../loginSignUp/loginModalv2Popup/loginModalv2Popup";
import VerifyPhoneNumberv2Popup from "../loginSignUp/verifyPhoneNumberv2Popup/verifyPhoneNumberv2Popup";
import { orderAgain } from "../global-functions/reOrder";

import { handleMealType } from "../global-functions/mealType";
/* eslint-disable */
import ReactGA from "react-ga";
import PromotionBanner from "../sharedComponent/promotionBanner/promotionBanner";
import IFrameUrl from "../layout-one/js/components/IFrameUrl";
import { roundFixed } from "../global-functions/roundFixed";
import { configure } from "mobx";
import { useDetectAdBlock } from "adblock-detect-react";
import Error404 from "../layout-one/js/Pages/Error404/error404";
import FilterProduct from "../layout-one/js/components/filterProduct/filterProduct";
import GroupOrderPopup from "../layout-one/js/components/groupOrderPopup/groupOrderPopup";
import ShareInvoiceQrcode from "../layout-one/js/components/shareInvoiceQrcode/shareInvoiceQrcode";
import LoginModalv2Screen from "../loginSignUp/loginModalv2Screen/loginModalv2Screen";
import {
  getDineInOrders,
  getGroupBill,
  getLoyaltyCards,
  getOrderInvoice,
  handleGetCountry,
  handleGetDeliveryCharges,
} from "../redux-store/action/apiCall";
import SubCategory from "../layout-one/js/components/SubCategory";
import {
  getLiveData,
  getNotification,
  getTableOrderId,
} from "../global-functions/pusher";
import CryptoJS from "crypto-js";
import LoyalityPopup from "../layout-one/js/components/loyalityPopup/loyalityPopup";
import SelectLoyaltyCardPopup from "../layout-one/js/components/selectLoyaltyCardPopup/selectLoyaltyCardPopup";
import SourceOfMeetBanner from "../sharedComponent/sourceOfMeetBanner/sourceOfMeetBanner";
import { checkHotelLoginRequired } from "../global-functions/loginAllow";
import TestComponent from "../layout-one/js/components/TestComponent";
import LoyaltyBanner from "../sharedComponent/LoyaltyBanner/LoyaltyBanner";
import EnabillLoadingPage from "../sharedComponent/enabillLoadingPage/enabillLoadingPage";
import FeaturedProductPopup from "../layout-one/js/components/FeaturedProductPopup/FeaturedProductPopup";
import { getServiceName } from "../global-functions/variables";
import CheckoutShortCutPopup from "../layout-one/js/components/checkoutShortCutPopup/checkoutShortCutPopup";
import { Dialog } from "@mui/material";
import CloseBillCheckout from "../layout-one/js/Pages/closeBillCheckout";
import DeliveryCheckout from "../layout-one/js/Pages/Checkout/DeliveryCheckout";
import AddressFrom from "../layout-one/js/components/addressForm/AddressFrom";
import GoogleLocation from "../layout-one/js/components/googleLocation/GoogleLocation";
import GuestModal from "../loginSignUp/guestModal/GuestModal";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux-store/store";
import Products from "../layout-one/js/Pages/Products/Products";

// const EnabillLoadingPage = lazy(() => import(
//   '../sharedComponent/enabillLoadingPage/enabillLoadingPage'))

configure({
  isolateGlobalState: true,
});
const TRACKING_ID = "UA-243425614-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Main({ t, i18n }) {
  const appState = useSelector((state) => state);
  // console.log("appState",appState)
  const adBlockDetected = useDetectAdBlock();
  const appDispatch = useDispatch();
  const { setDeliveryAddsInfo, setDeliveryDateTimePopup, setGuestLoggedIn, setGuestModal, setUserAddressPopup, setUserSaveDeliveryAdds,  } = bindActionCreators(actionCreators, appDispatch)
  let history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { slug } = useParams("slug");
  const [prodArry, setProdArry] = useState([]);
  const [storeFound, setStoreFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const matches = useMediaQuery("(min-width:1200px)");
  const [winMatches, setWinMatches] = useState(
    window.matchMedia("(min-width: 760px)").winMatches
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [oldInvoice, setOldInvoice] = useState(null);
  const gaEventTracker = useGaTracker("Contact us");
  const [invoiceId, setInvoiceId] = useState("");
  const [openOrders, setOpenOrders] = useState([]);
  const [loyaltyIsLoading, setLoyaltyIsLoading] = useState(false);
  const [initalMount, setInitalMount] = useState(true);
  const [shopLangDefault, setShopLangDefault] = useState(
    JSON.parse(
      localStorage.getItem(`${appState.storeInfo.slug}_shopLangDefault`)
    ) !== null
      ? JSON.parse(
          localStorage.getItem(`${appState.storeInfo.slug}_shopLangDefault`)
        )
      : false
  );
  const [messages, setMessages] = useState([]);

  const handleGroupBill = () => {
    let gbID =
      searchParams.get("gb_id") != null ? searchParams.get("gb_id") : null;
    let localGbId = localStorage.getItem(`${slug}_gbId`);
    if (gbID !== null) {
      appDispatch(getGroupBill(appState, gbID));
    } else if (localGbId) {
      appDispatch(getGroupBill(appState, localGbId));
    } else {
      appDispatch(closeGroupBill(slug));
    }
  };

  const handleRemoveAuthCookie = () => {
    if (getCookie("Profile")) {
      appDispatch({
        type: "SET_LOGIN",
        loggedIn: true,
        loginToken: getCookie("tokenAuth"),
      });
      appDispatch({
        type: "SET_USERINFO",
        userInfo: JSON.parse(getCookie("Profile")),
      });
    } else {
      erase("tokenAuth");
      erase("Profile");
      erase("userInfo");
      appDispatch({
        type: "SET_LOGOUT",
        loggedIn: false,
      });
    }
  };

  // set the local cart with redux cart
  useEffect(() => {
    if (slug == appState.storeInfo.slug) {
      let cart_name = appState.deliveryMode
        ? `delivery`
        : appState.hotelQrCode > 0
        ? `hotel`
        : appState.qrPickup > 0
        ? `pickUp`
        : appState.qrTable > 0
        ? `dineIn`
        : `menu`;

      if (appState.cart.length > 0) {
        let total = 0;
        let filterCart = initalMount
          ? appState.cart.filter((item) => item?.orderType == cart_name)
          : appState.cart;

        filterCart.forEach((element) => {
          total += element.price * element.quantity;
          if (element.sum_addons != undefined) {
            total += element.sum_addons * element.quantity;
          }
        });
        var form =
          JSON.parse(localStorage.getItem(`${slug}_${cart_name}_form`)) !== null
            ? JSON.parse(localStorage.getItem(`${slug}_${cart_name}_form`))
            : [];
        appDispatch({
          type: "SET_FORM_DATA",
          formData: form,
        });

        total = roundFixed(
          Number(total),
          appState.storeInfo.roundLevel,
          appState.storeInfo.roundMethod
        );
        appDispatch({ type: "updateSubTotal", subTotal: total, total: total });

        localStorage.setItem(
          `${slug}_${cart_name}_cart`,
          JSON.stringify(filterCart)
        );
      } else {
        localStorage.setItem(
          `${slug}_${cart_name}_cart`,
          JSON.stringify(appState.cart)
        );
      }
    }
  }, [appState.cart]);

  const checkLocalCart = (cart_name) => {
    var localCart =
      JSON.parse(localStorage.getItem(`${slug}_${cart_name}_cart`)) !== null
        ? JSON.parse(localStorage.getItem(`${slug}_${cart_name}_cart`))
        : [];
    var form =
      JSON.parse(localStorage.getItem(`${slug}_${cart_name}_form`)) !== null
        ? JSON.parse(localStorage.getItem(`${slug}_${cart_name}_form`))
        : [];
    let oldCartName =
      appState.hotelQrCode > 0
        ? `hotel`
        : appState.qrPickup > 0
        ? `pickUp`
        : appState.qrTable > 0
        ? `dineIn`
        : `menu`;

    if (appState.storeInfo.slug != slug || cart_name != oldCartName) {
      appDispatch({
        type: "SET_FORM_DATA",
        formData: form,
      });
      appDispatch({
        type: "updateCart",
        cart: localCart,
      });
    } else {
      if (appState.cart?.length > 0) {
        let filteredCart = localCart.filter(
          (item) => item?.orderType == cart_name
        );
        if (filteredCart) {
          appDispatch({
            type: "updateCart",
            cart: filteredCart,
          });
        }
      }
      if (appState.formData?.length > 0) {
        let filteredForm = form.filter((item) => item?.orderType == cart_name);
        if (filteredForm) {
          appDispatch({
            type: "SET_FORM_DATA",
            cart: filteredForm,
          });
        }
      }
    }
  };

  useEffect(() => {
    console.log(`appstate.loginSkip`, appState.loginSkip);
  }, [appState.loginSkip]);
  useEffect(() => {
    console.log(`appstate.qrPickup`, appState.qrPickup);
  }, [appState.qrPickup]);

  const getQr = async (delivery) => {
    console.log(delivery)
    if (searchParams.get("table") || searchParams.get("branch")) {
      let table =
        !delivery && searchParams.get("table") != null
          ? searchParams.get("table")
          : null;
      let branch =
        searchParams.get("branch") != null ? searchParams.get("branch") : null;
      let hotelQr =
        !delivery &&
        searchParams.get("hotelQrCode") != null &&
        searchParams.get("hotelQrCode") > 0
          ? searchParams.get("hotelQrCode")
          : null;
      let pickup =
        !delivery && searchParams.get("pickup") != null
          ? searchParams.get("pickup")
          : null;
      appDispatch({
        type: "SET_TABLE",
        qrTable: !delivery && table != null ? Number(table) : null,
      });
      appDispatch({
        type: "SET_BRANCH",
        qrBranch: branch != null ? Number(branch) : null,
      });
      appDispatch({
        type: "setHotelMode",
        hotelQrCode: hotelQr != null && !delivery ? Number(hotelQr) : null,
        hotelMode: hotelQr != null && !delivery ? true : false,
      });
      appDispatch({
        type: "setQrPickup",
        qrPickup: !delivery && pickup ? Number(pickup) : null,
      });
      // appDispatch({
      //   type: "setPickupMode",
      //   pickupMode: !delivery && pickup ? true : false,
      // });
      appDispatch({
        type: "setDeliveryMode",
        deliveryMode: delivery ? true : false,
      });
      appDispatch(checkOpenBill(appState));
    }
  };

  useEffect(() => {
    if (appState.deviceId) {
      getNotification(appState.deviceId, appState, appDispatch);
    }
  }, [appState.deviceId]);
  
  useEffect(() => {
    if(appState.qrBranch){
      let orderInvoice = appState.openBill ? "open_bill" : appState.qrPickup ? "pickup" : "dine_in"
      console.log(orderInvoice,'orderInvoice');
      localStorage.setItem(
      `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
      JSON.stringify({ ...appState.formData, order_invoice:orderInvoice })
    );
    appDispatch({
      type: "SET_FORM_DATA",
      formData: appState.formData,
      order_invoice: orderInvoice ,
    });}
    }, [appState.qrBranch]);
    const closeDeliveryOptions = (delivery, branch) => {
        if(delivery, branch){
          appDispatch({
            type: "setInvoiceTaxesChargesExtras",
            invoiceTaxesChargesExtras: [],
          });
        }else{
          setDeliveryAddsInfo(null);
          setUserAddressPopup(false);
          setGuestLoggedIn(false);
          setDeliveryDateTimePopup(false);
          setGuestModal(false)
          setUserSaveDeliveryAdds(false)
          appDispatch({
            type: "setDeliveryAddsPopup",
            deliveryAddsPopup: false,
          });
          appDispatch({
            type: "setCountryCityArea",
            countryCityArea: null,
          });
        }
    }
    
    
  const getStore = async (data) => {
   // Extract values from data and searchParams
let table = data?.tableId || (searchParams.get("table") && !searchParams.has("delivery") ? searchParams.get("table") : "");
let pickup = searchParams.get("pickup") || null;
let delivery = searchParams.has("delivery") ? true : false;
let branch = data?.branchId || searchParams.get("branch") || "";
let hotelQr = searchParams.get("hotelQrCode") || null;
let localGbId = localStorage.getItem(`${slug}_gbId`);
let gbID = searchParams.get("gb_id") || null; // Ensure gbID is defined


let menu = false;
let bannerStatus = true;

// Dispatch device ID and freeze off
appDispatch(getDeviceId(appState));
appDispatch(freezOff());

// Handle QR branch change
if (appState.qrBranch !== branch) {
  appDispatch({ type: "setLoyaltyObj", loyaltyObj: [] });
}

// Handle store slug mismatch
if (appState.storeInfo.slug && slug !== appState.storeInfo.slug) {
  cleanLimitedState();
  appDispatch({ type: "SET_TABLE", qrTable: table });
  appDispatch({ type: "SET_BRANCH", qrBranch: branch });
  appDispatch({ type: "setQrPickup", qrPickup: pickup });
  appDispatch({ type: "setHotelMode", hotelQrCode: hotelQr, hotelMode: !!hotelQr });
  appDispatch({ type: "setDeliveryMode", deliveryMode: !table && !pickup && !hotelQr && delivery });
} else {
  // Handle fallback for table, branch, hotelQr, pickup, and delivery
  table = table || (appState.qrTable && !pickup && !hotelQr && !delivery ? appState.qrTable : "");
  branch = branch || appState.qrBranch || "";
  hotelQr = hotelQr || (!table && !pickup && !delivery && appState.hotelQrCode ? appState.hotelQrCode : null);
  pickup = pickup || (!table && !hotelQr && !delivery && appState.qrPickup ? appState.qrPickup : null);
  delivery = delivery || (appState.deliveryMode && !table && !pickup && !hotelQr && !branch);

  console.log({ delivery, branch });
}

    if (delivery && branch) {
      console.log(`branchz`, `delivery`);
      handleGetDeliveryCharges(appState, appDispatch, branch);
      let res = await handleGetCountry(appState, appDispatch);
      closeDeliveryOptions(delivery, branch);
    }
    checkLocalCart(
      delivery
        ? `delivery`
        : hotelQr > 0
        ? `hotel`
        : pickup > 0
        ? `pickUp`
        : table > 0
        ? `dineIn`
        : `menu`
    );

// Set query URL based on conditions
let querryUrl = delivery
  ? `?table&branch=${branch}` // For delivery, no specific table but branch
  : hotelQr != null
  ? `?hotel_qrcode=${hotelQr}&branch=${branch}` // For hotel QR code
  : pickup != null
  ? `?pickup=${pickup}&branch=${branch}` // For pickup
  : table != null
  ? `?table=${table}&branch=${branch}` // For table
  : `?table&branch=${branch}`; // Fallback, just branch and empty table

// Dispatch setOrderQrCode based on delivery or query URL
appDispatch({
  type: "setOrderQrCode",
  orderQrCode: delivery ? `?delivery&branch=${branch}` : querryUrl,
});

    // querryUrl = delivery
    //   ? `?table&branch=${branch}`
    //   : hotelQr != null
    //   ? `?hotel_qrcode=${hotelQr}&branch=${branch}`
    //   : pickup != null
    //   ? `?pickup=${pickup}&branch=${branch}`
    //   :table != null
    //   ? `?table=${table}&branch=${branch}` : `?table&branch=${branch}`;
    // appDispatch({
    //   type: "setOrderQrCode",
    //   orderQrCode: delivery ? `?delivery&branch=${branch}` : querryUrl,
    // });
    getQr(delivery);

    if (gbID != null && gbID != "null") {
      let { groupTable, groupBranch } = await appDispatch(
        getGroupBill(appState, slug, gbID)
      );
      table = groupTable;
      branch = groupBranch;
    } else if (localGbId != null && localGbId != "null") {
      let { groupTable, groupBranch } = await appDispatch(
        getGroupBill(appState, slug, localGbId)
      );
      table = groupTable;
      branch = groupBranch;
    } else {
      appDispatch(closeGroupBill(slug));
    }

    let response = null;

    try {
      response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${slug}${querryUrl}`,
        {},
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );
      // menu = process.env.REACT_APP_API_MENU_URL ==  process.env.REACT_APP_API_URL ?? false
      appDispatch(
        handleIsAddToCartEnable(hotelQr, pickup, table, branch, delivery)
      );
    } catch (e) {
      "New Api store not found", e;
      if (e.code == "ERR_NETWORK") {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      } else if (e.response != undefined) {
        appDispatch(handleError(e));
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      if (e.code == "ERR_NETWORK") {
        setErrorMsg(e?.message);
      } else {
        setErrorMsg(e?.response?.data?.message);
      }

      // if (e.response.status != 200) {
      //   setErrorMsg(e.response.data.message);
      // }
      setStoreFound(false);
      setIsLoading(false);
    }

    if (response) {
      var allData = await response.data.data.store;
      var invoice = await response.data.data.split_invoice_id;
      var payTable = await response.data.data.pay_table_id;
      var getOpenBillId = await response.data.data.open_bill_id;
      var hotel_qrcode_obj =
        (await response.data.data.hotel_qrcode) != undefined
          ? response.data.data.hotel_qrcode
          : null;
      var tableObj =
        (await response.data.data.table) != undefined
          ? response.data.data.table
          : null;
      var branchObj =
        (await response.data.data.branch) != undefined
          ? response.data.data.branch
          : null;
      var pickupObj =
        (await response.data.data.pickup) != undefined
          ? response.data.data.pickup
          : null;

      let checkScanQrCode = Boolean(
        searchParams.get("table") ||
          searchParams.get("branch") ||
          searchParams.get("hotelQrCode") ||
          searchParams.get("gb_id") ||
          searchParams.get("share-bill")
      );
      appDispatch({
        type: "setOldInvoiceId",
        oldInvoice: invoice,
      });
      appDispatch({
        type: "setTableInvoice",
        tableInvoice: payTable,
      });
      appDispatch({
        type: "setOpenBillId",
        openBillId: getOpenBillId,
      });
      // if (adBlockDetected)
      if (adBlockDetected) {
        bannerStatus =
          Array.isArray(allData.banners) && allData.banners.length != 0;
      }
      const store_layout = 1;
      if (branchObj?.loyalty_card) {
        appDispatch(pushShopMode(appState, "Loyalty-cards"));
        getLoyaltyCards(appState, appDispatch, branch);
      } else {
        appDispatch(popShopMode(appState, "Loyalty-cards"));
        appDispatch({
          type: "setLoyaltyObj",
          loyaltyObj: [],
        });
      }
      if (response.data.success === true) {
        let storeInfo = {
          businessName: allData?.business.name,
          storeId: allData?.id,
          slug: allData?.slug,
          logo: allData?.business.logo,
          cover: allData?.business.cover,
          storeMinimumOrder: allData?.business.minimum_order,
          workingHours: allData?.business_hours,
          primaryColor: allData?.business.color.primary,
          secondaryColor: allData?.business.color.secondary,
          progressive: allData?.progressive,
          currencySymbol: allData?.business.currency == "SAR" ? "SAR" : "KWD",
          countryNameCode: allData?.business?.country,
          storeCurrency:
            allData?.business.currency == "SAR"
              ? { en: "SAR", ar: "ريال" }
              : { en: "KWD", ar: "دينار" },
          numberFixed: 3,
          storeBusyTime: allData?.busy_till,
          countries: allData?.countries,
          tapKey: branchObj?.keys?.tap,
          tap: branchObj?.tap,
          otp: allData?.otp,
          live: allData?.live,
          banners: allData?.banners,
          storeCategory: allData?.store_category,
          allowLogin:
            hotelQr != null
              ? checkHotelLoginRequired(JSON.parse(getCookie("hotelInfo")))
              : branchObj?.allow_login != null
              ? branchObj?.allow_login.id
              : 2,
          table: tableObj,
          branch: branchObj,
          pickup: pickupObj,
          pos: allData?.pos,
          iframeUrl: allData?.iframe_url,
          roundLevel:
            allData?.rounding != undefined
              ? allData?.rounding.level != undefined
                ? allData?.rounding.level
                : 0.001
              : 0.001,
          roundMethod:
            allData?.rounding != undefined
              ? allData?.rounding.method != undefined
                ? allData?.rounding.method
                : "average"
              : "average",
          subCategory: allData?.multiple_category,
          taxPricing: allData?.business?.tax_pricing,
          categoryProductImage:
            allData?.shop_image != undefined || null ? allData?.shop_image : true,
          meatSource:
            branchObj?.meat_source != null || undefined || ""
              ? branchObj?.meat_source
              : allData?.meat_source,
          buffetMode: allData?.id == 276 ? true : false, //276,
          checkoutShortCut: allData?.direct_checkout,
          showCalorie: allData?.show_calorie,
          defaultDeliveryCharges: branchObj?.delivery?.default_charges || 0,
          deliveryDays: Number(branchObj?.delivery?.max_days )|| 365,
          scheduledDelivery: !branchObj?.delivery?.scheduled || false,
        };

    // appDispatch(handleIsAddToCartEnable(hotelQr, pickup, table, branch, branchObj ));

        appDispatch(
          MainApi(
            storeInfo,
            allData,
            appState,
            payTable,
            hotelQr,
            pickup,
            hotel_qrcode_obj,
            checkScanQrCode,
            table,
            shopLangDefault,
            branchObj,
            delivery
          )
        );
        appDispatch({
          type: "setShowTagsAndDitesFilter",
          showTagsAndDitesFilter: allData.food_preference,
        });
        appDispatch({
          type: "setFilterProductMode",
          filterProductMode: false,
        });
        var arryParentCategory = [];
        var arryCategory = [];
        var arryProduct = [];
        // when new chunk
        if (payTable != null && !appState.pusherActive) {
          getLiveData(payTable, appState, appDispatch);
        }
        appDispatch(checkInvoice(appState, invoice, payTable, setOldInvoice));
        appDispatch(tagsAndDiets(allData.tags, Images));
        // Non-Progressive
        if (!allData.progressive) {
          for (var i = 0; i < allData.product_categories.length; i++) {
            var show = allData.product_categories[i]?.status;
            show =
              show == 1 || show == true || show == undefined ? true : false;
            if (branch !== null) {
              if (allData.product_categories[i].mealTypes.length > 0) {
                let mealTypes = allData.product_categories[i].mealTypes;
                show = await handleMealType(
                  mealTypes,
                  branch,
                  allData.product_categories[i].id
                );
              } else {
                show = true;
              }
            } else {
              show = true;
            }

            if (show) {
              if (allData.product_categories[i]) {
                var value = allData.product_categories[i];
                if (storeInfo.subCategory) {
                  if (value?.children?.length > 0) {
                    value?.children.forEach(async (child) => {
                      var childShow = child?.status;
                      childShow =
                        childShow == 1 ||
                        childShow == true ||
                        childShow == undefined
                          ? true
                          : false;
                      if (child.id === 3624) {
                        console.log(child);
                        console.log(child.has_products, childShow);
                      }

                      if (childShow) {
                        if (child?.mealTypes?.length > 0) {
                          let mealTypes = child.mealTypes;
                          childShow = await handleMealType(mealTypes, branch);
                        } else {
                          childShow = true;
                        }
                      }

                      if (child.has_products && childShow) {
                        let findCate = arryParentCategory.some(
                          (item) => item.id == child?.parent?.id
                        );
                        if (!findCate) {
                          let catData = {
                            ...value,
                            childLink: `/sub-category/${value.slug}?parent=${value.id}`,
                            urlLink: `/category/${child.slug}?parent=${value.id}&catid=${child.id}`,
                          };
                          arryParentCategory.push(catData);
                        }
                        let customeData = {
                          ...child,
                          parentId: child?.parent?.id,
                          childLink: `/sub-category/${value.slug}?parent=${value.id}`,
                          urlLink: `/category/${child.slug}?parent=${value.id}&catid=${child.id}`,
                        };
                        arryCategory.push(customeData);
                        //     console.log(arryParentCategory)
                        //     console.log(arryCategory)
                      }
                    });
                  }
                } else {
                  if (hotel_qrcode_obj != null) {
                    let hotelCategory =
                      hotel_qrcode_obj?.section?.inactive_categories;
                    show = !hotelCategory.some((cat) => cat.id == value.id);
                  }
                  if (value.has_products && show) {
                    let catData = {
                      ...value,
                      urlLink: `/category/${value.slug}?catid=${value.id}`,
                    };
                    arryCategory.push(catData);
                  }
                }
              }
            }
          }
          appDispatch({
            type: "SET_PARENT_CATEGORIES",
            parentCategories: arryParentCategory,
          });
          appDispatch({
            type: "SET_CATEGORIES",
            categories: arryCategory,
          });
          appDispatch({
            type: "SET_PRODUCTS",
            products: arryProduct,
          });
          appDispatch({
            type: "SET_FOR_PRODUCT_SEARCH",
            productsForSearch: arryProduct,
          });
        }
        // progressive
        else {
          appDispatch(handleCheckCreatedAt(appState));
          for (var i = 0; i < allData.product_categories.length; i++) {
            var show = allData.product_categories[i]?.status;
            show =
              show == 1 || show == true || show == undefined ? true : false;

            if (branch && show) {
              if (allData.product_categories[i].mealTypes.length > 0) {
                
                let mealTypes = allData?.product_categories[i]?.mealTypes;
                show = handleMealType(mealTypes, branch);
              } else {
                show = true;
              }
            } else {
              show = true;
            }
            let prodShow1 = false
            // if(allData?.product_categories[i].id == 3656){
              // console.log(show, allData?.product_categories[i]);
              if(allData?.product_categories[i]?.products.length>0 && show && !storeInfo.subCategory){
                let  getProd = allData?.product_categories[i]
                for (var j = 0; j < getProd.products.length; j++) {
                  let prod = getProd.products[j];
                  if (prod != [] && !prodShow1) {
                    let inActive = prod?.inactive_branches ?? [];
                    prodShow1 =
                    inActive.length > 0
                    ? !inActive.some((item) => item == branch)
                  : true;  
                  // console.log(prodShow1, prod, branch);
                }
                
            }
          }
          // }  
          
          show = !storeInfo.subCategory? prodShow1 : true;
          console.log(show);
          
          if (show) {
            if (allData.product_categories[i]) {
              var value = allData.product_categories[i];
              if (storeInfo.subCategory) {
                if (value?.children.length > 0) {
                  value.children.forEach(async (child) => {
                    if(child.id === 3624){
                      // console.log(child);
                    }
                    var childShow = child?.status;
                    childShow = (childShow == 1 || childShow == true || childShow == undefined) ? true : false;
                    
                    if (childShow) {
                      if (child.mealTypes.length > 0) {
                        let mealTypes = child.mealTypes;
                        childShow = await handleMealType(mealTypes, branch);
                      } else {
                        childShow = true;
                      }
                    }
                    
                    if (child.products.length > 0 && childShow) {
                      let findCate = arryParentCategory.some(
                        (item) => item.id == child?.parent?.id
                      );
                      if (!findCate) {
                        let catData = {
                          ...value,
                          childLink: `/sub-category/${value.slug}?parent=${child?.parent?.id}`,
                          urlLink: `/category/${child.slug}?parent=${child?.parent?.id}&catid=${child.id}`,
                        };
                          arryParentCategory.push(catData);
                        }
                        let customeData = {
                          ...child,
                          parentId: child?.parent?.id,
                          childLink: `/sub-category/${value.slug}?parent=${child?.parent?.id}`,
                          urlLink: `/category/${child.slug}?parent=${child?.parent?.id}&catid=${child.id}`,
                        };
                        arryCategory.push(customeData);
                        for (var j = 0; j < child.products.length; j++) {
                          let prod = child.products[j];
                          if (prod != []) {
                            setProdArry([...prodArry, prod]);
                            arryProduct.push(prod);
                          }
                        }
                      }
                    });
                  }
                } else {
                  if (hotel_qrcode_obj != null) {
                    let hotelCategory =
                    hotel_qrcode_obj?.section?.inactive_categories;
                    show = !hotelCategory.some((cat) => cat.id == value.id);
                  }
                  if (value?.products.length > 0 && show) {
                    let catData = {
                      ...value,
                      urlLink: `/category/${value.slug}`,
                    };
                    arryCategory.push(catData);
                  }
                  for (var j = 0; j < value.products.length; j++) {
                    let prod = value.products[j];
                    if (prod != []) {
                      let mealTypes = prod.mealTypes;
                      let inActive = prod?.inactive_branches ?? [];
                      
                      let prodShow =
                      inActive.length > 0
                      ? !inActive.some((item) => item == branch)
                      : true;
                      
                      if (mealTypes?.length > 0 && branch) {
                        prodShow = handleMealType(mealTypes, branch);
                      }
                      if (prodShow) {
                        setProdArry([...prodArry, prod]);
                        arryProduct.push(prod);
                      }
                    }
                  }
                }
              }
            }
          }
          appDispatch({
            type: "SET_PARENT_CATEGORIES",
            parentCategories: arryParentCategory,
          });
          appDispatch({
            type: "SET_CATEGORIES",
            categories: arryCategory,
          });
          appDispatch({
            type: "SET_PRODUCTS",
            products: arryProduct,
          });
          appDispatch({
            type: "SET_FOR_PRODUCT_SEARCH",
            productsForSearch: arryProduct,
          });
        }

        if (storeInfo.progressive == 1) {
          appDispatch({
            type: "setProductsLength",
            productsLength: arryProduct.length,
          });
        } else {
          appDispatch({
            type: "setProductsLength",
            productsLength:
              allData.extra != undefined
                ? allData.extra.product_length
                  ? allData.extra.product_length
                  : 0
                : 0,
          });
        }

        const favIcon = document.getElementById("favicon");
        favIcon.href = `${allData.business.logo}`;
        if (allData.business.color.primary) {
          document.documentElement.style.setProperty(
            "--th-primary",
            allData.business.color.primary
          );
        }
        if (allData.business.color.secondary) {
          document.documentElement.style.setProperty(
            "--th-secondary",
            allData.business.color.secondary
          );
        }
        if (
          appState.qrTable != null &&
          !appState.payTableMode &&
          appState.qrBranch &&
          appState.openBill &&
          !appState.shopMode.includes("share-bill")
        ) {
          // console.log(  appState.qrTable != null ,
          //   !appState.payTableMode ,
          //   appState.qrBranch ,
          //   appState.openBill ,
          //   !appState.shopMode.includes("share-bill"));
          appDispatch(getDineInOrders(appState, true, setInvoiceId));
        }
        if (
          bannerStatus &&
          storeInfo.banners != undefined &&
          storeInfo.banners.length > 0
        ) {
          appDispatch(handlePromotionBanner(appState));
        } else {
          appDispatch({ type: "setPromotionBanner", promotionBanner: false });
        }
        await appDispatch(handleSourceOfMeetBanner(storeInfo));
        setIsLoading(false);
      } else {
        // appDispatch(StoreNotFound());
        setStoreFound(false);
        setIsLoading(false);
      }
      // document.body.style.overflow = "visible";
      // console.log("scrolling start default");
    }
  };

  useEffect(() => {
    appDispatch({
      type: "setQrOptionStatus",
      qrOptionStatus: false,
    });
  }, [appState.qrOptionStatus]);

  const handleShareBillFlow = async () => {
    let decodeId = null;
    try {
      // Your code that may throw the error here
      const encryptCode = searchParams.get("share-bill") || null;
      const decodedQrCode = decodeURIComponent(`${encryptCode}`);
      const formattedQrCode = decodedQrCode.replace(/ /g, "+");
      const decryptedBytes = CryptoJS.AES.decrypt(
        formattedQrCode,
        "hsDatawLRTS6RmGM"
      );
      decodeId = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      if (error.message.includes("Malformed UTF-8 data")) {
        // Handle the error, e.g., log it or skip the problematic data
        console.error("Malformed UTF-8 data encountered:", error);
      } else {
        // Handle other types of errors
        console.error("Other error:", error);
      }
    }

    const shareBill = decodeId || appState.shareBill?.invoiceId || null;
    let result = null;
    if (shareBill) {
      result = await getOrderInvoice(shareBill, appDispatch, appState);
      getStore(result);
    } else {
      getStore(null);
    }
  };

  useEffect(() => {
    // handle screen match
    const handler = (e) => setWinMatches(e.matches);
    window.matchMedia("(min-width: 760px)").addListener(handler);
    // change url name shop with app
    ReactGA.pageview(window.location.pathname + window.location.search);
    let host = window.location.host;
    if (host.split(".").length != 1) {
      const currentUrl = window.location.href;
      const currentHost = window.location.host;
      if (currentHost == process.env.REACT_APP_SHOP_DOMAIN) {
        const newUrl = currentUrl.replace(
          window.location.host,
          `${process.env.REACT_APP_APP_DOMAIN}`
        );
        window.location.href = newUrl;
      }
    }

    // handle version system
    const Version = "6.0.0.9";
    const currentUrl = window.location.host;

    appDispatch({
      type: "setStoreDomain",
      storeDomain: window.location.hostname,
    });
    const cookieUrl = getCookie(`${currentUrl}_version`)
      ? getCookie(`${currentUrl}_version`)
      : false;
    const localUrl = localStorage.getItem(`${currentUrl}_version`)
      ? localStorage.getItem(`${currentUrl}_version`)
      : false;
    if (cookieUrl || localUrl) {
      if (Version == cookieUrl || Version == localUrl) {
        handleShareBillFlow();
      } else {
        appDispatch(cleanCache(appState));
        appDispatch(resetSplitOptions());
        setCookie(`${currentUrl}_version`, Version);
        localStorage.setItem(`${currentUrl}_version`, Version);
        appDispatch({
          type: "RESET",
        });
        handleShareBillFlow();
      }
    } else {
      appDispatch({
        type: "RESET",
      });
      setCookie(`${currentUrl}_version`, Version);
      localStorage.setItem(`${currentUrl}_version`, Version);
      handleShareBillFlow();
    }

    // reset some redux states
    appDispatch(resetSplitOptions());
    appDispatch({
      type: "setFilterProductMode",
      filterProductMode: false,
    });
    appDispatch({
      type: "setProductTags",
      productTags: [],
    });
    appDispatch({
      type: "setProductDietaries",
      productDietaries: [],
    });
    appDispatch({
      type: "setConfirmPopup",
      confirmPopup: false,
    });
    handleRemoveAuthCookie();
  }, []);

  // useEffect(() => {
  //   console.log(appState.shopMode);
  // }, [appState.shopMode]);

  const onLanguageHandle = () => {
    i18n.changeLanguage(appState.langValue);
    localStorage.setItem("Language", JSON.stringify(appState.langValue));
  };

  useEffect(() => {
    onLanguageHandle();
    document.documentElement.lang = appState.langValue;
  }, [appState.langValue]);

  const compareDay = async () => {
    var storeTime = "";
    storeTime = new Date(appState.storeInfo.storeBusyTime).getTime();

    if (storeTime == 0) {
      appDispatch({ type: "SET_StoreBusy", storeBusy: true });
    } else {
      var currentTime = new Date().getTime();

      var IsOpen = currentTime - storeTime;
      if (IsOpen < 0) {
        appDispatch({ type: "SET_StoreBusy", storeBusy: true });
      }
    }
  };

  useEffect(() => {
    appDispatch({ type: "SET_StoreBusy", storeBusy: false });

    if (appState.storeBusy != true) {
      compareDay();
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      appState.products &&
      appState.products.length > 0 &&
      appState.products != undefined
    ) {
      if (getCookie("Re-Order")) {
        appDispatch(freezOn());

        let invoiceId = getCookie("Re-Order");
        let orderResponse = orderAgain(appState, invoiceId);

        if (orderResponse.length > 0) {
          appDispatch({
            type: "updateCart",
            cart: orderResponse,
          });
          localStorage.setItem(
            `${appState.storeInfo.slug}_${getServiceName(appState)}_cart`,
            JSON.stringify(orderResponse)
          );
        } else {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: t("custom-msg.product-notfound"),
          });
        }
        appDispatch(freezOff());
        erase("Re-Order");
      }
    }
  }, [appState.products]);

  const handleCloseAll = () => {
    // appDispatch({ type: "setOtpModal", otpModal: false });
    appDispatch({ type: "setLoginModal", loginModal: false });
    appDispatch({
      type: "setFilterProductMode",
      filterProductMode: false,
    });
    appDispatch({ type: "setLoginModal_Screen", loginModalScreen: false });
    appDispatch({
      type: "setShareBillPopup",
      shareBillPopup: false,
      shareBillUrl: appState.shareBillUrl,
    });
  };

  useEffect(() => {
    console.log(
      "appState.loginModal",
      appState.loginModal,
      "appState.otpModal",
      appState.otpModal,
      "appState.qrOptionStatus",
      appState.qrOptionStatus,
      "appState.splitBill",
      appState.splitBill,
      "appState.paymentPopUp",
      appState.paymentPopUp,
      "appState.shareBillPopup",
      appState.shareBillPopup,
      "appState.loyalityPopupMode",
      appState.loyalityPopupMode,
      "appState.selectLoyaltyCardPopup",
      appState.selectLoyaltyCardPopup,
      "appState.optionalRewardsPopUp",
      appState.optionalRewardsPopUp,
      "appState.featuredProductPopupMode",
      appState.featuredProductPopupMode,
      "appState.payTablePopUp",
      appState.payTablePopUp,
      "appState.guestModalShow",
      appState.guestModalShow,
      "appState.userAddressPopup",
      appState.userAddressPopup,
      "appState.deliveryAddsPopup",
      appState.deliveryAddsPopup
    );
    if (
      appState.loginModal ||
      appState.otpModal ||
      appState.qrOptionStatus ||
      appState.splitBill ||
      appState.paymentPopUp ||
      appState.shareBillPopup ||
      appState.loyalityPopupMode ||
      appState.selectLoyaltyCardPopup ||
      appState.optionalRewardsPopUp ||
      appState.featuredProductPopupMode ||
      appState.payTablePopUp ||
      appState.guestModalShow ||
      appState.userAddressPopup ||
      appState.deliveryAddsPopup
    ) {
      document.body.style.overflow = "hidden";
      console.log("scrolling off");
    } else {
      document.body.style.overflow = "visible";
      console.log("scrolling start");
    }
  }, [
    appState.loginModal,
    appState.otpModal,
    appState.qrOptionStatus,
    appState.splitBill,
    appState.paymentPopUp,
    appState.shareBillPopup,
    appState.loyalityPopupMode,
    appState.selectLoyaltyCardPopup,
    appState.optionalRewardsPopUp,
    appState.featuredProductPopupMode,
    appState.payTablePopUp,
    appState.guestModalShow,
    appState.userAddressPopup,
    appState.deliveryAddsPopup,
  ]);

  // console.log(appState.orderProdQty);
  const [basename, setBasename] = useState(slug);

  useEffect(() => {
    const updateManifest = async () => {
      const manifest = document.querySelector('link[rel="manifest"]');
      const appleTouchIcon = document.querySelector(
        "link[rel='apple-touch-icon']"
      );
      const metaTag = document.createElement("meta");
      const manifestUrl = manifest.getAttribute("href");

      if (appleTouchIcon) {
        appleTouchIcon.href = `${
          appState.storeInfo?.logo ||
          "https://waiter.enabill.dev/static/media/enabillJan.4d0a758b184e3ac40bb9aef3bee391e6.svg"
        }`;
      }

      if (metaTag && manifest) {
        const updatedAppName = `${
          appState.storeInfo?.businessName?.en || "Enabill"
        } (${appState.storeInfo?.branch?.name?.en || "Enabill"})`;
        metaTag.name = "apple-mobile-web-app-title";
        metaTag.content = updatedAppName;
        document.head.appendChild(metaTag);
      }

      if (manifestUrl) {
        try {
          const response = await fetch(manifestUrl);
          const data = await response.json();
          // console.log(response);
          const updatedData = {
            ...data,
            start_url: `${process.env.REACT_APP_APP_DOMAIN}/${basename}?branch=${appState.qrBranch}`,
            icons: [
              {
                src: `${
                  appState.storeInfo?.logo ||
                  "https://waiter.enabill.dev/static/media/enabillJan.4d0a758b184e3ac40bb9aef3bee391e6.svg"
                }`,
                sizes: "64x64 32x32 24x24 16x16",
                type: "image/x-icon",
              },
            ],
            short_name: `${
              appState.storeInfo?.businessName?.en || "Enabill"
            } (${appState.storeInfo?.branch?.name?.en || "Enabill"})`,
            name: `${appState.storeInfo?.businessName?.en || "Enabill"} (${
              appState.storeInfo?.branch?.name?.en || "Enabill"
            })`,
          };

          const blob = new Blob([JSON.stringify(updatedData)], {
            type: "application/json",
          });
          const manifestBlobUrl = URL.createObjectURL(blob);
          manifest.setAttribute("href", manifestBlobUrl);
        } catch (error) {
          console.error("Error updating manifest start_url:", error);
        }
      }
    };

    // updateManifest();
  }, [basename, slug, appState.storeInfo]);

  useEffect(() => {
    if (appState.payTableMode) {
      getTableOrderId(appState.qrTable, appState, appDispatch, history);
    }
  }, [appState.payTableMode]);

  useEffect(() => {
    if (appState.qrBranch && appState.shopMode.includes("Loyalty-cards")) {
      let result = getLoyaltyCards(appState, appDispatch);
      setLoyaltyIsLoading(true);
    } else {
      appDispatch({
        type: "setLoyaltyObj",
        loyaltyObj: [],
      });
      setLoyaltyIsLoading(true);
    }
  }, [appState.loggedIn, appState.qrBranch]);


  useEffect(() => {
   console.log("appState.loggedIn",appState.loggedIn,appState)
  }, [appState.loggedIn])
  

  useEffect(() => {
    if (appState?.storeInfo) {
      appDispatch(getClosePayments(appState));
    }
  }, [appState?.storeInfo]);

  useEffect(() => {
    if (appState?.storeInfo && appState?.serviceMode?.length > 0) {
      appDispatch(handlepaymentMethods(appState));
    } else {
      appDispatch({
        type: "Set_Accepted_Payments",
        acceptedPayments: appState.acceptedPayments,
      });
    }
  }, [appState?.storeInfo, appState?.closePayments, appState?.serviceMode]);
    useEffect(() => {
    console.log(
      appState.deliveryMode,
      "deliveryMode",
      appState.deliveryAddsPopup,
      !appState.deliveryAddsInfo?.city_name
    );
    if (appState.deliveryMode && !appState.deliveryAddsInfo?.city_name) {
      appDispatch({
        type: "setDeliveryAddsPopup",
        deliveryAddsPopup: true,
      });
    } else {
      appDispatch({
        type: "setDeliveryAddsPopup",
        deliveryAddsPopup: false,
      });
    }
  }, [appState.deliveryMode, appState.countryCityArea]);
  useEffect(() => {
    console.log(appState.guestLoggedIn);
  }, [appState.guestLoggedIn]);

  if (isLoading)
    return (
      <div>
        <EnabillLoadingPage />
      </div>
    );

  return (
    <>
      {/* <Notifications position="bottom-right" /> */}
      {!storeFound ? (
        <BrowserRouter basename={`/${slug}`}>
          <Route exact path="/">
            <NotFound storeName={slug} errorMessage={errorMsg} />
          </Route>
        </BrowserRouter>
      ) : (
        <div
          className={`layoutOne ${
            appState.langValue === "ar" ? "directionRtl_family" : ""
          }`}
          style={
            appState.langValue === "ar"
              ? { direction: "rtl" }
              : { direction: "ltr" }
          }
        >
          {appState.storeBusy}
          {appState.isClosed == 0 ? (
            <BrowserRouter basename={`/${slug}`}>
              {appState.alert.show ? (
                <Alert
                  type={appState.alert.type}
                  show={appState.alert.show}
                  message={appState.alert.message}
                />
              ) : (
                ""
              )}

              <div
                className={`row m-0 ${
                  appState.langValue === "ar" ? "directionRtl_family" : ""
                }`}
              >
                {/* Freez Screen */}

                {appState.freezScreen && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 9100,
                    }}
                    open={appState.freezScreen}
                  >
                    {/* <CircularIndeterminate /> */}
                  </Backdrop>
                )}

                <div
                  className={` offset-xl-3 mr-auto  col-xl-6  p-0 leftSide`}
                  style={{ background: "white" }}
                >
                  <Switch>
                    <Route exact path="/test">
                      <TestComponent />
                    </Route>
                    <Route exact path="/external">
                      {matches ? <NotFound /> : <IFrameUrl />}
                    </Route>
                    <Route exact path="/user">
                      <UserInfo />
                    </Route>
                    <Route exact path="/">
                      {appState.storeBusy == false ? (
                        <HomeOne oldInvoice={oldInvoice} />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/branch">
                      {appState.storeBusy == false ? <Branch /> : <StoreBusy />}
                    </Route>
                    <Route exact path="/category/:slug">
                      {appState.storeBusy == false ? (
                        <Products />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/sub-category/:slug">
                      {appState.storeBusy == false ? (
                        <SubCategory />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/:slug/:islug">
                      {appState.storeBusy == false ? (
                        <SingleProduct />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/contact">
                      {appState.storeBusy == false ? (
                        <Contact />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/detail">
                      {appState.storeBusy == false ? <Detail /> : <StoreBusy />}
                    </Route>

                    <Route exact path="/checkout">
                      {appState.storeBusy == false ? (
                        appState.deliveryMode ? (
                          <DeliveryCheckout oldInvoice={oldInvoice} />
                        ) : (
                          <CheckOutOne oldInvoice={oldInvoice} />
                        )
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>

                    <Route exact path="/search">
                      {appState.storeBusy == false ? <Search /> : <StoreBusy />}
                    </Route>
                    <Route exact path="/status">
                      {appState.storeBusy == false ? <Status /> : <StoreBusy />}
                    </Route>
                    <Route exact path="/address-form">
                      {appState.storeBusy == false ? (
                        <AddressFrom />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/map">
                      {appState.storeBusy == false ? (
                        <GoogleLocation />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/cart">
                      {appState.storeBusy == false ? (
                        <CartOne />
                      ) : (
                        <StoreBusy />
                      )}
                    </Route>
                    <Route exact path="/openorder">
                      {appState.storeBusy == false ? (
                        <OpenOrder />
                      ) : (
                        <StoreBusy openOrders={openOrders} />
                      )}
                    </Route>
                    <Route exact path="/circularindeterminate">
                      <CircularIndeterminate />
                    </Route>

                    <Route exact path="/openorder"></Route>
                    <Route exact path="*">
                      <Error404 />
                    </Route>
                  </Switch>
                  {appState.loginModal && <LoginModalv2Popup />}
                  {appState.loginModalScreen && <LoginModalv2Screen />}
                  {appState.otpModal && <VerifyPhoneNumberv2Popup />}
                  {appState.promotionBanner &&
                    appState.storeInfo.banners != [] && <PromotionBanner />}
                  {appState.sourceOfMeetBannerImage && <SourceOfMeetBanner />}
                  {appState.loyaltyBanner && <LoyaltyBanner />}
                  {appState.guestModalShow && <GuestModal />}
                  {appState.groupOrderPopup && appState.qrBranch && (
                    <GroupOrderPopup />
                  )}
                  {appState.shareBillPopup && <ShareInvoiceQrcode />}
                  {appState.filterProductMode && <FilterProduct />}
                  {appState.progressive == 1 && appState.loyalityPopupMode && (
                    <LoyalityPopup />
                  )}
                </div>
                {/* {matches ? (
                  <div
                    className={`${appState.langValue == "ar"
                        ? "col-lg-7 p-0 p-fix side wpc_side_AR"
                        : "col-lg-7 p-0 p-fix side wpc_side_EN"
                      } ${appState.qrOptionStatus ? "pointer-events-non" : ""}`}
                  >
                    <SideComponent />
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </BrowserRouter>
          ) : (
            <IsClosed />
          )}
          {(appState.loginModal ||
            appState.otpModal ||
            appState.filterProductMode ||
            appState.groupOrderPopup ||
            appState.loyalityPopupMode ||
            appState.loginModalScreen ||
            appState.shareBillPopup ||
            appState.featuredProductPopupMode ||
            appState.guestModalShow) && (
            <Backdrop
              onClick={handleCloseAll}
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 3,
              }}
              open={
                appState.loginModal ||
                appState.otpModal ||
                appState.filterProductMode ||
                appState.groupOrderPopup ||
                appState.loginModalScreen ||
                appState.shareBillPopup ||
                appState.loyalityPopupMode ||
                appState.featuredProductPopupMode ||
                appState.guestModalShow
              }
            />
          )}
        </div>
      )}
    </>
    // test
  );
}

export default withTranslation()(Main);
