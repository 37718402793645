/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import "./StoreOption.scss";
import { withTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { IconButton } from "@mui/material";
import { Edit } from "@material-ui/icons";
import { get_lang } from "../../../../global-functions/apiGlobal";


function StoreOption({ t, i18n, isLoading, table, pickup }) {
  let history = useHistory();
  const appState = useSelector((state) => state);
  let services = appState.serviceMode;
  const [loading, setLoading] = useState(true);
  const [serviceName, setServiceName] = useState([]);
  const appDispatch = useDispatch();

  useEffect(() => {
    const handleServicesName = () => {
      let arr = [];
      let flag = true;
      if (!appState.hotelQrCode) {
        services.forEach((ele) => {
          if (ele == "dine-in") {
            arr.push("Dine In");
            return;
          }
          if (flag) {
            if (ele == "open-bill" || ele == "group-bill") {
              arr.push("Open Bill");
              flag = false;
              return;
            }
          }
        });
      }

      if (appState.hotelQrCode || !arr.includes("Open Bill")) {
        arr.push("Close Bill");
      }
      if(appState.deliveryMode){
        arr.push('Delivery');
      }
      setServiceName(arr);

      setLoading(false);
    };
    handleServicesName();
  }, []);

  return (
    <>
      <section className="storeInfo  px-3" id="store-options">
        {/* Services */}

        <div className="row m-0 justify-content-between mt-2 servicesOptions">
          <div className="row m-0 align-items-center">
            {/* <AccessTimeIcon className="clockIcon" /> */}
            <i
              className="fa fa-credit-card-alt text-muted"
              aria-hidden="true"
            ></i>
            <p className="m-0 text-muted mx-2">{t("Service.payment")}</p>
          </div>
          <div className="row m-0">
            <h6 className="m-0  accpetedPay d-flex">
              {appState.acceptedPayments.length > 0 &&
                appState?.acceptedPayments.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item == "wallets" ? (
                        <span key={index}>
                          <img
                            className="img-pro"
                            width="25"
                            src={`/images/quickpay-small.png`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {item == "apple_pay" ? (
                        <span key={index}>
                          <img
                            className="img-pro"
                            width="55"
                            src={`/images/apple-pay.png`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {item == "knet" ? (
                        <span key={index}>
                          <img
                            className="img-pro"
                            width="35"
                            src={`/images/Knet.png`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {/* {item == "kfast" ? (
                    <span key={index}>
                      <img
                        className="img-pro"
                        width="25"
                        src={`/images/kfast.png`}
                        alt=""
                      />
                    </span>
                  ) : (
                    ""
                  )} */}
                      {item == "cashable" ? (
                        <span key={index}>
                          <img
                            className="img-pro"
                            width="30"
                            src={`/images/simpleCash.png`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {item == "card" ? (
                        <span key={index}>
                          <img
                            className="img-pro"
                            width="25"
                            src={`/images/visa-small.png`}
                            alt=""
                          />
                        </span>
                      ) : (
                        " "
                      )}
                    </React.Fragment>
                  );
                })}
            </h6>
          </div>
        </div>

        {appState.qrTable ? (
          isLoading ? (
            <div className="col-12 col-md-12 px-2">
              <Skeleton height={70} />
            </div>
          ) : (
            <>
              {table && (
                <div className="row m-0  mt-3 py-4  tableShow d-flex align-items-center  justify-content-between ">
                  {/* <AccessTimeIcon className="clockIcon" /> */}

                  <p className="m-0 tableNo">{t("Forms.tableNo")}</p>
                  <p className="m-0 px-3 tableNo">{table}</p>
                </div>
              )}
            </>
          )
        ) : (
          ""
        )}

        {appState.qrPickup ? (
          isLoading ? (
            <div className="col-12 col-md-12 px-2">
              <Skeleton height={70} />
            </div>
          ) : (
            <>
              {pickup && (
                <div className="row m-0  mt-3 py-4  tableShow d-flex align-items-center  justify-content-between ">
                  {/* <AccessTimeIcon className="clockIcon" /> */}

                  {/* <p className="m-0 tableNo">{t("Forms.pickup")}</p> */}
                  <p className="m-0 tableNo">Pickup</p>
                  <p className="m-0 px-3 tableNo">
                    {" "}
                    {get_lang(pickup, appState.langValue)}
                  </p>
                </div>
              )}
            </>
          )
        ) : (
          ""
        )}
        {appState.deliveryMode && appState.deliveryAddsInfo && (
          <div className="row m-0  mt-3 py-3  tableShow d-flex align-items-center  justify-content-between ">
            {/* <AccessTimeIcon className="clockIcon" /> */}

            {/* <p className="m-0 tableNo">{t("Forms.pickup")}</p> */}
            <p className="m-0 tableNo">
              {" "}
              <spa>
                <DeliveryDiningIcon />
              </spa>{" "}
              {t("Delivery")}
            </p>
            <p className="m-0  DeliveryTitle">
              {" "}
              <h1 className={`m-0 d-flex align-items-center  DeliveryTitle ${appState.langValue === "ar"  ? "text-right familyGraphikArabic" : "text-left familyPoppins300" }`} >
                <span>{appState.deliveryAddsInfo?.city_name  ? get_lang(appState.deliveryAddsInfo?.city_name, appState.langValue)  : `City`}
                </span>
                <span className={` px-1 ${ appState.langValue === "ar" ? "text-right familyGraphikArabic" : "text-left familyPoppins300" }`}
               >
                  {appState.deliveryAddsInfo?.area_name
                    ? ` >  ${get_lang(
                        appState.deliveryAddsInfo?.area_name,
                        appState.langValue
                      )}`
                    : ` >  Area`}{" "}
                </span>
                <span>
                <IconButton
                  onClick={() =>
                    appDispatch({
                      type: "setDeliveryAddsPopup",
                      deliveryAddsPopup: true,
                    })
                  }
                >
                  {" "}
                  <Edit />{" "}
                </IconButton>
              </span>
              </h1>
           
            </p>
          </div>
        )}


        {/* {storeInfo.phone && (

)} */}
      </section>
    </>
  );
}
export default withTranslation()(StoreOption);
