/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./filterProduct.scss";
// material ui
import Button from "@mui/material/Button";
import { PieChart } from "react-minimal-pie-chart";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import IconButton from "@mui/material/IconButton";
import Icon from "@material-ui/core/Icon";
import chef from "../../assets/images/chef-choice.svg";
import mostSelling from "../../assets/images/most-selling.svg";
import trending from "../../assets/images/trending.svg";
import Megaphone from "../../assets/images/Megaphone.svg";
import DairyFree from "../../assets/images/dairy-free.svg";
import gluten from "../../assets/images/Gluten-Free.svg";
import vegan from "../../assets/images/vegan.svg";
import vegetarian from "../../assets/images/vegetarian.svg";
import mild from "../../assets/images/mild.svg";
import spicy from "../../assets/images/spicy.svg";
import nutFree from "../../assets/images/nut.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";

function FilterProduct(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDiets, setSelectedDiets] = useState([]);

  const handleChangeTags = (event) => {
    let arrTag = selectedTags;
    if (selectedTags.includes(Number(event.target.value))) {
      let index = selectedTags.indexOf(Number(event.target.value));
      arrTag.splice(index, 1);
      setSelectedTags(arrTag);
    } else {
      arrTag.push(Number(event.target.value));
      setSelectedTags(arrTag);
    }
    let flag = true;
    let tagArr = [];
    appState.tags.forEach((ele) => {
      if (flag) {
        if (ele.id == Number(event.target.value)) {
          ele.check_status = !ele.check_status;
          flag = false;
        }
      }
      tagArr.push(ele);
    });
    appDispatch({
      type: "setTags",
      tags: tagArr,
    });
  };

  const handleChangeDietaries = (event) => {
    let arr = selectedDiets;
    if (selectedDiets.includes(Number(event.target.value))) {
      let index = selectedDiets.indexOf(Number(event.target.value));
      arr.splice(index, 1);
      setSelectedDiets(arr);
    } else {
      arr.push(Number(event.target.value));
      setSelectedDiets(arr);
    }
    let flag = true;
    let arrDiet = [];
    appState.dietaries.forEach((ele) => {
      if (flag) {
        if (ele.id == Number(event.target.value)) {
          ele.check_status = !ele.check_status;
          flag = false;
        }
      }
      arrDiet.push(ele);
    });
    // setDietaries(arrDiet);
    appDispatch({
      type: "setDietaries",
      dietaries: arrDiet,
    });
  };

  const handleSubmitFilter = () => {
    appDispatch({
      type: "setProductTags",
      productTags: selectedTags,
    });
    appDispatch({
      type: "setProductDietaries",
      productDietaries: selectedDiets,
    });
    appDispatch({
      type: "setFilterProductMode",
      filterProductMode: false,
    });
  };


  const handleClearFilter = () => {
    let tagArr = [];
    let arrDiet = [];
    appState.tags.forEach((ele) => {
      ele.check_status = false;
      tagArr.push(ele);
    });
    appState.dietaries.forEach((ele) => {
      ele.check_status = false;
      arrDiet.push(ele);
    });
    appDispatch({
      type: "setTags",
      tags: tagArr,
    });
    appDispatch({
      type: "setDietaries",
      dietaries: arrDiet,
    });
    appDispatch({
      type: "setProductTags",
      productTags: [],
    });
    appDispatch({
      type: "setProductDietaries",
      productDietaries: [],
    });
  };

  useEffect(async () => {
    let arrTag = [];
    let tagSelect = [];
    let arrDiet = [];
    let dietSelect = [];

    if (appState.productTags != []) {
      appState.tags.forEach((ele) => {
        if (appState.productTags.includes(ele.id)) {
          ele.check_status = true;
          tagSelect.push(ele.id);
        } else {
          ele.check_status = false;
        }
        arrTag.push(ele);
      });
    }
    if (appState.productDietaries != []) {
      appState.dietaries.forEach((ele) => {
        if (appState.productDietaries.includes(ele.id)) {
          ele.check_status = true;
          dietSelect.push(ele.id);
        } else {
          ele.check_status = false;
        }
        arrDiet.push(ele);
      });
    }

    setSelectedTags(tagSelect);
    setSelectedDiets(dietSelect);
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`filterProductMain col-12  ${appState.langValue === "ar" ? "filterProductMainArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 col-xl-12">
        <IconButton onClick={() => appDispatch({
                type: "setFilterProductMode",
                filterProductMode: false,
              })} className="classForSvgColor mt-2" >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F" />
              </svg>

            </IconButton>

          <h1
            className={` mt-2 ${appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins300"
            }`}
          >
            {" "}
            {t("product.Food-Type")}
          </h1>

            <hr className="my-1" />
          <div id="TagCheckBoxParent">
            <div className="row m-0 TagselectPaymentRow TagselectPaymentRowWallet ">
              {appState.tags.length > 0 &&
                appState.tags.map((val, ind) => {
                  return (
                    <div
                      className={`tagManinCol direction-ltr my-2 ${ind >= 1 ? "ml-1" : ""
                        }`}
                      key={ind}
                      onChange={handleChangeTags}
                    >
                      <Button
                        className="w-100 h-100 p-0 "
                        variant="outlined"
                        style={{
                          color: val.color ? val.color : "#0E8FCA",
                          border: val.check_status
                            ? `2px solid  ${val.color} !important`
                            : "",
                        }}
                      >
                        <div className="paymentContainer  w-100">
                          <input
                            type="checkbox"
                            value={val.id}
                            name={get_lang(val.name, appState.langValue)}
                            id={"tags" + val.id}
                            // checked={selectedTags.includes(val.code)}
                            checked={val.check_status}
                          />
                          <label htmlFor={"tags" + val.id} className="p-0 m-0">
                            <i>
                              <img className="mx-2" src={val.iconPath} alt="" />
                            </i>
                            <span className="mx-1">
                              {get_lang(val.name, appState.langValue)}
                            </span>
                          </label>
                        </div>
                      </Button>
                    </div>
                  );
                })}
            </div>

            <h1
              className={` mt-2 ${appState.langValue === "ar"
                ? "text-right familyGraphikArabic800 "
                : " familyPoppins300"
                }`}
            >
              {" "}
              {t("product.Food-Options")}
            </h1>

            <div className="d-flex  align-items-center  w-100 justify-content-between ">
              <p
                className={` totalAmount mb-0  ${appState.langValue === "ar"
                  ? "text-right d-flex"
                  : "familyPoppins300"
                  }`}
              >
                {t("product.Filter-desc")}.
              </p>
            </div>
            <div className="row m-0 TagselectPaymentRow mt-3 TagselectPaymentRowWallet">
              {appState.dietaries.length > 0 &&
                appState.dietaries.map((val, ind) => {
                  return (
                    <div
                      className={`tagManinCol classforDietries direction-ltr my-2 ${ind >= 1 ? "ml-1" : ""
                        }`}
                      key={ind}
                      onChange={handleChangeDietaries}
                    >
                      <Button
                        className="w-100 h-100 p-0"
                        variant="outlined"
                        style={{ color: val.color ? val.color : "#0E8FCA" }}
                      >
                        <div className="paymentContainer w-100">
                          <input
                            type="checkbox"
                            value={val.id}
                            name={get_lang(val.name, appState.langValue)}
                            id={"diets" + val.id}
                            checked={val.check_status}
                          />
                          <label htmlFor={"diets" + val.id} className="p-0 m-0">
                            <img className="mx-2" src={val.iconPath} alt="" />
                            <span className="mx-1">
                              {get_lang(val.name, appState.langValue)}
                            </span>
                          </label>
                        </div>
                      </Button>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="bottomBtn">
            <Button
              onClick={handleSubmitFilter}
              className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("product.Filter")}</span>{" "}
            </Button>
            <Button
              onClick={handleClearFilter}
              className={` py-3 px-4 clearBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("product.clear-Filter")}</span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(FilterProduct);
