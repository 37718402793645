/* eslint-disable */
import axios from "axios";
import {
  closeGroupBill,
  createGroupBill,
  freezOff,
  freezOn,
  handleError,
  handleSuccess,
  popShopMode,
  pushShopMode,
} from ".";
import { getCookie } from "../../global-functions/cookieFunction";
import e from "cors";
import { isNotEmpty } from "../../utils";
import {
  dataFromat,
  formatTime,
  stringDateFromate,
} from "../../utils/genral-functions";
import { loyalityCardCustomLimit } from "../../global-functions/loyalityCardCustomLimit";
import {
  setDeliveryAddsInfo,
  setUserSaveDeliveryAdds,
} from "../action-creater";
import moment from "moment";

export const createGroupBillApi = (state) => {
  return async (dispatch) => {
    dispatch(freezOn());
    let body = {
      customer_id: state.loggedIn
        ? state.userInfo != []
          ? state.userInfo.customerId
          : null
        : null,
      table_id: state.qrTable,
      device_id: state.deviceId,
    };
    let resData = [];
    await axios
      .post(`${process.env.REACT_APP_API_URL}/group_bill/create`, body, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          resData = response.data.data;
          dispatch(
            createGroupBill(response.data.data.id, state.storeInfo.slug)
          );
          dispatch({
            type: "setGroupBillRes",
            groupBillRes: response.data.data,
          });
        }
        dispatch(freezOff());
        dispatch(handleSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return resData;
  };
};

export const addMemeberGroupBillApi = (state, id) => {
  return async (dispatch) => {
    dispatch(freezOn());
    let body = {
      customer_id: state.loggedIn
        ? state.userInfo != []
          ? state.userInfo.customerId
          : null
        : null,
      device_id: state.deviceId,
    };
    let resData = [];
    await axios
      .post(`${process.env.REACT_APP_API_URL}/group_bill/add/${id}`, body)
      .then((response) => {
        if (response.data.success === true) {
          resData = response.data.data;
          // dispatch({
          //   type: "setGroupBillRes",
          //   groupBillRes: response.data.data,
          // });
        }
        dispatch(freezOff());
        dispatch(handleSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return resData;
  };
};

export const getGroupBill = (state, slug, id, handleGroupState) => {
  let groupBranch = "";
  let groupTable = "";
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .get(`${process.env.REACT_APP_API_URL}/group_bill/${id}`, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data.dine_in;
          let members = response.data.data.members;
          groupTable = response.data.data?.table?.id;
          groupBranch = response.data.data?.branch?.id;
          if (members.length > 0) {
            let findMember = members.some((member) => {
              return member.device_id == state.deviceId;
            });
            if (!findMember) {
              dispatch(addMemeberGroupBillApi(state, id));
            }
          }
          if (res == null) {
            dispatch(createGroupBill(id, slug));
            dispatch(handleSuccess(response));
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: response.data.data,
            });
          } else if (res.isClosed) {
            dispatch(closeGroupBill(slug));
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `Your group is closed.`,
            });
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: [],
            });
          } else if (!res.isClosed) {
            dispatch(createGroupBill(id, slug));
            dispatch(handleSuccess(response));
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: response.data.data,
            });
          }
          // dispatch(createGroupBill(response.data.data.id, state.storeInfo.slug))
        }
        dispatch({
          type: "SET_TABLE",
          qrTable: groupTable,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: groupBranch,
        });
        dispatch(freezOff());
      })

      .catch((error) => {
        console.error(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return { groupTable: groupTable, groupBranch: groupBranch };
  };
};

export const getDineInOrders = (state, setId, setInvoiceId) => {
  return async (dispatch) => {
    let formData = { ...state.formData, openBill: Boolean(state.openBill) };
    if (state.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${state.groupId}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            let invoiceStatus = res?.service?.status != 'Cancelled' || false;
           
            dispatch({
              type: "SET_FORM_DATA",
              formData: invoiceStatus ? { ...state.formData, openBill: true } : [],
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify(invoiceStatus ? { ...state.formData, openBill: true } : [])
            );
          } else if (response.data.success === false) {
            dispatch(closeGroupBill(state.storeInfo.slug));
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          // dispatch(closeGroupBill(state.storeInfo.slug))

          if (error == undefined) {
            console.error("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${state.userInfo.customerId}/${state.qrBranch}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            dispatch({
              type: "SET_FORM_DATA",
              formData: formData,
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify(formData)
            );
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          dispatch(closeGroupBill(state.storeInfo.slug));
          if (error == undefined) {
            console.error("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    } else {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${state.deviceId}/${state.qrBranch}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            let invoiceStatus = res?.service?.status != 'Cancelled' || false;
            
            if (setId) {
              setInvoiceId( invoiceStatus ?  res.service?.invoice_id : null); 
            }
            
            dispatch({
              type: "SET_FORM_DATA",
              formData: invoiceStatus ? {
                ...state.formData,
                openBill: true,
                order_invoice: "open_bill",
              } : []
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify(invoiceStatus ? {
                ...state.formData,
                openBill: true,
                order_invoice: "open_bill",
              } : [])
              
            );
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          if (error == undefined) {
            console.error("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    }
  };
};

export const getOrderInvoice = async (id, dispatch, state) => {
  let flag = true;
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/order_invoice/${id}`,
      {
        headers: {
          Response: `DineIn`, // geting response same as getdine
          ContentType: "application/json",
        },
      }
    );
    if (res.data.success) {
      let getRes = res.data.data;
      let data = {};
      if (!getRes?.isClosed) {
        data = {
          invoiceId: getRes?.service?.invoice_id,
          deviceId: getRes?.device_id,
          openBill: getRes?.isOpen,
          groupBill: getRes?.groupBill,
          tableId: getRes?.table_id,
          branchId: getRes?.branch?.id,
          customerId: getRes?.customer_id,
          countryCode: getRes?.country_code,
          email: getRes?.email,
          number: getRes?.number,
          name: getRes?.full_name,
        };
        dispatch({
          type: "SET_FORM_DATA",
          formData: { ...state.formData, openBill: true },
        });
        localStorage.setItem(
          `${state.storeInfo.slug}_form`,
          JSON.stringify({ ...state.formData, openBill: true })
        );
        dispatch({
          type: "SET_TABLE",
          qrTable: data?.tableId,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: data?.branchId,
        });
        dispatch({
          type: "setShareBill",
          shareBill: data,
        });

        if (!state.shopMode.includes("share-bill")) {
          dispatch({
            type: "setShopMode",
            shopMode: [...state.shopMode, "share-bill"],
          });
        }
        return data;
      } else {
        dispatch({
          type: "SET_FORM_DATA",
          formData: [],
        });
        localStorage.removeItem(`${state.storeInfo.slug}_form`);
        dispatch({
          type: "SET_TABLE",
          qrTable: null,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: null,
        });
        dispatch({
          type: "setShareBill",
          shareBill: [],
        });

        dispatch({
          type: "setShopMode",
          shopMode: [],
        });
        return null;
      }
    }
  } catch (error) {
    console.error("Error updating:", error);
    return null;
  }
};

export const getLoyaltyCards = async (state, dispatch, branch) => {
  let flag = false;
  let getBranch = branch ? branch : state.qrBranch;

  const helperFunction = (rewards) => {
    let totalPunchs = rewards?.punch?.total;
    let collectedPunches = rewards?.punch?.collected;
    return collectedPunches < totalPunchs ? true : false;
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/loyalty_card?branch=${getBranch}`,
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: "application/json",
        },
      }
    );
    let res = response.data.data;
    let customeObj = [];
    if (response.data.success) {
      res.forEach((ele) => {
        if (ele.active && !flag) {
          flag = true;
        }
        var isLarger =
          ele.start_at !== null && ele.end_at !== null
            ? dataFromat(ele.start_at, ele.end_at)
            : false;
        // var isLarger = stringDateFromate(ele.start_at, ele.end_at );
        var customerLimit = loyalityCardCustomLimit(
          ele?.customer_limit?.limit,
          ele?.customer_limit?.count
        );
        let rewards = ele?.reward;
        customeObj.push({
          show: isLarger && customerLimit,
          id: ele?.id,
          active: ele?.active,
          punchable: helperFunction(rewards),
          for: ele?.for,
          loyaltyName: ele?.name,
          description: ele?.description != "" ? ele?.description : null,
          loyaltyLogo: ele?.logo,
          loyaltybgImage: ele?.bg_image,
          primaryColor: ele?.primary_color,
          secondaryColor: ele?.secondary_color,
          rewards: ele?.reward,
          redeemable: ele?.reward?.redeemable,
          conditions: ele?.conditions,
        });
      });
      dispatch({
        type: "setLoyaltyObj",
        loyaltyObj: customeObj,
      });
    }
    
    dispatch(freezOff());
  } catch (error) {
    if(error.response.status == 401){
      dispatch(handleLogoutConditons())
    }
    console.error("Error updating:", error);
    dispatch({
      type: "setLoyaltyObj",
      loyaltyObj: [],
    });
    return null;
  }
};

export const getLoyaltyCardDiscount = async (
  state,
  dispatch,
  id,
  currentOrders
) => {
  let branch = state.qrBranch;
  let arr = [...state.rewardsDetails];

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/loyalty_card/get_reward`,
      {
        branch_id: branch,
        loyalty_card_id: id,
        orders: currentOrders,
      },
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: "application/json",
        },
      }
    );

    if (response.data.success) {
      let data = state.loyaltyObj.find((item) => item.id == id);
      let discount = await response.data.data;
      if (isNotEmpty(discount)) {
        data = { ...data, rewardDiscount: discount };
        arr.push(data);
        return data;
      } else {
        return null;
      }
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};

export const getOpenBillFeaturedProducts = async (state, dispatch) => {
  let branchId = state.qrBranch;

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/open_bill/${branchId}`,
      {
        headers: {
          ContentType: "application/json",
        },
      }
    );
    let arr = [];
    if (response.data.success) {
      let res = response.data.data;

      if (res.length > 0) {
        // res = res[0]?.products

        res.forEach((ele) => {
          ele?.products.forEach((product) => {
            let data = {
              ...product,
              pathUrl: `/${product?.category?.slug}/${product?.slug}/?prodid=${product?.id}`,
            };
            arr.push(data);
          });
        });
      } else {
        arr = null;
      }
      dispatch({
        type: "setOpenBillFeatured",
        openBillFeatured: arr,
      });
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};

export const  handleGetCountry = async (state, dispatch) => {
    try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/country/1`
    );
    let res = response.data.data;
    if (response.data.success) {
      let cities = res?.cities;
      if (cities) {
        dispatch({
          type: "setCountryCityArea",
          countryCityArea: cities,
        });
      }
    }
    dispatch(freezOff());
  } catch (error) {
    console.error("Error updating:", error);
    dispatch({
      type: "setCountryCityArea",
      countryCityArea: null,
    });
    return null;
  }
};

export const handleGetCityArea = async (state, dispatch, slug) => {
    try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/delivery/areas?store=${slug}`
    );
    let res = response.data.data;
    if (response.data.success) {
        let cities = res;
          dispatch({
          type: "setCountryCityArea",
          countryCityArea: cities.length > 0 ? cities : null,
        });
      
      return res.length > 0 ? res : null;
    }else{
      return null;
    }

    dispatch(freezOff());
  } catch (error) {
    console.error("Error updating:", error);
    dispatch({
      type: "setCountryCityArea",
      countryCityArea: null,
    });
    return null;
  }
};

export const  handleDeliveryPreparingTime = async (dispatch, branch) => {
    try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/delivery/preparing_timing?branch=${branch}`
    );
    let res = response.data.data;
    if (response.data.success) {
      dispatch(freezOff());
      return res;
    }
    return null;
  } catch (error) {
    console.error("Error updating:", error);
    
    return null;
  }
};



export const handleGetDeliveryCharges = async (state, dispatch, branch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/delivery/charges?branch=${branch}`
    );
    let res = response.data.data;
    if (response.data.success) {
      dispatch({
        type: "setDeliveryChargesDetails",
        deliveryChargesDetails: response.data.data,
      });
    }
    dispatch(freezOff());
  } catch (error) {
    console.error("Error updating:", error);
    dispatch({
      type: "setDeliveryChargesDetails",
      deliveryChargesDetails: null,
    });
    return null;
  }
};

export const handleGetEstimateTime = async (branch) => {
  let returnData = [];
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/delivery/timing?branch=${branch}`
    );
    let res = response.data.data;
    if (response.data.success) {
      returnData(response.data.data);
    }
    dispatch(freezOff());
    return returnData;
  } catch (error) {
    return returnData;
  }
};

export const getUserDeliveryAddresses = async (state, dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/delivery/address`,
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
        },
      }
    );

    if (response.data.success) {
      if (response.data.data.length > 0) {
        let data = [];
        response.data.data.forEach((ele, ind) => {
          data.push({
            s_no: ind + 1,
            id: ele.id,
            name: ele?.name,
            block: ele?.block,
            street: ele?.street,
            house_no: ele?.house_no,
            avenue: ele?.avenue,
            floor: ele?.floor,
            appartment: ele?.appartment,
            area_id: ele?.area_id,
            city_id: ele?.city_id,
            lat: ele?.lat,
            lng: ele?.lng,
            customerId: ele?.customer_id,
            formTypeId: ele?.type?.id,
            formType: ele?.type?.name,
            fullAddress: `${ele?.block != null ? ele?.block + ", " : ""} ${
              ele?.street != null ? ele?.street + ", " : ""
            } ${ele?.house_no != null ? ele?.house_no + ", " : ""} ${
              ele?.avenue != null ? ele?.avenue + ", " : ""
            } ${ele?.floor != null ? ele?.floor + ", " : ""} ${
              ele?.appartment != null ? ele?.appartment + ", " : ""
            } `,
          });
        });
        // Check for matching id and move it to the first index
        const matchingIndex = data.findIndex(
          (item) => item.id === state.deliveryAddsInfo?.saveAddId
        );
        if (matchingIndex !== -1) {
          const [matchingItem] = data.splice(matchingIndex, 1);
          data.unshift(matchingItem);
        }

        return data;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};

export const postDeleteUserDeliveryAddress = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/delivery/address/delete/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: "application/json",
        },
      }
    );

    if (response.data.success) {
      if (response.data) {
          // return response.data.success
      } else {
        return null;
      }
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};

export const postUpdateUserDeliveryAddress = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/delivery/address/update/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: "application/json",
        },
      }
    );

    if (response.data.success) {
      if (response.data) {
          // return response.data.success
      } else {
        return null;
      }
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};

export const  getEstimatedTime = async (state, setDeliveryAddsInfo) => {
  const currentTime = moment();

  const isTimeInRange = (currentTime, startTime, endTime) => {
    const format = "HH:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);
      // If the end time is before the start time, it means the range goes over midnight
    if (end.isBefore(start)) {
      return (
        currentTime.isBetween(start, moment("23:59", format)) ||
        currentTime.isBetween(moment("00:00", format), end)
      );
    } else {
      return currentTime.isBetween(start, end);
    }
  };
  const currentDayName = moment().format("dddd");
  let inRange = false;
  let hasSlots = false;
  let start_time = "";
  let end_time = "";
  let flag = false;
  let activeDays = [];
  if (state.storeInfo?.deliveryShecheduled || state?.storeInfo?.deliveryAsap) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/delivery/timing?branch=${state?.qrBranch}` ///
      );
      let res = response.data.data;
      if (response.data.success) {
        res.forEach((ele) => {
          activeDays.push(Number(ele.day?.id) - 1);
          if (currentDayName == ele.day?.name && !flag) {
            console.log(currentDayName, ele.day.name ,flag)
              start_time = ele.starting_time;
            end_time = ele.ending_time;
            if (ele?.delivery_time_slots?.length > 0) {
              hasSlots = true;
            }

            inRange = isTimeInRange(
              currentTime,
              formatTime(moment(ele.starting_time, "HH:mm")),
              formatTime(moment(ele.ending_time, "HH:mm"))
            );
            //         const endTime = moment(slot.slotEnd, "HH:mm");
             console.log(ele.starting_time, ele.ending_time, isTimeInRange(
              currentTime,
              formatTime(moment(ele.starting_time, "HH:mm")),
              formatTime(moment(ele.ending_time, "HH:mm"))
            ))
              // inRange = current.isBefore(endTime)
            flag = true;
          }
        });
        // inRange = state.storeInfo?.deliveryShecheduled ?? inRange
        inRange = (inRange && state.storeInfo?.deliveryAsap && !state.storeInfo?.deliveryShecheduled ) ? true :  state.storeInfo?.deliveryShecheduled 
        stopAsapDueToShechedule = inRange && state.storeInfo?.deliveryShecheduled;
        console.log(inRange && state.storeInfo?.deliveryAsap,inRange && state.storeInfo?.deliveryShecheduled,`inRange && state.storeInfo?.deliveryAsap`, hasSlots, state.storeInfo?.deliveryAsap && !state.storeInfo?.deliveryShecheduled , state.storeInfo?.deliveryAsap , !state.storeInfo?.deliveryShecheduled )
          return { inRange, start_time, hasSlots, stopAsapDueToShechedule };
      }
    } catch (error) {
      return { inRange, start_time, hasSlots };
    }
  } 
  else {
    inRange = false;
    return { inRange, start_time, hasSlots };
  }
};
export const getEstimatedPickupTime = async (state) => {
  const currentTime = moment();

  const isTimeInRange = (currentTime, startTime, endTime) => {
    const format = "HH:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);

    // If the end time is before the start time, it means the range goes over midnight
    if (end.isBefore(start)) {
      return (
        currentTime.isBetween(start, moment("23:59", format)) ||
        currentTime.isBetween(moment("00:00", format), end)
      );
    } else {
      return currentTime.isBetween(start, end);
    }
  };  
  const currentDayName = moment().format("dddd");
  let inRange = false;
  let hasSlots = false;
  let start_time = "";
  let end_time = "";
  let flag = false;
  let activeDays = [];
  if (state.storeInfo?.pickupShecheduled) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pickup/timing?branch=${state?.qrBranch}` ///
      );
      let res = response.data.data;
      if (response.data.success) {
        res.forEach((ele) => {
          activeDays.push(Number(ele.day?.id) - 1);
          if (currentDayName == ele.day?.name && !flag) {
              start_time = ele.starting_time;
            end_time = ele.ending_time;
            if (ele?.pickup_time_slots?.length > 0) {
              hasSlots = true;
            }

            inRange = isTimeInRange(
              currentTime,
              formatTime(moment(ele.starting_time, "HH:mm")),
              formatTime(moment(ele.ending_time, "HH:mm"))
            );
            flag = true;
          }
        });

        return { inRange, start_time, hasSlots };
      }
    } catch (error) {
      return { inRange, start_time, hasSlots };
    }
  } else {
    inRange = true;
    return { inRange, start_time, hasSlots };
  }
};

//get store branch detail for getting branch id and store slug
export const postBranch = async (slug) => {
  let body = {};
  let resData = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}/branch/${slug}`, body, {
      headers: {
        ContentType: "application/json",
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        let res = response.data.data;
        resData = {
          branchId: res.id,
          storeSlug: res?.store?.slug,
        };
      }
      else{
        resData = null
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return resData;
};
//get old order products
export const postOldOrder = async (branch) => {
  let body = {};
  let resData = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}/customer/products?branch=${branch}&paginate=10&limit=10&offset=`, {}, {
      headers: {
        Authorization: `Bearer ${getCookie("tokenAuth")}`,
        ContentType: "application/json",
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        let res = response.data.data;
          // resData = {
        //   branchId: res.id,
        //   storeSlug: res?.store?.slug,
        // };
      }
      else{
        resData = null
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return resData;
};



export const getMicrosystcInvoice = async (table) => {
  console.error('getmicrosystcInvoice',table)
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/pos-integration/branch/microsystc/invoice/${table}`  
        )
    .then((response) => {
        console.error(response)
    })
         .catch((error) => {
           console.error(error);
           if (error != undefined) {
             // dispatch(handleError(error));
             dispatch({
               type: "setPusherActive",
               pusherActive: false,
             });
           } else {
             dispatch({
               type: "SHOW_ALERT",
               alertType: "warning",
               message: "Please check your network.",
             });
           }
           dispatch(freezOff());
         });
  return null;
};