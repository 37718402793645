import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PixelManager = () => {
    const appDispatch = useDispatch();
    const appState = useSelector((state) => state);

    useEffect(() => {
        const fetchPixelData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/delivery/socials?branch=${appState.qrBranch}`);
                if (res.data.success) {
                    const { snapchat, google, tiktok, meta: facebook, twitter } = res.data.data || {};
                    
                    // Initialize pixels based on their presence
                    if (snapchat) {
                        initializeSnapchatPixel(snapchat);
                    }
                    if (tiktok) {
                        initializeTiktokPixel(tiktok);
                    }
                    if (facebook) {
                        initializeFacebookPixel(facebook);
                    }
                    if (google) {
                        initializeGTM(google);
                    }
                }
            } catch (e) {
                handleError(e);
            }
        };

        fetchPixelData();
    }, []); 

    const handleError = (error) => {
        if (error.code === "ERR_NETWORK") {
            appDispatch({
                type: "SHOW_ALERT",
                alertType: "error",
                message: "Please check your network.",
            });
        } else if (error.response) {
            console.error('API Error:', error.response.data);
            // Optionally show an alert based on error response
        } else {
            console.error('Unexpected Error:', error);
            appDispatch({
                type: "SHOW_ALERT",
                alertType: "error",
                message: "An unexpected error occurred.",
            });
        }
    };

    const initializeSnapchatPixel = (snapchatId) => {
        window.snaptr = function () {
            window.snaptr.queue = window.snaptr.queue || [];
            window.snaptr.queue.push(arguments);
        };
        window.snaptr('init', snapchatId);
        window.snaptr('track', 'PAGE_VIEW');
    };

    const initializeTiktokPixel = (tiktokId) => {
        (function (w, d, t) {
            w.TiktokAnalyticsObject = t;
            var ttq = (w[t] = w[t] || []);
            ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'];
            ttq.setAndDefer = function (t, e) {
                t[e] = function () {
                    t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
            };
            for (var i = 0; i < ttq.methods.length; i++) {
                ttq.setAndDefer(ttq, ttq.methods[i]);
            }
            ttq.load = function (e, n) {
                var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
                ttq._i = ttq._i || {};
                ttq._i[e] = [];
                ttq._i[e]._u = i;
                ttq._t = ttq._t || {};
                ttq._t[e] = +new Date();
                ttq._o = ttq._o || {};
                ttq._o[e] = n || {};
                var o = document.createElement('script');
                o.type = 'text/javascript';
                o.async = true;
                o.src = i + '?sdkid=' + e + '&lib=' + t;
                var a = document.getElementsByTagName('script')[0];
                a.parentNode.insertBefore(o, a);
            };
            ttq.load(tiktokId);
            ttq.page();
        })(window, document, 'ttq');
    };

    const initializeFacebookPixel = (fbPixelId) => {
        const loadFacebookPixel = (f, b, e, v, n, t, s) => {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        };

        loadFacebookPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', fbPixelId);
        window.fbq('track', 'PageView');
    };

    const initializeGTM = (gtmId) => {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);
    };

    return null; // No UI component needed, this is for pixel initialization
};

export default PixelManager;
