export const filterByReference = (arr1, arr2) => {
  let res = [];
  if (arr1 != [] && arr2 != []) {
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return element.id === el.id;
      });
    });
    return res;
  } else {
    return true;
  }
};

export const filterTagDiet = async (arr, appState) => {
  let filterArr = [];
  let flag = true;
  for (var i = 0; i < arr.length; i++) {
        for (var j = 0; j < arr[i].tags.length; j++) {
            appState.productTags.forEach((element) => {
              if (arr[i].tags[j].id == element) {
                if(filterArr.length > 0) {
                    let result = filterArr.some((product) => product.id == arr[i].id)
                    if(!result) {
                        filterArr.push(arr[i]);
                    }
                }
                else{
                    filterArr.push(arr[i]);
                }
              }
            });
          }
 
    for (var k = 0; k < arr[i].food_preferences.length; k++) {
        appState.productDietaries.forEach((element) => {
          if (arr[i].food_preferences[k].id == element) {
            if(filterArr.length > 0) {
                let result = filterArr.some((product) => product.id == arr[i].id)
                if(!result) {
                    filterArr.push(arr[i]);
                }
            }
            else{
                filterArr.push(arr[i]);
            }
          }
        });
      }
  }

  return filterArr;
};

export const iconsTagDiet = async (arr, products=[], Images, dispatch) => {
  var globalTags = [];
  var globalDiets = [];
  for (var i = 0; i < products?.length; i++) {
    var tags = [];
    var food_preferences = [];
    if (
      products[i].tags != [] &&
      products[i].tags != undefined
    ) {
      for (var j = 0; j < products[i].tags.length; j++) {
        let data = await products[i].tags[j];
        let tagIcon = await data.icon;
        Images.forEach((element) => {
          if (tagIcon == element.name) {
            data =  {...data, iconPath: element.image}
          }
        });
        tags.push(data);
        if(globalTags.length > 0){
            let result = globalTags.some((gTag) => gTag.id == data.id)
            if(!result){
            let customeData = {...data, check_status: false}
            globalTags.push(customeData);
            }
        }else{
            let customeData = {...data, check_status: false}
            globalTags.push(customeData);
        }
      }
      products[i].tags = tags;
    }
    if (
      products[i].food_preferences != [] &&
      products[i].food_preferences != undefined
    ) {
      for (var j = 0; j < products[i].food_preferences.length; j++) {
        let foodPref = await products[i].food_preferences[j];
        let foodPrefIcon = await foodPref.icon;
        Images.forEach((element) => {
          if (foodPrefIcon == element.name) {
            foodPref = {...foodPref, iconPath: element.image}
          }
        });
        food_preferences.push(foodPref);
        if(globalDiets.length > 0){
            let result = globalDiets.some((gDiets) => gDiets.id == foodPref.id)
            if(!result){
            let customeData = {...foodPref, check_status: false}
            globalDiets.push(customeData);
            }
        }else{
            let customeData = {...foodPref, check_status: false}
            globalDiets.push(customeData);
        }
      }
      
    products[i].food_preferences = food_preferences;
    }
    arr.push(products[i]);
  }

 
  return arr;
};

export const    iconsTagDiets = async (arr, products, Images, dispatch) => {
  const globalTags = [];
  const globalDiets = [];
  const tagSet = new Set();
  const dietSet = new Set();

  // Map images for faster lookups by name
  const imageMap = Images.reduce((acc, img) => {
    acc[img.name] = img.image;
    return acc;
  }, {});

  for (const product of products) {
    // Process Tags
    if (product.tags && product.tags.length) {
      product.tags = product.tags.map(tag => {
        const iconPath = imageMap[tag.icon] || null;
        const tagWithIcon = { ...tag, iconPath };

        // Add to globalTags if not already present
        if (!tagSet.has(tag.id)) {
          tagSet.add(tag.id);
          globalTags.push({ ...tagWithIcon, check_status: false });
        }

        return tagWithIcon;
      });
    }

    // Process Food Preferences
    if (product.food_preferences && product.food_preferences.length) {
      product.food_preferences = product.food_preferences.map(foodPref => {
        const iconPath = imageMap[foodPref.icon] || null;
        const foodPrefWithIcon = { ...foodPref, iconPath };

        // Add to globalDiets if not already present
        if (!dietSet.has(foodPref.id)) {
          dietSet.add(foodPref.id);
          globalDiets.push({ ...foodPrefWithIcon, check_status: false });
        }

        return foodPrefWithIcon;
      });
    }

    arr.push(product);
  }

  return arr;
};
