/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./groupOrderInvite.scss";
// material ui
import Button from "@mui/material/Button";
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";

import QRCodeStyling from "qr-code-styling";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Link, useHistory } from "react-router-dom";



const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,

  dotsOptions: {
    color: "#000",
    type: "dot"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});
function GroupOrderInvite({ callingFrom }) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);

  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  let history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {

    appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: true });
  }

  // console.log(appState)

  

  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div

        className={`groupOrderInviteMain ${appState.langValue === "ar" ? "groupOrderInviteMainArabic" : " "
          }`}
      >
        <div className={` py-3   ${callingFrom === "openOrder"
          ? " "
          : "container-fluid px-4"
          }`}>

          <div className="d-flex justify-content-between">
            <div>
              <h3 className={`  ${appState.langValue === "ar"
                ? " familyGraphikArabic800 text-right"
                : " familyPoppins300"
                }`}>{t("group-order.Group-Order")}</h3>
              {/* {console.log("isko dekhi", appState.groupBillRes ? appState.groupBillRes.members.length ? appState.groupBillRes.members.length : 0 : "")} */}
              <AvatarGroup className="justify-content-end" total={appState.groupBillRes ? appState.groupBillRes.members.length  : 0}>
                {appState.groupBillRes ? appState.groupBillRes?.members.length ? appState.groupBillRes.members.length == 1 && <Avatar alt="A EnabillA" src="/images/avatar.jpg" /> : "" : ""}
                {appState.groupBillRes ? appState.groupBillRes?.members.length ? appState.groupBillRes.members.length == 2 && <Avatar alt="B EnabillB" src="/images/avatar3.svg" /> : "":""}
                {appState.groupBillRes ? appState.groupBillRes?.members.length ? appState.groupBillRes.members.length == 3 && <Avatar alt="C EnabillC" src="/images/avatar2.jpg" /> : "":""}
              </AvatarGroup>
            </div>


            <Button onClick={handleClick} className={` InviteBtn  ${appState.langValue === "ar"
              ? " familyGraphikArabic800 "
              : " familyPoppins300"
              }`}><span className="mx-2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.33337 1.5C3.58447 1.5 2.16671 2.91777 2.16671 4.66667C2.16671 6.41557 3.58447 7.83333 5.33337 7.83333C7.08227 7.83333 8.50004 6.41557 8.50004 4.66667C8.50004 2.91777 7.08227 1.5 5.33337 1.5ZM3.16671 4.66667C3.16671 3.47005 4.13676 2.5 5.33337 2.5C6.52999 2.5 7.50004 3.47005 7.50004 4.66667C7.50004 5.86328 6.52999 6.83333 5.33337 6.83333C4.13676 6.83333 3.16671 5.86328 3.16671 4.66667Z" fill="white" />
                <path d="M9.33337 1.5C9.05723 1.5 8.83337 1.72386 8.83337 2C8.83337 2.27614 9.05723 2.5 9.33337 2.5C10.53 2.5 11.5 3.47005 11.5 4.66667C11.5 5.86328 10.53 6.83333 9.33337 6.83333C9.05723 6.83333 8.83337 7.05719 8.83337 7.33333C8.83337 7.60948 9.05723 7.83333 9.33337 7.83333C11.0823 7.83333 12.5 6.41557 12.5 4.66667C12.5 2.91777 11.0823 1.5 9.33337 1.5Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.833374 12.6667C0.833374 10.9178 2.25114 9.5 4.00004 9.5H6.66671C8.41561 9.5 9.83337 10.9178 9.83337 12.6667C9.83337 13.6792 9.01256 14.5 8.00004 14.5H2.66671C1.65419 14.5 0.833374 13.6792 0.833374 12.6667ZM4.00004 10.5C2.80342 10.5 1.83337 11.47 1.83337 12.6667C1.83337 13.1269 2.20647 13.5 2.66671 13.5H8.00004C8.46028 13.5 8.83337 13.1269 8.83337 12.6667C8.83337 11.47 7.86332 10.5 6.66671 10.5H4.00004Z" fill="white" />
                <path d="M10.6667 9.5C10.3906 9.5 10.1667 9.72386 10.1667 10C10.1667 10.2761 10.3906 10.5 10.6667 10.5H12C13.1967 10.5 14.1667 11.47 14.1667 12.6667C14.1667 13.1269 13.7936 13.5 13.3334 13.5H10.6667C10.3906 13.5 10.1667 13.7239 10.1667 14C10.1667 14.2761 10.3906 14.5 10.6667 14.5H13.3334C14.3459 14.5 15.1667 13.6792 15.1667 12.6667C15.1667 10.9178 13.7489 9.5 12 9.5H10.6667Z" fill="white" />
              </svg></span>  <span className="mx-2">{t("group-order.Invite")}</span></Button>

          </div>

        </div>

      </div>
    </>
  );
}

export default withTranslation()(GroupOrderInvite);
