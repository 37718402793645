/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import Product from "../../components/Product";
import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@material-ui/core/Tab";

import { withTranslation, useTranslation, Trans } from "react-i18next";

// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";

// redux
import { useSelector, useDispatch } from "react-redux";

import Images from "../../assets/images/images";
import noImg from "../../../js/assets/images/no-image.svg";
import AddToCartPopup from "../../components/addToCartPopup/addToCartPopup";
import { handleCategory } from "../../../../redux-store/action";
import CheckoutBtn from "../../components/checkoutBtn/CheckoutBtn";
import { filterTagDiet, iconsTagDiet } from "../../../../global-functions/filterTagDiet";
import { handleMealType } from "../../../../global-functions/mealType";
import { sortedByNumber } from "../../../../utils";
import Productv2 from "../../components/Productv2/Productv2";
import MiscBanner from "../../components/MiscBanner";
import Page from "../../components/Page";
import CategoryTabsWithProducts from "../../components/TestComponent";
import CategoryProductsV2 from "../../components/categoryProductsV2/CategoryProductsV2";
const Products = () => {
  const { t } = useTranslation();
  const productRefs = useRef({}); 
  var { slug } = useParams();
  var slug = slug.trim();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let parent_id = searchParams.get("parent");
  let cat_id = searchParams.get("catid");
  const [categoryImg, setCategoryImg] = useState("");
  const [childCategory, setChildCategory] = useState([]);
  const [isChildLoading, setIsChildLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [activeVTabs, setActiveVTabs] = useState(0);
  const [value, setValue] = React.useState(0);
  const [parentValue, setParentValue] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [childProducts, setChildProducts] = useState([]);
  const [id, setId] = useState(0);
  const [catId, setCatId] = useState("");
  const [currentProdCategorySlug , setCurrentProdCategorySlug] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null)

  let pSlug = "";

  useEffect(() => {
    appDispatch({
      type: "setProductGroupId",
      productGroupId: null,
    });
    appDispatch({
      type: "SET_FEATURED_PRODUCTS",
      featuredProducts: null,
    });
  }, []);

  const getIdRes = async () => {
    if (appState.storeInfo.subCategory) {
      appState.parentCategories.length > 0 &&
        appState.parentCategories.forEach((category, ind) => {
          if (category.id == parent_id) {
            setParentValue(ind);
            category.children.forEach((child, ind) => {
              if (child.id == cat_id) {
                setValue(ind);
              }
            });
          }
        });
      for (var i = 0; i < appState.categories.length; i++) {
        if (slug === appState.categories[i].slug) {
          var idValue = await appState.categories[i].id;
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          if (appState.categories[i] == cat_id) {
            setValue(i);
          }
        }
      }
    } else {
      for (var i = 0; i < appState.categories.length; i++) {
        if (slug === appState.categories[i].slug) {
          var idValue = await appState.categories[i].id;
          setSelectedCategory(appState.categories[i].slug)
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          setValue(i);
        }
      }
    }

    if (cat_id) {
      setIsChildLoading(true);
      let childArr = [];
      appState.categories.forEach((category) => {
        if (category?.children?.length > 0) {
          category.children.forEach((child) => {
            if (child?.parent?.id == cat_id) {
              childArr.push(child);
            }
          });
        }
      });

      setChildCategory(childArr);
    }
  };
  
  const getIdRes2 = async () => {
      for (var i = 0; i < appState.categories.length; i++) {
 console.log(currentProdCategorySlug,"currentProdCategorySlug")

        if (currentProdCategorySlug == appState.categories[i].slug) {
 console.log(currentProdCategorySlug,"currentProdCategorySlug")

          var idValue = await appState.categories[i].id;
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          setValue(i);
        }
      }
  };
  const toCart = () => {
    history.push("/cart");
  };
  useEffect(() => {
    getIdRes();
  }, [slug]);
  useEffect(() => {
    getIdRes2();
  }, [currentProdCategorySlug])
  

  const handleChildCategory = async (id, type) => {
    const res = await appDispatch(
      handleCategory(id, appState, cat_id, "childProductFlow")
    );

    let findCategory = res;
    let product = findCategory;
    let arr = [];
    console.log(arr, "childprod");

    // Get tag-diet response for the product
    const tagDietResponse = await iconsTagDiet(
      arr,
      product,
      Images,
      appDispatch
    );
    arr = tagDietResponse;

    let finalArray;
    if (
      appState.productTags.length > 0 ||
      appState.productDietaries.length > 0
    ) {
      // Filter tags/diets based on appState if conditions are met
      const filterRes = await filterTagDiet(arr, appState, appDispatch);
      finalArray = filterRes;
    } else {
      finalArray = arr;
    }

    // Update childProducts state by preserving old data and adding new data without duplicates
    setChildProducts((prevChildProducts) => {
      // Use a Set to filter out duplicates based on unique identifiers (e.g., `id`)
      const uniqueProducts = [
        ...prevChildProducts,
        ...finalArray.filter(
          (newItem) =>
            !prevChildProducts.some(
              (existingItem) => existingItem.id === newItem.id
            )
        ),
      ];
      return uniqueProducts;
    });
  };
  const fetchProudct = async () => {
    // var prodArry = "";

    if (appState.storeInfo.progressive) {
      if (appState.categories.length !== 0) {
        for (var i = 0; i < appState.categories.length; i++) {
          if (id === appState.categories[i].id) {
            appDispatch({
              type: "setCurrentCategory",
              currentCategory: appState.categories[i],
            });
            // console.log(appState.categories[i],"category");

            var prodVal = await appState.categories[i].products;
            var prodArr = [];
            // if (appState.qrBranch != null) {
            // prodVal.forEach((ele) => {
            //   let inActive = ele?.inactive_branches ?? [];
            //   let prodShow = !inActive.includes(appState.qrBranch);
            //   console.log(ele.quantity, "produtc");
            //   let mealTypes = ele?.mealTypes;
            //   // let prodShow = true;
            //   if (mealTypes?.length > 0 && appState.qrBranch && prodShow) {
            //     prodShow = handleMealType(mealTypes, appState.qrBranch);
            //   }

            //   if (!appState.storeInfo?.showSoldProduct && show) {
            //     show = ele.quantity == 0 ? false : true;
            //   }

            //   if (prodShow) {
            //     ele.order = ele?.order_list ?? null;
            //     prodArr.push(ele);
            //   }
            // });
            // prodArr = sortedByNumber(prodArr);
            // // }
            // let arr = [];
            // const tagDietResponse = await iconsTagDiet(
            //   arr,
            //   prodArr,
            //   Images,
            //   appDispatch
            // );
            // arr = tagDietResponse;
            // if (
            //   appState.productTags.length > 0 ||
            //   appState.productDietaries.length > 0
            // ) {
            //   const filterRes = await filterTagDiet(arr, appState, appDispatch);
            //   setProducts(filterRes);
            // } else {
            //   setProducts(arr);
            // }
          }
        }
      }
      appState.products.forEach((ele) => {
        let inActive = ele?.inactive_branches ?? [];
        let prodShow = !inActive.includes(appState.qrBranch);
        console.log(ele.quantity, "produtc");
        let mealTypes = ele?.mealTypes;
        // let prodShow = true;
        if (mealTypes?.length > 0 && appState.qrBranch && prodShow) {
          prodShow = handleMealType(mealTypes, appState.qrBranch);
        }

        if (!appState.storeInfo?.showSoldProduct && show) {
          show = ele.quantity == 0 ? false : true;
        }

        if (prodShow) {
          ele.order = ele?.order_list ?? null;
          prodArr.push(ele);
        }
      });
      prodArr = sortedByNumber(prodArr);
      // }
      let arr = [];
      const tagDietResponse = await iconsTagDiet(
        arr,
        prodArr,
        Images,
        appDispatch
      );
      arr = tagDietResponse;
      if (
        appState.productTags.length > 0 ||
        appState.productDietaries.length > 0
      ) {
        const filterRes = await filterTagDiet(arr, appState, appDispatch);
        setProducts(filterRes);
      } else {
        setProducts(arr);
      }
    } else {
      if (appState.products.length > 0) {
        setProducts(appState.products);
      }
    }
    setIsLoading(false);
  };

  

  useEffect(() => {
    const getAllProducts = async () => {
      if (id != 0) {
        if (appState.storeInfo.progressive) {
          fetchProudct();
        } else {
          if (cat_id != catId) {
            let findCategory = undefined;

            if (findCategory != undefined) {
              let arr = [];
              let product = findCategory.data;
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(
                  arr,
                  appState,
                  appDispatch
                );
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
            } else {
              // let catID = cat_id;
              const res = await appDispatch(
                handleCategory(cat_id, appState, parent_id, "normalFlow")
              );
              let findCategory = res;
              let product = findCategory;
              let arr = [];
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(
                  arr,
                  appState,
                  appDispatch
                );
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
              let data = [...appState.categoryProducts];
              let catData = {
                category_id: cat_id,
                data: res,
              };
              data.push(catData);
              appDispatch({
                type: "setCategoryProduct",
                categoryProducts: data,
              });
            }
          }

          let flagChildProd = true;
          appState.categories.forEach((cat) => {
            if (cat.id == cat_id) {
              if (cat.children.length > 0 && flagChildProd) {
                {
                  flagChildProd = false;
                  let childrens = cat?.children;
                  for (const prodChild of childrens) {
                    handleChildCategory(prodChild.id, "childProductFlow");
                  }
                }
                if (flagChildProd) {
                  setChildProducts([]);
                }
              }
              appDispatch({
                type: "setCurrentCategory",
                currentCategory: cat,
              });
            }
          });
          setIsLoading(false);
        }
      }
    };
    getAllProducts();
    // setProducts(appState.products);
  }, [id, appState.productTags]);
  useEffect(() => {
    console.log(childProducts, "childProducts");
  }, [childProducts]);

  // Tabs work
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  // Tabs work
  const handleParentsChange = (event, newValue) => {
    if (parentValue == newValue) {
      setParentValue(-1);
    } else {
      setParentValue(newValue);
    }
  };
  const handleClick = (e, Link, id) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log(Link, id);
    history.push(Link);
  };

 

  // Set up the Intersection Observer to update `currentProductId`
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const categorySlug = entry.target.getAttribute("data-id");
            if(currentProdCategorySlug !== categorySlug){
              setCurrentProdCategorySlug(categorySlug)
            }          }
        });
      },
      {   
        root: null,
        rootMargin: "0px", // No extra margin
        threshold: 0.5, // Trigger when 80% of the product is visible
      }
    );

   // Filter out any undefined or null elements
  const elements = Object.values(productRefs.current).filter((el) => el instanceof Element);
  elements.forEach((el) => observer.observe(el));

  // Cleanup observer on component unmount
  return () => {
    elements.forEach((el) => observer.unobserve(el));
  };
  }, [products]);




 // Scroll to the first product of the selected category when the tab changes
 useEffect(() => {
  const firstProductInCategory = products.find(
    (product) => product.category?.slug === selectedCategory
  );

  console.log(firstProductInCategory, "firstProductInCategory", selectedCategory);
  
  if (firstProductInCategory) {
    const productIndex = products.findIndex(
      (product) => product.id === firstProductInCategory.id
    );
    
    console.log("Product index:", productIndex); // Log the index
    
    // Adjust the index (n - 1) to target the previous product
    const adjustedIndex = productIndex > 0 ? productIndex - 1 : 0 ;  // No change, or you can adjust it as per your needs
    console.log("Adjusted index:", adjustedIndex);

    // Ensure the adjusted index is valid (non-negative)
    if (adjustedIndex >= 0) {
      const element = productRefs.current[adjustedIndex];  // Use product.id to access ref
      console.log("Element at adjusted index:", adjustedIndex, element);

      // Check if element exists
      if (element) {
        // Get the height of your sticky header (AppBar)
        const appBarHeight = 45; // Set this to the height of your sticky AppBar

        setTimeout(() => {
          element.scrollIntoView({
            top: element.getBoundingClientRect().top + 100,
            behavior: "smooth",
            // block: "start",
            // inline: "start",
          });
        }, 100);
      } else {
        console.log("Element not found in productRefs:", firstProductInCategory.id);
      }
    }
  }
}, [selectedCategory, products]);


  return (
    <Page title="Category">
      <AddToCartPopup />
      <div id="wrapper">
        <div id="product-page">
          <MiscBanner
            image={categoryImg}
            urlLink={appState?.qrBranch ? `/${appState.orderQrCode}` : "/"}
          ></MiscBanner>
         <CategoryProductsV2 categories={appState.categories} products={products} />

       

         
          {!appState.payTableMode && appState.isAddToCartEnable ? (
            appState.isAddToCartEnable === null ? (
              <section id="reviewOrderButton">
                {appState.deviceId ? (
                  ""
                ) : appState.cart.length <= 0 ? (
                  ""
                ) : (
                  <div
                    id="process-btn"
                    className=" col-md-12 offset-xl-3 mr-auto col-xl-6"
                  >
                    <Button
                      variant="contained"
                      className="col-12  btn-main ProcessBtn text-capitalize"
                      onClick={toCart}
                    >
                      <strong>{t("product.Review-order")}</strong>

                      <span>
                        <span style={{ padding: "0 10px" }}>
                          {t("product.Total")}
                        </span>
                        <b>{appState.total}</b>
                        <span>
                          {" "}
                          &nbsp;
                          {get_lang(
                            appState.storeInfo.storeCurrency,
                            appState.langValue
                          )}
                        </span>
                      </span>
                    </Button>
                  </div>
                )}
              </section>
            ) : appState.cart.length <= 0 ? (
              ""
            ) : (
              <section id="reviewOrderButton">
                <div
                  id="process-btn"
                  className=" col-md-12 offset-xl-3 mr-auto col-xl-6"
                >
                  <Button
                    variant="contained"
                    className="col-12  btn-main ProcessBtn text-capitalize"
                    onClick={toCart}
                  >
                    <strong>{t("product.Review-order")}</strong>

                    <span>
                      {/* <span style={{ padding: "0 10px" }}>{t("product.Total")}</span> */}
                      <b>{parseFloat(appState.total).toFixed(3)}</b>
                      <span>
                        {" "}
                        &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </span>
                    </span>
                  </Button>
                </div>
              </section>
            )
          ) : (
            <>
              {appState.oldInvoice != null && appState.storeInfo?.hasPayMethods && (
                <section
                  id="reviewOrderButton"
                  className="offset-xl-3 mr-auto col-xl-6"
                >
                  <CheckoutBtn
                    label={"Pay-the-Bill"}
                    handleCheckout={() =>
                      history.push(`/checkout${appState.orderQrCode}`)
                    }
                  />
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default withTranslation()(Products);
