/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./SingleProductPopup.scss";
// material ui
import Button from "@mui/material/Button";
import { PieChart } from "react-minimal-pie-chart";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import IconButton from "@mui/material/IconButton";
import Icon from "@material-ui/core/Icon";
import chef from "../../assets/images/chef-choice.svg";
import mostSelling from "../../assets/images/most-selling.svg";
import trending from "../../assets/images/trending.svg";
import Megaphone from "../../assets/images/Megaphone.svg";
import DairyFree from "../../assets/images/dairy-free.svg";
import gluten from "../../assets/images/Gluten-Free.svg";
import vegan from "../../assets/images/vegan.svg";
import vegetarian from "../../assets/images/vegetarian.svg";
import mild from "../../assets/images/mild.svg";
import spicy from "../../assets/images/spicy.svg";
import nutFree from "../../assets/images/nut.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";
import SingleProduct from "../../Pages/SingleProduct";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../Alert";

function SingleProductPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { productInfo, handlecloseProductPopup } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(productInfo);
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`SingleProductPopup col-12  ${
          appState.langValue === "ar" ? "filterProductMainArabic" : " "
        }`}
      >
        <span className="d-flex align-items-center justify-content-end cross-btn col-12" >
            <IconButton
              onClick={handlecloseProductPopup}
              className="classForSvgColor mt-2"
            >
              <CloseIcon />
            </IconButton>
          </span>
        {appState.alert.show ? (
                <Alert
                  type={appState.alert.type}
                  show={appState.alert.show}
                  message={appState.alert.message}
                />
              ) : (
                ""
              )}
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-2 pb-3 col-xl-12 ">
          
          <SingleProduct
            productInfo={productInfo}
            type="popup-type"
            handlecloseProductPopup={handlecloseProductPopup}
          />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(SingleProductPopup);
