import { sortedByNumber } from "../utils";

export const handleAllAddonsWithOld = (
  allAddonCategories,
  appState,
  product,
  cartId
) => {
  let add = [];
  let total = 0;
  let newArr = [...allAddonCategories];
  let addonsInfo = [];
  let detailAddons = [];
  let arryAddonLimit = [];
  
  let localAddons = appState.cart.find((x) => x.id == cartId)?.addons;

  if (localAddons) {
    localAddons.forEach((localAddonItems) => {
      if (localAddonItems.product_id == product.id) {
        localAddonItems.total = function () {
          return this.quantity * this.price;
        };
        add.push(localAddonItems);
        total += localAddonItems.total();
      }
    });
  }

  if (newArr && newArr.length > 0) {
    newArr.forEach((element) => {
      let has_addons = false
      let objAddonLimit = {
        id: element.id,
        title: element.title,
        limit: element.limit || null,
        min: element.min || null,
        max: element.max || null,
        unique: element.unique || 0,
        required: element.required || null,
        addons_qty: 0,
        addons_length: element.product_addons.length,
        addons_category_name:element.category.name,
        order:element?.order ?? null
      };


      element.product_addons.forEach((addonItem) => {
        let found = add.some((ele) => ele.product_addon_id === addonItem.id);
        if(addonItem?.addon?.status)
       {
        has_addons = true;
        const data = {
          category_addon_id: element.id,
          product_addon_id: addonItem.id,
          required: element.required || null,
          min: element.min || null,
          max: element.max || null,
          unique: element.unique || 0,
          addon: addonItem.addon,
          description: addonItem.description,
          price: addonItem.price,
          maxQuantity: addonItem.quantity,
          product_id: product?.id,
          check_status: found,
          quantity: found
            ? add.find((x) => x.product_addon_id == addonItem.id).quantity
            : 0,
          total: function () {
            return this.quantity * this.price;
          },
        };

        addonsInfo.push(data);
       }
      });
      if(has_addons){
        arryAddonLimit.push(objAddonLimit);
      }
    });

     sortedByNumber(arryAddonLimit)
  }

  return { total, arryAddonLimit, addonsInfo };
};

// export const handleAllAddonsWithOld = (
//   allAddonCategories,
//   appState,
//   product,
//   cartId
// ) => {
//   let add = [];
//   var total = 0;
//   let newArr = [...allAddonCategories];
//   let detailAddons = [];
//   let arryAddonLimit = [];
//   let addonsInfo = [];
//   // let localAddons = await JSON.parse(
//   //   localStorage.getItem(`${storeInfo.slug}_cart`)
//   // ).filter((x) => x.product_id == item.id)[0];
//   let localAddons = appState.cart.filter((x) => x.id == cartId)[0];

//   if (localAddons !== undefined) {
//     localAddons = localAddons.addons;
//   }
//   if (newArr !== null && newArr !== undefined && newArr !== {}) {
//     if (newArr.length > 0) {
//       if (localAddons !== undefined) {
//         // if(localAddons.product_id.id == product.id)

//         localAddons.forEach((localAddonItems) => {
//           if (localAddonItems.product_id == product.id) {
//             localAddonItems.total = function () {
//               return this.quantity * this.price;
//             };
//             add.push(localAddonItems);
//             total += localAddonItems.total();
//           }
//         });
//       }
//       newArr.forEach((element, index) => {
//         let objAddonLimit = {
//           id: element.id,
//           title: element.title,
//           limit: element.limit != undefined ? element.limit : null,
//           min: element.min != undefined ? element.min : null,
//           max: element.max != undefined ? element.max : null,
//           unique: element.unique != undefined ? element.unique : 0,
//           required: element.required != undefined ? element.required : null,
//           addons_qty: 0,
//           addons_length: element.product_addons.length,
//         };
//         // console.log(element.product_addons)
//         element.product_addons.forEach((addonItem) => {
//           // It is for compare local stroge cart with live data
//           let found = add.some((ele) => {
//             // console.log(ele,element)
//             return ele.product_addon_id === addonItem.id;
//           });
//           if (appState.qrBranch != null) {
//             let getBranch = addonItem.branches.find(
//               (item) => item.branch.id == appState.qrBranch
//             );
//             if (getBranch != [] && getBranch != undefined) {
//               if (getBranch.quantity != undefined) {
//                 addonItem.quantity = parseInt(getBranch.quantity);
//               }
//               if (getBranch.price != undefined) {
//                 addonItem.price = getBranch.price;
//               }
//               if (getBranch.discount != undefined) {
//                 addonItem.discount = getBranch.discount;
//               }
//             }
//           }
//           if (!found) {
//             var data = {
//               category_addon_id: element.id,
//               product_addon_id: addonItem.id,
//               required: element.required != undefined ? element.required : null,
//               min: element.min != undefined ? element.min : null,
//               max: element.max != undefined ? element.max : null,
//               unique: element.unique != undefined ? element.unique : 0,
//               addon: addonItem.addon,
//               description: addonItem.description,
//               price: addonItem.price,
//               check_status: false,
//               maxQuantity: addonItem.quantity,
//               product_id: product.id,
//               quantity: 0,
//               total: function () {
//                 return this.quantity * this.price;
//               },
//             };
//             addonsInfo.push(data);
//           } else {
//             var data = {
//               category_addon_id: element.id,
//               product_addon_id: addonItem.id,
//               addon: addonItem.addon,
//               description: addonItem.description,
//               required: element.required != undefined ? element.required : null,
//               min: element.min != undefined ? element.min : null,
//               max: element.max != undefined ? element.max : null,
//               unique: element.unique != undefined ? element.unique : 0,
//               price: addonItem.price,
//               check_status: true,
//               maxQuantity: addonItem.quantity,
//               product_id: product.id,
//               quantity: add.find((x) => x.product_addon_id == addonItem.id)
//                 .quantity,
//               total: function () {
//                 return this.quantity * this.price;
//               },
//             };
//             addonsInfo.push(data);
//           }
//         });

//         arryAddonLimit.push(objAddonLimit);
//       });
//     }
//   }
//   return { total, arryAddonLimit, addonsInfo };
// };
