/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./startGroupOrderBtn.scss";
// material ui
import Button from "@mui/material/Button";
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";

import QRCodeStyling from "qr-code-styling";
import { RWebShare } from "react-web-share";
import ShareNetwork from "../../assets/images/ShareNetwork.svg"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import groupUserIcon from "../../assets/images/groupUser.svg"
import { createGroupBill } from "../../../../redux-store/action";
import { IconButton } from "@material-ui/core";


const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,

    dotsOptions: {
        color: "#000",
        type: "dot"
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 20
    }
});
function StartGroupOrderBtn(props) {
    const { t } = useTranslation();
    const appState = useSelector((state) => state);
    const appDispatch = useDispatch();
    const { handleClick } = props;




    return (
        <>
            <div

                className={`startGroupOrderBtnMain ${appState.langValue === "ar" ? "startGroupOrderBtnMainArabic" : " "
                    }`}
            >
                <section className="group_bill_parent my-2">
                    <div className="row m-0 your_order px-3">
                        <div className="col-12 p-0">
                            <div className="row m-0">
                                <Button
                                    onClick={handleClick}
                                    disabled={appState.freezScreen}
                                    className={`col-12 px-3 py-3  viewMenu-content  align-items-center ${appState.langValue === "ar"
                                        ? " familyGraphikArabic800 "
                                        : " familyPoppins300"
                                        }`}
                                >
                                    <span className="groupUserBtn">
                                        <img src={groupUserIcon} alt="" />
                                    </span>

                                    <h2 className={`m-0 ${appState.langValue === "ar"
                                        ? ""
                                        : "familyPoppins300"
                                        }`} >{t("group-order.Start-a-Group-Order")}</h2>

                                    <div className="icons">
                                        {/* <IconButton

                                        > */}
                                            {appState.langValue === "ar" ? (

                                                <ArrowBackIosIcon fontSize="inherit" />
                                            ) : (
                                                <ArrowForwardIosIcon
                                                    style={{ padding: 0 }}
                                                    fontSize="inherit"
                                                />
                                            )}
                                        {/* </IconButton> */}

                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default withTranslation()(StartGroupOrderBtn);
