/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./pickupDateTimePopUp.scss";
// material ui
import Button from "@mui/material/Button";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { get_lang } from "../../global-functions/apiGlobal";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux-store/store";
import axios from "axios";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { formatValue } from "../../utils/genral-functions";
import moment from "moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";

function PickupDateTimePopUp(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const {  setPickupDateTimePopup, setPickupAddsInfo } =
    bindActionCreators(actionCreators, appDispatch);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  const { availableTime, setAvailableTime } = props;
  const [enabledDays, setEnabledDays] = useState([]);
  const date = new Date();
  const [hours, setHours] = useState(availableTime?.selectedHours || 0);
  const [minutes, setMinutes] = useState(
    availableTime?.selectedMinutes || formatValue(date.getMinutes())
  );
  const [period, setPeriod] = useState(availableTime?.selectedPeriod || "AM");
  const [newValue, setNewValue] = useState(
    availableTime?.selectedDate || new Date()
  );
  const [nextBackBtn, setNextBackBtn] = useState(true);
  const [asaStatus, setAsaStatus] = useState(true);
  const currentTime = moment();
  const [slotsTime, setSlotsTime] = useState([])


  const handleClickConfirm = () => {
    if (nextBackBtn) {
      setNextBackBtn(false);
    } else {
      var decidedDate = moment(newValue).format("YYYY-MM-DD");
      setAvailableTime({
        ...availableTime,
        showDecidedTime: `${decidedDate} ${hours}:${minutes} ${period}`,
        decidedDate: `${decidedDate}`,
      });
      console.log({
        ...availableTime,
        showDecidedTime: `${decidedDate} ${hours}:${minutes} ${period}`,
        decidedDate: `${decidedDate}`,
      })
      setPickupDateTimePopup(false);
  }}

  const [isInRange, setIsInRange] = useState(false);

  const handleChangeHours = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 12) value = 0;
    if (value < 0) value = 12;
    setHours(formatValue(value));
  };

  const handleChangeMinutes = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 59) value = 0;
    if (value < 0) value = 59;
    setMinutes(formatValue(value));
  };
  const handleClickTime = (id) => {
    if(appState.pickupAddsInfo.pickup_time_slot_id == id){
      setPickupAddsInfo({ ...appState.pickupAddsInfo, pickup_time_slot_id: null });
     
    }else{
      setPickupAddsInfo({ ...appState.pickupAddsInfo, pickup_time_slot_id: id });
    }
  }

  const formatTime = (time) => {
    return moment(time, "HH:mm").format("hh:mm A");
  };

  const isTimeInRange = (currentTime, startTime, endTime) => {
    const format = "HH:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);

    // If the end time is before the start time, it means the range goes over midnight
    if (end.isBefore(start)) {
      return (
        currentTime.isBetween(start, moment("23:59", format)) ||
        currentTime.isBetween(moment("00:00", format), end)
      );
    } else {
      return currentTime.isBetween(start, end);
    }
  };

  const handleDateChange = (newDate) => {
    setNewValue(newDate);
  };

  // Function to disable tiles based on the day of the week
  const tileDisabled = ({ date, view }) => {
    // Disable dates based on day of the week
    if (view === "month") {
      const day = date.getDay();
      return !enabledDays.includes(day);
    }
    return false;
  };

  useEffect(() => {
    const currentDayName = moment().format("dddd");
    let start_time = "";
    let end_time = "";
    let flag = false;
    let activeDays = [];
    let time_slots = []

    const getEstimatedTime = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pickup/timing?branch=${appState?.qrBranch}`
        );
        let res = response.data.data;
        if (response.data.success) {
          res.forEach((ele) => {
            activeDays.push(Number(ele.day?.id) - 1);
            if (currentDayName == ele.day?.name && !flag) {
             
                start_time = ele.starting_time;
                end_time = ele.ending_time;
                if(ele?.pickup_time_slots?.length>0){
                  ele?.pickup_time_slots.forEach((slot) => {
                    time_slots.push({
                      id: slot.id,
                      start: moment(slot.starting_time, "hh:mm A"),
                      end: moment(slot.ending_time, "hh:mm A"),
                    })
                  })
              }

              setAvailableTime({
                ...availableTime,
                day: currentDayName,
                startTime: formatTime(moment(ele.starting_time, "HH:mm")),
                endTime: formatTime(moment(ele.ending_time, "HH:mm")),
              });
              const inRange = isTimeInRange(
                currentTime,
                formatTime(moment(ele.starting_time, "HH:mm")),
                formatTime(moment(ele.ending_time, "HH:mm"))
              );
              setIsInRange(inRange);
              const time = moment(ele.starting_time, "hh:mm A");
              setHours(time.format("hh"));
              setMinutes(time.format("mm"));
              setPeriod(time.format("A"));
              flag = true;
            }
          });
          setEnabledDays(activeDays);
          setSlotsTime(time_slots)
        setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)

      }
    };
    getEstimatedTime();
    if (!flag) {
      const currentHour = currentTime.format("hh");
      const currentMinute = currentTime.format("mm");
      const currentPeriod = currentTime.format("A");

      setHours(currentHour);
      setMinutes(currentMinute);
      setPeriod(currentPeriod);
    }



  }, []);

  useEffect(() => {
    if (hours > 0 || minutes > 0 || newValue) {

      // this is selected time by customer
      let selectedTime = moment(`${hours}:${minutes} ${period}`, "HH:mm A");
      // this is selected data by customer
      var decidedDate = moment(newValue).format("YYYY-MM-DD");
      var decidedTime = moment(selectedTime).format("HH:mm");
      // check that the selected time and date are avalaible or not
      var is_inRanpge = isTimeInRange(
        selectedTime,
        availableTime?.startTime,
        availableTime?.endTime,
      );

      setIsInRange(is_inRanpge);
    } else {
    }
  }, [hours, minutes, period, newValue]);

   // Calculate the max date (9 days from the current date)
   const maxDate = moment().add(appState.storeInfo?.pickupDays, "days").toDate();
  return (
    <>
      <div
        id="mySidenav"
        className={`pickupDateTime ${
          appState.langValue === "ar" ? "divideBillArabic" : " "
        }`}
      >
        <div className="container-fluid content px-4 pb-3 mt-4">
          <h1
            className={` d-flex align-items-center justify-content-between ${
              appState.langValue === "ar"
                ? " familyGraphikArabic800 "
                : " familyPoppins300"
            }`}
          >
            {t("Select Pickup schedule")}
            <span
              className={`${
                appState.langValue === "ar" ? "mr-auto" : "ml-auto"
              }`}
            >
              {" "}
              <IconButton onClick={() => setPickupDateTimePopup(false)}>
                {" "}
                <CloseIcon />{" "}
              </IconButton>{" "}
            </span>
          </h1>
          <hr />
          {nextBackBtn ? (
            <>
              <Calendar
                onChange={handleDateChange}
                value={newValue}
                minDate={new Date()} // Disable previous dates
                maxDate={maxDate} // Allow only up to 9 days from today
                className="custom-calendar"
                tileDisabled={tileDisabled} // Disable unwanted dates
                locale="en-US" // Ensure calendar starts on Sunday
              />
            </>
          ) : (
            <>
            
              {/* <div className="">
                <p>
                  {availableTime?.day} start time{" "}
                  <span style={{ color: "green" }}>
                    {availableTime?.startTime}
                  </span>{" "}
                  to end time{" "}
                  <span style={{ color: "red" }}>{availableTime?.endTime}</span>
                </p>
              </div> */}
              <div className="d-flex align-items-center justify-content-center">
              <div className="row">
                {slotsTime?.length > 0 &&
                  slotsTime.map((item, ind) => {
                    return <div className="col-lg-4 col-md-6 col-sm-6 col-6 p-2" key={ind}>
                      <Button 
                      onClick={()=> handleClickTime(item.id)}
                      variant={appState.pickupAddsInfo?.pickup_time_slot_id == item.id ? "contained" : "outlined"}
                      className={`  ${appState.pickupAddsInfo?.pickup_time_slot_id == item.id ? "option-btn-active" : "optionsBtn"}`}
                      > {item?.start.format("hh:mm A")} - {item?.end.format("hh:mm A")} </Button>
                      </div>
                    })}
                    </div>
                {/* <div
                  className="d-flex align-items-center justif y-content-center mt-70"
                  style={{ width: `80%`, textAlign: `center` }}
                >
                  <TextField
                    className="timeClass timeInputContainer"
                    placeholder="hh"
                    margin="no-margin"
                    value={hours}
                    type="number"
                    onChange={handleChangeHours}
                    inputProps={{ min: 1, max: 13 }}
                  />
                  <h1>:</h1>
                  <TextField
                    className="timeClass"
                    placeholder="mm"
                    margin="no-margin"
                    value={minutes}
                    type="number"
                    onChange={handleChangeMinutes}
                    inputProps={{ min: 0, max: 60 }}
                  />

                  <div className="d-flex align-items-center justify-content-center flex-column px-2 am-pm">
                    <Button
                      variant={period === "AM" ? "contained" : "outlined"}
                      className={`mb-2 ${
                        period === "AM" ? "active" : "non-active "
                      }`}
                      onClick={() => setPeriod("AM")}
                    >
                      AM
                    </Button>
                    <Button
                      variant={period === "PM" ? "contained" : "outlined"}
                      className={period === "PM" ? "active" : "non-active"}
                      onClick={() => setPeriod("PM")}
                    >
                      PM
                    </Button>
                  </div>
                </div> */}
              </div>

              {/* {!isInRange && (
                <p style={{ color: "red" }}>
                  Selected time is not within available range
                </p>
              )} */}
            </>
          )}

          <div
            className={`btn-div d-flex align-items-center justify-content-center ${
              !nextBackBtn ? "mt-70" : ""
            }`}
          ></div>

          <div className="bottomBtn col-md-12">
            <Button
              onClick={handleClickConfirm}
              disabled={!nextBackBtn && !appState.pickupAddsInfo.pickup_time_slot_id}
              className={` ${
                !nextBackBtn && !appState.pickupAddsInfo.pickup_time_slot_id ? "disabled-btn" : ""
              }  py-3 px-4 mt-3 confirmBtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {nextBackBtn ? t(`Confirm Date`) : t(`Confirm Time`)}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PickupDateTimePopUp);
