import React, { useState, useEffect } from "react";
import "./selectLoyalityCardSlider.scss";
import { useSelector, useDispatch } from "react-redux";
// cookie functions
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { AppBar, Button } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
// axios for api
import axios from "axios";
// material ui
import { Skeleton } from "@material-ui/lab";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/no-image.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { hexToRGBA } from "../../../../global-functions/colorConverter";
import { handleLoyalPunch, handleMaxPunch } from "../../../../global-functions/handlePunch";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { useSelectLoyaltyCard } from "../../hooks/useSelectLoyaltyCard";
const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
const SelectLoyalityCardSlider = (props) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(-1); // You need to initialize tabValue to one of the valid values.
  const { loyaltyCardId, setDiscountType, setLoyaltyCardId, setShowLoyaltyCardDetails, discountType } = props;
  const { appState, appDispatch, isLoading, value, LoyaltyCards, handleSelect } = useSelectLoyaltyCard(loyaltyCardId, setDiscountType, setLoyaltyCardId, setShowLoyaltyCardDetails);

  const handleChangeCard = (ind, id) => {
    if(tabValue === ind){
      setTabValue(-1);
      setDiscountType("")
			setLoyaltyCardId(null)
    }else{
      setLoyaltyCardId(id)
			setDiscountType("punch-card")
      setTabValue(ind)
    }
  };
  
  useEffect(() => {
    if(discountType != "punch-card"){
      setTabValue(-1)
    }
  }, [discountType])
  
 useEffect(() => {
   if(LoyaltyCards != null){
  if (LoyaltyCards.length == 1) {
    if(LoyaltyCards[0].show && LoyaltyCards[0].punchable){
      setTabValue(0)
      setDiscountType("punch-card")
      let loyaltyId = LoyaltyCards[0].id;
      setLoyaltyCardId(loyaltyId != undefined ? loyaltyId : null);
    }
  }
 }
 }, [LoyaltyCards])
 
  return (
    <div className="px-3" id="wrapperSelectLoyaltySlider">
      {isLoading ? (
        <>
          <div className="row m-0 mb-4 mt-3 d-flex flex-row">
            <div className="col-6  ">
              <Skeleton height={50} />
            </div>
            <div className="col-10 ">
              <Skeleton height={50} />
            </div>
            <div className="col-3 ">
              <Skeleton height={50} />
            </div>
          </div>
        </>
      ) : (
        LoyaltyCards != null &&  LoyaltyCards.length >0 &&(
          <>
            <div
              className={`pt-1 mt-2 ${appState.langValue === "ar"
                  ? "directionRt classForArabicReOrderMain"
                  : ""
                } ${appState.langValue == "ar" ? "arabicFamilyParent" : " "}`}
            >
              <h1
                className={`reOrderheading familySora600  my-3 ${appState.langValue === "ar" ? "text-right" : "text-left"
                  }`}
              >
                Choose loyalty card
              </h1>
            </div>

            <div
              className={`row loyaltyTabsParent ${appState.langValue === "ar"
                  ? "loyaltyTabsParentArabic pr-2 pl-3"
                  : "loyaltyTabsParentEn pr-3 pl-2"
                } `}
            >
              <AppBar className={`appBar featuredAppBar ${loyaltyCardId != null  ? "showIndicator" : "dontshowIndicator"}`} position="static">
                <Tabs
                  value={tabValue} 
                  aria-label="icon label tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  {LoyaltyCards.map((items, ind) => {
                    const {show, loyaltyLogo, loyaltybgImage, loyaltyName, description, active, id, punchable, primaryColor, secondaryColor, rewards, redeemable } =
                      items;
                    return (
                      show &&
                      <Tab
                        key={ind}
                        className={`categoryTab ${(punchable == false)
                          ? "pointer-events-none disableClass"
                          : ""
                        } ${active === true ? "cardActive" : "cardNotActive"
                        }`}
                        style={{
                          border: `1px solid #0000 `,
                          background: `#0000`,
                          boxSizing: 'border-box',
                        }}
                        onClick={()=> handleChangeCard(ind, id)}
                        icon={
                          <div className="w-100 item black " key={ind}>
                            <a className="w-100 cursor-pointer text-decoration-none">
                              <div
                                style={{
                                  backgroundImage: `linear-gradient(to bottom, ${hexToRGBA(secondaryColor, 0) ?? "rgba(196, 59, 44, 0.00)"}, ${primaryColor ?? "#C43B2C"}), url(${loyaltybgImage ?? noImg})`,
                                  backgroundPosition: 'center center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',

                                }}
                                className={` w-100 CardRow ${(punchable == false)
                                  ? "pointer-events-none disableClass"
                                  : ""
                                  } ${active === true ? "cardActive" : "cardNotActive"
                                  }`}

                              >
                                <div className="cardImageInner d-flex  align-items-center justify-content-between ">
                                    <div className="largeAvatar pt-3">
                                      <Avatar
                                        variant="square"
                                        src={
                                          loyaltyLogo
                                            ? loyaltyLogo
                                            : "../images/no-image.svg"
                                        }
                                      ></Avatar>
                                    </div>
                                    {(rewards?.punch?.total != undefined &&
                                    active) ? (
                                      <div className="punchRatingMain  ">
                                        {(redeemable && !punchable )? <h6 className="readyToCalime">Ready to Claim</h6> :  <StyledRating
                                          name="customized-color"
                                          defaultValue={handleLoyalPunch(
                                            rewards?.punch?.collected,
                                            rewards?.punch?.total,
                                            5
                                          )}
                                          max={handleMaxPunch(
                                            rewards?.punch?.total
                                          )}
                                          getLabelText={(value) =>
                                            `${value} Heart${
                                              value !== 1 ? "s" : ""
                                            }`
                                          }
                                          precision={0.5}
                                          icon={
                                            <img
                                              src={`/images/loyalCardIconFilled.svg`}
                                            />
                                          }
                                          emptyIcon={
                                            <img
                                              style={{ opacity: 0.55 }}
                                              src={`/images/loyalCardIconUnFilled.svg`}
                                            />
                                          }
                                          disabled
                                        />}
                                       
                                      </div>
                                    ) : (
                                      <p className="pt-3 cardPrice familyPoppins">
                                        {" "}
                                        No Punchs{" "}
                                      </p>
                                    )}
                                  </div>


                                  <div className=" cardContent py-3 d-flex align-items-center flex-column">
                                    <h1 className="cardProductName align-self-start   text-uppercase familyPoppins300 ">
                                      {get_lang(
                                        loyaltyName,
                                        appState.langValue
                                      )}
                                    </h1>
                                    <h4
                                      className={` text-right align-self-end  text-lowercase cardPrice familyPoppins ${
                                        appState.langValue == "ar" ? "" : ""
                                      }`}
                                    >
                                      {` ${rewards?.punch?.collected ?? 0 } of ${rewards?.punch?.total} Punches`}
                                    </h4>
                                    {/* {get_lang(
                                      description,
                                      appState.langValue
                                    ) != null ? (
                                      <h4
                                        className={` text-right align-self-end  text-lowercase cardPrice familyPoppins ${
                                          appState.langValue == "ar" ? "" : ""
                                        }`}
                                      >
                                        {get_lang(
                                          description,
                                          appState.langValue
                                        ).substring(0, 30)}...
                                      </h4>
                                    ) : (
                                      <h4  className="py-2"></h4 >
                                    )} */}
                                  </div>
                              </div>
                            </a>
                          </div>
                        }
                      />
                    );
                  })}
                </Tabs>
              </AppBar>

            </div>
          </>
        )
      )}
    </div>
  );
};

export default withTranslation()(SelectLoyalityCardSlider);
