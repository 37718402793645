/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import "./deliveryAddsPopup.scss";
import { useHistory } from "react-router-dom";

// material ui

import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  getDineInOrder,
  getPayTableInvoice,
  getTableInvoice,
} from "../../../../redux-store/action";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import {
  Avatar,
  InputLabel,
  TextareaAutosize,
  useMediaQuery,
} from "@material-ui/core";
import noImg from "../../assets/images/no-image.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";
import GoogleMapss from "../googleMap/googleMap";
import Dialog from "@mui/material/Dialog";
import {
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import CollapsFields from "../collapsFields/CollapsFields";
// import { TroubleshootSharp } from "@mui/icons-material";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CloseIcon from "@mui/icons-material/Close";
import SelectedFields from "../../components/selectFields/selectedfields";

function NewDeliveryPopup({ table }) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:1200px)");
  const [options, setOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false)
  const [areasObj, setAreasObj] = useState([])
  const [areaBranchId, setAreaBranchId] = useState(null)
  // This is used only for the example
  const [location, setLocation] = useState(
    appState.deliveryAddsInfo != null
      ? appState.deliveryAddsInfo
      : {
          lat: 24.872130930013252,
          lng: 66.9828699111145,
        }
  );

  const handleChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLocation({
      ...location,
      [name]: value,
    });

    if (name === "city_id") {
      // Find the corresponding label based on the selected value
      const selectedOption = options.find((option) => option.value === value);
      const label = selectedOption ? selectedOption.label : "";
      console.log(selectedOption, "selectedOption");
      appDispatch({
        type: "setDeliveryAddsInfo",
        deliveryAddsInfo: {
          ...appState.deliveryAddsInfo,
          city_id: value,
          city_name: label,
         
        },
        
      });
      setDisableBtn(true)
    } else if (name === "area_id") {
      const selectedOption = areaOptions.find(
        (option) => option.value === value
      );
      const label = selectedOption ? selectedOption.label : "";
      setAreaBranchId(selectedOption?.branchId)

      appDispatch({
        type: "setDeliveryAddsInfo",
        deliveryAddsInfo: {
          ...appState.deliveryAddsInfo,
          area_id: value,
          area_name: label,
          qrBranch: selectedOption?.branchId
        },
      });
      setDisableBtn(false)
    }
  };


  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };
  const getAreas = (areasArr) =>{
    console.log(areasObj, "areasObj", areasArr, "areasArr");
    if (appState.deliveryAddsInfo?.area_id != "") {
      let areas = areasObj.length > 0 ? areasObj : areasArr
      let area_Options = [];
      let findAreas = areas.filter(
        (ele) => ele.cityId == appState.deliveryAddsInfo?.city_id
      )

      console.log(findAreas, appState.deliveryAddsInfo?.city_id, "findAreas");
      // if (findAreas?.length > 0) {
      //   findAreas.forEach((ele) => {
      //     area_Options.push({ value: ele.value , label: ele.label });
      //   });
      // }
      console.log(area_Options)
      setAreaOptions(findAreas);
    }
  }
  useEffect(() => {
    if (appState.countryCityArea?.length > 0) {
      let optionData = [];
      let areasArr = [];
  
      const citySet = new Set(); // To track unique city IDs
      const areaSet = new Set(); // To track unique area IDs // get distinguished cities
  
      // Traverse each branch in the data
      appState.countryCityArea.forEach((obj) => {
        // Traverse each city in the obj's areas
        obj.areas.forEach((cityData) => {
          const city = cityData.city; // Extract the city object
          console.log(obj.id, "branch.id");
          // Check if the city ID is already in the set
          if (!citySet.has(city.id)) {
            citySet.add(city.id); // Add city ID to the set
            optionData.push({ value: city.id, label: city.name }); // Push city info
          }
          
          // Traverse each area within the city
          cityData.areas.forEach((area) => {
            // Check if the area ID is already in the set
            if (!areaSet.has(area.id)) {
              areaSet.add(area.id); // Add area ID to the set
              areasArr.push({
                branchId: obj?.branch?.id,
                cityId: city.id,
                value: area.id,
                label: area.name,
              });
            }
          });
        });
      });
  
      console.log(optionData, appState.countryCityArea);
      setOptions(optionData);
      setAreasObj(areasArr);
      // getAreas(areasArr)
      console.log(areasArr, "areasArr");
    }
  
    setDisableBtn(
      appState.deliveryAddsInfo?.city_id && appState.deliveryAddsInfo?.area_id
        ? false
        : true
    );
  }, []);
  
  useEffect(() => {
    if (appState.deliveryAddsInfo?.city_id != "") {
     getAreas([]);
    }
  }, [appState.deliveryAddsInfo?.city_id,areasObj]);

  const handleConfirm = () => {
    const { address, lat, lng } = location;
    appDispatch({ type: "SET_BRANCH", qrBranch: areaBranchId });

    appDispatch({
      type: "setDeliveryAddsPopup",
      deliveryAddsPopup: false,
    });
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={appState.deliveryAddsPopup}
        className="payTableBackdrop"
      />

      <div
        id="mySidenav"
        className={`deliveryAddsPopup pb-3  ${
          appState.langValue == "ar" ? "ArabicDeliveryAddsPopup" : " "
        } ${appState.deliveryAddsPopup ? "deliveryAddsPopupActive" : ""}`}
      >
        <div id="storeTitlePayTable">
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Link to="/">
                    <div className="DeliverySvg">
                      <DeliveryDiningIcon />
                    </div>
                  </Link>

                  <div className="mt-2 px-3">
                    <h1
                      className={`d-flex familyPoppins300 ${
                        appState.langValue === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      {t("Delivery")}
                    </h1>

                    {
                      <h1
                        className={`d-flex align-items-center  tableNo ${
                          appState.langValue === "ar"
                            ? "text-right familyGraphikArabic"
                            : "text-left familyPoppins300"
                        }`}
                      >
                        <span>
                          {appState.deliveryAddsInfo?.city_name
                            ? get_lang(
                                appState.deliveryAddsInfo?.city_name,
                                appState.langValue
                              )
                            : `City`}
                        </span>
                        <span
                          className={` px-1 ${
                            appState.langValue === "ar"
                              ? "text-right familyGraphikArabic"
                              : "text-left familyPoppins300"
                          }`}
                        >
                          {" "}
                          {appState.deliveryAddsInfo?.area_name
                            ? ` >  ${get_lang(
                                appState.deliveryAddsInfo?.area_name,
                                appState.langValue
                              )}`
                            : ` >  Area`}{" "}
                        </span>
                      </h1>
                    }
                  </div>
                </div>
                {appState.langValue == "ar" ? (
                  <Button
                    className={`infoBtn ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "familyManrope600"
                    }`}
                    variant="outlined"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    EN
                  </Button>
                ) : (
                  <Button
                    className={`infoBtn pb-3 ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "classForAr familyGraphikArabic600"
                    }`}
                    onClick={(e) => arLanguageHandle(e)}
                    variant="outlined"
                  >
                    عربي
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 p-0 selectLocations">
          <div className="col-12 ">
            <div className="px-2 mt-2  ">
              {/* <CollapsFields
                onChange={handleChangeText}
                name="city_id"
                value={location?.area}
                label="Location"
                options={appState.countryCityArea}
              /> */}
              <SelectedFields
                handleChange={handleChangeText}
                name="city_id"
                value={appState.deliveryAddsInfo?.city_id}
                label="City"
                options={options}
              />

              {areaOptions.length > 0 && (
                <div
                  className="mt-3"
                  style={{
                    direction: appState.langValue == "ar" ? "rtl" : "ltr",
                  }}
                >
                  <SelectedFields
                    handleChange={handleChangeText}
                    name="area_id"
                    value={appState.deliveryAddsInfo?.area_id}
                    label="Area"
                    options={areaOptions}
                  />
                </div>
              )}
              {/* <FormControl sx={{ m: 1, minWidth: 120 }}> */}
              {/* <p>{label}</p> */}
              {/* <Select
            value={location.area_name}
            onChange={handleChangeText}
            // name={name}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {options.map((item, ind) => (
              <MenuItem  key={ind}  value={item.value}>{get_lang(item.label, appState.langValue)}</MenuItem>
            ))}
          </Select> */}
              {/* <FormHelperText>Without label</FormHelperText> */}
              {/* </FormControl> */}
            </div>
          </div>
        </div>

        <div className="container-fluid content px-4 mt-2 confirmBtnClass">
          <div className="row mt-3 ">
            {/* {appState.tableInvoice != null && } */}
            <div className="col-12">
              {location && (
                <Button
                  className={` py-3 px-4  viewMenuBtn ${disableBtn ? "disable-confirm-btn" : ""}  ${ appState.langValue === "ar" ? " familyGraphikArabic600" : "" }`}
                  onClick={handleConfirm}
                >
                  {/* <img src="./images/billIcon.svg" className="mx-2" alt="bill" /> */}
                  {t("Confirm")}
                  {/* <span > {t("contact.I-already-ordered-I-want-to-pay.normal")} <strong> {t("contact.I-already-ordered-I-want-to-pay.bold")} </strong></span> <span >{appState.langValue === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}</span>  */}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(NewDeliveryPopup);
