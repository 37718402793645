/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./OptionalRewards.scss";
// material ui
import Button from "@mui/material/Button";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { get_lang } from "../../../../global-functions/apiGlobal";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Backdrop, useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import noImg from "../../assets/images/no-image.svg";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  handleChangeTotal,
  handleTotal,
} from "../../../../global-functions/customeObj";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function OptionalRewards(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const matches = useMediaQuery("(min-width:1200px)");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { currentOrders, optionalProducts } = props;
  const [requiredQty, setRequiredQty] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  const handleconfirm = () => {
    let newArr = [...appState.optionalRewardsDetials];
    let data = [];
    let id = null;
    
    newArr.forEach((ele) => {
      if (ele.free_qty > 0) {
        data.push({ order_id: ele.order_id, quantity: ele.free_qty });
        if (id == null) {
          id = ele.loyalty_card_id;
        }
      }
    });
    // setVoucherObj({ id: id, discount: data });
    
    
    appDispatch({
      type: "setLoyaltyRewardObj",
      loyaltyRewardObj: { id: id, discount:  appState.openBill? data : null },
    });
    appDispatch({
      type: "setOptionalRewardsPopUp",
      optionalRewardsPopUp: false,
    });
    // appDispatch({
    //   type: "setOptionalRewardsPopUp",
    //   optionalRewardsPopUp: false,
    // });
  };

  const handleSelect = (id) => {
    setSelectedItemId(id);
  };

  const handleIncrement = (id) => {
    let newArry = [...appState.optionalRewardsDetials];
    for (let i = 0; i < newArry.length; i++) {
      if (
        newArry[i].order_id == id &&
        newArry[i].free_qty < requiredQty &&
        newArry[i].free_qty != newArry[i].order_quantity &&
        totalQty < requiredQty
      ) {
        newArry[i].free_qty = newArry[i].free_qty + 1;
        let total = totalQty + 1;
        setTotalQty(total);
        let orderIndex = appState.cart.findIndex((item) => item.id == id);
        
        if (orderIndex !== -1) {
          // Clone the found order and update the 'loyalty_reward' property
          var updatedOrder = {
            ...appState.cart[orderIndex],
            loyalty_reward: newArry[i].free_qty, // Update the 'loyalty_reward' property as needed
          };
        }
        appState.cart[orderIndex] = updatedOrder;
        // appDispatch({ type: "updateCart", cart: newOrderObj });

        
      }
    }
    let res = handleChangeTotal(newArry, appState, appDispatch, "product");
    // setTotal(res.totalAmount)
    appDispatch({
      type: "setInvoiceTaxesChargesExtras",
      invoiceTaxesChargesExtras: res,
    });
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: newArry,
    });
  };

  const handleDecrement = (id) => {
    let newArry = [...appState.optionalRewardsDetials];
    for (let i = 0; i < newArry.length; i++) {
      if (newArry[i].order_id == id && newArry[i].free_qty > 0) {
        newArry[i].free_qty = newArry[i].free_qty - 1;
        let total = totalQty - 1;
        setTotalQty(total);
        let orderIndex = appState.cart.findIndex((item) => item.id == id);
        
        if (orderIndex !== -1) {
          // Clone the found order and update the 'loyalty_reward' property
          var updatedOrder = {
            ...appState.cart[orderIndex],
            loyalty_reward: newArry[i].free_qty, // Update the 'loyalty_reward' property as needed
          };
        }
        appState.cart[orderIndex] = updatedOrder;
        // appDispatch({ type: "updateCart", cart: newOrderObj });

        
      }
    }
    let res = handleChangeTotal(newArry, appState, appDispatch, "sub");
    // setTotal(res.totalAmount)
    appDispatch({
      type: "setInvoiceTaxesChargesExtras",
      invoiceTaxesChargesExtras: res,
    });
    appDispatch({
      type: "setOptionalRewardsDetails",
      optionalRewardsDetials: newArry,
    });
  };
  useEffect(() => {
    const firstCall = () => {
      let flag = true;
      let total = totalQty
      
      appState.optionalRewardsDetials.forEach((ele) => {
        if (flag) {
          setRequiredQty(ele.requiredQty);
          flag = false;
        }
        
        total = total + ele.free_qty
      });
      setTotalQty(total)
    };
    firstCall();
  }, []);
  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`optionalRewardsMain ${appState.langValue === "ar" ? "optionalRewardsMainArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3">
          <div className="headParent pt-3 d-flex justify-content-between alignn-items-center">
            <IconButton onClick={() => appDispatch({
              type: "setOptionalRewardsPopUp",
              optionalRewardsPopUp: false,
            })} className="classForSvgColor" >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F" />
              </svg>

            </IconButton>
            <div className="d-flex popupHeading align-items-center mt-2">
              <h1
                className={` mb-0 text-center ${appState.langValue === "ar"
                  ? "text-right familyGraphikArabic800 "
                  : " familyPoppins300"
                  }`}
              >
                {" "}
                Free Products
              </h1>
            </div>

            {/* <IconButton
              onClick={() =>
                appDispatch({
                  type: "setOptionalRewardsPopUp",
                  optionalRewardsPopUp: false,
                })
              }
              className="classForSvgColor mt-2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                  fill="#22282F"
                />
              </svg>
            </IconButton> */}
          </div>
          <hr className="my-1" />

          <div className={`tabsParent`}>
            <div id="TagCheckBoxParent">
              {/* for prroduct */}
              <section
                id="Products"
                className={` ${optionalProducts.length > 0 ? "" : "noProducts"
                  } `}
              >

                {isLoading
                  ? [0, 1, 2, 3, 4].map((a) => (
                    <div key={a} className="row m-0 mb-4 mt-3">
                      <div className="col-3 col-md-2 px-2">
                        <Skeleton className="prRectSkeleton" />
                      </div>
                      <div className="col-9 col-md-10 px-2">
                        <Skeleton
                          height={30}
                          style={{
                            transform: "translate(2px, 0)",
                            marginBottom: "10px",
                          }}
                        />
                        <Skeleton height={25} />
                        <Skeleton height={25} />
                      </div>
                    </div>
                  ))
                  : appState.optionalRewardsDetials &&
                  (appState.optionalRewardsDetials.length == 0 ? (
                    <div className="text-center mt-5">
                      <h4 className="text-muted">"No loyalty cards found"</h4>
                    </div>
                  ) : (
                    <div className="row m-0 productsRow">
                      {appState.optionalRewardsDetials.map((item, ind) => {
                        return (
                          // <div
                          //   key={ind}
                          //   className={`row py-2 mx-0 w-100 CardRow radio-item ${
                          //     (item.active)
                          //       ? "cardActive"
                          //       : "cardNotActive"
                          //   } ${selectedItemId !=  null ? selectedItemId === item.id ? "selected" : "" : loyaltyCardId === item.id ? "selected" : ""}`}
                          //   onClick={() => handleSelect(item.id)}
                          // >
                          <div
                            key={ind}
                            className={`row py-2 mx-0 w-100 CardRow radio-item `}
                          >
                            <div className="col-8 cardContent d-flex align-items-center  cardImage ">
                              
                              <div className="cardImageInner d-flex justify-content-center">
                                {/* <div className="largeAvatar">
                                  <Avatar
                                    variant="square"
                                    src={
                                      item?.product?.image
                                        ? item?.product?.image
                                        : "../images/no-image.svg"
                                    }
                                  ></Avatar>
                                </div> */}
                                <img src={
                                  item?.product?.image
                                    ? item?.product?.image
                                    : noImg
                                } alt="" />
                              </div>

                              <div className="px-3 cardContentInner">
                                <h1 className="cardProductName text-capitalize familyPoppins300 ">
                                  {get_lang(
                                    item?.product?.name,
                                    appState.langValue
                                  )}
                                </h1>
                                <h4 className=" text-capitalize cardPrice familyPoppins  ">
                                  Pay for:{" "}
                                  {Number(item?.order_quantity) -
                                    Number(item?.free_qty)}
                                </h4>
                                <h4 className=" text-capitalize cardPrice familyPoppins  ">
                                  Free Product: {Number(item?.free_qty)}
                                </h4>
                              </div>
                            </div>
                            <div className="col-4 cardContent d-flex align-items-center justify-content-center">


                              <div className=" cardContentInner d-flex flex-column align-items-end">
                                <h1 className="cardProductName  text-capitalize familyPoppins300 ">
                                  Free Products
                                </h1>
                                <div className={` ${appState.langValue === "ar"
                                  ? "d-flex flex-row-reverse align-items-center"
                                  : "d-flex  align-items-center"
                                  }`} >
                                  <span className="rewardIncDec minusButton">
                                    <IconButton
                                      id="IncDecItemQuan"
                                      size="small"
                                      className={` ${item?.free_qty == 0 ? "btnDisabled" : "qtyBtn"}`}
                                      onClick={() =>
                                        handleDecrement(item.order_id)
                                      }
                                      disabled={item?.free_qty == 0}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </span>

                                  <span

                                    className="qtyValue"
                                  >
                                    {item?.free_qty}
                                  </span>
                                  <span
                                    className={` rewardIncDec plusButton ${appState.langValue === "ar"
                                      ? "d-flex flex-column justify-content-center"
                                      : ""
                                      }`}
                                  >
                                    <IconButton
                                      id="IncDecItemQuan"
                                      size="small"
                                      onClick={() =>
                                        handleIncrement(item?.order_id)
                                      }
                                      className={` ${(item?.free_qty == requiredQty) || (totalQty === requiredQty) ? "btnDisabled" : "qtyBtn"}`}
                                      disabled={(item?.free_qty == requiredQty) || (totalQty === requiredQty)}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                {/* {} */}
                {/* 
                <div class="box first"></div>
<div class="box second"></div> */}
              </section>
            </div>
          </div>
          <div className="bottomBtn">
            <div className="d-flex justify-content-between">
              <h4 className=" text-capitalize cardPrice familyPoppins300  ">
                {/* {item?.order_quantity} */}
                Required Quantity/Selected:
              </h4>
              <h4 className="mx-1 text-capitalize cardPrice familyPoppins300  ">
                {/* {item?.order_quantity} */}
                {requiredQty}/{totalQty}
              </h4>
            </div>
            <Button
              onClick={handleconfirm}
              className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("checkOut.Confirm")}</span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(OptionalRewards);
