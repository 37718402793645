import * as React from "react";
import { Skeleton } from "@mui/material";
import { get_lang } from "../../../../global-functions/apiGlobal";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import './seletedFields.scss'

export default function SelectedFields(props) {
  const { value, handleChange, label='', name, options } = props;
  const appState = useSelector((state) => state);
  const { t } = useTranslation();

  return (
    <div id={`Selected-fields`} style={{ background: "#ffff" }}>
      {options ? (
        <FormControl 
          sx={{ m: 1, minWidth: 120 }} 
          className={appState.langValue === "ar" ? "rtl-class" : ""}
          variant="outlined"
        >
          <InputLabel>{t(label)}</InputLabel>
          <Select
            value={value}
            onChange={handleChange}
            name={name}
            displayEmpty
            label={t(label)}
            inputProps={{ "aria-label": t(label) }}
            placeholder={t(label)}
          >
            {options.map((item) => (
              <MenuItem key={item?.value} value={item.value}>
                {get_lang(item?.label, appState.langValue)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Skeleton height={20} width={200} />
          <Skeleton height={20} width={200} />
        </>
      )}
    </div>
  );
}
