/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./AddTipCustom.scss";
// material ui
import Button from '@mui/material/Button';
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { get_lang } from "../../global-functions/apiGlobal";




function AddTipCustom(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
  }
  const [inputRef] = useFocus()


  const [customAmount, setCustomAmount] = useState("")

  const handleCustomAmount = (event) => {
    const re = /^[.0-9\b]+$/;
    // console.log(event )

    // console.log('ejdfkdj')
    // if(Number(event.target.value) <= parseFloat(subTotal).toFixed(3) || event.nativeEvent.data == null){
    if (event.target.value === '' || event.nativeEvent.data == null || re.test(event.target.value)) {



      if (event.target.value.includes('.')) {
        // console.log(typeof customAmount)
        if (event.target.value.split('.')[1].length < 3) {
          // console.log(typeof customAmount)
          // console.log(typeof customAmount)
           // console.log(typeof customAmount)
           props.setCustomeTip(event.target.value)
           setCustomAmount(event.target.value);
           // console.log(typeof customAmount)
        }
      } else {
        props.setCustomeTip(event.target.value)
          setCustomAmount(event.target.value);
      }


      // }
    }

  };

  useEffect(() => {
    if (props.customeTip > 0) {
      setCustomAmount(props.customeTip)
    }
  }, [])

  return (
    <>
      <div
        id="mySidenav"
        className={`AddTipCustom ${appState.langValue === "ar" ? "divideBillArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 mt-4">
          <h1
            className={` ${appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins300"
              }`}
          >
            {t("checkOut.Add-a-tip")}
          </h1>

          <div className="inputFieldParent my-3 py-5">
            <TextField
              inputProps={{ max: 3, inputMode: "decimal", pattern: "[0-9]*" }}
              type="decimal"
              value={customAmount}
              label={null}
              variant="outlined"
              ref={inputRef}
              onChange={(event) => handleCustomAmount(event)}
              id="outlined-basic"
              name="phoneNumber"
              placeholder="0.00"
              autoFocus={true}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
            <h5 className="text-center familyPoppins300">                        {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
</h5>
          </div>
          <div className="bottomBtn">
            <Button
              onClick={() => props.setCustomeShow(false)}
              disabled={customAmount < 0}
              className={` py-3 px-4  mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                } ${customAmount < 0 ? `disabledBtn` : `confirmBtn`}`}
            >
              {" "}
              <span>{t("checkOut.Confirm")}</span>{" "}
            </Button>
            {/* <Button
              onClick={handleChangeSplit}
              className={` py-3 px-4 mt-3 deletebtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {" "}
              <span>{t("checkOut.Delete-Split")}</span>{" "}
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}



export default withTranslation()(AddTipCustom);