import { getCookie } from "../../global-functions/cookieFunction";

const initial_state = {
  langValue: "ar",
  isClosed: undefined,
  cart: [],
  subTotal: 0,
  total: 0,
  storeName: "",
  storeDescription: "",
  storeAddress: "",
  storeTimeSlots: "",
  storeDeliveryCharges: [],
  logo: "",
  cover: "",
  storeEmail: "",
  loggedIn: Boolean(getCookie("tokenAuth")),
  loggData: "",
  wallet: "",
  visibleFields: "",
  addonCart: [],
  sessionData: {
    slug: "",
    store_id: "",
    vendor_id: "",
    benchId: "",
    ipAddress: "",
  },
  storeInfo: {
    businessName: "",
    description: "",
    phone: "",
    logo: "",
    cover: "",
    email: "",
    workingHours: [],
    primaryColor: "",
    secondaryColor: "",
    storeProducts: [],
    storeAreas: [],
    storeDeliveryCharges: [],
    visbleFields: [],
    storeMinimumOrder: "",
    benchId: 0,
    slug: "",
    storeBusyTime: "",
    countries: [],
  },
  loginToken: "d",
  userInfo:
    JSON.parse(getCookie("Profile")) !== null
      ? JSON.parse(getCookie("Profile"))
      : [],
  serviceMode: [],
  parentCategories: [],
  categories: [],
  products: [],
  productsForSearch: [],
  categoryProducts: [],
  singleProduct: [],
  featuredProducts: [],
  formData:
    JSON.parse(localStorage.getItem("formData")) !== null
      ? JSON.parse(localStorage.getItem("formData"))
      : [],
  deliveryCharges: localStorage.getItem("deliveryCharges")
    ? localStorage.getItem("deliveryCharges")
    : 0,
  timeSlots: [],
  carCompanies: [],
  open: false,
  userOpen: false,
  alert: {
    show: false,
    message: "",
    type: "",
  },
  socialLinks: [],
  storeBusy: false,
  qrBranch: null,
  branchName: "",
  qrTable: null,
  tableNo: "",
  openOrder: {},
  acceptedPayments: {},
  newApi: {},
  isLoading: false,
  storeFound: false,
  openBill: false,
  freezScreen: false,
  loginModal: false,
  otpModal: false,
  signUpPhoneModal: false,
  signUpModal: false,
  fieldValues: [],
  qrRequired: false,
  qrPickup: null,
  addToCartPopup: false,
  saveCardList: [],
  cartProd: [],
  reviewModal: false,
  orderInvoice: [],
  storeTime: [],
  qrOptionStatus: false,
  deviceId: "",
  userCurrentStatus: false,
  storeDomain: "",
  splitBillOptions: false,
  payForItems: false,
  divideBill: false,
  payCustomAmount: false,
  shareInvoiceQrcode: false,
  splitBill: false,
  meta: [],
  progressive: true,
  loginValues: {},
  createdAt: null,
  promotionBanner: false,
  promotionCreatedAt: null,
  payTablePopUp: false,
  payTableMode: false,
  payTableOrders: [],
  tableInvoice: null,
  oldInvoice: null,
  loginSkip: false,
  paymentPopUp: false,
  branch: "",
  cardList: [],
  productTags: [],
  productDietaries: [],
  filterProductMode: false,
  tags: [],
  dietaries: [],
  productsLength: 0,
  currentCategory: [],
  campaignState: [],
  groupOrderPopup: false,
  groupId: null,
  loginModalScreen: false,
  groupBillMode: false,
  groupBillRes: "",
  orderProdQty: [],
  scrollKeyword: "",
  payTableDiscount: 0,
  payTableCharges: [],
  payTableTotalCharges: [],
  payTableTotalTax: 0,
  invoiceTaxesChargesExtras: [],
  combos: [],
  hotelMode: false,
  hotelQrCode: null,
  hotelObj: null,
  isAddToCartEnable: false,
  currentInvoiceId: null,
  opneBillId: null,
  shareBill: [],
  shopMode: [],
  shareBillPopup: false,
  shareBillUrl: "",
  loyalityPopupMode: false,
  loyaltyObj: [],
  selectLoyaltyCardPopup: false,
  selectedLoyaltyId: null,
  optionalRewardPopUp: false,
  optionalRewardsDetials: [],
  rewardsDetails: [],
  loyaltyRewardObj: [],
  loyaltyBanner: false,
  sourceOfMeetBanner: false,
  sourceOfMeetBannerImage: false,
  sourceOfMeetBannerImageUrls: [],
  storeDiscount: null,
  pusherActive: false,
  pushSubscription: null,
  featuredProductPopupMode: false,
  optionalRewardsPopUp: false,
  productGroupId: null,
  openBillFeatured: [],
  confirmPopup: false,
  confirmOrder: [],
  checkoutShortCutPopup: false,
  orderQrCode: `?table=&branch=`,
  showTagsAndDitesFilter: false,
  closePayments: null,
  deliveryMode: true,
  deliveryAddsPopup: false,
  deliveryAddsInfo: null,
  pickupAddsInfo: null,
  countryCityArea: null,
  deliveryChargesDetails: [],
  pickupMode: false,
  guestLoggedIn: false,
  guestModalShow: false,
  userAddressPopup: false,
  userSaveDeliveryAdds: {},
  deliveryDateTimePopup: false,
  deliveryPreparingTime: null,
  pickupDateTimePopup:false,
  currentServices: null,
};

// eslint-disable-next-line
export default function reducer(state = initial_state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "LangChange":
      return { ...state, langValue: action.langValue };
    case "updateCart":
      return { ...state, cart: action.cart };
    case "updateSubTotal":
      return {
        ...state,
        subTotal: action.subTotal,
        total: action.total,
      };

    case "Wallet":
      return { ...state, wallet: action };
    case "updateAddons":
      return { ...state, addonCart: action.addonCart };
    case "SET_USERINFO":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "SET_FORM_DATA":
      return { ...state, formData: action.formData };
    case "SET_PARENT_CATEGORIES":
      return { ...state, parentCategories: action.parentCategories };
    case "SET_CATEGORIES":
      return { ...state, categories: action.categories };
    case "SET_PRODUCTS":
      return { ...state, products: action.products };
    case "SET_FOR_PRODUCT_SEARCH":
      return { ...state, productsForSearch: action.productsForSearch };
    case "setCategoryProduct":
      return { ...state, categoryProducts: action.categoryProducts };
    case "SET_SINGLE_PRODUCTS":
      return { ...state, singleProduct: action.singleProduct };
    case "SET_STORE_INFORMATION":
      return { ...state, storeInfo: action.payload };
    case "SET_SERVICES_MODE":
      return { ...state, serviceMode: action.serviceMode };
    case "setFieldValues":
      return {
        ...state,
        fieldValues: action.fieldValues,
      };
    case "SET_LOGIN":
      return {
        ...state,
        loggedIn: true,
        loggData: action.loggData,
        loginToken: action.loginToken,
      };
    case "SET_LOGOUT":
      return { ...state, loggedIn: false, loggData: "", token: "" };
    case "SHOW_ALERT":
      return {
        ...state,
        alert: {
          show: true,
          message: action.message,
          type: action.alertType,
        },
      };
    case "HIDE_ALERT":
      return {
        ...state,
        alert: {
          show: false,
          message: "",
          type: "",
        },
      };
    case "showAddToCartPopup":
      return {
        ...state,
        addToCartPopup: action.addToCartPopup,
        cartProd: action.cartProd,
      };
    case "SOCIAL_LINK":
      return {
        ...state,
        socialLinks: action.socialLinks,
      };
    case "SET_CARS":
      return {
        ...state,
        carCompanies: action.carCompanies,
      };
    case "SET_StoreBusy":
      return {
        ...state,
        storeBusy: action.storeBusy,
      };
    case "SET_TABLE":
      return {
        ...state,
        qrTable: action.qrTable,
      };
    case "setBranchName":
      return {
        ...state,
        branchName: action.branchName,
      };
    case "SET_BRANCH":
      return {
        ...state,
        qrBranch: action.qrBranch,
      };
    case "setTableNo":
      return {
        ...state,
        tableNo: action.tableNo,
      };
    case "SET_ISCLOSED":
      return {
        ...state,
        isClosed: action.isClosed,
      };
    case "SET_OPEN_ORDER":
      return {
        ...state,
        openOrder: action.openOrder,
      };
    case "Set_Accepted_Payments":
      return {
        ...state,
        acceptedPayments: action.acceptedPayments,
      };
    case "newApi":
      return {
        ...state,
        newApi: action.newApi,
      };
    case "Set-Isloading":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "Set-StoreFound":
      return {
        ...state,
        storeFound: action.storeFound,
      };
    case "setOpenBill":
      return {
        ...state,
        openBill: action.openBill,
      };
    case "Set_FreezScreen":
      return {
        ...state,
        freezScreen: action.freezScreen,
      };
    case "setLoginModal":
      return {
        ...state,
        loginModal: action.loginModal,
      };
    case "setSignUpPhoneModal":
      return {
        ...state,
        signUpPhoneModal: action.signUpPhoneModal,
      };
    case "setOtpModal":
      return {
        ...state,
        otpModal: action.otpModal,
      };
    case "setSignUpModal":
      return {
        ...state,
        signUpModal: action.signUpModal,
      };
    case "setQrRequired":
      return {
        ...state,
        qrRequired: action.qrRequired,
      };
    case "setQrPickup":
      return {
        ...state,
        qrPickup: action.qrPickup,
      };
    case "setSaveCardList":
      return {
        ...state,
        saveCardList: action.saveCardList,
      };
    case "setReviewModal":
      return {
        ...state,
        reviewModal: action.reviewModal,
      };
    case "setOrderInvoice":
      return {
        ...state,
        orderInvoice: action.orderInvoice,
      };
    case "setStoreTime":
      return {
        ...state,
        storeTime: action.storeTime,
      };
    case "setQrOptionStatus":
      return {
        ...state,
        qrOptionStatus: action.qrOptionStatus,
      };
    case "setDeviceId":
      return {
        ...state,
        deviceId: action.deviceId,
      };
    case "setUserCurrentStatus":
      return {
        ...state,
        userCurrentStatus: action.userCurrentStatus,
      };
    case "setStoreDomain":
      return {
        ...state,
        storeDomain: action.storeDomain,
      };
    case "setSplitBillOptions":
      return {
        ...state,
        splitBillOptions: action.splitBillOptions,
      };
    case "setPayForItems":
      return {
        ...state,
        payForItems: action.payForItems,
      };
    case "setDivideBill":
      return {
        ...state,
        divideBill: action.divideBill,
      };
    case "setPayCustomAmount":
      return {
        ...state,
        payCustomAmount: action.payCustomAmount,
      };
    case "setShareInvoiceQrcode":
      return {
        ...state,
        shareInvoiceQrcode: action.shareInvoiceQrcode,
      };
    case "setSplitBill":
      return {
        ...state,
        splitBill: action.splitBill,
      };
    case "setMeta":
      return {
        ...state,
        meta: action.meta,
      };
    case "setProgressive":
      return {
        ...state,
        progressive: action.progressive,
      };
    case "setLoginValues":
      return {
        ...state,
        loginValues: action.loginValues,
      };
    case "setPromotionBanner":
      return {
        ...state,
        promotionBanner: action.promotionBanner,
      };
    case "setCreatedAt":
      return {
        ...state,
        createdAt: action.createdAt,
      };
    case "setPromotionCreatedAt":
      return {
        ...state,
        promotionCreatedAt: action.promotionCreatedAt,
      };
    case "setPayTablePopUp":
      return {
        ...state,
        payTablePopUp: action.payTablePopUp,
      };
    case "setPayTableMode":
      return {
        ...state,
        payTableMode: action.payTableMode,
      };
    case "setTableInvoice":
      return {
        ...state,
        tableInvoice: action.tableInvoice,
      };
    case "setPayTableOrders":
      return {
        ...state,
        payTableOrders: action.payTableOrders,
      };
    case "setOldInvoiceId":
      return {
        ...state,
        oldInvoice: action.oldInvoice,
      };
    case "setLoginSkip":
      return {
        ...state,
        loginSkip: action.loginSkip,
      };
    case "setPaymentPopUp":
      return {
        ...state,
        paymentPopUp: action.paymentPopUp,
      };
    case "updatedCardList":
      return {
        ...state,
        cardList: action.cardList,
      };
    case "setBranch":
      return {
        ...state,
        branch: action.branch,
      };
    case "setProductTags":
      return {
        ...state,
        productTags: action.productTags,
      };
    case "setProductDietaries":
      return {
        ...state,
        productDietaries: action.productDietaries,
      };
    case "setFilterProductMode":
      return {
        ...state,
        filterProductMode: action.filterProductMode,
      };
    case "setTags":
      return {
        ...state,
        tags: action.tags,
      };
    case "setDietaries":
      return {
        ...state,
        dietaries: action.dietaries,
      };
    case "setProductsLength":
      return {
        ...state,
        productsLength: action.productsLength,
      };
    case "setCurrentCategory":
      return {
        ...state,
        currentCategory: action.currentCategory,
      };
    case "setCampaignState":
      return {
        ...state,
        campaignState: action.campaignState,
      };
    case "setgroupOrderPopup":
      return {
        ...state,
        groupOrderPopup: action.groupOrderPopup,
      };
    case "setGroupId":
      return {
        ...state,
        groupId: action.groupId,
      };
    case "setLoginModal_Screen":
      return {
        ...state,
        loginModalScreen: action.loginModalScreen,
      };
    case "setGroupBillMode":
      return {
        ...state,
        groupBillMode: action.groupBillMode,
      };
    case "setGroupBillRes":
      return {
        ...state,
        groupBillRes: action.groupBillRes,
      };
    case "setOrderProdQty":
      return {
        ...state,
        orderProdQty: action.orderProdQty,
      };
    case "setScrollKeyword":
      return {
        ...state,
        scrollKeyword: action.scrollKeyword,
      };
    case "setPayTableDiscount":
      return {
        ...state,
        payTableDiscount: action.payTableDiscount,
      };
    case "setPayTableCharges":
      return {
        ...state,
        payTableCharges: action.payTableCharges,
        payTableTotalCharges: action.payTableTotalCharges,
        payTableTotalTax: action.payTableTotalTax,
      };
    case "setInvoiceTaxesChargesExtras":
      return {
        ...state,
        invoiceTaxesChargesExtras: action.invoiceTaxesChargesExtras,
      };
    case "setCombos":
      return {
        ...state,
        combos: action.combos,
      };
    case "setHotelMode":
      return {
        ...state,
        hotelMode: action.hotelMode,
        hotelQrCode: action.hotelQrCode,
      };
    case "setHotelObj":
      return {
        ...state,
        hotelObj: action.hotelObj,
      };
    case "setIsAddToCartEnable":
      return {
        ...state,
        isAddToCartEnable: action.isAddToCartEnable,
      };
    case "setCurrentInvoiceId":
      return {
        ...state,
        currentInvoiceId: action.currentInvoiceId,
      };
    case "setOpenBillId":
      return {
        ...state,
        openBillId: action.openBillId,
      };
    case "setShareBill":
      return {
        ...state,
        shareBill: action.shareBill,
      };
    case "setShareBillPopup":
      return {
        ...state,
        shareBillPopup: action.shareBillPopup,
        shareBillUrl: action.shareBillUrl,
      };
    case "setShareBillUrl":
      return {
        ...state,
        shareBillUrl: action.shareBillUrl,
      };
    case "setShopMode":
      return {
        ...state,
        shopMode: action.shopMode,
      };
    case "setLoyalityPopupMode":
      return {
        ...state,
        loyalityPopupMode: action.loyalityPopupMode,
        selectedLoyaltyId: action.selectedLoyaltyId,
      };
    case "setLoyaltyObj":
      return {
        ...state,
        loyaltyObj: action.loyaltyObj,
      };
    case "setSelectLoyaltyCardPopup":
      return {
        ...state,
        selectLoyaltyCardPopup: action.selectLoyaltyCardPopup,
      };
    case "setOptionalRewardsPopUp":
      return {
        ...state,
        optionalRewardsPopUp: action.optionalRewardsPopUp,
      };
    case "setOptionalRewardsDetails":
      return {
        ...state,
        optionalRewardsDetials: action.optionalRewardsDetials,
      };
    case "setRewardsDetails":
      return {
        ...state,
        rewardsDetails: action.rewardsDetails,
      };
    case "setLoyaltyRewardObj":
      return {
        ...state,
        loyaltyRewardObj: action.loyaltyRewardObj,
      };
    case "setSourceOfMeetBanner":
      return {
        ...state,
        sourceOfMeetBanner:
          action.sourceOfMeetBanner != null || undefined
            ? action.sourceOfMeetBanner
            : state.sourceOfMeetBanner,
        sourceOfMeetBannerImage:
          action.sourceOfMeetBannerImage != null || undefined
            ? action.sourceOfMeetBannerImage
            : state.sourceOfMeetBannerImage,
        sourceOfMeetBannerImageUrls:
          action.sourceOfMeetBannerImageUrls != null || undefined
            ? action.sourceOfMeetBannerImageUrls
            : state.sourceOfMeetBannerImageUrls,
      };
    case "setLoyaltyBanner":
      return {
        ...state,
        loyaltyBanner: action.loyaltyBanner,
      };
    case "setStoreDiscount":
      return {
        ...state,
        storeDiscount: action.storeDiscount,
      };
    case "setPusherActive":
      return {
        ...state,
        pusherActive: action.pusherActive,
      };
    case "pushSubscription":
      return {
        ...state,
        pushSubscription: action.pushSubscription,
      };
    case "setProductGroupId":
      return {
        ...state,
        productGroupId: action.productGroupId,
      };
    case "SET_FEATURED_PRODUCTS":
      return {
        ...state,
        featuredProducts: action.featuredProducts ?? state.featuredProducts,
        featuredProductPopupMode:
          action.featuredProductPopupMode ?? state.featuredProductPopupMode,
      };
    case "setOpenBillFeatured":
      return {
        ...state,
        openBillFeatured: action.openBillFeatured ?? state.openBillFeatured,
      };
    case "setConfirmPopup":
      return {
        ...state,
        confirmPopup: action.confirmPopup ?? state.confirmPopup,
        confirmOrder: action.confirmOrder ?? state.confirmOrder,
      };
    case "setCheckoutShortCutPopup":
      return {
        ...state,
        checkoutShortCutPopup:
          action.checkoutShortCutPopup ?? state.checkoutShortCutPopup,
      };
    case "setOrderQrCode":
      return {
        ...state,
        orderQrCode: action.orderQrCode ?? state.orderQrCode,
      };
    case "setShowTagsAndDitesFilter":
      return {
        ...state,
        showTagsAndDitesFilter:
          action.showTagsAndDitesFilter ?? state.showTagsAndDitesFilter,
      };
    case "setClosePayments":
      return {
        ...state,
        closePayments: action.closePayments ?? state.closePayments,
      };
    case "setDeliveryMode":
      return {
        ...state,
        deliveryMode: action.deliveryMode ?? state.deliveryMode,
      };
    case "setDeliveryAddsPopup":
      return {
        ...state,
        deliveryAddsPopup: action.deliveryAddsPopup ?? state.deliveryAddsPopup,
      };
    case "setDeliveryAddsInfo":
      return {
        ...state,
        deliveryAddsInfo: action.deliveryAddsInfo ?? state.deliveryAddsInfo,
      };
      case "setPickupAddsInfo":
        return {
          ...state,
          pickupAddsInfo: action.pickupAddsInfo ?? state.pickupAddsInfo,
        };
    case "setCountryCityArea":
      return {
        ...state,
        countryCityArea: action.countryCityArea ?? state.countryCityArea,
      };
    case "setDeliveryChargesDetails":
      return {
        ...state,
        deliveryChargesDetails:
          action.deliveryChargesDetails ?? state.deliveryChargesDetails,
      };
    case "setPickupMode":
      return {
        ...state,
        pickupMode: action.pickupMode ?? state.pickupMode,
      };
    case "setGuestLoggedIn":
      return {
        ...state,
        guestLoggedIn: action.guestLoggedIn ?? state.guestLoggedIn,
      };
    case "SET_GUEST_MODAL_SHOW":
      return {
        ...state,
        guestModalShow: action.guestModalShow ?? state.guestModalShow,
      };
    case "SET_USER_ADDRESS_POPUP":
      return {
        ...state,
        userAddressPopup: action.userAddressPopup ?? state.userAddressPopup,
      };
    case "SET_USER_SAVE_DELIVERY_ADDS":
    return{
      ...state,
      userSaveDeliveryAdds: action.userSaveDeliveryAdds ?? state.userSaveDeliveryAdds
    }
    case "SET_DELIVERY_DATE_TIME_POPUP":
    return{
      ...state,
      deliveryDateTimePopup: action.deliveryDateTimePopup ?? state.deliveryDateTimePopup
    }
    
    case "SET_DELIVERY_PREPARING_TIME":
    return{
      ...state,
      deliveryPreparingTime: action.deliveryPreparingTime ?? state.deliveryPreparingTime
    }
    
    case "SET_PICKUP_DATE_TIME_POPUP":
    return{
      ...state,
      pickupDateTimePopup: action.pickupDateTimePopup ?? state.pickupDateTimePopup
    }
    case "SET_CURRENT_SERVICES":
      return {  
        ...state,
        currentServices: action.currentServices ?? state.currentServices,
      };

    case "RESET":
      return initial_state;
    default:
      return state;
  }
}
