/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./selectLoyaltyCardPopup.scss";
// material ui
import Button from "@mui/material/Button";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { get_lang } from "../../../../global-functions/apiGlobal";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Backdrop, useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import {hexToRGBA } from "../../../../global-functions/colorConverter";
import noImg from "../../assets/images/no-image.svg";
import { handleMaxPunch } from "../../../../global-functions/handlePunch";



const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function SelectLoyaltyCardPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const matches = useMediaQuery("(min-width:1200px)");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const {loyaltyCardId, setDiscountType, setLoyaltyCardId,setShowLoyaltyCardDetails } = props

  const handleconfirm = () => {
    setDiscountType("punch-card")
    setLoyaltyCardId(selectedItemId)
    if(selectedItemId!=null ||loyaltyCardId!=null ){
      setShowLoyaltyCardDetails(true)
    }
    appDispatch({
      type: "setSelectLoyaltyCardPopup",
      selectLoyaltyCardPopup: false,
    });
 
  };


  const handleSelect = (id) => {
    setSelectedItemId(id);

  };

  useEffect(() => {
    setSelectedItemId(loyaltyCardId);
  }, [loyaltyCardId])
  

  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`SelectloyalityPopupMain ${appState.langValue === "ar" ? "SelectloyalityPopupMainMainArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3">
          <div className="d-flex justify-content-between alignn-items-center">
            <div className="d-flex align-items-center mt-2">
              <h1
                className={` mb-0 ${appState.langValue === "ar"
                    ? "text-right familyGraphikArabic800 "
                    : " familyPoppins300"
                  }`}
              >
                {" "}
                Select Loyalty Card
              </h1>
            </div>

            <IconButton
              onClick={() =>
                appDispatch({
                  type: "setSelectLoyaltyCardPopup",
                  selectLoyaltyCardPopup: false,
                })
              }
              className="classForSvgColor mt-2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                  fill="#22282F"
                />
              </svg>
            </IconButton>
          </div>
          <hr className="my-1" />

          <div className={`tabsParent`}>
            <div id="TagCheckBoxParent">
              {/* for prroduct */}
              <section
                id="Products"
                className={`products ${appState.loyaltyObj.length > 0 ? "" : "noProducts"
                  } `}
              >
                {isLoading
                  ? [0, 1, 2, 3, 4].map((a) => (
                    <div key={a} className="row m-0 mb-4 mt-3">
                      <div className="col-3 col-md-2 px-2">
                        <Skeleton className="prRectSkeleton" />
                      </div>
                      <div className="col-9 col-md-10 px-2">
                        <Skeleton
                          height={30}
                          style={{
                            transform: "translate(2px, 0)",
                            marginBottom: "10px",
                          }}
                        />
                        <Skeleton height={25} />
                        <Skeleton height={25} />
                      </div>
                    </div>
                  ))
                  : appState.loyaltyObj &&
                  (appState.loyaltyObj.length == 0 ? (
                    <div className="text-center mt-5">
                      <h4 className="text-muted">"No loyalty cards found"</h4>
                    </div>
                  ) : (
                    <div className=" m-0 productsRow">
                      {appState.loyaltyObj.map((item, ind) => {
                        return (
                          <div
                          key={ind}
                        
                          className={`row py-2 mx-0 w-100 CardRow radio-item ${
                            (item.active)
                              ? "cardActive"
                              : "cardNotActive"
                          } ${
                            (item?.punchable == false)
                              ? "pointer-events-none disabled"
                              : ""
                          } ${selectedItemId !=  null ? selectedItemId === item.id ? "selected" : "" : loyaltyCardId === item.id ? "selected" : ""}`}
                          onClick={() => handleSelect(item.id)}
                        >
                          <div className="col-4 d-flex align-items-center justify-content-center cardImage ">
                            <div className="cardImageInner d-flex justify-content-center">
                              <div className="largeAvatar">
                                <Avatar
                                  variant="square"
                                  src={
                                    item?.loyaltyLogo
                                      ? item?.loyaltyLogo
                                      : "../images/no-image.svg"
                                  }
                                ></Avatar>
                              </div>
                            </div>
                          </div>
                          <div className="col-8 cardContent">
                            <div className="px-3 cardContentInner">
                              <h1 className="cardProductName text-uppercase familyPoppins300 ">
                                {get_lang(
                                  item.loyaltyName,
                                  appState.langValue
                                )}
                              </h1>
                              <h4 className=" text-capitalize cardPrice familyPoppins  ">
                                {get_lang(
                                  item.description,
                                  appState.langValue
                                )}
                              </h4>
                              {item.active ? (
                                <div className="punchRatingMain">
                                  <StyledRating
                                    name="customized-color"
                                  
                                    defaultValue={handleLoyalPunch(rewards?.punch?.collected, rewards?.punch?.total, 5 )}
                                    max={handleMaxPunch(rewards?.punch?.total) }  
                                    getLabelText={(value) =>
                                      `${value} Heart${value !== 1 ? "s" : ""
                                      }`
                                    }
                                    precision={0.5}
                                    icon={<CircleIcon fontSize="inherit" />}
                                    emptyIcon={
                                      <RadioButtonUncheckedIcon fontSize="inherit" />
                                    }
                                    disabled
                                  />
                                </div>
                                
                              ) : (
                                <p style={{ color: "red" }}>
                                  {" "}
                                  Not Avalaible{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                  
                        );
                      })}
                    </div>
                  ))}
                {/* {} */}
              </section>
            </div>
          </div>
          <div className="bottomBtn">
            <Button
              onClick={handleconfirm}
              className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("checkOut.Confirm")}</span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(SelectLoyaltyCardPopup);
