/* eslint-disable */
import React from 'react';
/* eslint-disable */
import ReactDOM from 'react-dom';
import './index.css';
import './clarity'; // Import your config.js file
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import Main from './store/Main'
import * as Sentry from "@sentry/react";

import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux-store"
import 'bootstrap/dist/css/bootstrap.min.css';
import MainNew from './store/MainNew';
// var currentUrl = window.location.href;
// var urlWithoutQueryString = currentUrl.split('?')[0];
// https://5495c0a8457038cc31701d0896849dcb@o1183823.ingest.us.sentry.io/4508652588040192
  Sentry.init({
    dsn: "https://022245f316221e30ab3979435afc5703@sentry.enabill.dev/2",
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });


ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Switch>
            <Route path="/:slug">
              <Main />
              {/* <MainNew /> */}
            </Route>
          </Switch>
        </BrowserRouter>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
