import { getCookie, erase } from "../../global-functions/cookieFunction";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { handleTime } from "../../global-functions/time";
import { roundFixed } from "../../global-functions/roundFixed";
// get open bill orders
import { iconsTagDiet } from "../../global-functions/filterTagDiet";
import Images from "../../../src/layout-one/js/assets/images/images";
import { handleTapCharges } from "../../global-functions/charges";
import { handleInvoice } from "../../global-functions/customeObj";
import { handleMealType } from "../../global-functions/mealType";
import { compareStartAndEndTime, sortedByNumber } from "../../utils";
import { getServiceName } from "../../global-functions/variables";
import { handleServiceModes } from "../../global-functions/handleServiceModes";
import { setLangValue } from "../action-creater";
export const getPayTableInvoiceNoOrders = async (state, dispatch) => {
  var table = Number(state.qrTable);
  await axios
    .get(
      `${process.env.REACT_APP_API_URL}/order_invoice/pay_table_invoice/${table}`,
      {
        headers: {
          ContentType: "application/json",
        },
      }
    )
    .then((response) => {
      if (response.data.success === true) {
        let res = response.data.data.invoiceable;
        if (state.payTableMode) {
          let data = [];
          res.orders.forEach((element, index) => {
            data.push({
              ...element,
              meta: element.id,
              product_id: element?.product?.id,
            });
          });

          dispatch({
            type: "setPayTableOrders",
            payTableOrders: data,
          });
          let invoiceTotal = roundFixed(
            Number(res.amount),
            state.storeInfo.roundLevel,
            state.storeInfo.roundMethod
          );
          let invoiceExtras = handleInvoice(response.data.data, res, state);

          dispatch({
            type: "updateSubTotal",
            subTotal: roundFixed(
              Number(res.total_amount),
              state.storeInfo.roundLevel,
              state.storeInfo.roundMethod
            ),
            total: Number(invoiceExtras.totalAmount),
          });
          dispatch({
            type: "setInvoiceTaxesChargesExtras",
            invoiceTaxesChargesExtras: invoiceExtras,
          });

          dispatch({
            type: "setCombos",
            combos: res.combos,
          });

          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify({
              ...state.formData,
              order_invoice: "open_bill",
              openBill: true,
            })
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: { order_invoice: "open_bill", openBill: true },
          });
        }
        dispatch({
          type: "setTableInvoice",
          tableInvoice: response.data.data.id,
        });

        // dispatch({
        //   type: "setPayTablePopUp",
        //   payTablePopUp: true,
        // });
        dispatch(freezOff());
      } else if (response.data.success === false) {
        dispatch({
          type: "setPayTableOrders",
          payTableOrders: null,
        });
        dispatch({
          type: "updateSubTotal",
          subTotal: 0,
          total: 0,
        });
        localStorage.setItem(
          `${state.storeInfo.slug}_form`,
          JSON.stringify({
            ...state.formData,
            order_invoice: "",
            openBill: false,
          })
        );
        dispatch({
          type: "SET_FORM_DATA",
          formData: { order_invoice: "", openBill: false },
        });
        dispatch({
          type: "setQrOptionStatus",
          qrOptionStatus: false,
        });
        dispatch({
          type: "setPayTablePopUp",
          payTablePopUp: false,
        });
        dispatch(freezOff());
      }
    })
    .catch((error) => {
      console.log(error);
      if (error != undefined) {
        // dispatch(handleError(error));
      } else {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
        // console.log("else")
      }
      dispatch(freezOff());
    });
};
export const getPayTableInvoice = (state, payTable) => {
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .get(`${process.env.REACT_APP_API_URL}/order_invoice/${payTable}`, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        var checkIsPaid = response.data.data.invoiceable.status;
        var partials = response.data.data.partials;
        // console.log("checkIsPaid",checkIsPaid)

        if (response.data.success === true) {
          if (checkIsPaid == "Paid") {
            dispatch({
              type: "setTableInvoice",
              tableInvoice: null,
            });
            const winUrl = window.open(
              `${process.env.REACT_APP_PAY_URL}/invoice/in/${payTable}`,
              "_self"
            );
            winUrl.focus();
          } else if (checkIsPaid != "Paid") {
            localStorage.setItem(
              `${state.storeInfo.slug}_${getServiceName(state)}_form`,
              JSON.stringify({ ...state.formData, order_invoice: "open_bill" })
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: state.formData,
              order_invoice: "open_bill",
            });
            const res = response.data.data.invoiceable;

            let invoiceExtras = handleInvoice(response.data.data, res, state);
            dispatch({
              type: "setInvoiceTaxesChargesExtras",
              invoiceTaxesChargesExtras: invoiceExtras,
            });
            let data = [];

            res.orders.forEach((element, index) => {
              let metaId = `o_${element.id}`;
              let paymentStatus = false;
              let divideBillInfo = undefined;
              // this is for just pay with product split option
              if (partials != []) {
                partials.forEach((item) => {
                  let metaInfo = item?.meta;
                  if (item.status == "paid") {
                    if (metaInfo?.partial_type == "product") {
                      metaInfo?.data?.forEach((order) => {
                        if (!paymentStatus) {
                          paymentStatus = order.order_id == metaId;
                        }
                      });
                    }
                  }
                  // this is for just divide bill option
                  else if (metaInfo?.partial_type == "ratio") {
                    divideBillInfo = metaInfo.data;
                  }
                });
              }
              data.push({
                ...element,
                meta: metaId,
                paymentStatus: paymentStatus,
                divideBillInfo: divideBillInfo,
                product_id: element?.product?.id,
              });
            });

            if (response?.data?.data?.is_partial) {
              if (res?.meta) {
                let type = res?.meta?.partial_type;
                dispatch(pushShopMode(state, "is_partial"));
                dispatch(pushShopMode(state, type));
              }
            } else {
              dispatch(
                popShopMode(state, [
                  "customer",
                  "ratio",
                  "product",
                  "is_partial",
                ])
              );
            }

            dispatch({
              type: "setPayTableOrders",
              payTableOrders: data,
            });
            let comboData = [];
            res.combos.forEach((element, index) => {
              comboData.push({
                ...element,
                meta: `c_${element.id}`,
              });
            });

            dispatch({
              type: "setCombos",
              combos: comboData,
            });

            dispatch({
              type: "updateSubTotal",
              subTotal: roundFixed(
                Number(res.total_amount),
                state.storeInfo.roundLevel,
                state.storeInfo.roundMethod
              ),
              total: roundFixed(
                Number(invoiceExtras.totalAmount),
                state.storeInfo.roundLevel,
                state.storeInfo.roundMethod
              ),
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify({
                ...state.formData,
                order_invoice: "open_bill",
                openBill: true,
              })
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: { order_invoice: "open_bill", openBill: true },
            });
            // dispatch({
            //   type: "setPayTablePopUp",
            //   payTablePopUp: true,
            // });
            dispatch(freezOff());

            return response.data.data.invoiceable;
          }
        } else if (response.data.success === false) {
          dispatch({
            type: "setPayTableOrders",
            payTableOrders: null,
          });
          dispatch({
            type: "updateSubTotal",
            subTotal: 0,
            total: 0,
          });
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify({
              ...state.formData,
              order_invoice: "",
              openBill: false,
            })
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: { order_invoice: "", openBill: false },
          });
          dispatch({
            type: "setQrOptionStatus",
            qrOptionStatus: false,
          });
          dispatch({
            type: "setPayTablePopUp",
            payTablePopUp: false,
          });
          dispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          dispatch(freezOff());
        }
      })
      .catch((error) => {
        console.log(error);
        if (error != undefined) {
          // dispatch(handleError(error));
          dispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }
        dispatch(freezOff());
      });
  };
};

export const handleIsAddToCartEnable = (
  hotelQr,
  pickup,
  table,
  branch,
  delivery
) => {
  return async (dispatch) => {
    let mode = false;
    if ((hotelQr || pickup || table || delivery) && branch) {
      mode = true;
    }
    console.log(
      "hotelQr",
      hotelQr,
      "pickup",
      pickup,
      "table",
      table,
      `branch`,
      branch,
      `delivery`,
      delivery
    );
    console.log("isAddToCartEnable", mode);
    dispatch({
      type: "setIsAddToCartEnable",
      isAddToCartEnable: mode,
    });
  };
};

export const MainApi = (
  storeData,
  allData,
  state,
  payTable,
  hotelQr,
  pickup,
  hotel_qrcode_obj,
  checkScanQrCode,
  table,
  shopLangDefault,
  branchObj,
  delivery,
  settings
) => {
  //   const [prodArry, setProdArry] = useState([]);
  return async (dispatch) => {
    var arry = [];
    if (settings?.payments) {
      settings.payments.map((val) => arry.push(val));
    }
    if (!shopLangDefault) {
      dispatch({
        type: "LangChange",
        langValue: allData.shop_lang,
      });
      localStorage.setItem(
        `${storeData.slug}_shopLangDefault`,
        JSON.stringify(allData.shop_lang)
      );
    }
    dispatch({
      type: "SET_STORE_INFORMATION",
      payload: storeData,
      isLoading: false,
    });

    dispatch({
      type: "setStoreLocation",
      layout: allData.location,
    });

    dispatch({
      type: "Set-Layout",
      layout: allData.layout,
    });

    dispatch({
      type: "SetProgressive",
      progressive: allData.progressive,
    });

    dispatch({
      type: "setStoreDiscount",
      storeDiscount: allData?.discount ? allData?.discount : null,
    });
    var isClosedStore = allData.is_closed;
    if (isClosedStore === 0) {
      if (hotel_qrcode_obj) {
        let days = hotel_qrcode_obj?.section?.days;
        let start = hotel_qrcode_obj?.section?.starting;
        let end = hotel_qrcode_obj?.section?.ending;

        isClosedStore = compareStartAndEndTime(days, start, end) ? 0 : 1;
      }
    }
    dispatch({ type: "SET_ISCLOSED", isClosed: isClosedStore });

    dispatch(checkClosedOpenTime(allData.business_hours));

    dispatch({
      type: "newApi",
      newApi: allData,
    });
    dispatch({
      type: "Set_Accepted_Payments",
      acceptedPayments: arry,
    });

    dispatch({
      type: "SOCIAL_LINK",
      socialLinks: allData.social.links,
    });
    //service modes
    // Delivery mode  ----------------------
    handleServiceModes(settings, hotel_qrcode_obj, dispatch, delivery);
    dispatch({
      type: "SET_CARS",
      carCompanies: allData.car_companies,
    });

    var checkPickUp = null;
    // if (settings?.services.includes("pay-table")) {
    //   dispatch({
    //     type: "setQrPickup",
    //     qrPickup: null,
    //   });
    // } else {
    //   checkPickUp = await settings?.services.find((mode) => {
    //     return mode === "pick-up";
    //   });
    // }
    checkPickUp = await settings?.services?.find((mode) => {
      return mode === "pick-up";
    });

    dispatch({
      type: "setPickUp",
      pickUp: checkPickUp,
    });
    var getOpenBill =
      (await branchObj) != null
        ? settings?.services?.some(function (value) {
            return (
              (value == "open-bill" || value == "group-bill") &&
              !storeData.buffetMode &&
              !delivery
            );
          })
        : false;
    console.log(getOpenBill, "getOpenBill");
    dispatch({
      type: "setOpenBill",
      openBill: hotelQr != null ? false : pickup != null ? false : getOpenBill,
    });
    var getPayTable =
      (await branchObj) != null && !delivery
        ? settings?.services?.some((val) => {
            return val === "pay-table";
          })
        : false;
    dispatch({
      type: "setPayTableMode",
      payTableMode:
        !delivery && hotelQr != null
          ? false
          : pickup != null
          ? false
          : getPayTable,
    });
    if (Boolean(getPayTable) && payTable != null) {
      dispatch(getPayTableInvoice(state, payTable));
    }
    // else if (getPayTable && table) {
    //   dispatch({
    //     type: "setPayTablePopUp",
    //     payTablePopUp: getPayTable,
    //   });
    // } else {
    //   {
    //     dispatch({
    //       type: "setPayTablePopUp",
    //       payTablePopUp: getPayTable,
    //     });
    //   }
    // }
    var hotelObject = null;
    if (hotel_qrcode_obj != null) {
      hotelObject = {
        fixed: hotel_qrcode_obj?.section?.discount?.amount ?? 0,
        percent: hotel_qrcode_obj?.section?.discount?.percent ?? 0,
        days: hotel_qrcode_obj?.section?.days,
        starting: hotel_qrcode_obj?.section?.starting,
        ending: hotel_qrcode_obj?.section?.ending,
      };
    }
    dispatch({
      type: "setHotelObj",
      hotelObj: hotelObject,
    });
  };
};

const buildQueryParams = (state, id) => {
  const params = new URLSearchParams();

  if (
    state.storeInfo.pos != null &&
    !["kumar-murouj", "kumar-avenues", "kumar-warehouse", "concrete-avenues"].includes(state.storeInfo.slug) &&
    !(state.storeInfo.slug === "slider-station" && state.qrBranch === 259058) &&
    !(state.storeInfo.slug === "lunch-room-360" && state.qrBranch === 259062)
  ) {
    params.append("pos", state.storeInfo.pos);
  }

  if (state.qrBranch) {
    params.append("branch", state.qrBranch);
  }

  if (state.pickupMode) {
    params.append("by_type", "pickup");
  }

  if (state.deliveryMode) {
    params.append("by_type", "delivery");
  }

  return params.toString() ? `?${params.toString()}` : "";
};

export const handleCategory = (id, state, parent_id, slug, findCat, type) => {
  // console.log(state.pickupMode, 'state.pickUpMode');
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/product/category/${id}${buildQueryParams(state, id)}`;
      const headers = {
        Platform: "Shop",
        response: "shop",
        ContentType: "application/json",
      };

      const res = await axios.get(url, { headers });

      if (res.data.success === true) {
        // let prod =  await res.data.data.products
        let prod = await res.data.data;
        let prodArr = [];
        prod.forEach((ele) => {
          let mealTypes = ele?.mealTypes;
          let inActive = ele?.inactive_branches ?? [];
          let show = !inActive.includes(state.qrBranch);
          let checkMealType = false
          if (state.qrBranch != null && mealTypes?.length > 0 && show) {
            let filterByBranch = mealTypes.filter(
              (mealType) => mealType?.branch_id == state.qrBranch
            );
            // show = filterByBranch?.length > 0 ? filterByBranch.some((item)=> item?.services?.some((item) => state.serviceMode?.includes(item))):true;
          
            if(show){
              checkMealType = filterByBranch?.length > 0   
              ? filterByBranch.some((item) =>  Array.isArray(item?.services) &&   item.services.length > 0  
              ? item.services.some((service) =>  state.currentServices?.includes(service)) 
              : false ) 
              : false; 
            }
            if (checkMealType) {
              show = handleMealType(mealTypes, state.qrBranch);
            }
          }
          if (!state.storeInfo?.showSoldProduct && show) {
            show = ele.quantity == 0 ? false : true;
          }
          ele.order = ele?.list_order ?? null;

          if (show) {
            prodArr.push({
              ...ele,
              categoryId: Number(id),
              categorySlug: slug,
              category: findCat,
            });
          }
        });
        prodArr = sortedByNumber(prodArr);
        // }
        if (type == "normalFlow")
          dispatch({
            type: "SET_PRODUCTS",
            products: prodArr,
          });

        return prodArr;
      } else {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${res.data.message}`,
        });
      }
    } catch (e) {
      Promise.reject(e);
      dispatch(handleError(e));
      dispatch({
        type: "Set_FreezScreen",
        freezScreen: false,
      });
    }
  };
};

export const handleProduct = (id, branch_id, state) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${id}?branch=${branch_id}${
          state.pickupMode
            ? `${state.qrBranch != null ? "&" : "?"}` + `by_type=pickup`
            : ""
        }${
          state.deliveryMode
            ? `${state.qrBranch != null ? "&" : "?"}` + `by_type=delivery`
            : ""
        }`,
        {
          headers: {
            Plateform: "Shop",
            Accept: "application/json",
            ContentType: "application/json",
          },
        }
      );
      if (res.data.success === true) {
        let prod = await res.data.data;
        let arryProduct = [];
        arryProduct.push(prod);

        let arr = [];
        let product = arryProduct;
        const tagDietResponse = await iconsTagDiet(arr, product, Images);
        arr = tagDietResponse;

        dispatch({
          type: "SET_PRODUCTS",
          products: arryProduct,
        });
        return res;
      } else {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${res.data.message}`,
        });
      }
    } catch (e) {
      Promise.reject(e);
      console.log(e);
      if (e.response != undefined) {
        dispatch(handleError(e));
      } else {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      dispatch({
        type: "Set_FreezScreen",
        freezScreen: false,
      });
    }
  };
};

export const handleFeaturedProduct = (id, state) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${id}${
          state.qrBranch ? `?branch=${state.qrBranch}` : ""
        }`,
        {
          headers: {
            Plateform: "Shop",
            Accept: "application/json",
            ContentType: "application/json",
          },
        }
      );
      if (res.data.success === true) {
        let prod = await res.data.data;
        let arryProduct = [];
        arryProduct.push(prod);

        dispatch({
          type: "SET_FEATURED_PRODUCTS",
          featuredProducts: arryProduct,
        });
        return res;
      } else {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${res.data.message}`,
        });
      }
    } catch (e) {
      Promise.reject(e);
      console.log(e);
      if (e.response != undefined) {
        dispatch(handleError(e));
      } else {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      dispatch({
        type: "Set_FreezScreen",
        freezScreen: false,
      });
    }
  };
};

export const handleSearch = (userInput, state) => {
  return async (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: true,
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/filter/${
          state.storeInfo.slug
        }?${[
          state.storeInfo.pos != null &&
            ![
              "kumar-murouj",
              "kumar-avenues",
              "kumar-warehouse",
              "concrete-avenues",
            ].includes(state.storeInfo.slug) &&
            !(
              state.storeInfo?.slug == "lunch-room-360" &&
              state.qrBranch == 259062
            ) &&
            !(
              state.storeInfo?.slug == "slider-station" &&
              state.qrBranch == 259058
            ) &&
            `pos=${state.storeInfo.pos}`,
          state.qrBranch && `branch=${state.qrBranch}`,
          state.deliveryMode && `delivery=1`,
        ]
          .filter(Boolean)
          .join("&")
          .trim()}`,
        {
          value: userInput,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );
      // console.log('handle search',res)

      if (res.data.success === true) {
        let prod = await res.data.data;
        /// comment it no use

        // if (state.qrBranch != null && Array.isArray(prod) && prod.length > 0) {
        //   // console.log(prod)
        //   var arryProduct = [];
        //   prod.forEach((ele) => {
        // console.log("prod",prod)

        // let product = ele;
        // if (product != []) {
        //   console.log("first",product)
        //   let mealTypes = product.mealTypes;
        //   let prodShow = true;
        //   if (mealTypes?.length > 0 && state.qrBranch) {
        //     prodShow = handleMealType(mealTypes, state.qrBranch);
        //   }else{
        //     prodShow = false;
        //   }
        //   if (prodShow) {
        //     arryProduct.push(product);
        //   }
        // }
        //   });
        // }

        if (Array.isArray(prod) && prod.length == 0) {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `Product not found.`,
          });
        }

        // console.log("arryProduct",arryProduct)

        dispatch({
          type: "SET_PRODUCTS",
          products: prod,
        });
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        return res;
      } else {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${res.data.message}`,
        });
      }
    } catch (e) {
      Promise.reject(e);
      console.log(e);
      if (e.response != undefined) {
        dispatch(handleError(e));
      } else {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      dispatch({
        type: "Set_FreezScreen",
        freezScreen: false,
      });
    }
  };
};

export const checkClosedOpenTime = (response) => {
  return async (dispatch) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const currentTime = new Date();
    let day = weekday[currentTime.getDay()];
    let today = day.slice(0, 3);
    const todayTimeList = await response.filter((val, ind) => {
      return val.day.includes(today);
    });
    if (todayTimeList.length >= 0) {
      //  let storeOpenTime = new Date(todayTimeList[0].open_time)

      dispatch({
        type: "setStoreTime",
        storeTime: todayTimeList[0],
      });
    } else {
      dispatch({
        type: "setStoreTime",
        storeTime: "",
      });
    }
  };
};

export const checkOpenBill = (appState) => {
  return async (dispatch) => {
    if (appState.qrTable) {
      var getOpenBill = await appState.serviceMode.find((openbill) => {
        return openbill === "open-bill";
      });

      dispatch({
        type: "Set-OpenBill",
        openBill: getOpenBill,
      });
    } else if (appState.hotelMode && appState.deliveryMode) {
      dispatch({
        type: "Set-OpenBill",
        openBill: false,
      });
    }
  };
};

export const getSaveCard = (appState) => {
  return async (dispatch) => {
    var credToken = getCookie("tokenAuth");
    if (credToken != "" && credToken != null) {
      try {
        const newReq = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer/get_card`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("tokenAuth")}`,
              ContentType: "application/json",
            },
          }
        );

        if (newReq.data.success === true) {
          dispatch({ type: "setSaveCardList", saveCardList: newReq.data.data });
          dispatch({
            type: "Set_FreezScreen",
            freezScreen: false,
          });
          // dispatch({
          //   type: "SHOW_ALERT",
          //   alertType: "success",
          //   message: `${newReq.data.message}`,
          // });
        } else {
          dispatch({
            type: "Set_FreezScreen",
            freezScreen: false,
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: `${newReq.data.message}`,
          });
        }
      } catch (e) {
        Promise.reject(e);
        if (e.response != undefined) {
          dispatch(handleError(e));
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
      }
    }
  };
};

export const checkQrRequired = (appState, t) => {
  return async (dispatch) => {
    var checkDineIn = await appState.serviceMode.find((dineIn) => {
      return dineIn === "dine-in";
    });
    console.log(
      checkDineIn,
      appState.qrTable,
      `checkDineIn`,
      appState.serviceMode
    );
    if (appState.qrTable != null) {
      if (
        checkDineIn === "dine-in" &&
        (appState.qrTable || appState.qrPickup)
      ) {
        dispatch({
          type: "setQrRequired",
          qrRequired: true,
        });
        return;
      } else if (checkDineIn != "dine-in" && appState.qrTable) {
        dispatch({
          type: "setQrRequired",
          qrRequired: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${t("custom-msg.no-dineIn")}`,
        });
        dispatch({
          type: "SET_TABLE",
          qrTable: null,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: null,
        });
      }
    } else {
      dispatch({
        type: "setQrRequired",
        qrRequired: false,
      });
    }
  };
};

export const cleanCart = () => {
  return (dispatch) => {
    dispatch({
      type: "updateCart",
      cart: [],
    });
  };
};

export const StoreNotFound = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET",
    });
  };
};

export const freezOn = () => {
  return (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: true,
    });
  };
};

export const freezOff = () => {
  return (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: false,
    });
  };
};

export const addCartModalOpen = () => {
  return (dispatch) => {
    dispatch({
      type: "showAddToCartPopup",
      addToCartPopup: true,
    });
  };
};

export const handleLogoutConditons = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOGOUT",
      loggedIn: false,
    });
    dispatch({ type: "setLoginSkip", loginSkip: false });
    erase("tokenAuth");
    erase("Profile");

    localStorage.setItem("loggedIn", false);
    dispatch({
      type: "SET_USERINFO",
      userInfo: [],
    });
    dispatch({
      type: "SHOW_ALERT",
      alertType: "success",
      message: "Successfully LogOut",
    });
    localStorage.removeItem("userInfo");
    setLangValue({});
  };
};
export const getLogOut = (token) => {
  return async (dispatch) => {
    let resData = [];
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          dispatch(handleLogoutConditons());
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(handleLogoutConditons());
        }
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: error.response.message,
        });
      });
  };
};

export const handleCustomerReview = (
  appState,
  rating,
  review,
  handleLoading
) => {
  let { invoice_id } = appState.orderInvoice ? appState.orderInvoice : "";
  return async (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: true,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/rating/add`,
        {
          invoice_id: invoice_id,
          rating: rating ? rating : 1,
          review: review,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );

      if (res.data.success === true) {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: `${res.data.message}`,
        });
        dispatch({
          type: "setReviewModal",
          reviewModal: false,
        });

        dispatch({
          type: "setOrderInvoice",
          orderInvoice: "",
        });
      } else {
        dispatch({
          type: "Set_FreezScreen",
          freezScreen: false,
        });
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${res.data.message}`,
        });
      }
    } catch (e) {
      Promise.reject(e);
      if (e.response != undefined) {
        dispatch(handleError(e));
      } else {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      dispatch({
        type: "Set_FreezScreen",
        freezScreen: false,
      });
    }
  };
};

export const checkPaymentSource = (source) => {
  var payArray = ["knet", "kfast", "cash", "wallet", "card"];

  var ans = payArray.includes(source);
  if (ans) {
    return source;
  } else {
    return "save_card";
  }
};

export const openBillChecckout = (state, tapId, setTapId) => {
  return async (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/order_invoice/close_open_bill`,
        {
          table_id: state.formData.table_id,
          device_id: state.deviceId,
          customer_id: state.loggedIn
            ? state.userInfo != []
              ? state.userInfo.customerId
              : null
            : null,
          user_id: state.loggedIn
            ? state.userInfo != []
              ? state.userInfo.userId
              : null
            : null,

          number: `${
            !state.loggedIn ? state.formData.number : state.userInfo.number
          }`,
          country_code: `${
            !state.loggedIn
              ? state.formData.country_code
              : state.userInfo.countryCode
          }`,
          payment_source: state.formData.paymentMethod
            ? checkPaymentSource(state.formData.paymentMethod)
            : null,
          note: state.formData.note ? state.formData.note : "N/A",
          token: tapId != "" ? tapId : "",
          promo_code: state.formData.promoCode
            ? state.formData.promoCode
            : null,
          card_id: state.formData.saveCardId ? state.formData.saveCardId : null,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )

      .then((response) => {
        if (response.data.success === true) {
          // localStorage.removeItem("state.formData");
          setTapId("");
          dispatch({ type: "updateSubTotal", subTotal: 0 });
          localStorage.setItem(
            `${state.storeInfo.slug}_cart`,
            JSON.stringify([])
          );
          localStorage.removeItem(`${state.storeInfo.slug}_form`);
          dispatch({
            type: "SET_FORM_DATA",
            formData: { ...state.formData, openBill: false },
          });
          dispatch({
            type: "SET_TABLE",
            qrTable: null,
          });
          dispatch({
            type: "SET_BRANCH",
            qrBranch: null,
          });
          if (response.data.data.url != null) {
            const win = window.open(`${response.data.data.url}`, "_self");
            win.focus();
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "success",
              message: response.data.message,
            });

            dispatch({
              type: "setReviewModal",
              reviewModal: false,
            });
            dispatch({
              type: "setPusherActive",
              pusherActive: false,
            });
            dispatch(freezOff());
          }
          return "/";
        }
        if (response.data.success === false) {
          dispatch(freezOff());
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `${response.data.message} : ${response.data.data} `,
          });
        }
        dispatch({
          type: "setPusherActive",
          pusherActive: false,
        });
      })

      .catch((e) => {
        if (e.response != undefined) {
          dispatch(handleError(e));
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }
        dispatch(freezOff());
      });
  };
};

export const dineInCheckout = (state, formData, tapId, setTapId) => {
  return async (dispatch) => {
    dispatch({
      type: "Set_FreezScreen",
      freezScreen: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/order_invoice/dine_ins`,
        {
          full_name: `${
            !state.loggedIn
              ? formData.full_name
                ? formData.full_name
                : `Customer${Date.now()}`
              : state.userInfo.userFirstName
          }`,
          email: `${!state.loggedIn ? formData.email : state.userInfo.email}`,
          number: `${
            !state.loggedIn ? formData.number : state.userInfo.number
          }`,
          country_code: `${
            !state.loggedIn ? formData.country_code : state.userInfo.countryCode
          }`,
          table_id: formData.table_id ? formData.table_id : state.qrTable,
          branch_id: formData.branch_id,
          store_id: formData.store_id,
          orders: state.cart,
          device_id: state.deviceId,
          customer_id: state.loggedIn
            ? state.userInfo != []
              ? state.userInfo.customerId
              : null
            : null,
          user_id: state.loggedIn
            ? state.userInfo != []
              ? state.userInfo.userId
              : null
            : null,
          promo_code: state.formDatapromoCode ? state.formDatapromoCode : null,
          isOpen: formData.openBill,
          payment_source: formData.paymentMethod
            ? checkPaymentSource(formData.paymentMethod)
            : null,
          note: formData.note ? formData.note : "N/A",
          token: tapId != "" ? tapId : null,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          for (var i = 0; i < state.products.length; i++) {
            for (let j = 0; j < state.cart.length; j++) {
              if (state.cart[j].product_id === state.products[i].id) {
                state.products[i].meta.quantity -= state.cart[j].product_id;
              }
            }
          }
          if (formData.openBill === false) {
            localStorage.setItem("formData", JSON.stringify(state.formData));
          }

          setTapId("");
          dispatch({ type: "SET_PRODUCTS", products: state.products });
          localStorage.removeItem("formData");
          localStorage.removeItem(`${state.storeInfo.slug}_cart`);
          localStorage.removeItem(`${state.storeInfo.slug}_form`);
          dispatch({ type: "updateCart", cart: [] });
          dispatch({ type: "updateSubTotal", subTotal: 0 });
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SET_TABLE",
            qrTable: null,
          });
          dispatch({
            type: "SET_BRANCH",
            qrBranch: null,
          });
          erase("Re-Order");

          // getWallet();
          if (response.data.data.url != null) {
            const win = window.open(`${response.data.data.url}`, "_self");
            win.focus();
            return;
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "success",
              message: response.data.message,
            });
            dispatch(freezOff());
            return "/";
          }
        }
        if (response.data.success === false) {
          dispatch(freezOff());
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `${response.data.message} : ${response.data.data} `,
          });
        }
      })

      .catch((e) => {
        if (e.response != undefined) {
          dispatch(handleError(e));
          dispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }
        dispatch(freezOff());
      });
  };
};

// Clean Limited redux states
export const cleanLimitedState = () => {
  return async (dispatch) => {
    dispatch({ type: "setCampaignState", campaignState: [] });
    dispatch({ type: "setOptionalRewardsDetails", optionalRewardsDetials: [] });
    dispatch({
      type: "setSelectLoyaltyCardPopup",
      selectLoyaltyCardPopup: false,
    });
    dispatch({
      type: "setInvoiceTaxesChargesExtras",
      invoiceTaxesChargesExtras: [],
    });
    dispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: {} });
    dispatch({ type: "setShopMode", shopMode: [] });
    dispatch({ type: "setGuestLoggedIn", guestLoggedIn: false });
  };
};

// Clean and Reset all Cookies and localStorage related orders
export const cleanRest = (
  state,
  response,
  orders,
  history,
  type = "dine-in"
) => {
  return async (dispatch) => {
    localStorage.removeItem(`${state.storeInfo.slug}_cart`);
    localStorage.removeItem(`${state.storeInfo.slug}_form`);
    dispatch(closeGroupBill(state.storeInfo.slug));
    dispatch({ type: "updateCart", cart: [] });
    dispatch({ type: "SET_FORM_DATA", formData: [] });
    dispatch({ type: "setOrderProdQty", orderProdQty: [] });
    dispatch({ type: "setGroupBillRes", groupBillRes: [] });
    dispatch({ type: "updateSubTotal", subTotal: 0 });
    dispatch({ type: "SET_TABLE", qrTable: null });
    dispatch({ type: "SET_BRANCH", qrBranch: null });
    dispatch({ type: "setLoginSkip", loginSkip: false });
    dispatch({ type: "setQrOptionStatus", qrOptionStatus: false });
    dispatch({ type: "setPayTablePopUp", payTablePopUp: false });
    dispatch({ type: "setCampaignState", campaignState: [] });
    dispatch({ type: "setOptionalRewardsDetails", optionalRewardsDetials: [] });
    dispatch({ type: "setPaymentPopUp", paymentPopUp: false });
    dispatch({ type: "setPayTableDiscount", payTableDiscount: 0 });
    dispatch({
      type: "setSelectLoyaltyCardPopup",
      selectLoyaltyCardPopup: false,
    });
    dispatch({
      type: "setInvoiceTaxesChargesExtras",
      invoiceTaxesChargesExtras: [],
    });
    dispatch({ type: "setLoyaltyRewardObj", loyaltyRewardObj: null });
    dispatch({ type: "setHotelMode", hotelMode: false, hotelQrCode: null });
    dispatch({ type: "setHotelObj", hotelObj: null });
    dispatch({ type: "setQrPickup", qrPickup: null });
    dispatch({ type: "setIsAddToCartEnable", isAddToCartEnable: false });
    dispatch({ type: "setCombos", combos: [] });
    dispatch({ type: "setShareBill", shareBill: [] });
    dispatch({ type: "setShopMode", shopMode: [] });
    dispatch({ type: "setDeliveryAddsInfo", deliveryAddsInfo: {} });
    dispatch({ type: "setGuestLoggedIn", guestLoggedIn: false });
    dispatch({
      type: "setPayTableCharges",
      payTableCharges: 0,
      payTableTotalCharges: 0,
    });
    erase("Re-Order");

    if (response != null) {
      if (
        response.data.success === true &&
        response.data.data.url &&
        response.data.data.url != null
      ) {
        const winUrl = window.open(`${response.data.data.url}`, "_self");
        winUrl.focus();
        dispatch(resetSplitOptions());
      } else if (type === "delivery") {
        const winUrl = window.open(
          `${process.env.REACT_APP_PAY_URL}/delivery/${response?.data?.data?.service?.invoice_id}`,
          "_self"
        );
        winUrl.focus();
        dispatch(resetSplitOptions());
      } else if (type === "pickup") {
        const winUrl = window.open(
          `${process.env.REACT_APP_PAY_URL}/pickup/${response?.data?.data?.service?.invoice_id}`,
          "_self"
        );
        winUrl.focus();
        dispatch(resetSplitOptions());
      } else {
        if (response.data.success === true) {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
        }

        if (response.data.success === true) {
          dispatch({
            type: "setReviewModal",
            reviewModal: false,
          });
        }
        dispatch(freezOff());

        history.push("/");
      }
    } else {
      dispatch(freezOff());

      history.push("/");
    }
  };
};

// Clean cookies and localstorage and sessions when updated version found
export const cleanCache = (state) => {
  return async (dispatch) => {
    erase("tokenAuth");
    erase("Re-order");
    erase("Profile");
    [`hotel`, `pickUp`, `dineIn`, `menu`].forEach((item) => {
      localStorage.removeItem(`${state.storeInfo.slug}_${item}_cart`);
      localStorage.removeItem(`${state.storeInfo.slug}_${item}_form`);
    });
    localStorage.removeItem("userInfo");
    localStorage.setItem("loggedIn", false);
    dispatch({ type: "updateCart", cart: [] });
    dispatch({ type: "updateSubTotal", subTotal: 0 });
    dispatch({ type: "setQrRequired", qrRequired: false });
    dispatch({ type: "SET_LOGOUT" });
    dispatch({ type: "SET_USERINFO", userInfo: [] });
    dispatch({ type: "SET_FORM_DATA", formData: [] });
    dispatch({ type: "SET_TABLE", qrTable: null });
    dispatch({ type: "SET_BRANCH", qrBranch: null });
    dispatch({ type: "SET_PRODUCTS", products: [] });
    dispatch({ type: "setDeliveryAddsInfo", deliveryAddsInfo: {} });
  };
};

// get open bill orders
export const getDineInOrder = (state, history, status) => {
  return async (dispatch) => {
    dispatch(freezOn());
    if (state.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${state.groupId}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            dispatch(freezOff());
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            if (status) {
              history.push("/openorder");
            }
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            dispatch(freezOff());
          }
        })
        .catch((error) => {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
          if (error.response != undefined) {
            dispatch(handleError(error));
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          }
          if (error.response.status === 404) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error.message,
            });
          }
          dispatch(freezOff());
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${state.userInfo.customerId}/${state.qrBranch}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            dispatch(freezOff());
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            if (status) {
              history.push("/openorder");
            }
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            dispatch(freezOff());
          }
        })
        .catch((error) => {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
          if (error.response != undefined) {
            dispatch(handleError(error));
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          if (error.response.status === 404) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error.message,
            });
          }
          dispatch(freezOff());
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_din_in/${state.deviceId}/${state.qrTable}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            dispatch(freezOff());
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            if (status) {
              history.push("/openorder");
            }
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            dispatch(freezOff());
          }
        })
        .catch((error) => {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
          if (error.response != undefined) {
            dispatch(handleError(error));
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          if (error.response.status === 404) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error.message,
            });
          }
          dispatch(freezOff());
        });
    } else {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${state.deviceId}/${state.qrBranch}`,
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            dispatch(freezOff());
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            if (status) {
              history.push("/openorder");
            }
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "setQrOptionStatus",
              qrOptionStatus: false,
            });
            dispatch(freezOff());
          }
        })
        .catch((error) => {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
          if (error.response != undefined) {
            dispatch(handleError(error));
          } else {
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          if (error.response.status === 404) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: error.message,
            });
          }
          dispatch(freezOff());
        });
    }
  };
};

// get open bill orders
export const getTableInvoice = (state, history) => {
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/order_invoice/get_table_invoice/${state.qrTable}`,
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const dineIn = response.data.data;
          dispatch(updateTableInvoice(state, dineIn, history));
        } else if (response.data.success === false) {
          dispatch({
            type: "setQrOptionStatus",
            qrOptionStatus: false,
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          dispatch(freezOff());
        }
      })
      .catch((error) => {
        if (error.response != undefined) {
          dispatch(handleError(error));
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }

        if (error.response.status === 404) {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
        }
        dispatch(freezOff());
      });
  };
};

//update tableInvoice
export const updateTableInvoice = (state, dineIn, history) => {
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/order_invoice/update_table_invoice`,
        {
          dine_in_Id: dineIn.id,
          device_id: state.deviceId,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          dispatch({
            type: "setQrOptionStatus",
            qrOptionStatus: false,
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          var form = { ...state.formData, openBill: true };
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify(form)
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: form,
          });
          dispatch({
            type: "setOrderInvoice",
            orderInvoice: response.data.data.service,
          });
          dispatch(freezOff());
          history.push("/openorder");
        } else if (response.data.success === false) {
          dispatch({
            type: "setQrOptionStatus",
            qrOptionStatus: false,
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          dispatch(freezOff());
        }
      })
      .catch((error) => {
        if (error.response != undefined) {
          dispatch(handleError(error));
          dispatch({
            type: "setPusherActive",
            pusherActive: false,
          });
        } else {
          dispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        }
        if (error.response.status === 404) {
          localStorage.setItem(
            `${state.storeInfo.slug}_form`,
            JSON.stringify([])
          );
          dispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
          dispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error.message,
          });
        }
        dispatch(freezOff());
      });
  };
};

// set device id by uuid
export const getDeviceId = (state) => {
  let deviceID = uuidv4();
  return (dispatch) => {
    if (!localStorage.getItem(`deviceId`)) {
      localStorage.setItem("deviceId", deviceID);
      dispatch({
        type: "setDeviceId",
        deviceId: deviceID,
      });
    } else {
      dispatch({
        type: "setDeviceId",
        deviceId: localStorage.getItem(`deviceId`),
      });
    }
  };
};

//  remove add to cart product
export const handleItemRemove = (state, item) => {};

export const resetSplitOptions = () => {
  return (dispatch) => {
    dispatch({ type: "setPaymentPopUp", paymentPopUp: false });

    dispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
    dispatch({
      type: "setSplitBill",
      splitBill: false,
    });
    dispatch({
      type: "setDivideBill",
      divideBill: false,
    });
    dispatch({
      type: "setPayCustomAmount",
      payCustomAmount: false,
    });
    dispatch({
      type: "setPayForItems",
      payForItems: false,
    });
  };
};

export const handleSetMeta = (state) => {
  return (dispatch) => {
    let metaData = state.meta.length > 0 ? [...state.meta] : [];
    if (metaData.length > 0) {
    } else {
      for (let a = 0; a < state.cart.length; a++) {
        metaData.push(state.cart[a]);
      }
    }
    dispatch({
      type: "setMeta",
      meta: metaData,
    });
  };
};

export const handleCheckCreatedAt = (state) => {
  return async (dispatch) => {
    if (state.createdAt == null) {
      let create_at = handleTime();
      dispatch({
        type: "setCreatedAt",
        createdAt: create_at,
      });
    } else {
      let current_time = handleTime();
      // start time and end time
      var startTime = moment(state.createdAt, "HH:mm:ss a");
      var endTime = moment(current_time, "HH:mm:ss a");

      // calculate total duration
      var duration = moment.duration(endTime.diff(startTime));

      // duration in hours
      var hours = parseInt(duration.asHours());

      // duration in minutes
      var minutes = parseInt(duration.asMinutes()) % 60;
      if (minutes > 5) {
        dispatch({
          type: "SET_PRODUCTS",
          products: {},
        });
        dispatch({
          type: "setCategoryProduct",
          categoryProducts: [],
        });
        dispatch({
          type: "setCreatedAt",
          createdAt: current_time,
        });
      }
      // alert(hours + ' hour and ' + minutes + ' minutes.');
    }
  };
};

export const handlePromotionBanner = (state, setIsLoading) => {
  return async (dispatch) => {
    if (state.promotionCreatedAt == null) {
      let promo_create_at = handleTime();
      dispatch({
        type: "setPromotionCreatedAt",
        promotionCreatedAt: promo_create_at,
      });
      dispatch({ type: "setPromotionBanner", promotionBanner: true });
    } else {
      let current_time = handleTime();
      // start time and end time
      var startTime = moment(state.promotionCreatedAt, "HH:mm:ss a");
      var endTime = moment(current_time, "HH:mm:ss a");

      // calculate total duration
      var duration = moment.duration(endTime.diff(startTime));

      // duration in hours
      var hours = parseInt(duration.asHours());

      // duration in minutes
      var minutes = parseInt(duration.asMinutes()) % 60;
      if (hours > 4) {
        dispatch({ type: "setPromotionBanner", promotionBanner: true });
        dispatch({
          type: "setPromotionCreatedAt",
          promotionCreatedAt: current_time,
        });
      } else {
        dispatch({ type: "setPromotionBanner", promotionBanner: false });
      }
      // alert(hours + ' hour and ' + minutes + ' minutes.');
    }
  };
};
export const handleSourceOfMeetBanner = (storeInfo) => {
  return async (dispatch) => {
    dispatch({ type: "setSourceOfMeetBanner", sourceOfMeetBannerImage: false });
    let checkMeatSourceInBranch = Boolean(
      storeInfo?.branch?.meat_source ?? null
    );
    let checkMeatSourceInStore = Boolean(storeInfo?.meatSource ?? null);
    // console.log(checkMeatSourceInBranch, checkMeatSourceInStore)
    if (checkMeatSourceInBranch || checkMeatSourceInStore) {
      // console.log("ifff working")
      let arr = [];
      if (checkMeatSourceInBranch) {
        arr.push(storeInfo?.branch?.meat_source);
      } else if (checkMeatSourceInStore) {
        arr.push(storeInfo?.meatSource);
      }
      dispatch({ type: "setSourceOfMeetBanner", sourceOfMeetBanner: true });
      dispatch({
        type: "setSourceOfMeetBanner",
        sourceOfMeetBannerImageUrls: arr,
      });
    } else {
      // console.log("else working")
      dispatch({ type: "setSourceOfMeetBanner", sourceOfMeetBanner: false });
    }
  };
};

export const checkInvoice = (state, invoice, payTable, setOldInvoice) => {
  return async (dispatch) => {
    if (invoice != null) {
      setOldInvoice(invoice);
      dispatch({
        type: "setQrOptionStatus",
        qrOptionStatus: true,
      });
      dispatch({
        type: "setPayTablePopUp",
        payTablePopUp: false,
      });
    } else {
      dispatch({
        type: "setQrOptionStatus",
        qrOptionStatus: false,
      });
    }
  };
};

export const closePayTable = (state) => {
  return async (dispatch) => {
    dispatch({
      type: "setOldInvoiceId",
      oldInvoice: null,
    });
    dispatch({
      type: "setPayTablePopUp",
      payTablePopUp: false,
    });
    dispatch({
      type: "setQrOptionStatus",
      qrOptionStatus: false,
    });
  };
};

export const handleError = (e) => {
  return async (dispatch) => {
    if (e?.response) {
      let response = e.response;
      let message = e?.response?.data?.message?.replace(/-/g, " ");
      console.log(e);
      dispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: message,
      });
    } else {
      console.warn("Handler is not working");
    }
  };
};

export const handleSuccess = (e) => {
  return async (dispatch) => {
    let response = e.response;
    let message = e.data.message.replace(/-/g, " ");
    console.log(e);
    dispatch({
      type: "SHOW_ALERT",
      alertType: "success",
      message: message,
    });
  };
};

export const getFoodPreference = () => {
  return async (dispatch) => {
    let resData = [];
    await axios
      .get(`${process.env.REACT_APP_API_URL}/food-preference`, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          resData = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return resData;
  };
};

export const tagsAndDiets = (tags, Images) => {
  return async (dispatch) => {
    var globalTags = [];
    var globalDiets = [];
    tags.forEach((tag) => {
      Images.forEach((element) => {
        if (tag.icon == element.name) {
          tag = { ...tag, iconPath: element.image };
        }
      });
      globalTags.push(tag);
    });
    let foodPreference = await dispatch(getFoodPreference());
    if (foodPreference != []) {
      foodPreference.forEach((food) => {
        Images.forEach((element) => {
          if (food.icon == element.name) {
            food = { ...food, iconPath: element.image };
          }
        });
        globalDiets.push(food);
      });
    }

    dispatch({
      type: "setTags",
      tags: globalTags,
    });
    dispatch({
      type: "setDietaries",
      dietaries: globalDiets,
    });
  };
};

export const createGroupBill = (group_id, store_slug) => {
  localStorage.setItem(`${store_slug}_gbId`, group_id);
  return async (dispatch) => {
    dispatch(handleGroupBillModel(true));
    dispatch({
      type: "setGroupId",
      groupId: `${group_id}`,
    });
  };
};

export const closeGroupBill = (store_slug) => {
  localStorage.removeItem(`${store_slug}_gbId`);
  return async (dispatch) => {
    dispatch({
      type: "setGroupId",
      groupId: null,
    });
    dispatch(handleGroupBillModel(false));
  };
};

export const handleGroupBillModel = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: "setGroupBillMode",
      groupBillMode: mode,
    });
  };
};

// Clean cookies and localstorage and sessions when updated version found
export const pushShopMode = (state, mode) => {
  return async (dispatch) => {
    let shopMode = state.shopMode;
    if (!shopMode.includes(mode)) {
      dispatch({
        type: "setShopMode",
        shopMode: [...state.shopMode, mode],
      });
    }
  };
};

// Clean cookies and localstorage and sessions when updated version found
export const popShopMode = (state, mode) => {
  return async (dispatch) => {
    let shopMode = state.shopMode;
    if (shopMode.length > 0) {
      var arryWithOutMode = shopMode.filter((val) => !mode.includes(val));
      dispatch({
        type: "setShopMode",
        shopMode: arryWithOutMode,
      });
    }
  };
};

export const getClosePayments = (appState) => {
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .get(`${process.env.REACT_APP_API_URL}/site_setting/close_payments`, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          dispatch({
            type: "setClosePayments",
            closePayments: response?.data?.data,
          });
        }
        dispatch(freezOff());
      })

      .catch((error) => {
        console.log(error);
        dispatch({
          type: "SHOW_ALERT",
          alertType: "warning",
          message: "Please check your network.",
        });
        dispatch(freezOff());
      });
  };
};
export const handlepaymentMethods = (appState) => {
  return async (dispatch) => {
    dispatch(freezOn());
    var acceptedPayments = [...appState?.acceptedPayments];
    if (appState?.closePayments != null) {
      Object.entries(appState?.closePayments).forEach(
        ([paymentMethod, modes]) => {
          /// checking
          let flag = false;
          let method = true;
          if (modes?.length > 0) {
            modes?.forEach((mode) => {
              if (!flag) {
                if (appState?.serviceMode.includes(mode)) {
                  flag = true;
                } else {
                  flag = false;
                }
              }
            });
          } else {
            flag = false;
            method = true;
          }
          /// checking flagment
          if (flag) {
            method = false;
          } else {
            method = true;
          }
          //// update Method
          // console.log("method", method, "=>", paymentMethod)
          if (method) {
            if (!acceptedPayments.includes(paymentMethod)) {
              acceptedPayments.push(paymentMethod);
            }
          } else {
            const filteredPaymentMethods = acceptedPayments.filter(
              (method) => method !== paymentMethod
            );
            acceptedPayments = [...filteredPaymentMethods];
          }
        }
      );
    }
    dispatch({
      type: "Set_Accepted_Payments",
      acceptedPayments: acceptedPayments,
    });
    dispatch(freezOff());
  };
};
