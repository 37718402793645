/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../../store/StateContext";
import noImg from "../../assets/images/no-image.svg";
import usermar from "../../assets/images/usermar2.svg";
import clockImage from "../../assets/images/clockOpenBill.svg";

// Redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang, get_lang_status } from "../../../../global-functions/apiGlobal";
import { withTranslation, useTranslation } from "react-i18next";
import "./closeBillOrders.scss";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { parseNumber } from "../../../../global-functions/roundFixed";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const CloseBillOrders = ({ item, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const appState = useSelector((state) => state);
  const isAddons = item.sum_addons;
  return (
    <div className="closeBillOrders"
    //  id={item.prod_id}
     >
   

      <div className="row m-0 d-flex align-items-start">

        <div
          className={` col-12 px-0 ${appState.langValue === "ar" ? " " : ""
            }`}
        >
          <div className="row m-0">
            <div className="col-12 px-0 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center ">
              
                <span className={` ${appState.langValue === "ar" ? "text-right" : "text-left familyPoppins300 familyManrope40"}  numberListing d-flex align-items-center justify-content-center`}>
                  { appState.langValue === "ar" ? <> x{item.quantity}  </>  :<> {item.quantity}x  </> }  
                </span>
                <Typography className="mx-2 my-0" gutterBottom variant="h5" component="h2">
                  <strong className={`toCapitialize d-flex ${appState.langValue === "ar" ? "" : "familyPoppins300"}`}>
                    {item.product != null
                      ? get_lang(item.product?.name, appState.langValue)
                      : "Name is not available"}
                  </strong>
                </Typography>
              </div>
            </div>
          </div>




          <div
            className={` d-flex   ${appState.langValue === "ar" ? "addonsWrapAr " : "addonsWrapEn "
              }`}
          >
            {!item.addons.length ? (
              ""
            ) : (
              <span
                className={`addonsh3  ${appState.langValue == "ar" ? "text-right" : "text-left familyPoppins300"
                  }`}
                style={{ textAlign: appState.langValue === "ar" ? "right" : "left", lineHeight:appState.langValue === "ar" ? "35px" : "", fontFamily: "Poppins, sans-serif", fontSize: "1.25rem", wordWrap: 'break-word', }}
              >
                {" "}
                {t("single-product.Extras")}<span className="mx-1">:</span>
              </span>
            )}
            <span className="" style={{textAlign: appState.langValue === "ar" ? "right" : "left",fontFamily: "Poppins, sans-serif",fontSize: "1.25rem",wordWrap: 'break-word',}}>
              {item.addons &&
                item.addons.map((itemAddon, ind) => {
                  const langValue = appState.langValue;
                  const addonName = get_lang(itemAddon.product_addon.addon.name, langValue);
                  const langStatus = get_lang_status(itemAddon.product_addon.addon.name, langValue);
                  const quantityText = langStatus ? `x${itemAddon.quantity} ` : `${itemAddon.quantity}x`;

                  return (
                    <span key={ind} className={`addonsh3 ${langValue === "ar" ? "text-right" : "text-left familyPoppins300 familyManrope40"}`}>
                      <span>
                        <span>{quantityText}</span>
                        <span className="text-capitalize">{addonName}, </span>
                      </span>
                    </span>
                  );
                })
              }
            </span>
          </div>
        </div>
    
      </div>
    </div>
  );
};

export default withTranslation()(CloseBillOrders);
