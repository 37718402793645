/* eslint-disable */
import React from "react";
import "./splitBillOptions.scss";
// material ui
import Button from "@mui/material/Button";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// redux

function SplitBillOptions(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();

  const handleShowPayForItems = () => {
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
    appDispatch({
      type: "setPayForItems",
      payForItems: true,
    });
  };

  const handleShowDivideBill = () => {
    appDispatch({
      type: "setDivideBill",
      divideBill: true,
    });
  };

  const handleShowPayCustomAmount = () => {
    appDispatch({
      type: "setPayCustomAmount",
      payCustomAmount: true,
    });
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
  };

  return (
    <div
      id="mySidenav"
      className={`splitBillOptions   ${
        appState.langValue === "ar" ? "splitBillOptionsArabic" : " "
      }`}
    >
      <div className="container-fluid content px-4 pb-3 my-5">
        <h1
          className={` ${
            appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins300"
          }`}
        >
          {t("checkOut.Split-Bill")}
        </h1>
        <div className="row mt-3 px-2">
          { (appState.shopMode.includes('is_partial') ?  appState.shopMode.includes('product') ? true : false : true ) &&
            <Button
              onClick={handleShowPayForItems}
              className={`py-3 px-4 d-flex justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600 " : ""
              }`}
            >
              <span className="text-capitalize">
                {t("checkOut.Pay-for-your-items")}
              </span>{" "}
            </Button>
          }

          {(appState.shopMode.includes('is_partial') ? appState.shopMode.includes('ratio') ? true : false : true ) && <Button
            onClick={handleShowDivideBill}
            className={` py-3 px-4 mt-3 justify-content-center ${
              appState.langValue === "ar" ? " familyGraphikArabic600" : ""
            }`}
          >
            {" "}
            <span className="text-capitalize">
              {t("checkOut.Divide-the-bill-equally")}
            </span>{" "}
          </Button>}
          { <Button
            onClick={handleShowPayCustomAmount}
            className={` py-3 px-4 mt-3 justify-content-center ${
              appState.langValue === "ar" ? " familyGraphikArabic600" : ""
            }`}
          >
            {" "}
            <span className="text-capitalize">
              {t("checkOut.Pay-a-custom-amount")}
            </span>{" "}
          </Button>}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(SplitBillOptions);
