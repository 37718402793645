/* eslint-disable no-extra-label */
/* eslint-disable eqeqeq */

import { get_lang } from "./apiGlobal";

// import StateContext from "../layout-one/js/StateContext";

export const addAddOnsRes = (appState, addOnsCart, quantityValue) => {
  // Main cart for loop
  for (let i = 0; i < appState.addonCart.length; i++) {
    //Product cart for loop
    inner_loop: for (let a = 0; a < addOnsCart.length; a++) {
      if (
        appState.addonCart[i].addon_id == addOnsCart[a].addon_id &&
        appState.addonCart[i].product_id == addOnsCart[a].product_id
      ) {
        let product = [...appState.addonCart];
        product[i].quantity += quantityValue;
        product[i].totalAddonAmount =
          product[i].quantity * product[i].addon_price;
        if (a == addOnsCart.length - 1) {
          return;
        } // Match After Last product has been matched
        break inner_loop;
      }
    }
  }

  let add_on = [...addOnsCart];

  return add_on;
};

export const addToCartRes = () => {};

export const myCartRes = (cart, addOnsCart) => {
  let myCart = [...cart];
  if (addOnsCart.length > 0) {
    for (let i = 0; i < myCart.length; i++) {
      for (let y = 0; y < addOnsCart.length; y++) {
        let product = cart.filter((item, index) =>
          item.product_id
            .toString()
            .includes(addOnsCart[y].product_id.toString())
        )[0];
        if (product.prod_id === myCart[i].prod_id) {
          myCart[i].sum_addons =
            myCart[i].sum_addons +
            parseFloat(addOnsCart[y].addon_price) *
              parseFloat(addOnsCart[y].quantity);
        }
      }
    }
  }

  return myCart;
};

export const handleAddonsQtyByProduct = (
  addons,
  quantityValue,
  dispatch,
  appState
) => {
  let isError = false;
  addons.some((item) => {
    if (item.check_status) {
      const currentQty = item.quantity * (quantityValue + 1);
      if (currentQty > item.maxQuantity && item.maxQuantity != null) {
        dispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${get_lang(
            item.addon?.name,
            appState.langValue
          )} is out of stock!`,
        });
        isError = true;
        return true; // Stop iterating
      }
    }
    return false;
  });

  return !isError;
};

export const handleCheckBoxAddonsQtyByProduct = (
  addon,
  quantityValue,
  dispatch,
  appState
) => {
  let isError = false;
  if (!addon.check_status) {
    const currentQty = quantityValue;
    if (currentQty > addon.maxQuantity && addon.maxQuantity != null) {
      dispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `${get_lang(
          addon.addon?.name,
          appState.langValue
        )} is out of stock!`,
      });
      isError = true;
    }
  }

  return !isError;
};

export const globalHandleCheck = (
  addonLimit,
  addons,
  addon_id,
  category_id,
  dispatch,
  appState,
  productQty
) => {
  let flag = true;
  var arr = [...addons];
  let partictularAddon = addonLimit.find((x) => x.id == category_id);
  var productIndex = arr.find((x) => x.product_addon_id == addon_id);
  var get = arr.find((x) => x.product_addon_id == addon_id);
  let qtyFlag = handleCheckBoxAddonsQtyByProduct(
    get,
    productQty,
    dispatch,
    appState
  );
  if (flag && qtyFlag) {
    get.check_status = !get.check_status;
    if (get.check_status) {
      get.quantity = 1;
    } else {
      get.quantity = 0;
    }
    flag = false;
    arr[productIndex] = get;
  }
  return arr;
};

export const globalHandleRadio = (
  addonLimit,
  addons,
  addon_id,
  category_id,
  dispatch,
  appState,
  productQty
) => {
  let flag = true;
  var arr = [...addons];
  let temp = [];

  arr.forEach((ele, ind) => {
    if (ele.category_addon_id == category_id) {
      if (ele.product_addon_id == addon_id) {
        let qtyFlag = handleCheckBoxAddonsQtyByProduct(
          ele,
          productQty,
          dispatch,
          appState
        );

        if (flag && qtyFlag) {
          ele.check_status = true;
          ele.quantity = 1;
          flag = false;
        }
      } else {
        ele.check_status = false;
        ele.quantity = 0;
      }
    }
    temp.push(ele);
  });
  return temp;
};

export const handleAddonsIncrement = (
  addonsLimit,
  addons,
  addon_id,
  category_id,
  dispatch,
  appState,
  productQty
) => {
  const particularAddon = addonsLimit.find((x) => x.id === category_id);
  const updatedAddons = [...addons];
  if (
    particularAddon.max === null ||
    particularAddon.addons_qty < particularAddon.max
  ) {
    const addonIndex = updatedAddons.findIndex(
      (x) => x.product_addon_id === addon_id
    );
    const currentAddon = updatedAddons[addonIndex];
    const currentAddonQty = (currentAddon.quantity + 1) * productQty;
    if (
      currentAddonQty <= currentAddon.maxQuantity ||
      currentAddon.maxQuantity === null
    ) {
      currentAddon.quantity++;
      updatedAddons[addonIndex] = currentAddon;
    } else {
      dispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `${get_lang(
          currentAddon.addon.name,
          appState.langValue
        )} is out of stock!`,
      });
    }
  }
  return updatedAddons;
};
export const handleAddonsDecrement = (
  addonsLimit,
  addons,
  addon_id,
  category_id
) => {
  const updatedAddons = [...addons];
  const categoryIndex = updatedAddons.findIndex((x) => x.id === category_id);
  const addonIndex = updatedAddons.findIndex(
    (x) => x.product_addon_id === addon_id
  );
  const currentAddon = updatedAddons[addonIndex];
  if (currentAddon.quantity > 0) {
    currentAddon.quantity--;
    if (currentAddon.quantity < 1) {
      globalHandleCheck(addonsLimit, addons, addon_id, category_id);
    }
    updatedAddons[addonIndex] = currentAddon;
  }
  return updatedAddons;
};

export const handleGroupId = (id, cart) => {
  const foundItem = cart.find((item) => item.id == id);

  if (foundItem) {
    return foundItem.pGroupId ?? null;
  } else {
    // Item with the provided ID was not found in the cart
    return null; // Or handle the absence of the item accordingly
  }
};

export const handleStockQuantity = async (cart, response, dispatch, state) => {
  let arr = [...state.orderProdQty];
  let prodTotalQty = 0; /// user sel quantity
  let flag = true;
  // console.log(arr);
  await cart.forEach((ele) => {
    if (ele.product_id === response.id) {
      prodTotalQty = prodTotalQty + ele.quantity; /// jitni quantity addtocart kr chuka
    }
  });

  await arr.forEach((item) => {
    if (item.product_id == response.id) {
      item.prodTotalQty = prodTotalQty;
      item.maxQuantity = response.maxQuantity;
      flag = false;
    }
  });
  if (flag) {
    let orderProdQty = {
      product_id: response.id,
      prodTotalQty: prodTotalQty,
      maxQuantity: "",
      name: response?.slug,
    };
    arr.push(orderProdQty);
  }
  dispatch({
    type: "setOrderProdQty",
    orderProdQty: arr,
  });

  return prodTotalQty;
};

export const handleOrdersPayload = (orders) => {
  // console.log("mainOrder_handleOrdersPayload ",orders)
  let arr = [];
  if (orders?.length > 0) {
    orders.forEach((item) => {
      let data = {
        quantity: item?.quantity,
        price: item?.price,
        meta: item?.meta,
        addons: item?.addons,
        product_id: item?.product_id,
        note: item?.note,
        loyalty_reward: item?.loyalty_reward ? item?.loyalty_reward : null,
        placed_by: item.placed_by  ,
      };

      arr.push(data);
    });
  }
  return arr;
};

export const handleBranchPriceQty = () => {
  return;
};

// this AddToCart Function is only for layout out and in Home component, Don't use this function in Layout One
