/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./UserAddressPopup.scss";
// material ui
import Button from "@mui/material/Button";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { get_lang } from "../../global-functions/apiGlobal";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux-store/store";
import axios from "axios";
import {
  getUserDeliveryAddresses,
  postDeleteUserDeliveryAddress,
} from "../../redux-store/action/apiCall";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
function UserAddressPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { setUserAddressPopup, setUserSaveDeliveryAdds, setDeliveryAddsInfo } =
    bindActionCreators(actionCreators, appDispatch);
  const history = useHistory();

  const handleClickConfirm = () => {
    setUserAddressPopup(false);
    history.push(`/address-form?addtype=2`);
  };

  const handleSelectAddress = (id, type) => {
    let res = appState.userSaveDeliveryAdds.filter((item) => item.id === id);
    if (res) {
      setDeliveryAddsInfo({
        ...appState.deliveryAddsInfo,
        appartment: res[0]?.appartment ? res[0]?.appartment : "",
        area_id: res[0]?.area_id ? res[0]?.area_id : "",
        aveneu: res[0]?.avenue ? res[0]?.avenue : "",
        block: res[0]?.block ? res[0]?.block : "",
        city_id: res[0]?.city_id ? res[0]?.city_id : "",
        floor: res[0]?.floor ? res[0]?.floor : "",
        lat: res[0]?.lat ? res[0]?.lat : "",
        lng: res[0]?.lng ? res[0]?.lng : "",
        street: res[0]?.street ? res[0]?.street : "",
        formType: res[0]?.formTypeId ? res[0]?.formTypeId : 1,
        fullAddress: res[0]?.fullAddress ? res[0]?.fullAddress : "",
        saveAddId: res[0]?.id ? res[0]?.id : 0,
        house_no: res[0]?.house_no ? res[0]?.house_no : "",
        delivery_address_id: id
      });
    }
    setUserAddressPopup(false);
    if (type === 1) {
      history.push(`/address-form?addtype=3`);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const getUserAddress = async () => {
      let res = await getUserDeliveryAddresses(appState, appDispatch);
      if (isMounted) {
        setUserSaveDeliveryAdds(res);
      }
    };
    getUserAddress();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClickDelete = (id) => {
    let res = appState.userSaveDeliveryAdds.filter((item) => item.id !== id);
    postDeleteUserDeliveryAddress(id);
    setUserSaveDeliveryAdds(res);
  };


  return (
    <>
      <div
        id="mySidenav"
        className={`UserAddress ${
          appState.langValue === "ar" ? "divideBillArabic" : " "
        }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 mt-4">
          <h1
            className={` d-flex align-items-center justify-content-between ${
              appState.langValue === "ar"
                ? " familyGraphikArabic800 "
                : " familyPoppins300"
            }`}
          >
            {t("Save Addresses")}
            <span
              className={`${
                appState.langValue === "ar" ? "mr-auto" : "ml-auto"
              }`}
            >
              {" "}
              <IconButton onClick={() => setUserAddressPopup(false)}>
                {" "}
                <CloseIcon />{" "}
              </IconButton>{" "}
            </span>
          </h1>
          <hr />
       <div className="mb-100">
            {appState.userSaveDeliveryAdds?.length > 0 &&
              appState.userSaveDeliveryAdds.map((item, index) => {
                return (
                  <Card
                    key={index}
                    className={`mt-3 ${
                      appState.deliveryAddsInfo?.saveAddId === item.id
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <CardContent
                      // className="cursor-pointer"
                    >
                      <div
                        className={`row w-100  d-flex align-items-center justify-content-between`}
                      >
                        <span
                          className="cursor-pointer"
                          onClick={() => handleSelectAddress(item.id)}
                        >
                          {" "}
                          <span
                            className={`homeIcon ${
                              appState.langValue === "ar" ? "mr-3" : "ml-3"
                            }`}
                          >
                            <MapsHomeWorkIcon />
                          </span>{" "}
                          <span className="px-1">
                            {" "}
                            {t("Address")} {item.s_no}{" "}
                          </span>
                        </span>
                        <span>
                          {" "}
                          <IconButton
                            onClick={() => handleClickDelete(item.id)}
                          >
                            {" "}
                            <DeleteForeverIcon />{" "}
                          </IconButton>{" "}
                          <IconButton
                            onClick={() => handleSelectAddress(item.id, 1)}
                          >
                            {" "}
                            <ModeEditIcon />{" "}
                          </IconButton>{" "}
                        </span>
                      </div>
                      <div
                        onClick={() => handleSelectAddress(item.id)}
                        className={`row w-100  px-3 d-flex align-items-center justify-content-between cursor-pointer`}
                      >
                        <p>
                          {" "}
                          <span
                            style={{
                              color: appState.storeInfo.primaryColor,
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            {item?.formTypeId === 1
                              ? `${t("Home")} :`
                              : item?.formTypeId == 2
                              ? `${t("Apartment")} :`
                              : `${t("Office")} :`}{" "}
                          </span>{" "}
                          {item?.fullAddress}
                        </p>
                        <strong>Select</strong>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
          </div> 
          
          {/* <section id="reviewOrderButton">
                <div id="process-btn" className=" col-md-12 offset-xl-3 mr-auto col-xl-6">
                <Button
              onClick={handleClickConfirm}
              className={` py-3 px-4 mt-3 confirmBtn justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("Add new address")}</span>{" "}
            </Button>
                </div>
              </section> */}

       
          <div className="bottomBtn col-md-12">
          <Button
              onClick={handleClickConfirm}
              className={`w-100 py-3 px-4 mt-6 add-location justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }  `}
            >
              <span>{t("Add new address")} </span> 
            </Button>
            <Button
              onClick={() => setUserAddressPopup(false)}
              className={` py-3 px-4 mt-3 confirmBtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {" "}
              <span> {t("Confirm")} </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(UserAddressPopup);
