/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import './ProductV3.scss'
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
// import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../../store/StateContext";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Chip from "@mui/material/Chip";
import noImg from "../../assets/images/no-image.svg";
// redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";
// for Localization
import { withTranslation, useTranslation, Trans } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const ProductV3 = ({ item, ind, parent_id, callingFrom, handleClickShowProductPopup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  let { slug, islug } = useParams();
  
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let cat_id = searchParams.get("catid");
  const [cartBorderShow, setCartBorderShow] = useState(false);
  useEffect(() => {
    if (slug == undefined) {
      slug = item.category.slug;
    }
    let flag = true;
    if (appState?.cart?.length > 0) {
      appState?.cart?.map((ele) => {
        if (ele?.product_id == item.id && flag) {
          setCartBorderShow(flag);
          flag = false;
        }
      });
    }
  }, []);
  const myRef = useRef(null);
 

  const handleScroll = (id) => {
    appDispatch({
      type: "setScrollKeyword",
      scrollKeyword: id,
    });
  };

  return (
    <section id="product-v3">
      <div
        onClick={()=>handleScroll(`${ind}-${item.id}`)}
        id={`${ind}-${item.id}`}
        className="col-12 p-1 px-1 inner_prod prod_main_div"
      >
        {item.quantity <= 0 && item.quantity != null ? (
          <a>
            <div
              className={`row prod_content_row m-0  s  ${
                cartBorderShow
                  ? appState.langValue == "ar"
                    ? "addToCartBorderAr"
                    : "addToCartBorderEn"
                  : ""
              }`}
            >
              <div
                className={`prod_categ_img  px-0 ${
                  callingFrom === "search"
                    ? "col-3 col-md-2 prod_categ_img_search_inhance"
                    : ""
                } ${
                  !appState.storeInfo?.categoryProductImage
                    ? "hideProductImage"
                    : ""
                }  `}
                style={{
                  background: `url(${
                    item.image ? `${item.image}` : `${noImg}`
                  })no-repeat center center / cover`,
                  width: "",
                  // center top/100% 100% no-repeat
                }}
              >
                {/* <img
                    src={item.image ? `${item.image}` : `${noImg}`}
                    alt=""
                    className="img-fluid "
                  /> */}
              </div>
              <div
                className={`${
                  !appState.storeInfo?.categoryProductImage
                    ? "col-12"
                    : "col-6 col-md-6"
                }  prod_content ${
                  callingFrom === "search" ? "col-9 col-md-10" : ""
                }`}
              >
                <div>
                  <h4
                    className={` ${
                      appState.langValue === "ar" ? "text-right" : "text-left"
                    }`}
                  >
                    <strong className="d-flex text-capitalize">
                      {item.name != null
                        ? get_lang(item.name, appState.langValue)
                        : "Not Available"}
                    </strong>
                  </h4>

                  {item.description != null &&
                  get_lang(item.description, appState.langValue) != null
                    ? get_lang(item.description, appState.langValue) != "N/A"
                      ? get_lang(item.description, appState.langValue)
                          .split("<br>")
                          .map(
                            (item, i) =>
                              i == 0 && (
                                <p
                                  style={{ overflow: "hidden" }}
                                  key={i}
                                  className={`m-0 d-flex ${
                                    appState.langValue === "ar"
                                      ? "text-right"
                                      : "text-left"
                                  }`}
                                >
                                  {item != "" && `${item.slice(0, 70)}...`}
                                </p>
                              )
                          )
                      : ""
                    : ""}

                  <h6 className="d-flex" style={{ color: "red" }}>
                    {t("single-product.Out-of-stock")}
                  </h6>
                </div>
              </div>
            </div>
          </a>
        ) : ( item.has_addons ?
          <Link
            to={`${
              appState.storeInfo.progressive
                ? `/${ item.category.slug }/${item.slug }?parent=${parent_id}&prodid=${item.id}&catid=${cat_id}`
                : `/${ item.category.slug }/${item.slug}?parent=${parent_id}&prodid=${item.id}&catid=${cat_id}`}`}
            className="prod_link"
          >
            <div
              className={`row prod_content_row m-0 ${
                cartBorderShow
                  ? appState.langValue == "ar"
                    ? "addToCartBorderAr"
                    : "addToCartBorderEn"
                  : ""
              }`}
            >
              <div
                className={` prod_categ_img px-0 ${
                  callingFrom === "search"
                    ? "col-3 col-md-2 prod_categ_img_search_inhance"
                    : ""
                } ${
                  !appState.storeInfo?.categoryProductImage
                    ? "hideProductImage"
                    : ""
                }`}
                style={{
                  background: `url(${
                    item.image ? `${item.image}` : `${noImg}`
                  })no-repeat center center / cover`,
                  width: "",
                  // center top/100% 100% no-repeat
                }}
              >
                {/* <img
                    src={item.image ? `${item.image}` : `${noImg}`}
                    alt=""
                    className="img-fluid "
                  /> */}
              </div>
              <div
                className={`${
                  !appState.storeInfo?.categoryProductImage
                    ? "col-12"
                    : "col-6 col-md-6"
                }  prod_content ${
                  callingFrom === "search" ? "col-9 col-md-10" : ""
                }`}
              >
                <div>
                  <h4
                    className={` ${
                      appState.langValue === "ar" ? "text-right" : "text-left"
                    }`}
                  >
                    <strong className="  text-capitalize">
                      {`${
                        item.name != null
                          ? get_lang(item.name, appState.langValue)
                          : "Not Available"
                      }`}
                      {/* {get_lang(item.name, appState.langValue).length} */}
                    </strong> 
                  </h4>

                  {item.description != null &&
                  get_lang(item.description, appState.langValue) != null
                    ? get_lang(item.description, appState.langValue) != "N/A"
                      ? get_lang(item.description, appState.langValue)
                          .split("<br>")
                          .map(
                            (item, i) =>
                              i == 0 && (
                                <p
                                  style={{ overflow: "hidden" }}
                                  key={i}
                                  className={`m-0 d-flex ${
                                    appState.langValue === "ar"
                                      ? "text-right"
                                      : "text-left"
                                  }`}
                                >
                                  {item != "" && `${item.slice(0, 70)}...`}
                                </p>
                              )
                          )
                      : ""
                    : ""}
                </div>
                <div
                  className={`d-flex flex-wrap  ${
                    appState?.storeInfo?.showCalorie && item?.calories != null
                      ? "hascalorie"
                      : ""
                  }`}
                >
                  {item.type == "Variable" ? (
                    <h6
                      className={` ${
                        appState.langValue === "ar"
                          ? "text-right pl-2"
                          : "text-left pr-2"
                      }`}
                    >
                      <strong className="d-flex">
                        {parseFloat(item.min).toFixed(3)} &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}{" "}
                        - {parseFloat(item.max).toFixed(3)} &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </strong>
                    </h6>
                  ) : (
                    <h6
                      className={` ${
                        appState.langValue === "ar"
                          ? "text-right pl-2"
                          : "text-left pr-2"
                      }`}
                    >
                      {item.price - item.discount == 0 ? (
                        <strong className="d-flex">
                          {t("product.Price-on-Selection")}
                        </strong>
                      ) : (
                        <strong className="d-flex">
                          {parseFloat(item.price - item.discount).toFixed(3)}{" "}
                          &nbsp;
                          {get_lang(
                            appState.storeInfo.storeCurrency,
                            appState.langValue
                          )}{" "}
                        </strong>
                      )}
                    </h6>
                  )}
                  {appState?.storeInfo?.showCalorie &&
                    item?.calories != null && (
                      <span className=" d-flex">
                        <h6
                          className={` ${
                            appState.langValue === "ar"
                              ? "text-right"
                              : "text-left"
                          }`}
                        >
                          {" "}
                          <strong className="d-flex">
                            <span>|</span>

                            <span className="px-2 calorieColor">
                              {parseFloat(item?.calories).toFixed(3)} &nbsp;
                              Cal.
                            </span>
                          </strong>
                        </h6>
                      </span>
                    )}
                </div>

                <span className="d-flex flex-wrap">
                  {item.tags != [] &&
                    item.tags != undefined &&
                    item.tags.map((tag, index) => {
                      return (
                        <div
                          key={index}
                          className={` familyPoppins my-1  ${
                            index >= 1 ? "" : ""
                          } ${
                            appState.langValue === "ar"
                              ? "d-flex justify-content-end  direction-ltr pl-1 "
                              : "pr-1"
                          }`}
                        >
                          <Chip
                            style={{ color: tag.color ? tag.color : "#0000" }}
                            icon={
                              tag.iconPath != null ? (
                                <img src={tag.iconPath} />
                              ) : (
                                ""
                              )
                            }
                            className="ChipClass"
                            label={`${get_lang(tag.name, appState.langValue)}`}
                            variant="outlined"
                          />
                        </div>
                      );
                    })}
                  {item.food_preferences != [] &&
                    item.food_preferences != undefined &&
                    item.food_preferences.map((food_preference, index) => {
                      return (
                        <div
                          key={index}
                          className={` familyPoppins my-1  ${
                            index >= 1 ? "" : ""
                          } ${
                            appState.langValue === "ar"
                              ? "d-flex justify-content-end  direction-ltr pl-1 "
                              : "pr-1"
                          }`}
                        >
                          <Chip
                            style={{
                              color: food_preference.color
                                ? food_preference.color
                                : "#0000",
                            }}
                            icon={
                              food_preference.iconPath != null ? (
                                <img src={food_preference.iconPath} />
                              ) : (
                                ""
                              )
                            }
                            className="ChipClass"
                            label={`${get_lang(
                              food_preference.name,
                              appState.langValue
                            )}`}
                            variant="outlined"
                          />
                        </div>
                      );
                    })}
                </span>
              </div>
            </div>
          </Link>
          :
          <a
         
            className="prod_link"
          >
            <div
              className={`row prod_content_row m-0 ${
                cartBorderShow
                  ? appState.langValue == "ar"
                    ? "addToCartBorderAr"
                    : "addToCartBorderEn"
                  : ""
              }`}
              onClick={() => handleClickShowProductPopup(item.id, item.slug, item.categorySlug)}

            >
              <div
                className={` prod_categ_img px-0 ${
                  callingFrom === "search"
                    ? "col-3 col-md-2 prod_categ_img_search_inhance"
                    : ""
                } ${
                  !appState.storeInfo?.categoryProductImage
                    ? "hideProductImage"
                    : ""
                }`}
                style={{
                  background: `url(${
                    item.image ? `${item.image}` : `${noImg}`
                  })no-repeat center center / cover`,
                  width: "",
                  // center top/100% 100% no-repeat
                }}
              >
                {/* <img
                    src={item.image ? `${item.image}` : `${noImg}`}
                    alt=""
                    className="img-fluid "
                  /> */}
              </div>
              <div
                className={`${
                  !appState.storeInfo?.categoryProductImage
                    ? "col-12"
                    : "col-6 col-md-6"
                }  prod_content ${
                  callingFrom === "search" ? "col-9 col-md-10" : ""
                }`}
              >
                <div>
                  <h4
                    className={` ${
                      appState.langValue === "ar" ? "text-right" : "text-left"
                    }`}
                  >
                    <strong className="  text-capitalize">
                      {`${
                        item.name != null
                          ? get_lang(item.name, appState.langValue)
                          : "Not Available"
                      }`}
                      {/* {get_lang(item.name, appState.langValue).length} */}
                    </strong> 
                  </h4>

                  {item.description != null &&
                  get_lang(item.description, appState.langValue) != null
                    ? get_lang(item.description, appState.langValue) != "N/A"
                      ? get_lang(item.description, appState.langValue)
                          .split("<br>")
                          .map(
                            (item, i) =>
                              i == 0 && (
                                <p
                                  style={{ overflow: "hidden" }}
                                  key={i}
                                  className={`m-0 d-flex ${
                                    appState.langValue === "ar"
                                      ? "text-right"
                                      : "text-left"
                                  }`}
                                >
                                  {item != "" && `${item.slice(0, 70)}...`}
                                </p>
                              )
                          )
                      : ""
                    : ""}
                </div>
                <div
                  className={`d-flex flex-wrap  ${
                    appState?.storeInfo?.showCalorie && item?.calories != null
                      ? "hascalorie"
                      : ""
                  }`}
                >
                  {item.type == "Variable" ? (
                    <h6
                      className={` ${
                        appState.langValue === "ar"
                          ? "text-right pl-2"
                          : "text-left pr-2"
                      }`}
                    >
                      <strong className="d-flex">
                        {parseFloat(item.min).toFixed(3)} &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}{" "}
                        - {parseFloat(item.max).toFixed(3)} &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </strong>
                    </h6>
                  ) : (
                    <h6
                      className={` ${
                        appState.langValue === "ar"
                          ? "text-right pl-2"
                          : "text-left pr-2"
                      }`}
                    >
                      {item.price - item.discount == 0 ? (
                        <strong className="d-flex">
                          {t("product.Price-on-Selection")}
                        </strong>
                      ) : (
                        <strong className="d-flex">
                          {parseFloat(item.price - item.discount).toFixed(3)}{" "}
                          &nbsp;
                          {get_lang(
                            appState.storeInfo.storeCurrency,
                            appState.langValue
                          )}{" "}
                        </strong>
                      )}
                    </h6>
                  )}
                  {appState?.storeInfo?.showCalorie &&
                    item?.calories != null && (
                      <span className=" d-flex">
                        <h6
                          className={` ${
                            appState.langValue === "ar"
                              ? "text-right"
                              : "text-left"
                          }`}
                        >
                          {" "}
                          <strong className="d-flex">
                            <span>|</span>

                            <span className="px-2 calorieColor">
                              {parseFloat(item?.calories).toFixed(3)} &nbsp;
                              Cal.
                            </span>
                          </strong>
                        </h6>
                      </span>
                    )}
                </div>

                <span className="d-flex flex-wrap">
                  {item.tags != [] &&
                    item.tags != undefined &&
                    item.tags.map((tag, index) => {
                      return (
                        <div
                          key={index}
                          className={` familyPoppins my-1  ${
                            index >= 1 ? "" : ""
                          } ${
                            appState.langValue === "ar"
                              ? "d-flex justify-content-end  direction-ltr pl-1 "
                              : "pr-1"
                          }`}
                        >
                          <Chip
                            style={{ color: tag.color ? tag.color : "#0000" }}
                            icon={
                              tag.iconPath != null ? (
                                <img src={tag.iconPath} />
                              ) : (
                                ""
                              )
                            }
                            className="ChipClass"
                            label={`${get_lang(tag.name, appState.langValue)}`}
                            variant="outlined"
                          />
                        </div>
                      );
                    })}
                  {item.food_preferences != [] &&
                    item.food_preferences != undefined &&
                    item.food_preferences.map((food_preference, index) => {
                      return (
                        <div
                          key={index}
                          className={` familyPoppins my-1  ${
                            index >= 1 ? "" : ""
                          } ${
                            appState.langValue === "ar"
                              ? "d-flex justify-content-end  direction-ltr pl-1 "
                              : "pr-1"
                          }`}
                        >
                          <Chip
                            style={{
                              color: food_preference.color
                                ? food_preference.color
                                : "#0000",
                            }}
                            icon={
                              food_preference.iconPath != null ? (
                                <img src={food_preference.iconPath} />
                              ) : (
                                ""
                              )
                            }
                            className="ChipClass"
                            label={`${get_lang(
                              food_preference.name,
                              appState.langValue
                            )}`}
                            variant="outlined"
                          />
                        </div>
                      );
                    })}
                </span>
              </div>
            </div>
          </a>
        )}
      </div>
    </section>
  );
};

export default withTranslation()(ProductV3);
