/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import "./loginModalv2Popup.scss";
// react router dom
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// material ui
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DoneIcon from "@mui/icons-material/Done";
// svg icons
import IMAGES from "../Assets/images2";
import InputMaskField from "../../sharedComponent/react-input-mask/inputfield";
import Page from "../../layout-one/js/components/Page";
import Backdrop from "@mui/material/Backdrop";
import leftArrow from "../../layout-three/js/assets/images/leftArrow.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Axios from "axios";
import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
//  cookie functions
import { getCookie, setCookie } from "../../global-functions/cookieFunction";
import { freezOn, freezOff, handleError } from "../../redux-store/action";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { checkNameRequired } from "../../global-functions/loginAllow";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux-store/store";
import { formatKuwaitiMobile } from "../../utils/genral-functions";
import { useMediaQuery } from "@material-ui/core";

function LoginModalv2Popup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { setGuestLoggedIn, setLoginModal, setLoginSkip, setLoginState, setFreezScreen, setLangValue, setGuestModal } = bindActionCreators(actionCreators, appDispatch);
  const [fullWidth] = React.useState(true);
  const history = useHistory();
  // const navigate = useNavigate();
  const deliveryCheckoutUrl = (window.location.pathname == `/${appState.storeInfo?.slug}/checkout` && appState.deliveryMode) ? true : false;
  const [loading, setLoading] = useState(false);
  const min450 = useMediaQuery("(min-width:450px)");

  const [loginValues, setLoginValues] = useState({
    phoneCode: "965",
    phoneNumber: appState.loginValues?.phoneNumber ? appState.loginValues?.phoneNumber : "",
    fullName: appState.loginValues?.fullName ? appState.loginValues?.fullName : "",
    email: appState.loginValues?.email ? appState.loginValues?.email : "",
  });
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const handleClose = () => {
    setLoginModal(false)
  };

  const handleSkip = () => {
    setLoginSkip(true);
    setLoginModal(false)
  };

  const handleGuestLogin = () => {
    setLoading(true);
    setFreezScreen(true)



    // appDispatch({ type: "setLoginSkip", loginSkip: false });
    // setGuestLoggedIn(true);
    // setLoginModal(false)
    // setGuestModal(true)
    // appDispatch({
    //   type: "SET_LOGIN",
    //   loggedIn: false,
    //   loginToken: '',
    // });
    console.log(appState.loginValues?.phoneNumber, appState.loginValues?.fullName);
    let kuwaitNumbre =  appState.loginValues?.phoneNumber != '' ? formatKuwaitiMobile(appState.loginValues?.phoneNumber) : null;
    console.log(appState.loginValues?.phoneNumber, appState.loginValues?.fullName, kuwaitNumbre, kuwaitNumbre != null);

    if (loginValues?.phoneCode && loginValues?.phoneNumber !== "" && loginValues?.fullName !== "" && kuwaitNumbre != null) {
      setLoginState(loginValues)
      appDispatch({ type: "setLoginSkip", loginSkip: false });
      setGuestLoggedIn(true);
      setLoginModal(false)
      appDispatch({
        type: "SET_LOGOUT",
        loggedIn: false,
      });
      history.push('/address-form')
      setFreezScreen(false)
      setLoading(false);

    } else {
      setFreezScreen(false)
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: kuwaitNumbre == null ? t("Please give correct phone number.")  :(appState.loginValues?.phoneNumber == '' && appState.loginValues?.fullName == '' ) ? t("Phone Number and Full Name is required") : appState.loginValues?.phoneNumber == '' ?  t("Phone Number  is required"): t("Full Name is required"),
      });
    }


  };

  const date = new Date();
  let year = date.getFullYear();

  const updateField = (event) => {
    if (event.target.name === "phoneNumber") {
      if (
        loginValues.phoneNumber.length < 8 ||
       event.nativeEvent.inputType === "deleteContentBackward"
      ) {
        setLoginValues({
          ...loginValues,
          [event.target.name]: event.target.value,
        });
        setLoginState({
          ...loginValues,
          [event.target.name]: event.target.value,
        })
        // console.log(event.target.value)
      }
    } else {
      setLoginValues({
        ...loginValues,
        [event.target.name]: event.target.value,
      });
      setLoginState({
        ...loginValues,
        [event.target.name]: event.target.value,
      })
    }
  };
  
  const handleGuest = () => {

  };
  const phoneLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFreezScreen(true)
    setLoginState(loginValues)
    if (loginValues?.phoneCode && loginValues?.phoneNumber !== "") {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/send/guest/otp`,
          {
            country_code: loginValues.phoneCode,
            number: loginValues.phoneNumber,
            provider: appState?.storeInfo?.branch?.provider,
          }
        );
        setLoading(false);
        if (response.data.data === true) {
          setLoading(false);
          setFreezScreen(false)
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          setLoginModal(false)
          appDispatch({ type: "setOtpModal", otpModal: true });


        } else {
          setLoading(false);
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          setFreezScreen(false)
        }
      } catch (e) {
        setFreezScreen(false)

        setLoading(false);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
      }
    } else {
      setFreezScreen(false)
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: t("Login.plz-fill-required-fields"),
      });
    }
  };

  const guestLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFreezScreen(true)
    setLoginState(loginValues)
    if (loginValues?.phoneCode && loginValues?.phoneNumber !== "" && loginValues?.fullName !== "") {
      if (formatKuwaitiMobile(`${loginValues?.phoneNumber}`)) {
        setFreezScreen(false)
        setGuestLoggedIn(false);
        setLoginModal(false)
        setGuestModal(false)
        history.push('/address-form')
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: t("Number Format is not valid"),
        });
        setFreezScreen(false)
        setLoading(false);

      }
    } else {
      setFreezScreen(false)
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: t("Name and phone number are required for guest."),
      });
    }
  };


  return (
    <>
      <div
        id="mySidenav"
        className={`loginModalMainv2popup col-12 py-3   ${appState.langValue == "ar" ? "loginModalMainv2popupArabic" : " "
          }`}
      >
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 15,
            }}
            open={loading}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}
        <div className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}>
          <div className="row pt-2 mx-0 ">
            <div
              className={`col-md-12 px-0 d-flex  classforPaddinglogo ${"justify-content-between"}`}
            >
              {
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="BackBtn"
                >
                  {appState.langValue === "ar" ? (
                    <ArrowForwardIcon />
                  ) : (
                    <ArrowBackIcon />
                  )}
                </Button>
              }
              <div>
                {appState.langValue === "ar" ? (
                  <Button
                    className="px-5  arabicBtn"
                    variant="contained"
                    onClick={(e) => setLangValue(`en`)}
                  >
                    English
                  </Button>
                ) : (
                  <Button
                    className="px-5  arabicBtn"
                    variant="contained"
                    onClick={(e) => setLangValue(`ar`)}
                  >
                    العربيه
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3 mx-0 ">
            <div className="col-md-12 px-0 d-flex classforPaddinglogo  d-flex aling-items-center justify-content-between">
          <span>
          <h1
            className={`familyManrope800 mt-1  ${appState.langValue === "ar" ? "d-flex" : ""
              }`}
          >
            {t("Login.Verify-Your-Mobile-Number")}
          </h1>
          <p
            className={`familyManrope600 mt-1 textf4f4 ${appState.langValue === "ar" ? "d-flex" : ""
              }`}
          >
            {t("Login.You-will-recieve-a-one-time-password-shortly")}
          </p>
          </span>

          <img src={IMAGES.enabill} alt="enabill-logo" />

          </div>
          
          </div>

          <form onSubmit={phoneLogin}>
            <div
              className={`classforPaddingTextfield ${appState.langValue === "ar" ? "classforArabicPhone" : ""
                }`}
            >
              <div
                className={`row mt-3  ${appState.langValue === "ar" ? "classforArabicPhoneField" : ""
                  }`}
              >
                <div className="col-12">
                  <InputMaskField
                    value={loginValues?.phoneNumber}
                    onChange={updateField}
                    inputProps={{ inputMode: "numeric" }}
                    label={t("Forms.phone-number")}
                    className="mt-2"
                    variant="outlined"
                    type="tel"
                    mask="99999999"
                    id="outlined-basic"
                    name="phoneNumber"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className="inputadornmentImg flagImg"
                            src={IMAGES.kuwaitinput}
                            alt="flag"
                          />{" "}
                          <span className="px-2 textOver mobCode">
                            +965<span className="countryCode"></span>{" "}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                className={`row mt-3  ${appState.langValue === "ar" ? "classforArabicTextField" : ""
                  }`}
              >
                <div className="col-12">
                  <TextField
                    className="mt-1"
                    label={(
                      appState.hotelMode &&
                        checkNameRequired(JSON.parse(getCookie("hotelInfo"))) ||  deliveryCheckoutUrl) 
                        ? t("Forms.fullNameReq")
                        : t("Forms.fullName")
                    }
                    type="text"
                    name="fullName"
                    value={loginValues?.fullName}
                    onChange={updateField}
                    variant="outlined"
                    required={
                      (appState.hotelMode &&
                      checkNameRequired(JSON.parse(getCookie("hotelInfo")))) ||  deliveryCheckoutUrl
                    }
                  />
                </div>
              </div>
              <div
                className={`row mt-3  ${appState.langValue === "ar" ? "classforArabicTextField" : ""
                  }`}
              >
                <div className="col-12">
                  <TextField
                    className="mt-1"
                    label={ appState.deliveryMode && t("Forms.email") }
                    type="email"
                    name="email"
                    value={loginValues?.email}
                    onChange={updateField}
                    variant="outlined"
                  
                  />
                </div>
              </div>
            </div>

            <div className="classforBtnPadding">
              <div className="row my-3">
                <div className="col-12">
                  <Button
                    className=" blackBtn fontSize1rem bgEnabill py-3 textCapitalized familyManrope800 "
                    variant="contained"
                    type="submit"
                  >
                    {loading ? t("Login.redirecting") : appState.deliveryMode && deliveryCheckoutUrl ? t("Continue") : t("Login.Verify")}
                  </Button>
                </div>
              </div>
            </div>
            {appState.storeInfo.allowLogin == "3" && !appState.openBill && !appState.deliveryMode ? (
              <div className="classforBtnPadding">
                <div className="row my-3">
                  <div className="col-12">
                    <p
                      onClick={handleSkip}
                      className="w-100 fontSize0P9rem btnEnabill2  my-0 text-center textCapitalized familyManrope800 cursor-pointer "
                    >
                      {" "}
                      {t("checkOut.Skip")}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {(appState.storeInfo.allowLogin != "1" && appState.deliveryMode && deliveryCheckoutUrl) && (
              <div className="classforBtnPadding">
                <div className="row my-1">
                  <div className="col-12 d-flex justify-content-center align-items-center flex-column textf4f4">
                    <p className="m-0">{t("OR")}</p>
                    <Button 
                    onClick={handleGuestLogin} className="w-100 fontSize0P9rem btnEnabill3   fontSize1rem mt-2 py-3 textCapitalized familyManrope800  "                    
                     variant="contained"
                  >
                    {t("Continue as a Guest")}
                  </Button>
                    {/* <p onClick={handleGuestLogin} className="w-100 fontSize0P9rem btnEnabill2  my-0 text-center  familyManrope800 cursor-pointer ">
                      {t("Continue as a Guest")}a
                    </p> */}
                  </div>
                </div>
              </div>
            )}
          </form>
          {/* //<div className="row mt-2 d-flex justify-content-center">
             // <p className="m-0 text-center">{t("Login.OR")}</p>
           // </div>
            //<div className="classforBtnPadding socialLoginIconOnLargeScreen">
            //<div className="row mt-2 mx-0 ">
              //<div className="col-4 p-0">
                //<FbLogin
                  // fbloginHandleclose={handleClose}
                  // fbloading={loading}
                  // setfbLoading={setLoading}
                  // setAlertMessage={setAlertMessage}
                //  />
               // </div>

                //<div className="col-4 p-0">
                  //<LoginGoogle
                  // loginHandleclose={handleClose}
                  // loading={loading}
                  // setLoading={setLoading}
                  // setAlertMessage={setAlertMessage}
                 // />
                //</div>

                //<div className="col-4 p-0">
                  //<AppleLogin
                  // loginHandleclose={handleClose}
                  // loading={loading}
                  // setLoading={setLoading}
                  // setAlertMessage={setAlertMessage}
                  ///>
                //</div>
              //</div>
            //</div> */}

          <div className="row mt-4 ">
            <div
              className={`col-12 ${appState.langValue === "ar" ? "text-right" : ""
                }`}
            >
              <p className="familyManrope600 notRegisterMain mb-14 ">
                <span>
                  <img className="img-fluid" src={IMAGES.union} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Save-your-cards")}
                </span>
              </p>

              {min450 && <hr />}

              <p className="familyManrope600 notRegisterMain mb-14">
                <span>
                  <img className="img-fluid" src={IMAGES.newsPaper} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Tap-to-re-order")}
                </span>
              </p>
              {min450 && <hr />}
              <p className="familyManrope600 notRegisterMain mb-14">
                <span>
                  <img className="img-fluid" src={IMAGES.giftPaper} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Get-discounts-and-cashbacks")}
                </span>
              </p>
              {min450 && <hr />}
            </div>
          </div>
          <div className="row ">
            <div className={`col-12 ${appState.langValue === "ar" ? "" : ""}`}>
              <p
                className={`familyManrope600 termsAndCondition text-center ${appState.langValue === "ar" ? "direction-ltr" : ""
                  }`}
              >
                <span className="textf9f9">
                  {" "}
                  <DoneIcon /> {t("Forms.Terms-&-Conditions-apply")}
                </span>
              </p>

              <p
                className={`familyManrope rights-reserved m-0 ${appState.langValue === "ar" ? "text-right" : ""
                  }`}
              >
                © {year} {t("Forms.Enabill-All-rights-reserved")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(LoginModalv2Popup);
